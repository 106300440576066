import styled from "styled-components";

const pc = window.screen.width > 529 ? true : false;

const ContenedorOpcion = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4vw;
    padding-top: ${pc ? "6vw" :"17vw"};
    flex-direction: column;
    margin-left: ${pc && "17vw" };

    & h2 {
        font-size: ${pc ? "2.3vw" : "7vw"};
        margin-right: auto;
        font-weight: 700;
    }
`;

export default ContenedorOpcion;