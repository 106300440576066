import { useContext, useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components"
import TarifasYOfertas from "./tarifasYofertas";
import perfilContext from "../../../contexts/perfilHotelContext";
import sessionContext from "../../../contexts/sessionContext";
import Pagina404 from "../../pagina404";
import Reserva from "./reserva";
import { comprobarHotelOVilla } from "../../../config/auth";
import CargandoCont from "../../utils/cargando";
import Modal from "../../utils/modal";

//imagenes
import cargandoImg from "../../../images/cargando.gif";
import flecha from "../../../images/flecha1.png";

const pc = window.screen.width > 529 ? true : false;

const PerfilContenedor = styled.div`
    display: flex;
    padding-top: ${pc ? "4.5vw" : "17vw"};
    align-items: center;
    justify-content: center;
`;

const Detalles = styled.div`
    display:flex;
    flex-direction:column;
    width: 98vw;
    padding-left: ${pc && "19vw"};
    margin-top: ${pc ? "1.3vw" : ""};
    padding-left: ${!pc && "2vw"};
    margin-bottom: ${!pc && "5vh"};

    & h3 {
        font-size: ${pc ? "2vw" : ""};
        font-weight: 600;
    }
    & p {
        font-weight: 400;
    }
`;

const ImagenesCont = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: ${!pc && "center"};
`;

const Imagen = styled.div`
    width: ${pc ? "10vw" : "19vw"};
    height: ${pc ? "10vw" : "19vw"};
    cursor: pointer;
    margin: 2vw;
    background-image: url(${e => e.image && e.image});
    background-size: cover;
`;

const ContImg = styled.div`
    width: ${pc ? "60vw": "100vw"};
    height: ${pc ? "100vh" : "75vh"};
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: #000;
`;

const ImagenModal = styled.img`
    object-fit: contain;
    max-width: 100%;
    height: 100%;
    background-color: #000;
`;

const Flecha = styled.button`
    border: none;
    width: ${pc ? "5vw" : "15vw"};
    height: ${pc ? "5vw" : "15vw"};
    position: ${pc ? "relative" : "absolute"};
    bottom: ${!pc && "8.5%"};
    z-index: 1000;
    left:${e => !e.right && !pc && "1%"};
    right: ${e => e.right && !pc && "1%"};
    cursor: pointer;
    border-radius: 90%;
    margin-left: ${e => e.right && pc ? "1.8vw" : "0"};
    margin-right: ${e => !e.right && pc ? "1.8vw" : "0"};
    background-color: #004aad;
    display: flex;
    align-items: center;
    justify-content: center;

    & img {
        transform: rotate(${e => e.right && "180deg"});
        width: ${pc ? "3.5vw" : "9vw"};
    }
`;

const DescripcionText = styled.div`
    width: 65vw;
    margin-bottom: ${pc ? "2vw" : ""};
`;

export default function PerfilVilla (){

    const { name } = useParams();
    const {session} = useContext(sessionContext);

    const navigate = useNavigate();
    const search = useLocation().search;

    let [hotel, setHotel] = useState([undefined, {}]);

    const { setPerfil, perfil } = useContext(perfilContext);


    let [tarifas, setTarifas] = useState(undefined);
    let [hotelId, setHotelId] = useState("");
    let [imagenes, setImagenes] = useState([]);
    let [imagen, setImagen] = useState([]);
    let [descripcion, setDescripcion] = useState([]);

    async function comprobarVilla () {
        const hotel = await comprobarHotelOVilla(name, "villas");

        if (typeof hotel == "object"){
            const newPerfil = perfil;
            setHotelId({id:hotel.id, name:hotel.nombre});
            newPerfil.name = hotel.nombre;
            newPerfil.imgUrl = hotel.fotoPerfil ? hotel.fotoPerfil : "";
            setPerfil(JSON.parse(JSON.stringify(newPerfil)));
            setHotel([true, hotel]);
            if (hotel.ofertas){
                if (hotel.ofertas.length > 0) {
                    setTarifas(hotel.ofertas);
                } else {
                    setTarifas([]);
                }
            } else {
                setTarifas([]);
            }
            if (hotel.imagenes) {
                const newImagenes = imagenes;
                Object.keys(hotel.imagenes).map((value)=>{
                    newImagenes.push(hotel.imagenes[value]);
                });
                setImagenes(JSON.parse(JSON.stringify(newImagenes)));
            }
            if (hotel.descripcion) {
                setDescripcion(hotel.descripcion.split("\n"));
            }
        } else {
            if (hotel == "No") {
                setHotel([false, {}]);
            } else if (hotel == "error") {
                setHotel(["Sucedio un error", {}]);
            }
            setPerfil({name:"Villa no encontrada", imgUrl:""});
        }
    }

    useEffect(()=>{
        comprobarVilla();
    },[]);

    return <PerfilContenedor>
        <Modal imagen={true} visible={imagen[1]} setVisible={()=>setImagen([])}>
            <Flecha style={{opacity:imagen[0] > 0 ? "1" : "0.35"}} onClick={()=>imagen[0] > 0 && setImagen([imagen[0]-1, imagenes[imagen[0]-1]])}>
                <img src={flecha} />
            </Flecha> 
            <ContImg>
                <ImagenModal src={imagen[1]}/>
            </ContImg>
            <Flecha style={{opacity:imagen[0] == imagenes.length-1 ? "0.35" : "1"}} onClick={()=>imagen[0] < imagenes.length-1 && setImagen([imagen[0]+1, imagenes[imagen[0]+1]]) } right={true}>
                <img src={flecha} />
            </Flecha>
        </Modal>    
        { hotel[0] === undefined ?
            <CargandoCont>
                <img src={cargandoImg} />
            </CargandoCont> 
        : hotel[0] === true ? 
            <div>
                {!search ? tarifas !== undefined &&
                    <TarifasYOfertas villa={perfil.name} tarifas={tarifas}/>
                : search == "?tab=fotos-y-detalles" ?
                    <Detalles>
                        <h3>Fotos</h3>
                        <ImagenesCont>
                            { imagenes.map((value, index)=>(
                                <Imagen key={index} image={value} onClick={()=>setImagen([index, value])} />
                            ))}
                        </ImagenesCont>
                        { imagenes.length < 1 &&
                            <p style={{color:"#6F6F6F"}}>No hay fotos</p>
                        }
                        <h3>Descripción</h3>
                        <DescripcionText>
                            {descripcion.length > 0 ?
                                descripcion.map((value, index)=>value == "" ? (<br key={index} />) : (<p key={index} style={{color:"#6F6F6F"}}>{value}</p>))
                                :
                                <p>No hay descripción</p>
                            }
                        </DescripcionText>
                    </Detalles>
                : search == "?tab=solicitar-reserva" || search == "?tab=modificar-reserva" ? tarifas !== undefined &&
                    <Reserva tarifas={tarifas} id={hotelId.id} name={hotelId.name} />
                : 
                    <Pagina404/>
                }
            </div>
        : hotel[0] === false ? 
            <div>Villa no encontrada</div>
        : hotel[0] == "error" &&
            <div>Sucedio un error</div>
       }
    </PerfilContenedor>
}