import React, { useState, useContext, useEffect } from "react";
import { slideInLeft, slideOutLeft, fadeIn, fadeOut} from "react-animations";
import { useLocation, useNavigate } from "react-router-dom";
import "../../styles/navbar.css";
import styled, {keyframes} from "styled-components";
import { actualizarImgPerfil, cerrarSession } from "../../config/auth";
import sessionContext from "../../contexts/sessionContext";
import hotelContext from "../../contexts/perfilHotelContext";
import PantallaCarga from "../pantallaDeCarga";
import Modal from "../utils/modal";
import CargandoCont from "../utils/cargando";

// imagenes
import logo from "../../images/logoTurisFreelance11.webp";
import logo1 from "../../images/logoo.webp";
import logoAncho from "../../images/logoAncho.png";
import flecha from "../../images/flecha1.png";
import hotelSinImg from "../../images/hotelSinImg.png";
import camara from "../../images/camara.png";
import home from "../../images/home.png";
import contacto from  "../../images/contacto.webp";
import login from "../../images/login.png";
import cargandoImg from "../../images/cargando.gif";
import user from "../../images/imgUserPerfil.png";


const pc = window.screen.width > 529 ? true : false;

const OpcionesCont = styled.div`
  animation: 0.4s ${props => props.setAnimacion ? keyframes(slideInLeft) : keyframes(slideOutLeft)};
  background-color: #004aad;
  display: flex;
  flex-direction:column;
  height: 100%;
  width: ${window.screen.width > 529 ? "17vw" : "65%"};
  align-items: center;
  z-index:500;
`;

const FondoQuitarOpciones = styled.div`
    position: absolute;
    animation: 0.4s ${props => props.setAnimacion ? keyframes(fadeIn) : keyframes(fadeOut)};
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height:100%;
    display: ${props => props.display};
    display: ${window.screen.width > 529 && "none"};
  `;

const BtnDevolver = styled.div`
  padding: 1vw;
  width: 11.4%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  & img {
    width: 100%;
  }

  @media (min-width: 530px) {
    width: 4vw;
  }
`;

const Msg = styled.div`
  position: fixed;
  display: ${props => props.activo ? "flex" : "none"};
  width: 100%;
  align-items: center;
  justify-content: center;
  bottom: 0;
  left: 0;

  & div {
    padding: ${pc ? "1vw 2vw" : "2vw 3vw"};
    margin-bottom: 3px;
    text-align: center;
    border-top-right-radius: 0.4vw;
    border-top-left-radius: 0.4vw;
    background-color: ${props => props.error ? "#26AC20" : "#eb1a1a"};
    color: #fff;
  }
`;


function Navbar({opcionProp, children}) {

  const navigate = useNavigate();
  const location = useLocation();

  const url = location.search;
  const urlFull = location.pathname;
  const urlFull1 = urlFull.charAt(1) + urlFull.charAt(2) + urlFull.charAt(3) + urlFull.charAt(4) + urlFull.charAt(5) + urlFull.charAt(6) + urlFull.charAt(7) + urlFull.charAt(8) + urlFull.charAt(9) + urlFull.charAt(10);
  const urlHotel = urlFull.charAt(1) + urlFull.charAt(2) + urlFull.charAt(3) + urlFull.charAt(4) + urlFull.charAt(5);

  let [msgValImg, setMsgValImg] = useState([false, ""]);
  let [cargando, setCargando] = useState(false);

  const {session, setSession} = useContext(sessionContext);
  const {perfil} = useContext(hotelContext);
  
  let [btnOpciones, setBtnOpciones] = useState(undefined);
  let [btnOpenMenu, setBtnOpenMenu] = useState(true);

  let [screenCarga, setScreenCarga] = useState(false);

  const tab = "?tab=";

  const tabSoli = "?tab=solicitudes="; 

  function validarUrl (){
    let result = false;
    const tab = "?tab=solicitudes="
    const searchs = ["traslados", "grupos", "seguros", "reservasVillas", "reservasHoteles", "vuelos"];
    
    if (location.search == "?tab=solicitudes") {
        result = true;
    } else {
        searchs.map((value)=>{
            if (tab+value == location.search)  {
                result = true;
            }
        });
    }

    return result;
  };

  function toggleMenuOpciones(){
    if (window.screen.width < 530){
      setBtnOpenMenu(false);
      if (btnOpciones == undefined){
        setBtnOpciones(true);
        setTimeout(()=>{
          setBtnOpenMenu(true);
        },350);
      } else if (btnOpciones == true) {
        setBtnOpciones(false);
        setTimeout(()=>{
          setBtnOpciones(undefined);
          setBtnOpenMenu(true);
        }, 350);
      }
    }
  }

  async function entrarOsalir(){
    if (session.session) {
        setScreenCarga(true);
        navigate("/");
        await cerrarSession();
        toggleMenuOpciones();
        setTimeout(()=>{
          setScreenCarga(false);
        },300);
    } else {
      navigate("/login");
      toggleMenuOpciones();
    }
  }

  
  async function updateImgPerfil (img){
    setCargando(true);
    const result = await actualizarImgPerfil(img);
    if (result[0]) {

      const newSession = session;
      newSession.user.imgPerfil = URL.createObjectURL(img);
      setSession(JSON.parse(JSON.stringify(newSession)));
      setMsgValImg([true, "Foto actualizada correctamente!"]);
      setTimeout(()=>{setMsgValImg([false, ""])}, 2000);

    } else {

      setMsgValImg([false, result[1]]);
      setTimeout(()=>{setMsgValImg([false, ""])}, 3500);

    }
    setCargando(false);
  }

  useEffect(()=>{
    if (urlFull1 == "plataforma" || urlFull == "/admin") {
      if (session.session === false) {
        navigate("/login");
      } else {
          if (urlFull == "/admin") {
            if (session.admin === false) {
              navigate("/");
            }
          }
      }
    };

    if (urlHotel == "hotel" || urlHotel == "villa") {
      if (!session.session) {
        if (perfil.name) {
          if ((perfil.name !== "Hotel no encontrado" && urlHotel == "hotel") || (perfil.name !== "Villa no encontrada" && urlHotel == "villa")) {
            if (urlFull.replaceAll("%20", " ")+url == "/"+urlHotel+"/"+perfil.name+"?tab=solicitar-reserva" || !url || urlFull.replaceAll("%20", " ")+url == "/"+urlHotel+"/"+perfil.name+"?tab=modificar-reserva") {
              navigate("/"+urlHotel+"/"+perfil.name+"?tab=fotos-y-detalles");
            }
          }
        }
      }
    }
    
  });

  useEffect(()=>{
    if (window.screen.width > 529) {
      setBtnOpciones(true);
      setBtnOpenMenu(true);
    }
  },[]);

  return (
    <div style={{display:"flex", flexDirection:"column"}}>
      <Modal visible={cargando} cargando={true} setVisible={()=>{}}>
        <div style={{display:"flex", alignItems:"center", flexDirection:"column", justifyContent:"center"}}>
          <p>Actualizando tu foto de perfil</p>
          <CargandoCont style={{margin:0, marginTop:"0.2vw"}}><img src={cargandoImg}/></CargandoCont>
        </div>
      </Modal>
      <div className="navbarCont" style={{display:urlFull == "/login" ? "none" : "flex"}}>
        <div className="logo" style={{marginLeft:pc && session.session && "18%"}}>
          <img onClick={()=>navigate("/")} src={window.screen.width < 530 ? logo : logoAncho}/>
        </div>
        { urlFull1 == "plataforma" ?
          <BtnDevolver onClick={()=>navigate(-1)}>
            <img src={flecha}/>
          </BtnDevolver>
          : urlHotel == "hotel" ?
            session.session ?
              <BtnDevolver onClick={()=>navigate(-1)}>
                <img src={flecha}/>
              </BtnDevolver>
            :
            <div onClick={()=>toggleMenuOpciones()} className="btnMostrarOpciones animationOpen">
              <div className={ btnOpciones ? "lineaBtn lineaTop" : "lineaBtn"}/>
              <div className={btnOpciones ? "lineaBtn lineaBottom" : "lineaBtn"}/>
              <div style={{marginBottom:0, opacity:btnOpciones ? "0" : "1"}} className="lineaBtn"/>
            </div>
            :
          <div onClick={()=>toggleMenuOpciones()} className="btnMostrarOpciones animationOpen">
            <div className={ btnOpciones ? "lineaBtn lineaTop" : "lineaBtn"}/>
            <div className={btnOpciones ? "lineaBtn lineaBottom" : "lineaBtn"}/>
            <div style={{marginBottom:0, opacity:btnOpciones ? "0" : "1"}} className="lineaBtn"/>
          </div>
        }
      </div>
    
      <input style={{display:"none"}} type="file" accept=".jpg, .png, .jpeg" name="image" onChange={(e)=>updateImgPerfil(e.target.files[0])} className="btnImg"/>

      <div style={{display:btnOpciones === undefined ? "none" : "flex", zIndex: session.session && "1200"}} className="opciones">
            <OpcionesCont setAnimacion={btnOpciones}>
                
                <div className="logoOpciones" style={{marginTop:perfil.name ? !session.session ? pc ? "3vw" :"16vw" : undefined : undefined}}>
                  { session.session ?
                    <div className="contImgPerfil" style={{ marginTop: session.session && pc && "0.4vw", backgroundImage:`url("${urlHotel != "hotel" ? (session.user && session.user.imgPerfil) ? session.user.imgPerfil : user : (perfil.imgUrl) ? perfil.imgUrl : hotelSinImg}")`}}>
                      { urlHotel != "hotel" &&
                        <img style={{top: session.session && pc && "4vw"}} onClick={()=>document.querySelector(".btnImg").click()} className="imgCamara" src={camara}/>
                      }
                    </div>
                    :
                    urlHotel != "hotel" && 
                      <img style={{display:"none", marginBottom:"6vw"}} onClick={()=>{navigate("/"); toggleMenuOpciones()}} src={logo1}/>
                  }
                  { session.session &&
                    <p className="txtNameUser">
                      {urlHotel == "hotel" ? perfil.name : session.user.name ? session.user.name : "Sin nombre" }
                    </p>
                  }
                </div>
              { session.session && <div style={{marginBottom:window.screen.width > 529 && "1vw"}} className="opcionesUserCont">
                  <div style={{marginBottom:0}} onClick={()=>navigate("/")} className={urlFull == "/" ? "opcionUser opcionActive" : "opcionUser"}>
                    <p>Pagina principal</p>
                  </div>
                </div>
                }
                {  session.admin ? validarUrl() ?
                    <div className="opcionesUserCont">
                      <div onClick={()=>{navigate(tabSoli+"reservasHoteles"); toggleMenuOpciones()}} className={url == tabSoli+"reservasHoteles" ? "opcionActive opcionUser" : "opcionUser"}>
                        <p>Reservas de Hoteles</p>
                      </div>
                      <div onClick={()=>{navigate(tabSoli+"reservasVillas"); toggleMenuOpciones()}} className={url == tabSoli+"reservasVillas" ? "opcionActive opcionUser" : "opcionUser"}>
                        <p>Reservas de Villas</p>
                      </div>
                      <div onClick={()=>{navigate(tabSoli+"traslados"); toggleMenuOpciones()}} className={url == tabSoli+"traslados" ? "opcionActive opcionUser" : "opcionUser"}>
                        <p>Traslados</p>
                      </div>
                      <div onClick={()=>{navigate(tabSoli+"seguros"); toggleMenuOpciones()}} className={url == tabSoli+"seguros" ? "opcionActive opcionUser" : "opcionUser"}>
                        <p>Seguros</p>
                      </div>
                      <div onClick={()=>{navigate(tabSoli+"grupos"); toggleMenuOpciones()}} className={url == tabSoli+"grupos" ? "opcionActive opcionUser" : "opcionUser"}>
                        <p>Grupos</p>
                      </div>
                      <div onClick={()=>{navigate(tabSoli+"vuelos"); toggleMenuOpciones()}} className={url == tabSoli+"vuelos" ? "opcionActive opcionUser" : "opcionUser"}>
                        <p>Vuelos</p>
                      </div>
                    </div>
                  :
                    <div className="opcionesUserCont">
                      <div onClick={()=>{navigate("/admin?tab=usuarios"); toggleMenuOpciones()}} className={url == tab+"usuarios" ? "opcionActive opcionUser" : "opcionUser"}>
                        <p>Usuarios</p>
                      </div>
                      <div onClick={()=>{navigate("/admin?tab=ofertas"); toggleMenuOpciones()}} className={url == tab+"ofertas" ? "opcionActive opcionUser" : "opcionUser"}>
                        <p>Ofertas</p>
                      </div>
                      <div onClick={()=>{navigate("/admin?tab=hoteles"); toggleMenuOpciones()}} className={url == tab+"hoteles" ? "opcionActive opcionUser" : "opcionUser"}>
                        <p>Hoteles</p>
                      </div>
                      <div onClick={()=>{navigate("/admin?tab=villas"); toggleMenuOpciones()}} className={url == tab+"villas" ? "opcionActive opcionUser" : "opcionUser"}>
                        <p>Villas</p>
                      </div>
                      <div onClick={()=>{navigate("/admin?tab=seguros"); toggleMenuOpciones()}} className={url == tab+"seguros" ? "opcionActive opcionUser" : "opcionUser"}>
                        <p>Seguros</p>
                      </div>
                      <div onClick={()=>{navigate("/admin?tab=solicitudes"); toggleMenuOpciones()}} className={url == tab+"solicitudes" ? "opcionActive opcionUser" : "opcionUser"}>
                        <p>Solicitudes</p>
                      </div>
                    </div>
                  : session.session && (
                    (urlFull == "/plataforma/ofertas" || urlFull == "/" || urlFull == "/plataforma/ofertas/" || urlFull == "/plataforma/perfil") ?
                    <div className="opcionesUserCont">
                      <div onClick={()=>{navigate("/plataforma/ofertas"); toggleMenuOpciones()}} className={urlFull == "/plataforma/ofertas" ? "opcionUser opcionActive" : "opcionUser"}>
                        <p>Ofertas</p>
                      </div>
                      <div onClick={()=>{navigate("/plataforma/hoteles"); toggleMenuOpciones()}} className="opcionUser">
                        <p>Hoteles</p>
                      </div>
                      <div onClick={()=>{navigate("/plataforma/tarifario"+tab+"hoteles"); toggleMenuOpciones()}} className="opcionUser">
                        <p>Tarifario</p>
                      </div>
                      <div onClick={()=>{navigate("/plataforma/villas"); toggleMenuOpciones()}} className="opcionUser">
                        <p>Villas</p>
                      </div>
                      <div onClick={()=>{navigate("/plataforma/vuelos"); toggleMenuOpciones()}} className="opcionUser">
                        <p>Vuelos</p>
                      </div>
                      <div onClick={()=>{navigate("/plataforma/traslados"); toggleMenuOpciones()}} className="opcionUser">
                        <p>Traslados</p>
                      </div>
                      <div onClick={()=>{navigate("/plataforma/seguros-de-viajes"); toggleMenuOpciones()}} className="opcionUser">
                        <p>Seguros de viajes</p>
                      </div>
                      <div onClick={()=>{navigate("/plataforma/grupos"); toggleMenuOpciones()}} className="opcionUser">
                        <p>Grupos</p>
                      </div>
                      <div onClick={()=>{navigate("/plataforma/mis-ventas"); toggleMenuOpciones()}} className="opcionUser">
                        <p>Mis ventas</p>
                      </div>
                      <div onClick={()=>{navigate("/plataforma/perfil"); toggleMenuOpciones()}} className={urlFull == "/plataforma/perfil" ? "opcionUser opcionActive" : "opcionUser"}>
                        <p>Mi cuenta</p>
                      </div>
                    </div>
                    : urlFull == "/plataforma/hoteles" ?
                      <div className="opcionesUserCont">
                        <div onClick={()=>{navigate("/plataforma/hoteles"); toggleMenuOpciones()}} className={ urlFull == "/plataforma/hoteles" && !url  ? "opcionActive opcionUser" : "opcionUser"}>
                            <p>Hoteles</p>
                        </div>
                        <div onClick={()=>{navigate("/plataforma/tarifario"+tab+"hoteles"); toggleMenuOpciones()}} className="opcionUser">
                            <p>Tarifario</p>
                        </div>
                        <div onClick={()=>{navigate("/plataforma/hoteles?tab=mis-solicitudes"); toggleMenuOpciones()}} className={url == tab+"mis-solicitudes" ? "opcionActive opcionUser" : "opcionUser"}>
                            <p>Mis solicitudes</p>
                        </div>
                      </div>
                      : urlFull == "/plataforma/villas" ?
                      <div className="opcionesUserCont">
                        <div onClick={()=>{navigate("/plataforma/villas"); toggleMenuOpciones()}} className={ urlFull == "/plataforma/villas" && !url  ? "opcionActive opcionUser" : "opcionUser"}>
                            <p>Villas</p>
                        </div>
                        <div onClick={()=>{navigate("/plataforma/villas?tab=mis-solicitudes"); toggleMenuOpciones()}} className={url == tab+"mis-solicitudes" ? "opcionActive opcionUser" : "opcionUser"}>
                            <p>Mis solicitudes</p>
                        </div>
                      </div>
                      : urlFull == "/plataforma/vuelos" ?
                          <div className="opcionesUserCont">
                            <div onClick={()=>{navigate("plataforma/vuelos"); toggleMenuOpciones()}} className={ urlFull == "/plataforma/vuelos" && !url ? "opcionActive opcionUser" : "opcionUser"}>
                              <p>Solicitar cotización</p>
                            </div>
                            <div onClick={()=>{toggleMenuOpciones(); navigate("plataforma/vuelos"+tab+"mis-solicitudes")}} className={ url == tab+"mis-solicitudes" ? "opcionActive opcionUser" : "opcionUser"}>
                              <p>Mis solicitudes</p>
                            </div>
                          </div>
                        : urlFull == "/plataforma/traslados" ?
                          <div className="opcionesUserCont">
                            <div onClick={()=>{toggleMenuOpciones(); navigate("plataforma/traslados")}} className={ urlFull == "/plataforma/traslados" && !url ? "opcionActive opcionUser" : "opcionUser"}>
                              <p>Solicitar cotización</p>
                            </div>
                            <div onClick={()=>{toggleMenuOpciones(); navigate("plataforma/traslados"+tab+"mis-solicitudes")}} className={ url == tab+"mis-solicitudes" ? "opcionActive opcionUser" : "opcionUser"}>
                              <p>Mis solicitudes</p>
                            </div>
                          </div>
                        : urlFull == "/plataforma/seguros-de-viajes" ?
                          <div className="opcionesUserCont">
                            <div onClick={()=>{toggleMenuOpciones(); navigate("plataforma/seguros-de-viajes")}} className={ urlFull == "/plataforma/seguros-de-viajes" && !url ? "opcionActive opcionUser" : "opcionUser"}>
                              <p>Euroschengen</p>
                            </div>
                            <div onClick={()=>{toggleMenuOpciones(); navigate("/plataforma/seguros-de-viajes"+tab+"platinum")}} className={url == tab+"platinum" ? "opcionActive opcionUser" : "opcionUser"}>
                              <p>Platinum</p>
                            </div>
                            <div onClick={()=>{toggleMenuOpciones(); navigate("/plataforma/seguros-de-viajes"+tab+"gold")}} className={url == tab+"gold" ? "opcionActive opcionUser" : "opcionUser"}>
                              <p>Gold</p>
                            </div>
                            <div onClick={()=>{toggleMenuOpciones(); navigate("/plataforma/seguros-de-viajes"+tab+"silver")}} className={url == tab+"silver" ? "opcionActive opcionUser" : "opcionUser"}>
                              <p>Silver</p>
                            </div>
                            <div onClick={()=>{toggleMenuOpciones(); navigate("/plataforma/seguros-de-viajes"+tab+"plus")}} className={url == tab+"plus" ? "opcionActive opcionUser" : "opcionUser"}>
                              <p>Plus</p>
                            </div>
                            <div onClick={()=>{toggleMenuOpciones(); navigate("/plataforma/seguros-de-viajes"+tab+"national")}} className={url == tab+"national" ? "opcionActive opcionUser" : "opcionUser"}>
                              <p>National</p>
                            </div>
                            <div onClick={()=>{toggleMenuOpciones(); navigate("/plataforma/seguros-de-viajes"+tab+"student")}} className={url == tab+"student" ? "opcionActive opcionUser" : "opcionUser"}>
                              <p>Student</p>
                            </div>
                            <div onClick={()=>{toggleMenuOpciones(); navigate("/plataforma/seguros-de-viajes"+tab+"mis-solicitudes")}} className={url == tab+"mis-solicitudes" ? "opcionActive opcionUser" : "opcionUser"}>
                              <p>Mis solicitudes</p>
                            </div>
                          </div>
                        : urlFull == "/plataforma/grupos" ?
                          <div className="opcionesUserCont">
                            <div onClick={()=>{toggleMenuOpciones(); navigate("plataforma/grupos")}} className={ urlFull == "/plataforma/grupos" && !url ? " opcionActive opcionUser" : "opcionUser"}>
                              <p>Solicitar cotización</p>
                            </div>
                            <div onClick={()=>{toggleMenuOpciones(); navigate("plataforma/grupos"+tab+"mis-solicitudes")}} className={ url == tab+"mis-solicitudes" ? " opcionActive opcionUser" : "opcionUser"}>
                              <p>Mis solicitudes</p>
                            </div>
                          </div>
                        : urlFull == "/plataforma/mis-ventas" ?
                          <div className="opcionesUserCont">
                            <div onClick={()=>{toggleMenuOpciones(); navigate("plataforma/mis-ventas")}} className={ urlFull == "/plataforma/mis-ventas" && !url ? " opcionActive opcionUser" : "opcionUser"}>
                              <p>Hoteles</p>
                            </div>
                            <div onClick={()=>{toggleMenuOpciones(); navigate("plataforma/mis-ventas"+tab+"reservasVillas")}} className={ url == tab+"reservasVillas" ? " opcionActive opcionUser" : "opcionUser"}>
                              <p>Villas</p>
                            </div>
                            <div onClick={()=>{toggleMenuOpciones(); navigate("plataforma/mis-ventas"+tab+"grupos")}} className={ url == tab+"grupos" ? " opcionActive opcionUser" : "opcionUser"}>
                              <p>Grupos</p>
                            </div>
                            <div onClick={()=>{toggleMenuOpciones(); navigate("plataforma/mis-ventas"+tab+"vuelos")}} className={ url == tab+"vuelos" ? " opcionActive opcionUser" : "opcionUser"}>
                              <p>Vuelos</p>
                            </div>
                            <div onClick={()=>{toggleMenuOpciones(); navigate("plataforma/mis-ventas"+tab+"seguros-de-viajes")}} className={ url == tab+"seguros-de-viajes" ? " opcionActive opcionUser" : "opcionUser"}>
                              <p>Seguros de viajes</p>
                            </div>
                            <div onClick={()=>{toggleMenuOpciones(); navigate("plataforma/mis-ventas"+tab+"transporte")}} className={ url == tab+"transporte" ? " opcionActive opcionUser" : "opcionUser"}>
                              <p>Traslados</p>
                            </div>
                            <div onClick={()=>{toggleMenuOpciones(); navigate("plataforma/mis-ventas"+tab+"total-ganancias")}} className={ url == tab+"total-ganancias" ? " opcionActive opcionUser" : "opcionUser"}>
                              <p>Total ganancias</p>
                            </div> 
                          </div>
                        : urlFull == "/plataforma/tarifario" ?
                          <div className="opcionesUserCont">
                            <div onClick={()=>{toggleMenuOpciones(); navigate("/plataforma/tarifario"+tab+"hoteles")}} className={ url == tab+"hoteles" ? "opcionActive opcionUser" : "opcionUser"}>
                              <p>Hoteles</p>
                            </div>
                            <div onClick={()=>{toggleMenuOpciones(); navigate("/plataforma/tarifario"+tab+"villas")}} className={ url == tab+"villas" ? " opcionActive opcionUser" : "opcionUser"}>
                              <p>Villas</p>
                            </div>
                          </div>
                        : ((urlHotel == "hotel" && perfil.name !== "Hotel no encontrado") || (urlHotel == "villa" && perfil.name !== "Villa no encontrada")) &&
                        <div className="opcionesUserCont">
                          <div onClick={()=>{toggleMenuOpciones(); navigate(urlFull)}} className={ (urlHotel == "hotel" || urlHotel == "villa") && !url ? " opcionActive opcionUser" : "opcionUser"}>
                            <p>Tarifas & Ofertas</p>
                          </div>
                          <div onClick={()=>{toggleMenuOpciones(); navigate(urlFull+tab+"fotos-y-detalles")}} className={ url == tab+"fotos-y-detalles" ? " opcionActive opcionUser" : "opcionUser"}>
                            <p>Fotos y detalles</p>
                          </div>
                          { url != "?tab=modificar-reserva" && <div onClick={()=>{toggleMenuOpciones(); navigate(urlFull+tab+"solicitar-reserva")}} className={ url == tab+"solicitar-reserva" ? " opcionActive opcionUser" : "opcionUser"}>
                            <p>Solicitar reserva</p>
                          </div>}
                        </div>
                        
                    )
                }

                { ((urlHotel == "hotel" && !session.session && perfil.name !== "Hotel no encontrado") || (urlHotel == "villa" && !session.session && perfil.name !== "Villa no encontrada")) &&
                    <div className="logoOpciones">
                      <div style={{backgroundImage:`url("${perfil.imgUrl ? perfil.imgUrl : hotelSinImg}")`}} className="contImgPerfil"></div>
                      <p className="txtNameUser">{perfil.name}</p>
                    </div>
                }

                { ((urlHotel == "hotel" && !session.session && perfil.name !== "Hotel no encontrado") || (urlHotel == "villa" && !session.session && perfil.name !== "Villa no encontrada")) &&
                  <div className="opcionesUserCont">
                    <div onClick={()=>{toggleMenuOpciones(); navigate(urlFull+tab+"fotos-y-detalles")}} className={ url == tab+"fotos-y-detalles" ? " opcionActive opcionUser" : "opcionUser"}>
                      <p>Fotos y detalles</p>
                    </div>
                  </div>
                }

                    <div className="opcionesBtnCont contOpcionesOff" style={{marginTop:session.session ? "auto" : !pc && "auto" , marginBottom:session.session ? "8vw" : !pc && "8vw"}} >
                      { !session.session &&
                        <div style={{display:"flex", flexDirection:"column"}}>
                          <div></div><div onClick={()=>{navigate("/"); toggleMenuOpciones()}} className="btnOpcion">
                            <img src={home}/>
                            <p>Inicio</p>
                          </div>
                          <div onClick={()=>{navigate("/#contact"); toggleMenuOpciones()}} className="btnOpcion">
                            <img src={contacto}/>
                            <p>Contacto</p>
                          </div>
                        </div>
                      }
                      <div style={{height: pc && "2vw"}} onClick={()=>entrarOsalir()} className="btnOpcion">
                        <img style={{width:pc && "1.3vw"}} src={login}/>
                        <p style={{fontSize: pc && "13px" }}>{session.session ? "Salir" : "Entrar"}</p>
                      </div>
                    </div> 

              <Msg activo={msgValImg[1]} error={msgValImg[0]}><div><p>{msgValImg[1]}</p></div></Msg>
            </OpcionesCont>

            <FondoQuitarOpciones display={btnOpciones ? "flex" : "none"} setAnimacion={btnOpciones} onClick={()=>toggleMenuOpciones()}/>
          </div>
      { session.session &&
        !pc && 
          <div onClick={(()=>toggleMenuOpciones())} style={{display:btnOpenMenu ? cargando ? "none" : "flex" : "none", left:btnOpciones === true ? "56vw" : "0", transform: btnOpciones && "rotate(180deg)"}} className="btnToggleMenuCont">
            <div className="toggleMenu" style={{borderLeft:btnOpciones ? "3vh solid #fff" : "3vh solid #004aad"}}/>
          </div>   
      }
      { screenCarga &&
        <PantallaCarga/>
      }      
      {children}
    </div>
  );
}

export default Navbar;
