import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const configuracionFirebase = {
    apiKey: "AIzaSyA_MQy5czLFIXB1bUE9YHxhPuqiZF0Kcww"
}

initializeApp(configuracionFirebase);

export const auth = getAuth();

