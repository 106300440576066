import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import ContenedorOpcion from "../contenedorOpcion";
import sessionContext from "../../../contexts/sessionContext";
import {verificarEmail} from "../../login";
import { actualizarPerfil, getInfoUser } from "../../../config/auth";
import { Formulario, TopFormulario, FormularioCont, Columna, BtnEnviar, Input, Select, SpanValidacion } from "../../utils/formulario";

//imagenes
import incorrecto from "../../../images/incorrecto.png";
import cargandoImg1 from "../../../images/cargando1.gif";
import cargandoImg from "../../../images/cargando.gif";
import correcto from "../../../images/correcto.png";

const pc = window.screen.width > 529 ? true : false;

const MiCuentaContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
`

const MsgValidacion = styled.div`
    background-color: ${props => props.error ? "#26AC20" : "#eb1a1a"};
    border-radius: ${pc ? "0.3vw" : "3vw"};
    padding: ${pc ? "0.7vw" : "3vw"};
    display: ${props => props.activo ? "flex" : "none"};
    align-items: center;
    margin-bottom: ${pc && "1.5vw"};
    width: ${pc && "50%"};

    & p {
        color:#fff;
        font-weight: 500;
        font-size: ${pc ? "1.2vw" : " 4.2vw"};
        margin-left: ${pc ? "0.5vw" : "2vw"};
    }

    & img {
        width: ${pc ? "3.5%" : "8%"};
    }
`;

const CargandoDiv = styled.div`
    width: ${pc ? "3vw" : "10vw"};

    & img {
        width: 100%;
    }
`;


export default function MiCuenta () {

    const {session, setSession} = useContext(sessionContext);

    let [inputsDefault, setInputsDefault] = useState({nombre:["", ""], documentoIdentidad:["", ""], telefono:["", ""], email:["", ""], ciudad:["", ""], direccion:["", ""], nombreBanco:["", ""], numeroCuenta:["", ""]});
    let [inputs, setInputs] = useState(inputsDefault);
    let [msgVal, setMsgVal] = useState("");
    let [cargando, setCargando] = useState(false);
    let [cargandoInputs, setCargandoInputs] = useState(true);
    let [datos, setDatos] = useState(true);

    function updateValueInput (tipo, valor) {
        const newInputs = JSON.parse(JSON.stringify(inputs));
        newInputs[tipo][0] = valor;

        setInputs(newInputs);
    }

    function comprobarSiEsIgual (){
        const inputsArray = Object.keys(inputs);
        let result = [];
        inputsArray.map((value)=>{
            if (inputs[value][0] != inputsDefault[value][0]) {
                result.push(value);
            }
        });

        return result.length > 0 ? result : false;
    }

    function validarForm (){
        setMsgVal("");
        setCargando(true);
        const newInputs = JSON.parse(JSON.stringify(inputs));
        const inputsActualizados = comprobarSiEsIgual();

        Object.keys(newInputs).map((value)=>{
            newInputs[value][1] = "";
        });

        let result = inputsActualizados === false ? "sinCambios" : true;

        if (inputsActualizados !== false) {
            inputsActualizados.map((value)=>{
                if (newInputs[value][0].length > 0) {
                    if (value == "numeroCuenta" || value == "documentoIdentidad") {
                        if (!isNaN(newInputs[value][0])) {
                            if (newInputs[value][0].length < 4) {
                                newInputs[value][1] = "Minimo 4 caracteres.";
                                result = false;    
                            }
                        } else {
                            newInputs[value][1] = "Solo se admiten numeros.";
                            result = false;
                        }
                    }
                    if (value == "nombre" || value == "direccion" || value == "ciudad" || value == "nombreBanco") {
                        if (newInputs[value][0].length < 4) {
                            newInputs[value][1] = "Minimo 4 caracteres.";
                            result = false;
                        }
                    }
                    if (value == "email") {
                        if (!verificarEmail.test(newInputs.email[0])) {
                            newInputs[value][1] = "Ingresa un email verdadero.";
                            result = false;
                        }
                    }
                } else {
                    newInputs[value][1] = "Rellena este campo.";
                    result = false;
                }
            });
        }

        if (result === true) {
            enviarDatos(inputsActualizados);
        } else {
            setCargando(false);
        }

        setInputs(newInputs);
    }

    async function enviarDatos (newDatos){
        const newValores = {};
        newDatos.map((value)=>{
            newValores[value] = inputs[value][0];
        });

        const result = await actualizarPerfil(newValores);
        if (result[0]){
            let session1 = session;
            if (newValores.nombre) {
                session1.user.name = newValores.nombre;
            }
            if (newValores.email) {
                session1.user.email = newValores.email;
            }
            if (newValores.telefono) {
                session1.user.telefono = newValores.telefono;
            }
            setSession(session1);

            Object.keys(inputsDefault).map((value)=>{
                Object.keys(newValores).map((value1)=>{
                    if (value == value1) {
                        if (inputsDefault[value] != newValores[value]) {
                            let newInputs = JSON.parse(JSON.stringify(inputsDefault));
                            newInputs[value][0] = newValores[value];
                            setInputsDefault(newInputs);
                        }
                    }
                });
            })
            
            setMsgVal("Información actualizada.");
        } else {
            setMsgVal(result[1]);
        }
        setCargando(false);
    }

    async function getUserData (){
        setCargandoInputs(true);
        const newInputs = JSON.parse(JSON.stringify(inputsDefault));
        const newInfoUser = await getInfoUser();

        if (session.user) {
            newInputs.nombre[0] = session.user.name ? session.user.name : "";
            newInputs.email[0] = session.user.email;
            newInputs.telefono[0] = session.user.telefono ? session.user.telefono : "";
        }
        if (newInfoUser) {
            if (typeof newInfoUser == "object") {
                setCargandoInputs(false);
                Object.keys(newInfoUser).map((value)=>{
                    if (value != "nombre" && value != "uid" && value != "total" && value != "totalComision") {
                        newInputs[value][0] = newInfoUser[value];
                    }
                });
            } else {
                setDatos(newInfoUser);
                setCargandoInputs(false);
            }
        } else {
            setCargandoInputs(false);
        }

        setInputsDefault(newInputs);
        setInputs(newInputs);
    }

    useEffect(()=>{
        getUserData();
    },[session.user]);

    // useEffect(()=>{
    //     const inputs1 = JSON.parse(JSON.stringify(inputsDefault));
    //     inputs1.ciudad[0] = "Cali";
    //     setInputs(inputs1);
    // },[])


    return (
       <ContenedorOpcion>
            <MiCuentaContainer>
                <h2>Mi cuenta</h2>
                <Formulario>
                    <FormularioCont>
                        <TopFormulario>
                            <h2>Actualiza tu información</h2>
                        </TopFormulario>
                        { cargandoInputs ?
                            <CargandoDiv>
                                <img src={cargandoImg1}/>
                            </CargandoDiv>
                            : datos !== true ? <p>{datos}</p> :
                            <div style={{width:"100%", display:"flex", flexDirection:"column", alignItems:"center"}}>
                                <Columna>
                                    <label>Nombre completo</label>
                                    <SpanValidacion>{inputs.nombre[1]}</SpanValidacion>
                                    <Input defaultValue={inputs.nombre[0] == "" ? "" : inputs.nombre[0]} error={inputs.nombre[1]} onChange={(valor)=>updateValueInput("nombre", valor.target.value)} placeholder={inputs.nombre[0] == "" ? "Sin nombre" : undefined}/>

                                    <label>No. documento de identidad</label>
                                    <SpanValidacion>{inputs.documentoIdentidad[1]}</SpanValidacion>
                                    <Input defaultValue={inputs.documentoIdentidad[0]} error={inputs.documentoIdentidad[1]} onChange={(valor)=>updateValueInput("documentoIdentidad", valor.target.value)} placeholder=""/>
                                </Columna>
                                <Columna>
                                    <label>No. de telefono</label>
                                    <SpanValidacion>{inputs.telefono[1]}</SpanValidacion>
                                    <Input defaultValue={inputs.telefono[0] == "" ? "" : inputs.telefono[0]} error={inputs.telefono[1]} onChange={(valor)=>updateValueInput("telefono", valor.target.value)} placeholder="+1 1234567"/>

                                    <label>Correo electronico</label>
                                    <SpanValidacion>{inputs.email[1]}</SpanValidacion>
                                    <Input defaultValue={inputs.email[0]} error={inputs.email[1]} onChange={(valor)=>updateValueInput("email", valor.target.value)} placeholder=""/>

                                    <label>Ciudad</label>
                                    <SpanValidacion>{inputs.ciudad[1]}</SpanValidacion>
                                    <Input defaultValue={inputs.ciudad[0]} error={inputs.ciudad[1]} onChange={(valor)=>updateValueInput("ciudad", valor.target.value)} placeholder=""/>      
                                </Columna>
                                <Columna>
                                    <label>Direccion de residencia</label>
                                    <SpanValidacion>{inputs.direccion[1]}</SpanValidacion>
                                    <Input defaultValue={inputs.direccion[0]} error={inputs.direccion[1]} onChange={(valor)=>updateValueInput("direccion", valor.target.value)} placeholder=""/>

                                    <label>Nombre del banco</label>
                                    <SpanValidacion>{inputs.nombreBanco[1]}</SpanValidacion>
                                    <Input defaultValue={inputs.nombreBanco[0]} error={inputs.nombreBanco[1]} onChange={(valor)=>updateValueInput("nombreBanco", valor.target.value)} placeholder=""/>

                                    <label>No. de cuenta</label>
                                    <SpanValidacion>{inputs.numeroCuenta[1]}</SpanValidacion>
                                    <Input defaultValue={inputs.numeroCuenta[0]} error={inputs.numeroCuenta[1]} onChange={(valor)=>updateValueInput("numeroCuenta", valor.target.value)} placeholder=""/>      
                                </Columna>
                            </div>
                        }
                    </FormularioCont>
                </Formulario>
                <MsgValidacion activo={msgVal != "" && true} error={msgVal != "Información actualizada." ? false : true}>
                    <img src={msgVal != "Información actualizada." ? incorrecto : correcto}/>
                    <p>{msgVal}</p>
                </MsgValidacion>
                <BtnEnviar onClick={()=>!cargando && !cargandoInputs && validarForm()}>
                    { cargando ?
                        <CargandoDiv>
                            <img src={cargandoImg}/>
                        </CargandoDiv>
                        :
                        <p>Guardar</p>
                    }
                </BtnEnviar>
            </MiCuentaContainer>
       </ContenedorOpcion>
    );
}