import { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import sessionContext from "../../contexts/sessionContext";
import { añadirVilla, deleteItem, getSolicitudesCliente, searchHotel, searchHotelOVilla } from "../../config/auth";
import CargandoCont from "../utils/cargando";
import styled from "styled-components";
import {validarFecha, restaFechas, cambiarFecha, isNum} from "../utils/validar";
import Modal, {BtnCerrar} from "../../components/utils/modal";
import EditarVilla from "./editarVilla";

//imagenes
import cargandoImg from "../../images/cargando.gif";
import mas from "../../images/mas.png";
import hotelSinImg from "../../images/hotelSinImg.png";
import buscar from "../../images/search.png";
import quitar1 from "../../images/quitar1.png";
import quitar from "../../images/quitar.png";

const pc = window.screen.width > 529 ? true : false;

const PlataformaContainer = styled.div`
    display: flex;
    padding: 3vw;
    padding-top: ${pc ? "0px" : "3vw"};
    height: 100vh;
    background-color: #fff;
    flex-direction:column;
    padding-left:0;

    & h2 {
        font-size: ${pc ? "2vw" : "7vw"};
        font-weight: 700;
        margin-bottom: ${pc ? "1vw" : "3vw"};
    }
`;

const Msg = styled.div`
  position: fixed;
  display: ${props => props.activo ? "flex" : "none"};
  width: 100%;
  align-items: center;
  justify-content: center;
  bottom: 0;
  left: 0;
  z-index: 4000;

  & div {
    padding: ${pc ? "1vw 2vw" : "2vw 3vw"};
    margin-bottom: 3px;
    text-align: center;
    border-top-right-radius: 0.4vw;
    border-top-left-radius: 0.4vw;
    background-color: ${props => props.error ? "#26AC20" : "#eb1a1a"};
    color: #fff;
  }
`;

const ModalEliminar = styled.div`
    display: flex;
    padding: 1vw;
    align-items: center;
    flex-direction: column;

    & h3{
        font-weight: 600;
        text-align: center;
        font-size: ${pc ? "" : "4.5vw"};
        width: ${!pc && "60vw"};
    }

    & p {
        color:#585858;
        font-size: ${pc ? "" : "4vw"};
    }
`;

const Btns = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: ${pc ? "90%" : "100%"};
    margin-top: ${pc ? "1vw" : "3vw"};
`;

const Button = styled.button`
    width: 45%;
    background-color: ${props => props.red ? "#eb1a1a" : props.yes ? "#26AC20" : "#767676"};
    color:#fff;
    padding: ${pc ? "0.5vw" : "1.2vw"};
    border: none;
    font-size: ${pc ? "" : "3.2vw"};
    border-radius: ${pc ? "0.5vw" : "1vw"};
    align-items: center;
    justify-content: center;
    cursor: pointer;
    display: flex;
    opacity: ${p => p.opacity && "0.5"};

    &:hover{
        background-color: ${e => e.red ? "#D31A1A" : e.yes ? "#2CA026" : "#646363"};
    }
`;

const HotelesContenedor = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    padding-bottom: ${e => e.paddingBottom ? pc ? "4vw" : "7vw" : undefined};
`;

const BuscarContenedor = styled.form`
    display: flex;
    background-color: #E0E0E0;
    border-radius: 5vw;
    width: 85%;
    margin-top: 2vw;
    height: ${pc && "3vw"};

    & input {
        border: none;
        padding: ${!pc && "3vw"};
        padding-left: ${pc ? "1.3vw" : "3vw"};
        background-color: transparent;
        width: 90%;
    }
    & input:focus{
        outline: none;
    }
    & button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: ${pc ? "3.5vw" : "13%"};
        padding: ${pc ? "1vw" : "2.3vw"};
        background-color: #004aad;
        border-top-right-radius: 5vw;
        border-bottom-right-radius: 5vw;
        margin-left: auto;
        cursor: pointer;
        border: none;
    }
    & button img{
        width: 100%;
    }
`;

const HotelesCont = styled.div`
    margin-top:${pc ? "2vw" : "4.5vw"};
    display: flex;
    width: 100%;
    flex-wrap: wrap;
`;

const Villa = styled.div`
    display: flex;
    position: relative;
    margin: 0 1vw;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1vw;
    width: ${pc ? "10vw" : "30%"};
    margin-bottom: 2vw;
    cursor: pointer;

    & p {
        margin-top: 0.67vw;
        font-size: ${pc ? "1vw" : "3.5vw"};
        font-weight: 600;
        text-align: center;
    }
`;

const HotelImg = styled.div`
    width: ${pc ? e => e.add ? "3.7vw" : "5vw" : "12vw"};
    height: ${pc ? e => e.add ? "3.7vw" : "5vw" : "12vw"};
    background-image: url(${e => e.imagen ? e.imagen : hotelSinImg });
    background-size: cover;
    background-repeat: no-repeat;
    background-color: ${e => e.add && "#26AC20"};
    border-radius: 11vw;
`;

const HotelImgFondo = styled.div`
    width: ${pc ? "5vw" : "12vw"};
    height: ${pc ? "5vw" : "12vw"};
    background-image: url(${e => e.imagen ? e.imagen : hotelSinImg });
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 11vw;
`;

const ModalAddHotel = styled.div`
    padding: ${pc ? "2vw" : ""};
    border-radius: ${pc ? "0.5vw" : "1vw"};
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: ${pc ? "50vw" : "70vw"};

    & h3 {
        font-weight: 700;
        margin-bottom: ${pc ? "1vw" : ""};
        font-size: ${pc ? "1.9vw" : ""};
    }
    & h4 {
        font-weight: 600;
        font-size: ${pc ? "1.4vw" : ""};
        color:#004aad;
        margin-bottom: ${pc ? "0.3vw" : ""};
        margin-right: auto;
        border-bottom: 1px solid #C4C4C4;
        width: 100%;
    }
`;

const FilaModalHotel = styled.div`
    display: flex;
    flex-direction: ${pc ? "row" : "column"};
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    margin-bottom: ${pc ? "1.2vw" : "2vw"};
`;

const Campo = styled.div`
    display: flex;
    flex-direction: ${e => e.oferta ? "row" : "column"};
    margin-right: ${e => e ? pc && "0" : pc && "2vw"};
    width: ${e => pc ? e.oferta ? "100%" : "43%" : e.oferta ? "100%" : "90%"};
    margin:${!pc && "0 auto"};
    margin-bottom: ${e => e.oferta ? pc ? "0.5vw" : "2vw" : ""};
    position: relative;
    background-color: ${e => e.oferta && "#004aad"};
    color: ${e => e.oferta && "#fff"};
    padding: ${e => e.oferta && pc ? "0.5vw" : "1vw"};
    border-radius: ${e => e.oferta && pc ? "0.5vw" : "1vw"};
    padding-left: ${e => e.oferta ? "1vw" : "0"};
    align-items: ${e => e.oferta && "center"};

    & img {
        width: ${pc ? "2vw" : "3vw"};
        margin-left: auto;
        cursor: pointer;
    }

    & p {
        font-size: ${e => e.rango ? "12px" : ""};
    }

    & label {
        color:#000;
        font-weight: 500;
        font-size: ${pc ? "1.1vw" : ""};
    }

    & input {
        padding: ${pc ? "0.3vw" : "0.5vw 1vw"};
        border: 1px solid #999797;
        border-radius: 3px;
        font-size: ${pc ? "0.9vw" : "3.5vw"};
        color:#505050;        
    }
    & textarea {
        padding: ${pc ? "0.3vw" : "0.5vw 1vw"};
        border: 1px solid #999797;
        border-radius: 3px;
        font-size: ${pc ? "0.9vw" : "3.5vw"};
        color:#505050;
        resize: none;
    }

    & input:focus {
        outline:1px solid #B3B3B3;  
    }
    & textarea:focus {
        outline:1px solid #B3B3B3;  
    }
`;

const Input = styled.input`
    border:1px solid ${props => props.error ? "#eb1a1a!important" : "#999797"};
`;

const SpanValidacion = styled.div`
    background-color: #eb1a1a;
    display: ${e => e.active ? "block" : "none"};
    color: #fff;
    padding: ${pc ? "0.5vw!important" : "1vw"};
    position: absolute;
    bottom: ${e => e.rango ? "125%" : "65%"};
    right: 5%;
    border-radius: ${pc ? "0.5vw" : "1vw"};
    font-size: ${pc ? "0.9vw" : "2.5vw"};
    opacity: 0.91;

    & span {
        z-index: 50;
        position: relative;
    }
`;

const DivLinea = styled.div`
    position: absolute;
    right: 3%;
    bottom: -0.18vw;
    background-color: #eb1a1a;
    height: ${pc ? "1.3vw!important" : "4vw!important"};
    width: ${pc ? "1.3vw!important" : "4vw!important"};
    transform: rotate(45deg);
    z-index: 10;
`;

const Textarea = styled.textarea`
    border:1px solid ${props => props.error ? "#eb1a1a!important" : "#999797"};
`;

const Select = styled.select`
    border:1px solid ${props => props.error ? "#eb1a1a!important" : "#999797"};
`;

const RangosCont = styled.div`  
    display: flex;
    width: 70%;
    align-items: center;
    justify-content: center;

    & p {
        margin-right: 2%;
    }
`;

const Dias = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
`;

const Dia = styled.div`
    width: ${pc ? "20%" : "33.33%"};
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: ${pc ? "0.8vw" : "2vw"};
`;

export default function Villas (){

    const navigate = useNavigate();
    const location = useLocation();

    const { session } = useContext(sessionContext);

    let [villas, setHoteles] = useState(undefined);    
    let [inputHotel, setInputHotel] = useState(undefined);
    let [search, setSearch] = useState(undefined);
    let [localidad, setLocalidad] = useState({"Zona este":undefined, "Zona norte":undefined});
    let [localidadInput, setLocalidadInput] = useState("Todos");
    let [ultimoElemento, setUltimoElemento] = useState(undefined);
    let [ultimoElementoLocalidad, setUltimoElementoLocalidad] = useState({"Zona este":undefined, "Zona norte":undefined});

    let [msgVal1, setMsgVal1] = useState("");
    let [msgVal2, setMsgVal2] = useState("");
    let [idVilla, setidVilla] = useState("");

    let [cargando, setCargando] = useState(false);
    let [cargandoHotel, setCargandoHotel] = useState(false);
    let [cargando1, setCargando1] = useState(false);

    let [modal, setModal] = useState(false);
    let [modalHotel, setModalVilla] = useState(false);
    let [modalAddHotel, setModalAddHotel] = useState(false);
    let [editVilla, setEditVilla] = useState(false);

    const defaultInput = {nombre:["", ""], zona:["", ""], descripcion:["", ""], fotoPerfil:["", ""], imagenes:["", ""], oferta:{codigoOferta:["", ""], vencimientoOferta:["", ""], desde:["", ""], hasta:["", ""], rango:{rangoMin:["", ""], rangoMax:["", ""], precio:["", ""], dias:[[], ""]}}};
    let [inputs, setInputs] = useState(defaultInput);

    let [oferta, setOferta] = useState(false);
    let [ofertasAdd, setOfertasAdd] = useState([]);
    let [rango, setRango] = useState(false);
    let [subRango, setSubRango] = useState(false);
    let [rangos, setRangos] = useState([]);

    async function borrarVilla (){
        if (!cargando) {
            setCargando(true);
            const result = await deleteItem({id:idVilla}, "villa");
            if (result[0]) {
                setMsgVal1([true, "Villa eliminada correctamente!"]);
                const items = villas;
                let zona = "";
                items.map((value, index)=>{
                    if (value.id == idVilla) {
                        items.splice(index, 1);
                        setHoteles(items);
                        if (idVilla) {
                            zona = value.zona;
                        }
                    }
                });
                if (idVilla) {
                    if (search) {
                        if (search[0].id === idVilla) {
                            setSearch(undefined);
                            setInputHotel("");
                        }
                    }
                    if (localidadInput !== "Todos") {
                        const newLocalidad = localidad;
                        localidad[zona].map((value, index)=>{
                            if (idVilla === value.id) {
                                newLocalidad[zona].splice(index, 1);
                            }
                        });
                        setLocalidad(JSON.parse(JSON.stringify(newLocalidad)));
                    }
                }
            } else {
                setMsgVal1([false, result[1]]);
            }

            setTimeout(()=>{
                setMsgVal1([false, ""]);
            }, 2700);

            setidVilla("");

            setModal(false);
            setCargando(false);
        }
    }

    function eliminarOferta (id){
        const newOfertas = JSON.parse(JSON.stringify(ofertasAdd));
        newOfertas.splice(id, 1);
        setOfertasAdd(newOfertas);
    }

    function actualizarInput (tipo, valor, tipo2, tipo3){
        const newInputs = inputs;
        if (tipo2 == "oferta") {
            if (tipo == "desde" || tipo == "hasta" || tipo == "vencimientoOferta") {
                newInputs.oferta[tipo][0] = cambiarFecha(valor);
            } else {
                newInputs.oferta[tipo][0] = valor;
            }
        } else if (tipo2 == "rango"){
            if (tipo == "dias") {
                if (valor == true) {
                    if (tipo3 == "todos"){
                        newInputs.oferta.rango.dias = [["todos"], ""];
                    } else {
                        newInputs.oferta.rango.dias[0].push(tipo3);
                    }
                } else {
                    newInputs.oferta.rango.dias[0].map((dia, index)=>{
                        if (dia == tipo3) {
                            newInputs.oferta.rango.dias[0].splice(index, 1);
                        };
                    });
                }
            } else {
                newInputs.oferta.rango[tipo][0] = valor;
            }
        } else {
            newInputs[tipo][0] = valor;
        }
        setInputs(JSON.parse(JSON.stringify(newInputs)));
    } 

    function validarOferta (oferta1, validar){
        let result = true;
        const newInputs = oferta1 ? {oferta:oferta1} : inputs;
        const ofertaSelect = oferta1 ? oferta1 : inputs.oferta;

        if (rango) {
            const valRango = validarRango();
            if (!valRango) {
                result = false;
            } else {
                add_o_del_rango("add");
            }
        }

        Object.keys(ofertaSelect).map((value, index)=>{
            if (value != "rango") {
                newInputs.oferta[value][1] = "";
                if (newInputs.oferta[value][0].length > 0) {
                    if (value == "codigoOferta") {
                        if (newInputs.oferta[value][0].length < 2) {
                            newInputs.oferta[value][1] = "Minimo 2 caracteres.";
                            result = false;
                        }
                    } else if (value == "desde" || value == "hasta" || value == "vencimientoOferta") {
                        if (validarFecha(newInputs.oferta[value][0]) === true) {
                            if (value == "desde" || value == "hasta") {
                                if (restaFechas(newInputs.oferta.desde[0], newInputs.oferta.hasta[0]) == 0) {
                                    newInputs.oferta[value][1] = "Minimo un dia.";
                                    result = false;
                                } else if (restaFechas(newInputs.oferta.desde[0], newInputs.oferta.hasta[0]) < 0) {
                                    newInputs.oferta.hasta[1] = "Esta fecha tiene que ser despues que la fecha Periodo (desde)";
                                    result = false;
                                }
                            }
                        } else {
                            newInputs.oferta[value][1] = validarFecha(newInputs.oferta[value][0]);
                            result = false;
                        }
                    }
                } else {
                    newInputs.oferta[value][1] = "Rellena este campo.";
                    result = false;
                }
            }
            
        });

        rangos.map((rango, index)=>{
            if (index == rangos.length-1) {
                if (rango.length == 0) {
                    result = false;
                    alert("La oferta debe tener minimo un rango. si no, eliminala.");
                }

                rango.map((rango)=>{
                    if (rango.rangos.length > 0) {
                        let valido = false;
                        rango.rangos.map((subRango)=>{
                            if (subRango.dias[0].includes("todos")) {
                                valido = true;
                            }
                        });
                        if (!valido) {
                            result = false;
                            alert("Los rangos deben tener un sub-rango con todos los dias.");
                        }
                    } else {
                        result = false;
                        alert("Los rangos deben tener minimo un sub-rango. si no, eliminalo.");
                    }
                });
            }
            
        });

        if (!validar) {
            if (result) {
                const newOfertas = ofertasAdd;
                newOfertas.push(inputs.oferta);
                setOfertasAdd(JSON.parse(JSON.stringify(newOfertas)));
                Object.keys(inputs.oferta).map((value)=>{
                    if (value != "rango") {
                        newInputs.oferta[value] = ["", ""];
                    } else {
                        newInputs.oferta.rango = defaultInput.oferta.rango;
                    }
                });
                setOferta(false);
            }
            
            setInputs(JSON.parse(JSON.stringify(newInputs)));
        };
        


        return result;
    }

    function validarRango (valRango){
        let valid = true;
        const newInputs = valRango || inputs.oferta.rango;

        Object.keys(valRango || newInputs).map((campo)=>{
            newInputs[campo][1] = "";
            if (newInputs[campo][0].length > 0) {
                if (campo != "dias") {
                    if (isNum(newInputs[campo][0])) {
                        if (newInputs[campo][0] > 0) {
                            if (campo == "rangoMax" || campo == "rangoMin"){
                                if (parseInt(newInputs.rangoMin[0]) > parseInt(newInputs.rangoMax[0])) {
                                    newInputs.rangoMax[1] = "El rango maximo tiene que ser mayor al rango minimo.";
                                    valid = false;
                                } else {
                                    rangos[rangos.length-1].map((rango)=>{
                                        if (parseInt(newInputs.rangoMin[0]) <= parseInt(rango.rangoMax[0]) && parseInt(newInputs.rangoMax[0]) >= parseInt(rango.rangoMax[0])) {
                                            newInputs.rangoMin[1] = "Este rango ya existe en esta oferta.";
                                            newInputs.rangoMax[1] = "Este rango ya existe en esta oferta.";
                                            valid = false;
                                        } else if (parseInt(newInputs.rangoMin[0]) >= parseInt(rango.rangoMin[0]) && parseInt(newInputs.rangoMax[0]) <= parseInt(rango.rangoMax[0])){
                                            newInputs.rangoMin[1] = "Este rango ya existe en esta oferta.";
                                            newInputs.rangoMax[1] = "Este rango ya existe en esta oferta.";
                                            valid = false;
                                        } else if (parseInt(newInputs.rangoMin[0]) <= parseInt(rango.rangoMin[0]) && parseInt(newInputs.rangoMin[0]) >= parseInt(rango.rangoMax[0])){
                                            newInputs.rangoMin[1] = "Este rango ya existe en esta oferta.";
                                            newInputs.rangoMax[1] = "Este rango ya existe en esta oferta.";
                                            valid = false;
                                        } else if (parseInt(newInputs.rangoMin[0]) <= parseInt(rango.rangoMin[0]) && (parseInt(newInputs.rangoMax[0]) <= parseInt(rango.rangoMax[0]) && parseInt(newInputs.rangoMax[0]) >= parseInt(rango.rangoMin[0]))){
                                            newInputs.rangoMin[1] = "Este rango ya existe en esta oferta.";
                                            newInputs.rangoMax[1] = "Este rango ya existe en esta oferta.";
                                            valid = false;
                                        }
                                    })
                                }
                            }
                        } else {
                            newInputs[campo][1] = "0 no es un valor aceptable.";
                            valid = false;
                        }
                    } else {
                        newInputs[campo][1] = "Solo se admiten numeros, sin puntos, comas, ni simbolos.";
                        valid = false;
                    };
                } else {
                    if (subRango !== false) {
                        let dias = [];
                        rangos[rangos.length-1][subRango].rangos.map((rango)=>{
                            dias = dias.concat(rango.dias[0]);
                        });

                        dias.map((e)=>{
                            newInputs.dias[0].map((a)=>{
                                if (a == e) {
                                    newInputs.dias[1] = newInputs.dias[0].length > 1 ? "Algunos de los dias que seleccionaste, ya existen." : "El dia que seleccionaste ya existe.";
                                    valid = false;
                                };
                            });
                        });
                    }
                    
                }
            } else {
                if (subRango !== false) {
                    if (campo != "rangoMin" && campo != "rangoMax") {
                        newInputs[campo][1] = "Rellena este campo.";
                        valid = false;
                    }
                } else {
                    newInputs[campo][1] = "Rellena este campo.";
                    valid = false;
                }
            }
        });

        if (!valRango) {
            const newInputs1 = inputs;
            newInputs1.oferta.rango = newInputs;
            setInputs(JSON.parse(JSON.stringify(newInputs1)));
        }

        return valid;
    }
    
    function cerrarAddRango (){
        setRango(false);
        setSubRango(false);
        const inputs1 = inputs; 
        inputs1.oferta.rango = defaultInput.oferta.rango;
        setInputs(JSON.parse(JSON.stringify(inputs1)));
    }

    function add_o_del_rango (tipo, indexElemento, indexSubRango){
        const newRangos = rangos;
        if (tipo == "add") {
            const result = validarRango();
            if (result) {
                const rangoNew = inputs.oferta.rango;
                rangoNew.rangos = [];
                rangoNew.rangos.push({precio:rangoNew.precio, dias:rangoNew.dias});
                delete rangoNew.precio; delete rangoNew.dias;
                newRangos[rangos.length-1].push(rangoNew);
                cerrarAddRango();
            }
        } else if (subRango !== false){
            const result = validarRango();
            if (result) {
                const rangoNew = inputs.oferta.rango;
                newRangos[newRangos.length-1][subRango].rangos.push({precio:rangoNew.precio, dias:rangoNew.dias});
                cerrarAddRango();
            }

        } else if (tipo == "subDel"){
            newRangos[rangos.length-1][indexElemento].rangos.splice(indexSubRango, 1);
            setRangos(JSON.parse(JSON.stringify(newRangos)));    
        } 
        else {
            newRangos[rangos.length-1].splice(indexElemento, 1);
            setRangos(JSON.parse(JSON.stringify(newRangos)));
        }
    }
    
    function validarImagenes (){
        const archivosPermitidos = ["image/png", "image/jpg", "image/jpeg"];
        const newInputs = inputs;
        let result = true;

        newInputs.fotoPerfil[1] = "";
        newInputs.imagenes[1] = "";

        const imagen = document.querySelector("#imgPerfil").files[0];
        if (imagen) {
            if (imagen.size > 7242881) {
                result = false;
                newInputs.fotoPerfil[1] = "Cada imagen debe pesar menos de 7 MB.";
            }
            let valid = false;
            archivosPermitidos.map((formato)=>{
                if (imagen.type == formato) {
                    valid = true;
                }
            });
            if (!valid) {
                result = false;
                newInputs.fotoPerfil[1] = "Solo se admiten estos archivos: .png, .jpg y .jpeg.";
            }
        } else {
            result = false;
            newInputs.fotoPerfil[1] = "Rellena este campo.";
        }

        const imagenes = document.querySelector("#inputImagenes").files;
        if (imagenes.length < 31) {
            Object.keys(imagenes).map((imagen)=>{
                let valid = false;
                if (imagenes[imagen].size > 7242881) {
                    result = false;
                    newInputs.imagenes[1] = "Cada imagen debe pesar menos de 7 MB.";
                }
                archivosPermitidos.map((formato)=>{
                    if (imagenes[imagen].type == formato) {
                        valid = true;
                    }
                });
                if (!valid) {
                    result = false;
                    newInputs.imagenes[1] = "Solo se admiten estos archivos: .png, .jpg y .jpeg.";
                }
            });                                                        
        } else {
            result = false;
            newInputs.imagenes[1] = "Maximo 30 imagenes.";
        }

        setInputs(JSON.parse(JSON.stringify(newInputs)));

        return result;
    }
    
    function validarVilla (){
        let result = true;
        let newInputs = inputs;

        Object.keys(newInputs).map((value)=>{
            if (value == "zona" || value == "nombre") { 
                newInputs[value][1] = "";
                if (inputs[value][0].length > 0) {
                    if (value == "nombre") {
                        if (inputs[value][0].length < 4){
                            result = false;
                            newInputs[value][1] = "Minimo 4 caracteres.";
                        };
                    };
                } else {
                    result = false;
                    newInputs[value][1] = "Rellena este campo.";
                };
            }
        });

        setInputs(JSON.parse(JSON.stringify(newInputs)));

        return result;
    }

    async function agregarVilla (){
        const valForm = validarVilla();
        const imgVal = validarImagenes();

        if (valForm && (!oferta) && (!rango)) {
            const imagenes = [];
            const newImagenes = document.querySelector("#inputImagenes").files;
            const imgPerfil = document.querySelector("#imgPerfil").files[0];
            imagenes.push(imgPerfil);

            if (newImagenes.length > 0) {
                Object.keys(newImagenes).map((imagen)=>{
                    imagenes.push(newImagenes[imagen]);
                });
            };

            let inputs1 = inputs;
            delete inputs1.oferta;
            if (ofertasAdd.length > 0) {
                inputs1.ofertas = ofertasAdd;
            } else {
                inputs1.ofertas = [];
            }

            inputs1.ofertas.map((value, index)=>{
                delete inputs1.ofertas[index].rango;
                if (rangos[index]) {
                    if (rangos[index].length > 0){
                        inputs1.ofertas[index].rangos = rangos[index];
                    } else {
                        inputs1.ofertas[index].rangos = [];
                    };
                };
            });

            if (imgVal) {
                setCargandoHotel(true);
                if (!cargandoHotel) {
                    const result = await añadirVilla({villa:inputs1, imagenes:imagenes});
                    if (result === true) {
                        setTimeout(()=>{
                            setCargandoHotel(true)
                        }, 20);
                        setTimeout(()=>{
                            setOfertasAdd([]);
                            setInputs(JSON.parse(JSON.stringify(defaultInput)));
                            setMsgVal1([true, "Villa agregada correctamente!"]);
                            setModalAddHotel(false);
                            setTimeout(()=>{
                                navigate("/");
                            }, 400);
                            setTimeout(()=>{
                                navigate("/admin?tab=villas");
                            }, 500);
                            setCargandoHotel(false);
                        }, 4500);
                    } else {
                        setMsgVal1([false, result]);
                        setCargandoHotel(false);
                    };
                    setTimeout(()=>{
                        setMsgVal1("");
                    }, 4000);
                }
            }
        } else{
            if (oferta) {
                validarOferta();
            }
        }
    }

    async function obtenerVillas (urg){
        if (villas === undefined || urg === true) {
            setCargando(true);
            const result = await getSolicitudesCliente({tipo:"villas"});
            if (result[0]) {
                setHoteles(result[1].datos);
                setUltimoElemento(result[1].ultimoElemento);
            } else {
                setHoteles(result[0]);
                setMsgVal2(result[1]);
            }
            setCargando(false);
        } else {
            if (ultimoElemento !== false) {
                setCargando1(true);
                const result = await getSolicitudesCliente({tipo:"villas", ultimoElemento:ultimoElemento});
                setUltimoElemento(result[1].ultimoElemento);
                if (result[0]) {
                    const newHoteles = villas;
                    result[1].datos.map((value)=>{
                        let si = true;
                        newHoteles.map((old)=>{
                            if (old.id == value.id) {
                                si = false;
                            }
                        })
                        if (si) {
                            newHoteles.push(value);
                        }
                    });
                    setHoteles(JSON.parse(JSON.stringify(newHoteles)));
                } else {
                    setHoteles(result[0]);
                    setMsgVal2(result[1]);
                }
                setCargando1(false);
            }
        }
    };

    async function buscarVilla (e){
        e.preventDefault();
        if (inputHotel !== undefined) {
            if (inputHotel.replace(/\s+/g, " ").trim().length > 0) {
                setCargando(true);
                const result = await searchHotelOVilla(inputHotel.toLowerCase().replace(/\s+/g, " ").trim(), "villas");
                if (result[0]) {
                    setSearch(result[1]);
                } else {
                    setSearch(false);
                    setMsgVal2(result[1]);
                }
                setCargando(false);
            }
        }
    };

    async function getVillasLocalidad (local1, btn){
        const local = local1 || localidadInput;
        setSearch(undefined);
        setInputHotel("");
        if (local != "Todos") {
            setLocalidadInput(local);
            
            if (localidad[local] === undefined) {
                setCargando(true);
                const result = await getSolicitudesCliente({tipo:"villas", search:"zona", word:local});
                if (result[0]) {
                    const newLocalidad = localidad;
                    newLocalidad[local] = result[1].datos;
                    setLocalidad(JSON.parse(JSON.stringify(newLocalidad)));
                    const ultimoElementoLocalidad1 = ultimoElementoLocalidad;
                    ultimoElementoLocalidad1[local] = result[1].ultimoElemento;
                    setUltimoElementoLocalidad(ultimoElementoLocalidad1);
                } else {
                    const newLocalidad = localidad;
                    newLocalidad[local] = result[0];
                    setLocalidad(newLocalidad);
                    setMsgVal2(result[1]);
                }
                setCargando(false);
            } else {
                if (btn) {
                    if (ultimoElementoLocalidad[local] !== false) {
                        setCargando1(true);
                        const result = await getSolicitudesCliente({tipo:"villas", search:"zona", word:local, ultimoElemento:ultimoElementoLocalidad[local]});
                        const ultimoElementoLocalidad1 = ultimoElementoLocalidad;
                        ultimoElementoLocalidad1[local] = result[1].ultimoElemento;
                        setUltimoElementoLocalidad(ultimoElementoLocalidad1);
                        if (result[0]) {
                            const newLocalidad = localidad;
                            result[1].datos.map((value)=>{
                                let si = true;
                                newLocalidad[local].map((old)=>{
                                    if (old.id == value.id) {
                                        si = false;
                                    }
                                })
                                if (si) {
                                    newLocalidad[local].push(value);
                                }
                            });
                            setLocalidad(newLocalidad);
                        } else {
                            const newLocalidad = localidad;
                            newLocalidad[local] = result[0];
                            setLocalidad(newLocalidad);
                            setMsgVal2(result[1]);
                        }
                        setCargando1(false);
                    };
                }
            };
        } else {
            setLocalidadInput(local);
        }
    };

    function getDataVillaEdit (){
        let result = "";
        if (search) {
            search.map((villa, index)=>{
                if (villa.id == idVilla) {
                    result = villa;
                }
            });
        }
        if (localidad[localidadInput]) {
            localidad[localidadInput].map((villa, index)=>{
                if (villa.id == idVilla) {
                    result = villa;
                }
            });
        }
        if (villas) {
            villas.map((villa, index)=>{
                if (villa.id == idVilla) {
                    result = villa;
                }
            });
        }
        return result;
    }

    useEffect(()=>{
        setEditVilla(false);
        setModalVilla(false);        
    },[location.search]);
  
    useEffect(()=>{
        if (session.user) {
            obtenerVillas();
        }
    },[session.user]);

    return (
        <div>
            { editVilla ?
                <EditarVilla villa={getDataVillaEdit()} setClose={()=>{setEditVilla(false); setModalVilla(false); setidVilla(""); setTimeout(()=>{document.querySelector("#optionLocalVillas").value=localidadInput},50);}} />
                :
            <PlataformaContainer>
                <Modal visible={modal} setVisible={()=>{}}>
                    <ModalEliminar> 
                        <h3>¿Estas seguro de eliminar este hotel?</h3>
                        <p>Esta acción no se puede deshacer.</p>
                        {cargando && <CargandoCont><img src={cargandoImg}/></CargandoCont>}
                        <Btns>
                            <Button disabled={cargando} opacity={cargando} onClick={()=>{setModal(false); setidVilla("")}} >No estoy seguro</Button>
                            <Button disabled={cargando} opacity={cargando} red={true} onClick={()=>borrarVilla()}>Estoy seguro</Button>
                        </Btns>
                    </ModalEliminar>
                </Modal>
                { modalAddHotel &&
                    <Modal visible={modalAddHotel} setVisible={()=>{}}>
                        <ModalAddHotel>
                            <BtnCerrar onClick={()=>!cargandoHotel && setModalAddHotel(false)}><img src={quitar1}/></BtnCerrar>
                            <h3>Agrega un villa</h3>
                            <h4>Datos basicos</h4>
                            <FilaModalHotel>
                                            <Campo>
                                                <label>Nombre<span style={{color:"#eb1a1a"}}>*</span></label>
                                                <SpanValidacion active={inputs.nombre[1]}><span>{inputs.nombre[1]}</span><DivLinea/></SpanValidacion>
                                                <Input value={inputs.nombre[0]}  error={inputs.nombre[1]} onChange={(e)=>actualizarInput("nombre", e.target.value)} />
                                            </Campo>
                                            <Campo>
                                            <SpanValidacion active={inputs.zona[1]}><span>{inputs.zona[1]}</span><DivLinea/></SpanValidacion>
                                                <label>Zona de la villa<span style={{color:"#eb1a1a"}}>*</span></label>
                                                <Select defaultValue="DEFAULT" onChange={(e)=>actualizarInput("zona", e.target.value)} error={inputs.zona[1]}>
                                                    <option value="DEFAULT" disabled>Sin seleccionar</option>
                                                    <option>Zona este</option>
                                                    <option>Zona norte</option>
                                                </Select>
                                            </Campo>
                                            <Campo>
                                                <SpanValidacion active={inputs.fotoPerfil[1]}><span>{inputs.fotoPerfil[1]}</span><DivLinea/></SpanValidacion>
                                                <label>Foto de perfil<span style={{color:"#eb1a1a"}}>*</span></label>
                                                <Input id="imgPerfil" accept=".jpg, .png, .jpeg" type="file"/>
                                            </Campo>
                                        </FilaModalHotel>
                                        <h4>Fotos y detalles</h4>
                                        <FilaModalHotel style={{flexDirection:"column"}}>
                                            <Campo style={{width:"100%", marginBottom:0}}>
                                                <SpanValidacion active={inputs.descripcion[1]}><span>{inputs.descripcion[1]}</span><DivLinea/></SpanValidacion>
                                                <label>Descripción</label>
                                                <Textarea style={{height:"18vh"}} value={inputs.descripcion[0]} error={inputs.descripcion[1]} onChange={(e)=>actualizarInput("descripcion", e.target.value)}/>
                                            </Campo>
                                            <Campo style={{marginBottom:0}}>
                                                <SpanValidacion active={inputs.imagenes[1]}><span>{inputs.imagenes[1]}</span><DivLinea/></SpanValidacion>
                                                <label>Fotos</label>
                                                <Input accept=".jpg, .png, .jpeg" multiple id="inputImagenes" type="file"/>
                                            </Campo>
                            </FilaModalHotel>
                            <h4>Ofertas</h4>
                            { ofertasAdd.map((value, index)=>(
                                <Campo oferta={true} key={index}>
                                    <p>Oferta {index+1}</p>
                                    <img onClick={()=>eliminarOferta(index)} src={quitar}/>
                                </Campo>
                            ))}
                            { oferta ?
                                <FilaModalHotel style={{backgroundColor:"#FDFDFD", marginTop:ofertasAdd.length > 0 ? pc ? "1vw" : "" : pc ? "1" : "" , border:"1px solid #E4E4E4"}}>
                                    <Campo>
                                        <SpanValidacion active={inputs.oferta.codigoOferta[1]}><span>{inputs.oferta.codigoOferta[1]}</span><DivLinea/></SpanValidacion>
                                        <label>Codigo de oferta<span style={{color:"#eb1a1a"}}>*</span></label>
                                        <Input onChange={(e)=>actualizarInput("codigoOferta", e.target.value, "oferta")} error={inputs.oferta.codigoOferta[1]}  type="text"/>
                                    </Campo>
                                    <Campo>
                                        <SpanValidacion active={inputs.oferta.vencimientoOferta[1]}><span>{inputs.oferta.vencimientoOferta[1]}</span><DivLinea/></SpanValidacion>
                                        <label>Vencimiento oferta<span style={{color:"#eb1a1a"}}>*</span></label>
                                        <Input onChange={(e)=>actualizarInput("vencimientoOferta", e.target.value, "oferta")} error={inputs.oferta.vencimientoOferta[1]}  type="date"/>
                                    </Campo>
                                    <Campo>
                                        <SpanValidacion active={inputs.oferta.desde[1]}><span>{inputs.oferta.desde[1]}</span><DivLinea/></SpanValidacion>
                                        <label>Periodo (Desde)<span style={{color:"#eb1a1a"}}>*</span></label>
                                        <Input onChange={(e)=>actualizarInput("desde", e.target.value, "oferta")} error={inputs.oferta.desde[1]}  type="date"/>
                                    </Campo>
                                    <Campo>
                                        <SpanValidacion active={inputs.oferta.hasta[1]}><span>{inputs.oferta.hasta[1]}</span><DivLinea/></SpanValidacion>
                                        <label>Periodo (Hasta)<span style={{color:"#eb1a1a"}}>*</span></label>
                                        <Input onChange={(e)=>actualizarInput("hasta", e.target.value, "oferta")} error={inputs.oferta.hasta[1]}  type="date"/>
                                    </Campo>
                                    <h4 style={{margin:"10px", width:"85%"}}>Rangos</h4>
                                    { rangos.length > 0 && rangos[rangos.length-1].map((value, index)=>
                                        (<Campo style={{width:"85%", display:subRango === false ? "flex" : index !== subRango ? "none" : "flex" , marginBottom:"4%", flexDirection:"column"}} rango={true} oferta={true} key={index}>
                                            <div style={{display:"flex", width:"100%", alignItems:"center"}}>
                                                <p>{value.rangoMin}-{value.rangoMax} Personas</p>
                                                <img onClick={()=>add_o_del_rango("del", index)} src={quitar}/>
                                            </div>
                                            <div style={{display:"flex", margin:"2%", width:"100%",  flexDirection:"column", alignItems:"center"}}>
                                                {rangos[rangos.length-1][index].rangos.length > 0 ? rangos[rangos.length-1][index].rangos.map((rango, indexSubRango)=>(
                                                    <div style={{display:"flex", marginTop:indexSubRango != 0 && "3%", padding:"1%", backgroundColor:"#fff", color:"#000", width:"80%", alignItems:"center"}}>
                                                        <p>Precio: ${rango.precio[0]} ({rango.dias[0].map((dia, index)=>(dia+(index != rango.dias[0].length-1 ? ", " : "")))})</p>
                                                        <img onClick={()=>add_o_del_rango("subDel", index, indexSubRango)} src={quitar1}/>
                                                    </div>
                                                ))
                                                : 
                                                <p>No hay sub-rangos</p>
                                                }
                                            </div>
                                            <Btns>
                                                <Button onClick={()=>{setRango(true); setSubRango(index)}} style={{fontSize:pc ? "0.85vw" : "3vw", padding:pc ? "0.3vw" : "1vw"}}>Agregar sub-rango</Button>
                                            </Btns>
                                        </Campo>)
                                    )}
                                    {   rango ?
                                            <div style={{display:"flex", width:"100%", alignItems:"center", flexDirection:"column"}}>
                                                <RangosCont>
                                                   {subRango === false && <Campo style={{width: "30%", padding: 0, marginRight: "2%"}}>
                                                        <SpanValidacion rango={true} active={inputs.oferta.rango.rangoMin[1]}><span>{inputs.oferta.rango.rangoMin[1]}</span><DivLinea rango={true} /></SpanValidacion>
                                                        {/* <label>Rango minimo<span style={{color:"#eb1a1a"}}>*</span></label> */}
                                                        <Input placeholder="1" onChange={(e)=>actualizarInput("rangoMin", e.target.value, "rango", )} error={inputs.oferta.rango.rangoMin[1]}  type="text"/>
                                                    </Campo>}
                                                    {subRango === false && <p>-</p>}
                                                    {subRango === false && <Campo style={{width: "30%", padding: 0, marginRight: "2%"}}>
                                                        <SpanValidacion rango={true} active={inputs.oferta.rango.rangoMax[1]}><span>{inputs.oferta.rango.rangoMax[1]}</span><DivLinea/></SpanValidacion>
                                                        {/* <label>Rango Maximo<span style={{color:"#eb1a1a"}}>*</span></label> */}
                                                        <Input placeholder="10" onChange={(e)=>actualizarInput("rangoMax", e.target.value, "rango", )} error={inputs.oferta.rango.rangoMax[1]}  type="text"/>
                                                    </Campo>}
                                                    <Campo style={{width: "30%", padding: 0, marginRight: "2%"}}>
                                                        <SpanValidacion rango={true} active={inputs.oferta.rango.precio[1]}><span>{inputs.oferta.rango.precio[1]}</span><DivLinea/></SpanValidacion>
                                                        {/* <label>Precio<span style={{color:"#eb1a1a"}}>*</span></label> */}
                                                        <Input placeholder="Precio" onChange={(e)=>actualizarInput("precio", e.target.value, "rango", )} error={inputs.oferta.rango.precio[1]}  type="text"/>
                                                    </Campo>
                                                </RangosCont>
                                                <Campo style={{alignItems:"center", width:"90%"}}>
                                                        <label>Dias<span style={{color:"#eb1a1a"}}>*</span></label>
                                                        <SpanValidacion style={{marginRight: "44%", marginBottom: pc ? "8.5%" : "23%"}} active={inputs.oferta.rango.dias[1]}><span>{inputs.oferta.rango.dias[1]}</span><DivLinea/></SpanValidacion>
                                                        <Dia>
                                                            <label>Todos</label>
                                                            <input onChange={(e)=>actualizarInput("dias", e.target.checked, "rango", "todos")} type="checkbox"/>
                                                        </Dia>
                                                        {inputs.oferta.rango.dias[0][0] != "todos" && <Dias style={{flexDirection:"row!important"}}>                                                        
                                                            <Dia>
                                                                <label>Lunes</label>
                                                                <input onChange={(e)=>actualizarInput("dias", e.target.checked, "rango", "lunes")} type="checkbox"/>
                                                            </Dia>
                                                            <Dia>
                                                                <label>Martes</label>
                                                                <input onChange={(e)=>actualizarInput("dias", e.target.checked, "rango", "martes")} type="checkbox"/>
                                                            </Dia>
                                                            <Dia>
                                                                <label>Miercoles</label>
                                                                <input onChange={(e)=>actualizarInput("dias", e.target.checked, "rango", "miercoles")} type="checkbox"/>
                                                            </Dia>
                                                            <Dia>
                                                                <label>Jueves</label>
                                                                <input onChange={(e)=>actualizarInput("dias", e.target.checked, "rango", "jueves")} type="checkbox"/>
                                                            </Dia>
                                                            <Dia>
                                                                <label>Viernes</label>
                                                                <input onChange={(e)=>actualizarInput("dias", e.target.checked, "rango", "viernes")} type="checkbox"/>
                                                            </Dia>
                                                            <Dia>
                                                                <label>Sabado</label>
                                                                <input onChange={(e)=>actualizarInput("dias", e.target.checked, "rango", "sabado")} type="checkbox"/>
                                                            </Dia>
                                                            <Dia>
                                                                <label>Domingo</label>
                                                                <input onChange={(e)=>actualizarInput("dias", e.target.checked, "rango", "domingo")} type="checkbox"/>
                                                            </Dia>
                                                        </Dias>}
                                                    </Campo>
                                                <Btns style={{marginBottom:pc?"1vw":"1vw"}}>
                                                    <Button onClick={()=>cerrarAddRango()} style={{fontSize:pc ? "0.85vw" : "3vw", padding:pc ? "0.3vw" : "1vw"}}>Cerrar</Button>
                                                    <Button onClick={()=>add_o_del_rango(subRango === false && "add")} style={{fontSize:pc ? "0.85vw" : "3vw", padding:pc ? "0.3vw" : "1vw"}}>{subRango !== false ? "Añadir Sub-rango" : "Añadir rango"}</Button>
                                                </Btns>
                                            </div>
                                        :
                                            <FilaModalHotel>
                                                <Btns>
                                                    <Button onClick={()=>setRango(true)} style={{fontSize:pc ? "0.85vw" : "3vw", padding:pc ? "0.3vw" : "1vw"}}>Agregar rango</Button>
                                                </Btns>
                                            </FilaModalHotel>
                                    }
                                    <Btns style={{marginBottom:pc?"1vw":"1vw", marginTop:pc? "3.5vw" : "7vw"}}>
                                        <Button onClick={()=>{setOferta(false); const newRangos = rangos; newRangos.splice(rangos.length-1, 1); setRangos(JSON.parse(JSON.stringify(newRangos)));  const inputs1 = inputs; inputs1.oferta = defaultInput.oferta; setInputs(JSON.parse(JSON.stringify(inputs1)))}} style={{fontSize:pc ? "0.85vw" : "3vw", padding:pc ? "0.3vw" : "1vw"}}>Cerrar</Button>
                                        <Button onClick={()=>validarOferta()} style={{fontSize:pc ? "0.85vw" : "3vw", padding:pc ? "0.3vw" : "1vw"}}>Añadir oferta</Button>
                                    </Btns>
                                </FilaModalHotel>
                                :
                                <FilaModalHotel>
                                    <Btns>
                                        <Button disabled={cargandoHotel} opacity={cargandoHotel} onClick={()=>{if(!cargandoHotel){setOferta(true); setRangos([...rangos, []])}}} style={{fontSize:pc ? "0.85vw" : "3vw", padding:pc ? "0.3vw" : "1vw"}}>Agregar oferta</Button>
                                    </Btns> 
                                </FilaModalHotel>
                            }
                            <Btns>
                                <Button opacity={cargandoHotel} onClick={()=>!cargandoHotel && setModalAddHotel(false)}>Cancelar</Button>
                                <Button opacity={cargandoHotel} style={{backgroundColor:cargandoHotel ? "#fff" : ""}} onClick={()=>{if (!cargandoHotel){agregarVilla()}}} yes={true}>
                                    { cargandoHotel ?
                                        <CargandoCont><img src={cargandoImg}/></CargandoCont>
                                        :
                                        "Agregar villa"
                                    }
                                </Button>
                            </Btns>
                        </ModalAddHotel>
                    </Modal>
                }

                    <div>
                        <HotelesContenedor paddingBottom={true}>
                            <h2>Villas</h2>
                            {villas !== undefined && villas !== false && villas.length > 0 &&
                                <BuscarContenedor>
                                    <input value={inputHotel} onChange={(e)=>{setInputHotel(e.target.value)}} placeholder="Busca una villa"/>
                                    {(inputHotel || search !== undefined) && <img onClick={()=>{setSearch(undefined); setInputHotel(""); setLocalidadInput("Todos"); document.querySelector("#optionLocalVillas").value="Todos"}} style={{cursor:"pointer", width:pc ? "2.5vw" : "8vw", height: pc ? "2.5vw" : "8vw", margin:"auto 0"}} src={quitar1}/>}
                                    <button type="submit" onClick={(e)=>buscarVilla(e)}>
                                        <img src={buscar}/>
                                    </button>
                                </BuscarContenedor>
                            }
                            { villas !== undefined && villas !== false && villas.length > 0 &&
                                <div style={{display:"flex", alignItems:"center", marginLeft:"auto", marginRight:"10%", marginTop:pc ? "0.5vw" : "1.4vw"}}>
                                    <p style={{marginRight:pc ? "0.5vw" : "1vw", color:"#515151", fontSize:pc ? "1.3vw" : "3.5vw"}}>Zona de villas:</p>
                                    <select style={{cursor:"pointer", fontSize:!pc && "3.5vw"}} id="optionLocalVillas" defaultValue="Todos" onChange={(e)=>getVillasLocalidad(e.target.value)}>
                                        <option defaultValue="Todos">Todos</option>
                                        <option>Zona este</option>
                                        <option>Zona norte</option>
                                    </select>
                                </div>
                            }
                            { cargando ?
                                <CargandoCont>
                                    <img src={cargandoImg} />
                                </CargandoCont>
                                : search !== undefined ? 
                                <HotelesContenedor>
                                    <HotelesCont>
                                        {(search !== false) ?
                                            search.length > 0 ?
                                            search.map((value)=>(
                                                <Villa title={value.nombre} key={value.id} onClick={()=>{setidVilla(value.id); setModalVilla(true)}}>
                                                    <HotelImgFondo imagen={hotelSinImg}><HotelImg imagen={value.fotoPerfil}/></HotelImgFondo>
                                                    <p>{value.nombre.length > 15 ? value.nombre.slice(0, 16)+"…" : value.nombre}</p>
                                                </Villa>
                                            ))
                                            : 
                                            <p>No hay resultados para tu busqueda.</p> 
                                        : <p>{msgVal2}</p>
                                        }
                                    </HotelesCont>
                                </HotelesContenedor>
                                : localidadInput != "Todos" ?
                                    localidad !== undefined ?
                                        <HotelesContenedor>
                                            <HotelesCont>
                                                {(localidad[localidadInput] !== false) ?
                                                    localidad[localidadInput].length > 0 ?
                                                    localidad[localidadInput].map((value)=>(
                                                        <Villa title={value.nombre} key={value.id} onClick={()=>{setidVilla(value.id); setModalVilla(true)}}>
                                                            <HotelImgFondo imagen={hotelSinImg}><HotelImg imagen={value.fotoPerfil}/></HotelImgFondo>
                                                            <p>{value.nombre.length > 15 ? value.nombre.slice(0, 16)+"…" : value.nombre}</p>
                                                        </Villa>
                                                    ))
                                                    : 
                                                    <p>No hay villas disponibles en esta localidad.</p> 
                                                : <p>{msgVal2}</p>
                                                }
                                            </HotelesCont>
                                            {
                                                (ultimoElementoLocalidad[localidadInput] !== undefined && ultimoElementoLocalidad[localidadInput] !== false) ?
                                                cargando1 ? 
                                                    <CargandoCont><img src={cargandoImg}/></CargandoCont>
                                                    : 
                                                    <p onClick={()=>getVillasLocalidad(undefined, true)} style={{cursor:"pointer", textDecoration:"underline", padding:"0.5vw"}}>Ver mas</p>
                                                : 
                                                undefined   
                                            }
                                    </HotelesContenedor>
                                    : undefined
                                : <HotelesContenedor>
                                    <HotelesCont>
                                        <Villa onClick={()=>setModalAddHotel(true)}>
                                            <HotelImg add={true} imagen={mas} />
                                            <p>Añadir villa</p>
                                        </Villa>
                                        {(villas !== false && villas !== undefined) ?
                                            villas.length > 0 ?
                                            villas.map((value)=>(
                                                <Villa title={value.nombre} key={value.id} onClick={()=>{setidVilla(value.id); setModalVilla(true)}}>
                                                    <HotelImg imagen={value.fotoPerfil}/>
                                                    <p>{value.nombre.length > 15 ? value.nombre.slice(0, 16)+"…" : value.nombre}</p>
                                                </Villa>
                                            ))
                                            : 
                                            <p>No hay villas disponibles.</p> 
                                        : <p>{msgVal2}</p>
                                        }
                                    </HotelesCont>
                                    {
                                        (ultimoElemento !== undefined && ultimoElemento !== false) ?
                                        cargando1 ? 
                                            <CargandoCont><img src={cargandoImg}/></CargandoCont>
                                            : 
                                            <p onClick={()=>obtenerVillas()} style={{cursor:"pointer", textDecoration:"underline", padding:"0.5vw"}}>Ver mas</p>
                                        : 
                                        undefined   
                                    }
                                </HotelesContenedor>        
                            }
                        </HotelesContenedor>
                        <Modal visible={modalHotel} setVisible={()=>{setidVilla(""); setModalVilla(false)}}>
                            <ModalEliminar> 
                                    <h3>¿Que deseas hacer?</h3>
                                    {/* <p>Esta acción no se puede deshacer.</p> */}
                                    <Btns>
                                        <Button edit={true} onClick={()=>setEditVilla(true)} >Ver/Modificar</Button>
                                        <Button red={true} onClick={()=>{setModalVilla(false); setModal(true)}}>Eliminarlo</Button>
                                    </Btns>
                            </ModalEliminar>
                        </Modal>
                    </div>
                <BtnCerrar onClick={()=>setModal(false)}><img src={quitar}/></BtnCerrar>
                <Msg activo={msgVal1[1]} error={msgVal1[0]}><div><p>{msgVal1[1]}</p></div></Msg>
            </PlataformaContainer>
            }
        </div>
    );
}