import styled from "styled-components";

const pc = window.screen.width > 529 ? true : false;

export const BtnConfirmar = styled.button`
    padding: ${pc ? "0.5vw" : "1vw"};
    border-radius: ${pc ? "0.5vw" : "1vw"};
    color:#fff;
    background-color:#26AC20;
    border:none;
    cursor: pointer;
`;