import styled from "styled-components"

const pc = window.screen.width > 529 ? true : false;

const Pagina404 = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: ${props => props.marginTop === true ? "0" : "32vh"};
    height: ${props => props.marginTop && "100vh"};
    margin-left: ${pc && "17vw"};

    & h3 {
        font-size: ${pc ? "3.5vw" : "7vw"};
        font-size: 600;
    }
    & p {
      text-align: center;
      font-weight: 400;
    }
`;

export default function ({marginTop}){

    return (
        <Pagina404 marginTop={marginTop}>
            <h3>Pagina no encontrada</h3>
            <p>Esto se debe a que el link que visitaste posiblemente fue eliminado o el link es incorrecto.</p>
        </Pagina404>
    )
}