import React from "react";
import {Document, Page, Text, View, Image, Font} from "@react-pdf/renderer";
import bordeImg from "../../../images/bordePDF1.png";
import logo from "../../../images/logo3.png";
import Fuente100 from "../../../fonts/Poppins-Thin.ttf";
import Fuente200 from "../../../fonts/Poppins-Light.ttf";
import Fuente300 from "../../../fonts/Poppins-Regular.ttf";
import Fuente400 from "../../../fonts/Poppins-Medium.ttf";
import Fuente500 from "../../../fonts/Poppins-SemiBold.ttf";
import Fuente600 from "../../../fonts/Poppins-Bold.ttf";
import { cambiarFechaInput } from "../../utils/validar";

Font.register({
    family: 'Poppins',
    fonts:[
        {src:Fuente100, fontStyle:"normal", fontWeight: 100},
        {src:Fuente200, fontStyle:"normal", fontWeight: 200},
        {src:Fuente300, fontStyle:"normal", fontWeight: 300},
        {src:Fuente400, fontStyle:"normal", fontWeight: 400},
        {src:Fuente500, fontStyle:"normal", fontWeight: 500},
        {src:Fuente600, fontStyle:"normal", fontWeight: 600}
    ]
});

export default function cotizacionPdf ({info}){

    const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

    function fecha (fechaEntrada, fechaSalida){
        let dateEntrada = cambiarFechaInput(fechaEntrada);
        let dateSalida = cambiarFechaInput(fechaSalida);

        dateEntrada = new Date(dateEntrada[5]+dateEntrada[6]+"/"+dateEntrada[8]+dateEntrada[9]+"/"+dateEntrada[0]+dateEntrada[1]+dateEntrada[2]+dateEntrada[3]);
        dateSalida = new Date(dateSalida[5]+dateSalida[6]+"/"+dateSalida[8]+dateSalida[9]+"/"+dateSalida[0]+dateSalida[1]+dateSalida[2]+dateSalida[3]);

        let fecha = "";

        if (dateEntrada.getMonth() == dateSalida.getMonth()) {
            fecha = dateEntrada.getDate() + "-" + dateSalida.getDate() + " de " + meses[dateEntrada.getMonth()]
        } else if (dateEntrada.getMonth() != dateSalida.getMonth()) {
            fecha = dateEntrada.getDate() + " de " + meses[dateEntrada.getMonth()] + " - " + dateSalida.getDate() + " de " + meses[dateSalida.getMonth()];
        }
        
        return fecha;
    }

    function acortarNumero (num){
        let total = num;
        let totalFull = "";
        let index = false;

        if (total != undefined && total != null) {
            total = total.toString().split("");

            total.map((num, index1)=>{
                if (num == ".") {
                    index = index1;
                };
            });
    
            if (index) {
                total.map((num, index1)=>{
                    if (index1 <= index+2) {
                        totalFull = totalFull+num;
                    };
                });
            } else {
                totalFull = num;
            }
        } else {
            totalFull = num;
        }

        return totalFull;
    }

    return (
        <Document>
            <Page size="A4">
                <View style={{display:"flex", flexDirection:"column", fontFamily:"Poppins", fontWeight:300}}>
                    <Image src={bordeImg} style={{position:"absolute", left:0, top:0, height:"100vh", width:"6.2vw"}} />
                    <Image style={{width:"15.1vw", marginLeft:"15.5vw", marginTop:"4vw"}} src={logo}/>
                    <Text style={{marginTop:"2vw", marginLeft:"auto", letterSpacing:"0.5vw", marginRight:"6vw", fontSize:"3.9vw", color:"#004aad", fontFamily: 'Poppins', fontWeight:600}}>
                        COTIZACIÓN HOTEL
                    </Text>
                    <View style={{display:"flex", width:"35vw", fontSize:"2vw", margin:"2vw 5vw 0 auto"}}>
                        <Text style={{color:"#004aad", fontWeight:500}}>FECHA: <Text style={{color:"#000", fontWeight:300}}>{new Date ().getDate()+" de "+meses[new Date().getMonth()]+" del "+new Date().getUTCFullYear()}</Text></Text>
                        <Text style={{color:"#004aad", fontWeight:500}}>CLIENTE: <Text style={{color:"#000", fontWeight:300}}>{info.form[0].cliente[0]}</Text></Text>   
                        <Text style={{color:"#004aad", fontWeight:500}}>HOTEL: <Text style={{color:"#000", fontWeight:300}}>{info.hotel}</Text></Text>
                    </View>
                    <View style={{ padding:"0.5vw", paddingLeft:"1vw", width:"92%", borderRadius:"4px", marginLeft:"7vw", marginTop:"3vw", backgroundColor:"#004aad"}}>
                        <Text style={{color:"#fff", fontWeight:600, margin:"1vw auto"}}>DETALLES</Text>
                        <View style={{margin:"0 auto", width:"98%", border:"1px solid #fff", marginBottom:"2vw", borderBottom:"none", borderRight:"none"}}>
                            <View style={{display:"flex", flexDirection:"row", borderBottom:"1px solid #fff"}}>
                                <View style={{display:"flex", justifyContent:"center", borderRight:"1px solid #fff", width:"16.66%", padding:"2vw"}}>
                                    <Text style={{fontSize:"1.5vw", textAlign:"center", color:"#fff"}}>Codigo Tarifa</Text>
                                </View>
                                <View style={{display:"flex", justifyContent:"center", borderRight:"1px solid #fff", width:"16.66%", padding:"2vw"}}>
                                    <Text style={{fontSize:"1.5vw", textAlign:"center", color:"#fff"}}>Habitación</Text>
                                </View>
                                <View style={{display:"flex", justifyContent:"center", borderRight:"1px solid #fff", width:"16.66%", padding:"2vw"}}>
                                    <Text  style={{fontSize:"1.5vw", textAlign:"center", color:"#fff"}}>Fecha</Text>
                                </View>
                                <View style={{display:"flex", justifyContent:"center", borderRight:"1px solid #fff", width:"16.66%", padding:"2vw"}}>
                                    <Text style={{fontSize:"1.5vw", textAlign:"center", color:"#fff"}}>Ocupación</Text>
                                </View>
                                <View style={{display:"flex", justifyContent:"center", borderRight:"1px solid #fff", width:"16.66%", padding:"2vw"}}>
                                    <Text style={{fontSize:"1.5vw", textAlign:"center", color:"#fff"}}>Tarifa adulto x noche</Text>
                                </View>
                                <View style={{display:"flex", justifyContent:"center",borderRight:"1px solid #fff", width:"16.66%", padding:"2vw"}}>
                                    <Text style={{fontSize:"1.5vw", textAlign:"center", color:"#fff"}}>Tarifa niño x noche</Text>
                                </View>
                              {/*   <View style={{display:"flex", justifyContent:"center", borderRight:"1px solid #fff", width:"12%", padding:"0.5vw"}}>
                                    <Text style={{fontSize:"1.3vw", textAlign:"center", color:"#fff"}}>Subtotal</Text>
                                </View> */}
                            </View>
                            { info.form.map((value, index)=>(
                                <View style={{display:"flex", flexDirection:"row", borderBottom:"1px solid #fff"}}>
                                     <View style={{display:"flex", justifyContent:"center", borderRight:"1px solid #fff", width:"16.66%", padding:"2vw"}}>
                                        <Text style={{fontSize:"1.5vw", textAlign:"center", color:"#fff"}}>{value.codigoTarifa}</Text>
                                    </View>
                                    <View style={{display:"flex", justifyContent:"center", borderRight:"1px solid #fff", width:"16.66%", padding:"2vw"}}>
                                        <Text style={{fontSize:"1.5vw", textAlign:"center", color:"#fff"}}>{value.habitacion}</Text>
                                    </View>
                                    <View style={{display:"flex", justifyContent:"center", borderRight:"1px solid #fff", width:"16.66%", padding:"2vw"}}>
                                        <Text style={{fontSize:"1.5vw", textAlign:"center", color:"#fff"}}>{fecha(value.fechaEntrada[0], value.fechaSalida[0])}</Text>
                                    </View>
                                    <View style={{display:"flex", justifyContent:"center", borderRight:"1px solid #fff", width:"16.66%", padding:"2vw"}}>
                                        <Text style={{fontSize:"1.5vw", textAlign:"center", color:"#fff"}}>{value.adultos[0]} adulto{value.adultos[0] > 1 && "s"} { value.niños[0] > 0 && " +"}</Text>
                                        <Text style={{fontSize:"1.5vw", textAlign:"center", color:"#fff"}}>{value.niños[0] > 0 && value.niños[0] + " niño"}{value.niños[0] > 1 && "s"}</Text>
                                    </View>
                                    <View style={{display:"flex", justifyContent:"center", borderRight:"1px solid #fff", width:"16.66%", padding:"2vw"}}>
                                        <Text style={{fontSize:"1.5vw", textAlign:"center", color:"#fff"}}>RD ${acortarNumero(info.infoPrecios.precioAdulto[index])}</Text>
                                    </View>
                                    <View style={{display:"flex", justifyContent:"center", borderRight:"1px solid #fff", width:"16.66%", padding:"2vw"}}>
                                        <Text style={{fontSize:"1.5vw", textAlign:"center", color:"#fff"}}>RD ${acortarNumero(info.infoPrecios.precioNiño[index]) || 0}</Text>
                                    </View>
                                 {/*    <View style={{display:"flex", justifyContent:"center", borderRight:"1px solid #fff", width:"12%", padding:"2vw"}}>
                                        <Text style={{fontSize:"1.3vw", textAlign:"center", color:"#fff"}}>RD ${(info.infoPrecios.precioAdulto[index]*value.adultos[0])*info.infoPrecios.dias+(info.infoPrecios.precioNiño[index]*value.niños[0])*info.infoPrecios.dias}</Text>
                                    </View> */}
                                </View>
                            ))
                            }
                            <View style={{display:"flex", flexDirection:"row", borderBottom:"1px solid #fff"}}>
                                <View style={{borderRight:"1px solid #fff", width:"16.66%", padding:"2vw"}}>
                                    <Text style={{fontSize:"1.5vw", fontWeight:500, textAlign:"center", color:"#fff"}}>Total:</Text>
                                </View>
                                <View style={{borderRight:"1px solid #fff", width:"16.66%", padding:"2vw"}}>
                                    <Text style={{fontSize:"1.5vw", fontWeight:500, textAlign:"center", color:"#fff"}}>USD ${info.total}</Text>
                                </View>
                                <View style={{borderRight:"1px solid #fff", width:"66.68%", padding:"2vw"}}>
                                </View>
                            </View>
                        </View>
                        {info.infoPrecios.precioNiño.length > 0 && <Text style={{fontSize:"1.9vw", margin:"1vw", marginLeft:"auto", fontWeight:500, color:"#fff"}}>{info.infoPrecios.niñoGratis} niño gratis</Text>}
                    </View>
                    <View style={{display:"flex", marginTop:"5vw", fontWeight:400, marginLeft:"8vw", color:"#5F5F5F", fontSize:"1.7vw", flexDirection:"column"}}>
                        <Text style={{fontWeight:600, color:"#494949"}}>Condiciones de pago:</Text>
                        <Text>*Se confirma la reserva con RD$1.000 por habitación.</Text>
                        <Text>*El monto restante debe estar saldado 7 días antes del día de entrada.</Text>
                        <Text>*El cambio de la moneda se realizará de acuerdo a la tasa del actual, según el banco banreservas.</Text>
                        <Text style={{fontWeight:600, marginTop:"3vw", color:"#494949"}}>-Politicas:</Text>
                        <Text style={{width:"90%", marginBottom:"2vw"}}>*En caso de que esta reserva este próxima a la fecha del resort no tendrá fecha de cancelación. En caso de querer cancelar conllevara una penalización según contrato de la agencia con el Hotel.</Text>
                    </View>
                    <View style={{position:"absolute", height:"100vh", flexDirection:"column", alignItems:"center", justifyContent:"flex-end", width:"100vw", display:"flex"}}>
                        <Text style={{color:"#004aad", fontWeight:500}}>¡GRACIAS!</Text>
                        <View style={{height:"3px", marginBottom:"1px", marginLeft:"auto", marginRight:"5vw", backgroundColor:"#004aad", width:"83%"}} />
                        <Text style={{fontSize:"2vw"}}>TurisFreelance.com</Text>
                        <Text style={{fontSize:"1.8vw", marginBottom:"2px"}}>+1 (849) 465-2311</Text>
                    </View>
                </View>
            </Page>
        </Document>
    )
}