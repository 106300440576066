import React from "react";
import {Document, Page, Text, View, Image, Font} from "@react-pdf/renderer";
import bordeImg from "../../../images/bordePDF1.png";
import logo from "../../../images/logo3.png";
import Fuente100 from "../../../fonts/Poppins-Thin.ttf";
import Fuente200 from "../../../fonts/Poppins-Light.ttf";
import Fuente300 from "../../../fonts/Poppins-Regular.ttf";
import Fuente400 from "../../../fonts/Poppins-Medium.ttf";
import Fuente500 from "../../../fonts/Poppins-SemiBold.ttf";
import Fuente600 from "../../../fonts/Poppins-Bold.ttf";
import { cambiarFechaInput } from "../../utils/validar";

Font.register({
    family: 'Poppins',
    fonts:[
        {src:Fuente100, fontStyle:"normal", fontWeight: 100},
        {src:Fuente200, fontStyle:"normal", fontWeight: 200},
        {src:Fuente300, fontStyle:"normal", fontWeight: 300},
        {src:Fuente400, fontStyle:"normal", fontWeight: 400},
        {src:Fuente500, fontStyle:"normal", fontWeight: 500},
        {src:Fuente600, fontStyle:"normal", fontWeight: 600}
    ]
});

export default function cotizacionPdf ({info}){

    const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

    function fecha (fechaProp){
        let date = cambiarFechaInput(fechaProp);

        date = new Date(date[5]+date[6]+"/"+date[8]+date[9]+"/"+date[0]+date[1]+date[2]+date[3]);

        let fecha = "";

        fecha = date.getDate() + " de " + meses[date.getMonth()];
        
        return fecha;
    }

    return (
        <Document>
            <Page size="A4">
                <View style={{display:"flex", flexDirection:"column", fontFamily:"Poppins", fontWeight:300}}>
                    <Image src={bordeImg} style={{position:"absolute", left:0, top:0, height:"100vh", width:"6.2vw"}} />
                    <Image style={{width:"15.1vw", marginLeft:"15.5vw", marginTop:"4vw"}} src={logo}/>
                    <Text style={{marginTop:"2vw", marginLeft:"auto", letterSpacing:"0.5vw", marginRight:"6vw", fontSize:"3.9vw", color:"#004aad", fontFamily: 'Poppins', fontWeight:600}}>
                        COTIZACIÓN VILLA
                    </Text>
                    <View style={{display:"flex", width:"35vw", fontSize:"2vw", margin:"2vw 5vw 0 auto"}}>
                        <Text style={{color:"#004aad", fontWeight:500}}>FECHA: <Text style={{color:"#000", fontWeight:300}}>{new Date().getDate()+" de "+meses[new Date().getMonth()]+" del "+new Date().getFullYear()}</Text></Text>
                        <Text style={{color:"#004aad", fontWeight:500}}>CLIENTE: <Text style={{color:"#000", fontWeight:300}}>{info.form[0].cliente[0]}</Text></Text>   
                        <Text style={{color:"#004aad", fontWeight:500}}>VILLA: <Text style={{color:"#000", fontWeight:300}}>{info.villa}</Text></Text>
                    </View>
                    <View style={{ padding:"0.5vw", paddingLeft:"1vw", width:"88vw", borderRadius:"4px", marginLeft:"10vw", marginTop:"6vw", backgroundColor:"#004aad"}}>
                        <Text style={{color:"#fff", fontWeight:600, margin:"1vw auto"}}>DETALLES</Text>
                        <View style={{margin:"0 auto", width:"98%", border:"1px solid #fff", marginBottom:"2vw", borderBottom:"none", borderRight:"none"}}>
                            <View style={{display:"flex", flexDirection:"row", borderBottom:"1px solid #fff"}}>
                                <View style={{display:"flex", justifyContent:"center", borderRight:"1px solid #fff", width:"25%", padding:"2vw"}}>
                                    <Text style={{fontSize:"1.5vw", textAlign:"center", color:"#fff"}}>Codigo Tarifa</Text>
                                </View>
                                <View style={{display:"flex", justifyContent:"center", borderRight:"1px solid #fff", width:"25%", padding:"2vw"}}>
                                    <Text style={{fontSize:"1.5vw", textAlign:"center", color:"#fff"}}>Fecha Entrada</Text>
                                </View>
                                <View style={{display:"flex", justifyContent:"center", borderRight:"1px solid #fff", width:"25%", padding:"2vw"}}>
                                    <Text style={{fontSize:"1.5vw", textAlign:"center", color:"#fff"}}>Fecha Salida</Text>
                                </View>
                                <View style={{display:"flex", justifyContent:"center", borderRight:"1px solid #fff", width:"25%", padding:"2vw"}}>
                                    <Text style={{fontSize:"1.5vw", textAlign:"center", color:"#fff"}}>Rango Personas</Text>
                                </View>
                            </View>
                            { info.form.map((value, index)=>(
                                <View style={{display:"flex", flexDirection:"row", borderBottom:"1px solid #fff"}}>
                                    <View style={{display:"flex", justifyContent:"center", borderRight:"1px solid #fff", width:"25%", padding:"2vw"}}>
                                        <Text style={{fontSize:"1.5vw", textAlign:"center", color:"#fff"}}>{value.codigoTarifa}</Text>
                                    </View>
                                    <View style={{display:"flex", justifyContent:"center", borderRight:"1px solid #fff", width:"25%", padding:"2vw"}}>
                                        <Text style={{fontSize:"1.5vw", textAlign:"center", color:"#fff"}}>{fecha(value.fechaEntrada[0])}</Text>
                                    </View>
                                    <View style={{display:"flex", justifyContent:"center", borderRight:"1px solid #fff", width:"25%", padding:"2vw"}}>
                                        <Text style={{fontSize:"1.5vw", textAlign:"center", color:"#fff"}}>{fecha(value.fechaSalida[0])}</Text>
                                    </View>
                                    <View style={{display:"flex", justifyContent:"center", borderRight:"1px solid #fff", width:"25%", padding:"2vw"}}>
                                        <Text style={{fontSize:"1.5vw", textAlign:"center", color:"#fff"}}>{value.rangoPersonas[0]} personas</Text>
                                    </View>
                                </View>
                            ))
                            }
                            <View style={{display:"flex", flexDirection:"row", borderBottom:"1px solid #fff"}}>
                                <View style={{borderRight:"1px solid #fff", width:"25%", padding:"2vw"}}>
                                    <Text style={{fontSize:"1.5vw", textAlign:"center", color:"#fff"}}>Total:</Text>
                                </View>
                                <View style={{borderRight:"1px solid #fff", width:"25%", padding:"2vw"}}>
                                    <Text style={{fontSize:"1.5vw", textAlign:"center", color:"#fff"}}>USD ${info.total}</Text>
                                </View>
                                <View style={{borderRight:"1px solid #fff", width:"50%", padding:"2vw"}}>
                                </View>
                            </View>
                        </View>
                        {/* <Text style={{fontSize:"1.6vw", fontWeight:500, color:"#fff"}}>Precio niño: <Text style={{fontSize:"1.6vw", fontWeight:300, color:"#fff"}}>RD ${info.infoPrecios.precioNiño} ({info.infoPrecios.niñoGratis} niño gratis)</Text></Text> */}
                    </View>
                    {/* <View style={{display:"flex", fontWeight:400, marginLeft:"8vw", marginTop:"15vw", color:"#5F5F5F", fontSize:"1.7vw", flexDirection:"column"}}>
                        <Text style={{fontWeight:600, color:"#494949"}}>Condiciones de pago:</Text>
                        <Text>*Se confirma la reserva con el 10% monto total.</Text>
                        <Text>*El monto restante debe estar saldado 15 días antes del día de entrada.</Text>
                        <Text>*El cambio de la moneda se realizará de acuerdo a la tasa del actual, según el banco banreservas.</Text>
                        <Text style={{fontWeight:600, marginTop:"3vw", color:"#494949"}}>-Politicas:</Text>
                        <Text style={{width:"90%"}}>*En caso de que esta reserva este próxima a la fecha del resort no tendrá fecha de cancelación. En caso de querer cancelar conllevara una penalización según contrato de la agencia con el Hotel.</Text>
                    </View> */}
                    <View style={{position:"absolute", height:"100vh", flexDirection:"column", alignItems:"center", justifyContent:"flex-end", width:"100vw", display:"flex"}}>
                        <Text style={{color:"#004aad", fontWeight:500}}>¡GRACIAS!</Text>
                        <View style={{height:"3px", marginBottom:"1px", marginLeft:"auto", marginRight:"5vw", backgroundColor:"#004aad", width:"83%"}} />
                        <Text style={{fontSize:"2vw"}}>TurisFreelance.com</Text>
                        <Text style={{fontSize:"1.8vw", marginBottom:"2px"}}>+1 (849) 465-2311</Text>
                    </View>
                </View>
            </Page>
        </Document>
    )
}