import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { getSeguros, modificarSeguro } from "../../config/auth";
import sessionContext from "../../contexts/sessionContext";
import CargandoCont from "../utils/cargando";
import Modal from "../utils/modal";

// imagenes
import cargandoImg from "../../images/cargando.gif";

const pc = window.screen.width > 529 ? true : false;

const SegurosContenedor = styled.div`
    
    & h2 {
        font-size: ${pc ? "2vw" : "7vw"};
        font-weight: 700;
        margin-bottom: ${pc ? "1vw" : "3vw"};
    }
`;

const SegurosCont = styled.div`
    padding: 1vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
        
    & h3 {
        color:#545454;
        font-weight: 700;
        font-size: ${pc ? "" : "4.5vw"};
    }
`;

const Seguro = styled.div`
    display: flex;
    align-items: center;
    width: ${pc ? "30%" : "40%"};
    flex-direction: column;
    margin-bottom: ${pc ? "2vw" : "5vw"};
`;

const Btn = styled.button`
    display: flex;
    justify-content: center;
    width: ${pc ? "60%" : "80%"};
    padding: ${pc ? "0.4vw" : "1vw"};
    border: none;
    background-color: ${e => e.modificar ? "#F5B114" : "#9B9B9B"};
    margin-bottom: ${pc ? "0.6vw" : "1vw"};
    color:#fff;
    border-radius: ${pc ? "0.4vw" : "1vw"};
    font-size: ${pc ? "" : "3vw"};
    cursor: pointer;

    &:hover {
        background-color: ${e => e.modificar ? "#F0AC0F" : "#8C8C8C"};
    }
`;

const Msg = styled.div`
  position: fixed;
  display: ${props => props.activo ? "flex" : "none"};
  width: 100%;
  align-items: center;
  justify-content: center;
  bottom: 0;
  left: 0;

  & div {
    padding: ${pc ? "1vw 2vw" : "2vw 3vw"};
    margin-bottom: 3px;
    text-align: center;
    border-top-right-radius: 0.4vw;
    border-top-left-radius: 0.4vw;
    background-color: ${props => props.error ? "#26AC20" : "#eb1a1a"};
    color: #fff;
  }
`;

export default function Seguros (){

    const { session } = useContext(sessionContext);

    let [modal, setModal] = useState(false);
    let [msgVal, setMsgVal] = useState([false, ""]);

    const seguros = ["euroschengen", "gold", "national", "platinum", "plus", "silver", "student"];
    let [segurosInfo, setSegurosInfo] = useState({});
    let [seguro, setSeguro] = useState();

    function primerLetraMayuscula (e){
        const letras = e.split("");
        let word = letras[0].toUpperCase();
        
        letras.map((value, index)=>{
            if (index > 0) {
                word = word + value;
            }
        });

        return word;
    }

    async function subirSeguro (archivo){
        setModal(true);
        const result = await modificarSeguro({seguro:seguro, archivo:archivo});
        if (result === true) {
            obtenerSeguros();
            setMsgVal([true, "Seguro modificado correctamente"]);
            setTimeout(()=>{
                setMsgVal([false, ""]);
            },2500);
        } else {
            setMsgVal([false, result]);
            setTimeout(()=>{
                setMsgVal([false, ""]);
            },2500);
        }
        setModal(false);
    }

    async function obtenerSeguros(){
        const result = await getSeguros();
        if (result[0] === true) {
            setSegurosInfo(result[1]);
        }
    }

    useEffect(()=>{
        if (session.user) {
            obtenerSeguros();
        }
    },[session.user]);

    return (
        <SegurosContenedor>
            <Modal cargando={true} visible={modal} setVisible={()=>{}}>
                <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
                    <p>Modificando seguro</p> 
                    <CargandoCont>
                        <img src={cargandoImg}/>
                    </CargandoCont>
                </div>
            </Modal>
            <input type="file" id="btnSeguro" onChange={(e)=>subirSeguro(e.target.files[0])} style={{display:"none"}} />
            <h2>Seguros</h2>
            <SegurosCont>
                { seguros.map((value)=>(
                    <Seguro key={value}>
                        <h3>{primerLetraMayuscula(value)}</h3>
                        <Btn onClick={()=>window.open(segurosInfo[value])}>Ver</Btn>
                        <Btn onClick={()=>{document.querySelector("#btnSeguro").click(); setSeguro(value)}} modificar={true}>Modificar</Btn>
                    </Seguro>
                ))}
            </SegurosCont>
            <Msg activo={msgVal[1]} error={msgVal[0]}><div><p>{msgVal[1]}</p></div></Msg>
        </SegurosContenedor>
    )
}