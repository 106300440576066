import {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import {validarFecha, restaFechas, cambiarFecha, cambiarFechaInput, isNum} from "../utils/validar";
import styled from "styled-components";
import CargandoCont from "../utils/cargando";
import { addFotoPropiedad, añadirHotel, eliminarFotoPropiedad } from "../../config/auth";
import Modal from "../../components/utils/modal";

// imagenes
import cargandoImg from "../../images/cargando.gif";
import quitar from "../../images/quitar.png";
import edit from "../../images/edit.png";
import edit1 from "../../images/edit1.png";
import quitar1 from "../../images/quitar1.png";
import addImg from "../../images/addImg.png";

const pc = window.screen.width > 529 ? true : false;

const PlataformaContainer = styled.div`
    display: flex;
    padding: 3vw;
    padding-top: ${pc ? "6vw" : "18vw"};
    background-color: #fff;
    flex-direction:column;
    margin-left: ${pc && "17vw"};

    & h2 {
        font-size: ${pc ? "2vw" : "7vw"};
        font-weight: 700;
        margin-bottom: ${pc ? "1vw" : "3vw"};
    }

    & h3 {
        font-weight: 700;
        margin-bottom: ${pc ? "1vw" : ""};
        font-size: ${pc ? "1.9vw" : ""};
    }

    & h4 {
        font-weight: 600;
        font-size: ${pc ? "1.4vw" : ""};
        color:#004aad;
        margin-bottom: ${pc ? "0.3vw" : ""};
        margin-right: auto;
        border-bottom: 1px solid #C4C4C4;
        width: 100%;
    }
`;

const FilaModalHotel = styled.div`
    display: flex;
    flex-direction: ${pc ? "row" : "column"};
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    margin-bottom: ${pc ? "1.2vw" : "2vw"};
`;

const Campo = styled.div`
    display: flex;
    flex-direction: ${e => e.oferta ? "row" : "column"};
    margin-right: ${e => e ? pc && "0" : pc && "2vw"};
    width: ${e => pc ? e.oferta ? "100%" : "23%" : e.oferta ? "100%" : "90%"};
    margin:${!pc && "0 auto"};
    margin-bottom: ${e => e.oferta && pc ? "1vw" : "2vw"};
    position: relative;
    background-color: ${e => e.oferta && "#004aad"};
    color: ${e => e.oferta && "#fff"};
    padding: ${e => e.oferta && pc ? "0.5vw" : "1vw"};
    border-radius: ${e => e.oferta && pc ? "0.5vw" : "1vw"};
    padding-left: ${e => e.oferta ? "1vw" : "0"};
    align-items: ${e => e.oferta && "center"};

    & img {
        width: ${pc ? "1.8vw" : "6vw"};
        cursor: pointer;
    }

    & label {
        color:#000;
        font-weight: 500;
        font-size: ${pc ? "1.1vw" : ""};
    }

    & input {
        padding: ${pc ? "0.3vw" : "0.5vw 1vw"};
        border: 1px solid #999797;
        border-radius: 3px;
        font-size: ${pc ? "0.9vw" : "3.5vw"};
        color:#505050;        
    }
    & textarea {
        padding: ${pc ? "0.3vw" : "0.5vw 1vw"};
        border: 1px solid #999797;
        border-radius: 3px;
        font-size: ${pc ? "0.9vw" : "3.5vw"};
        color:#505050;
        resize: none;
    }

    & input:focus {
        outline:1px solid #B3B3B3;  
    }
    & textarea:focus {
        outline:1px solid #B3B3B3;  
    }
`;

const SpanValidacion = styled.div`
    background-color: #eb1a1a;
    display: ${e => e.active ? "block" : "none"};
    color: #fff;
    padding: ${pc ? "0.5vw" : "1vw"};
    position: absolute;
    bottom: 70%;
    right: 5%;
    border-radius: ${pc ? "0.5vw" : "1vw"};
    font-size: ${pc ? "0.9vw" : "2.5vw"};
    opacity: 0.91;

    & span {
        z-index: 50;
        position: relative;
    }
`;

const DivLinea = styled.div`
    position: absolute;
    right: 3%;
    bottom: -0.18vw;
    background-color: #eb1a1a;
    width: 1.3vw;
    height: 1.3vw;
    transform: rotate(45deg);
    z-index: 10;
`;

const Btns = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: ${pc ? "90%" : "100%"};
    margin-top: ${pc ? "1vw" : "3vw"};
`;

const Button = styled.button`
    width: 45%;
    background-color: ${props => props.red ? "#eb1a1a" : props.yes ? "#26AC20" : "#767676"};
    color:#fff;
    padding: ${pc ? "0.5vw" : "1.2vw"};
    border: none;
    font-size: ${pc ? "" : "3.2vw"};
    border-radius: ${pc ? "0.5vw" : "1vw"};
    align-items: center;
    justify-content: center;
    cursor: pointer;
    display: flex;

    & img {
        width: ${pc ? "" : "6.5vw"};
    }
`;

const Input = styled.input`
    border:1px solid ${props => props.error ? "#eb1a1a!important" : "#999797"};
`;

const Textarea = styled.textarea`
    border:1px solid ${props => props.error ? "#eb1a1a!important" : "#999797"};
    width: ${pc ? "70vw" : "85vw"};
    height: ${pc ? "35vh" : "20vh"};
    padding: 0.5vw!important;
`;

const Select = styled.select`
    border:1px solid ${props => props.error ? "#eb1a1a!important" : "#999797"};
`;

const Oferta = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    background-color:#004aad;
    border-radius: ${pc ? "0.5vw" : "1vw"};
    padding: ${pc ? "0.5vw" : "2vw"};
    margin-top: ${pc ? "1vw" : "3vw"};

    & span {
        font-size: ${pc ? "1.2vw" : ""};
        color:#fff;
    }
`;

const SubCampo = styled.div`
    display: flex;
    margin-right: ${e => e ? pc && "0" : pc && "2vw"};
    width: 90%;
    margin: 0 auto;
    margin-top: ${pc ? "0.7vw" : "2vw"};
    position: relative;
    background-color: #fff;
    color: #000;
    padding: ${pc ? "0.3vw" : "1vw"};
    border-radius: ${pc ? "0.5vw" : "1vw"};
    align-items: center;

    & img {
        width: ${pc ? "2vw" : "6vw"};
        cursor: pointer;
    }

    & p {
        color:#000;
        font-weight: 500;
        font-size: ${pc ? "1vw" : "2.5vw"};
        margin-left: 0.2vw;
    }
`;

const BtnAddOferta = styled.div`
    background-color: #26AC20;
    width: ${pc ? "40%" : "50%"};
    margin: ${pc ? "1vw" : "2vw"} auto 0 auto;
    padding: ${pc ? "0.3vw" : "1vw"};
    font-size: ${pc ? "1vw" : "3.1vw"};
    color: #fff;
    font-weight: 500;
    cursor: pointer;
    border-radius: ${pc ? "0.2vw" : ""};
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background-color: #2CA026;
    }
`;

const Dias = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`;

const Dia = styled.div`
    width: ${pc ? "20%" : "33.33%"};
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: ${pc ? "0.8vw" : "2vw"};

    & input[type="checkbox"]{
        cursor: pointer
    }
`;

const ImagenesCont = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    & img {
        width: ${pc ? "15vw" : "30vw"} ;
        height: ${pc ? "10vw" : "25vw"};
        margin: ${pc ? "1.5vw" : "5vw"};
    }
    & div{
        position: relative;
    }

    & div:first-child:hover {
        background-color: #EDEDED!important;
    }

    & span {
        background-color: gray;
        position: absolute;
        border-radius: 2.5vw;
        height: ${pc ? "2.5vw" : "7vw"} ;
        width: ${pc ? "2.5vw" : "7vw"};
        display: flex;
        align-items: center;
        justify-content: center;
        right: ${pc ? "2%" : "5%"};
        top: ${pc ? "2%" : "5%"};
        cursor: pointer;
    }
    & span img {
        width: ${pc ? "1.8vw" : "5vw"};
        height: auto;
    }
    
`;

const ModalEliminar = styled.div`
    display: flex;
    padding: 1vw;
    align-items: center;
    flex-direction: column;

    & h3{
        font-weight: 600;
        text-align: center;
        font-size: ${pc ? "1.5vw" : "4.5vw"};
        width: ${!pc && "60vw"};
    }

    & p {
        color:#585858;
        font-size: ${pc ? "" : "4vw"};
    }
`;

const Msg = styled.div`
  position: fixed;
  display: ${props => props.activo ? "flex" : "none"};
  width: 100%;
  align-items: center;
  justify-content: center;
  bottom: 0;
  left: 0;
  z-index: 5000;

  & div {
    padding: ${pc ? "1vw 2vw" : "2vw 3vw"};
    margin-bottom: 3px;
    text-align: center;
    border-top-right-radius: 0.4vw;
    border-top-left-radius: 0.4vw;
    background-color: ${props => props.error ? "#26AC20" : "#eb1a1a"};
    color: #fff;
  }
`;

export default function EditarHotel ({setClose, hotel}){

    const navigate = useNavigate();

    const ofertaDefault = {DBL:["", ""], SGL:["", ""], TPL:["", ""], niños:["", ""], dias:[[], ""], codigoOferta:["", ""], desde:["", ""], habitacion:["", ""], hasta:["", ""], minimoNoches:["", ""], niñoGratis:["", ""], vencimientoOferta:["", ""]};
    const defaultInput = {nombre:["", ""], zonaHotelera:["", ""], descripcion:["", ""]};
    let [newHotel, setNewHotel] = useState(defaultInput);

    let [fotoPerfil, setFotoPerfil] = useState(false);
    let [oferta, setOferta] = useState(false);
    let [ofertaEdit, setOfertaEdit] = useState(false);
    let [ofertasAdd, setOfertasAdd] = useState([]);
    let [idOferta, setIdOferta] = useState(false);
    let [idOfertaEdit, setIdOfertaEdit] = useState(false);
    let [idSubOferta, setIdSubOferta] = useState(false);
    let [tipoOferta, setTipoOferta] = useState(false);

    let [diferente, setDiferente] = useState(false);
    let [diferente1, setDiferente1] = useState(false);

    let [cargando, setCargando] = useState(false);
    let [cargandoAddImg, setCargandoAddImg] = useState(false);
    let [msgVal, setMsgVal] = useState("");
    let [msgVal1, setMsgVal1] = useState([false, ""]);
    let [modalEliminarFoto, setModalEliminarFoto] = useState(false);
    let [idFotoDel, setIdFotoDel] = useState("");

    function validarOferta (){
        let result = true;
        const newInputs = {oferta:ofertaEdit || oferta};

        Object.keys(ofertaEdit || oferta).map((value, index)=>{
            if (value != "ofertas") {
                newInputs.oferta[value][1] = "";
                if (newInputs.oferta[value][0].length > 0) {
                    if (value == "codigoOferta" || value == "habitacion") {
                        if (newInputs.oferta[value][0].length < 4) {
                            newInputs.oferta[value][1] = "Minimo 4 caracteres.";
                            result = false;
                        }  else {
                            if (value == "codigoOferta") {
                                ofertasAdd.map((oferta, index)=>{
                                    if (oferta.codigoOferta == newInputs.oferta.codigoOferta[0] && index !== idOfertaEdit) {
                                        newInputs.oferta.codigoOferta[1] = "Este codigo de oferta ya esta en uso.";
                                        result = false;
                                    };
                                });
                            }
                        }
                    } else if (value == "desde" || value == "hasta" || value == "vencimientoOferta") {
                        if (validarFecha(newInputs.oferta[value][0]) === true) {
                            if (value == "desde" || value == "hasta") {
                                if (restaFechas(newInputs.oferta.desde[0], newInputs.oferta.hasta[0]) == 0) {
                                    newInputs.oferta[value][1] = "Minimo un dia.";
                                    result = false;
                                } else if (restaFechas(newInputs.oferta.desde[0], newInputs.oferta.hasta[0]) < 0) {
                                    newInputs.oferta.hasta[1] = "Esta fecha tiene que ser despues que la fecha Periodo (desde)";
                                    result = false;
                                }
                            }
                        } else {
                            if (validarFecha(newInputs.oferta[value][0]) !== "El dia que ingresaste ya paso." && validarFecha(newInputs.oferta[value][0]) !== "El mes que ingresaste ya paso.") {
                                newInputs.oferta[value][1] = validarFecha(newInputs.oferta[value][0]);
                                result = false;
                            }
                        }
                    } else if (value == "minimoNoches"){
                        if (!isNum(newInputs.oferta[value][0])) {
                            result = false;
                            newInputs.oferta[value][1] = "Solo se admiten numeros, sin puntos, comas ni simbolos.";
                        }
                    } else if (value == "SGL" || value == "DBL" || value == "TPL" || value == "niños"){
                        if (isNaN(newInputs.oferta[value][0])) {
                            result = false;
                            newInputs.oferta[value][1] = "Escribe un numero correcto.";
                        }
                    }
                    
                } else {
                    if (value != "notificado") {
                        newInputs.oferta[value][1] = "Rellena este campo.";
                        result = false;
                    }
                }
            }
        });

        if (result) {
            if (!ofertaEdit) {
                const ofertaGeneral = {ofertas:[{dias:oferta.dias[0], datos:{}}]};

                Object.keys(oferta).map((campo)=>{
                    if (campo == "SGL" || campo == "DBL" || campo == "TPL" || campo == "niños") {
                        ofertaGeneral.ofertas[0].datos[campo] = oferta[campo][0];
                    } else {
                        if (campo != "dias") {
                            ofertaGeneral[campo] = oferta[campo][0];
                        }
                    }
                });

                setOfertasAdd([...ofertasAdd, ofertaGeneral]);
                setOferta(false);
                setIdSubOferta(false);
                setIdOferta(false);
                setTipoOferta(false);
                setDiferente1(true);
            } else {
                setOfertaEdit(false); 
                const ofertaEditNew = ofertaEdit;
                Object.keys(ofertaEditNew).map((campo)=>{
                    if (campo != "ofertas") {
                        ofertaEditNew[campo] = ofertaEditNew[campo][0];
                    }
                });
                const ofertasAddNew = ofertasAdd;
                ofertasAddNew[idOfertaEdit] = ofertaEditNew;

                setOfertasAdd(JSON.parse(JSON.stringify(ofertasAddNew))); 
                setIdOfertaEdit(false);
                setDiferente1(true);
            }
        } else {
            if (ofertaEdit) {
                setOfertaEdit(JSON.parse(JSON.stringify(newInputs.oferta)));
            } else {
                setOferta(JSON.parse(JSON.stringify(newInputs.oferta)));
            }
        }
    }

    function validarSubOferta (){
        let result = true;
        const newInputs = {oferta:oferta};

        Object.keys(oferta).map((value)=>{
            newInputs.oferta[value][1] = "";
            if (newInputs.oferta[value][0].length > 0) {
                if (value == "niños" || value == "SGL" || value == "DBL" || value == "TPL"){
                    if (isNaN(newInputs.oferta[value][0])) {
                        result = false;
                        newInputs.oferta[value][1] = "Escribe un numero correcto.";
                    }
                } else if (value == "dias"){
                    if (idOferta !== false) {
                        ofertasAdd.map((oferta, index)=>{
                            let dias = [];
                            if (index == idOferta) {
                                oferta.ofertas.map((subOferta, index)=>{
                                    if (index !== idSubOferta) {
                                        dias = dias.concat(subOferta.dias);
                                    }
                                });
                            }
                            dias.map((diaOfertas)=>{
                                newInputs.oferta.dias[0].map((diaOferta)=>{
                                    if (diaOfertas == diaOferta) {
                                        newInputs.oferta.dias[1] = newInputs.oferta.dias[0].length > 1 ? "Algunos de los dias que seleccionaste, ya existen." : "El dia que seleccionaste ya existe.";
                                        result = false;
                                    };
                                });
                            });
                        });
                    }
                }
            } else {
                if (value != "notificado") {
                    newInputs.oferta[value][1] = "Rellena este campo.";
                    result = false;
                }
            }
        });

        if (result) {
            const ofertasAddNew = ofertasAdd;
            const indexSubOferta1 = idSubOferta !== false ? idSubOferta : ofertasAdd[idOferta].ofertas.length;
            if (tipoOferta == "update") {
                Object.keys(newInputs.oferta).map((campo)=>{
                    if (campo != "dias") {
                        ofertasAddNew[idOferta].ofertas[indexSubOferta1].datos[campo] = newInputs.oferta[campo][0];
                    } else {
                        ofertasAddNew[idOferta].ofertas[indexSubOferta1].dias = newInputs.oferta.dias[0];
                    }
                });
            } else {
                const subOferta = {dias:newInputs.oferta.dias[0], datos:{}}
                delete newInputs.oferta.dias;
                Object.keys(newInputs.oferta).map((campo)=>{
                    subOferta.datos[campo] = newInputs.oferta[campo][0];
                });
                ofertasAddNew[idOferta].ofertas.push(subOferta);

            }
            setOferta(false);
            setIdOferta(false);
            setTipoOferta(false);
            setIdSubOferta(false);
            setOfertasAdd(JSON.parse(JSON.stringify(ofertasAddNew)));
            setDiferente1(true);
        } else {
            setOferta(JSON.parse(JSON.stringify(newInputs.oferta)));
        }

    }

    function btnSubOferta (tipo, indexOferta, indexSubOferta){
        const ofertaDefaultNew = {};

        ofertaDefaultNew.dias = [tipo == "add" ? [] : ofertasAdd[indexOferta].ofertas[indexSubOferta].dias, ""];

        const campos = ["SGL", "DBL", "TPL", "niños"];
        campos.map((campo)=>{
            ofertaDefaultNew[campo] = [tipo == "add" ? "" : ofertasAdd[indexOferta].ofertas[indexSubOferta].datos[campo], ""];
        });

        tipo == "update" ? setTipoOferta("update") : tipo == "add" && setTipoOferta("add"); 
        setIdOferta(indexOferta);
        setIdSubOferta(indexSubOferta);
        setOferta(ofertaDefaultNew);
    }

    function eliminarOferta (id, subOferta, indexSubOferta){
        if (subOferta) {
            const newOfertas = JSON.parse(JSON.stringify(ofertasAdd));
            newOfertas.map((oferta, index)=>{
                if (oferta.codigoOferta == subOferta) {
                    newOfertas[index].ofertas.splice(indexSubOferta, 1);
                }
            });
            setOfertasAdd(newOfertas);
            setDiferente1(true);    
        } else {
            const newOfertas = JSON.parse(JSON.stringify(ofertasAdd));
            newOfertas.splice(id, 1);
            setOfertasAdd(newOfertas);
        }
    }

    function actualizarInput (tipo, valor){
        const newHotel1 = newHotel;
        newHotel1[tipo][0] = valor;
        setNewHotel(JSON.parse(JSON.stringify(newHotel1)));
    }

    function actualizarInputOferta (edit, tipo, valor){
        const oferta1 = JSON.parse(JSON.stringify(ofertaEdit || oferta));
        if (tipo == "desde" || tipo == "hasta" || tipo == "vencimientoOferta") {
            oferta1[tipo][0] = cambiarFecha(valor);
        } else if (tipo == "dias"){
            const index = oferta1.dias[0].findIndex(e => e == valor);
            if (index > -1) {
                oferta1.dias[0].splice(index, 1);
            } else {
                if (valor == "todos") {
                    oferta1.dias[0] = ["todos"];  
                } else {
                    oferta1.dias[0].push(valor);  
                }
            }
        } else {
            oferta1[tipo][0] = valor;
        };
        if (ofertaEdit) {
            setOfertaEdit(JSON.parse(JSON.stringify(oferta1)));
        } else {
            setOferta(JSON.parse(JSON.stringify(oferta1)));
        }
    }

    async function validarHotel (){
        let result = true;
        const newHotel1 = newHotel;

        newHotel1.nombre[1] = "";
        newHotel1.zonaHotelera[1] = "";

        if (newHotel.nombre[0].length == 0){
            newHotel1.nombre[1] = "Rellena este campo.";
            result = false;
        }

        if (newHotel.zonaHotelera[0].length == 0) {
            newHotel1.zonaHotelera[1] = "Rellena este campo.";
            result = false;
        }

        ofertasAdd.map((oferta)=>{
            let valido = false;
            oferta.ofertas.map((subOferta)=>{
                if (subOferta.dias.includes("todos")) {
                    valido = true;
                }
            });
            if (!valido) {
                result = false;
                alert("Las ofertas deben tener una sub-oferta con todos los dias.");
            }
        })


        setNewHotel(JSON.parse(JSON.stringify(newHotel1))); 
        if (oferta) {
            if (tipoOferta == "new") {
                validarOferta();
            } else {
                validarSubOferta();
            }
        }
        if (oferta == false) {    
            newHotel1.ofertas = ofertasAdd;
            newHotel1.id = hotel.id;
            if (fotoPerfil) {
                newHotel1.fotoPerfil = [fotoPerfil, ""];
            }

            if (result) {
                setCargando(true);
                const enviar = await añadirHotel({hotel:newHotel1}, true);
                if (enviar === true) {
                    navigate("/");
                    setTimeout(()=>{
                        navigate("/admin?tab=hoteles");
                    }, 40);
                } else {
                    setMsgVal(enviar);
                }
                setCargando(false);
            }
        }
    }

    async function borrarFoto (){
        setCargando(true);
        const result = await eliminarFotoPropiedad(idFotoDel, hotel.id, "hoteles")
        if (result === true) {
            setMsgVal1([true, "Foto eliminada correctamente."]);
            delete hotel.imagenes[idFotoDel];
        } else {
            setMsgVal1([false, result]);
        }
        setTimeout(()=>{
            setMsgVal1([false, ""]);
        }, 3800);
        setCargando(false);
        setModalEliminarFoto(false);
    }

    async function añadirFoto (img) {
        setCargandoAddImg(true);
        const result = await addFotoPropiedad(img, hotel.id, "hoteles");
        if (result[0] === true) {
            setMsgVal1([true, "Foto añadida correctamente."]);
            let numMayor = 0;
            Object.keys(hotel.imagenes).map((campo)=>{
                if (campo.charAt(7)+campo.charAt(8) > numMayor){
                    numMayor = parseInt(campo.charAt(7)+campo.charAt(8));
                }
            });
            let campo = "imagen_"+(numMayor+1);
            hotel.imagenes[campo] = result[1];
        } else {
            setMsgVal1([false, result[1]]);
        }
        setTimeout(()=>{
            setMsgVal1([false, ""]);
        }, 3800);
        setCargandoAddImg(false);
    }

    useEffect(()=>{
        const newHotel1 = newHotel;
        const ofertas = JSON.parse(JSON.stringify(hotel.ofertas));

        Object.keys(hotel).map((value)=>{
            if (value == "nombre" || value == "zonaHotelera" || value == "descripcion")  {
                newHotel1[value][0] = hotel[value];
            }
        });

        setOfertasAdd(ofertas);
    },[]);

    useEffect(()=>{
        let diferente11 = false;
        if (ofertasAdd.length != hotel.ofertas.length) {
            diferente11 = true;
        }
        
        Object.keys(newHotel).map((value)=>{
            if (value == "zonaHotelera" || value == "descripcion" || value == "nombre") {
                if (newHotel[value][0] !== hotel[value]) {
                    diferente11 = true;
                }
            } 
            if (fotoPerfil !== false){
                diferente11 = true;
            }
        });
        
        if (diferente1) {
            diferente11 = true;
        }

        setDiferente(diferente11);
    },[newHotel, ofertasAdd, fotoPerfil]);

    return (
        <PlataformaContainer>
            <input onChange={(e)=>añadirFoto(e.target.files[0])} style={{display:"none"}} id="btnAddImg" type="file" accept=".jpg, .png, .jpeg"/>
            <Msg activo={msgVal1[1]} error={msgVal1[0]}><div><p>{msgVal1[1]}</p></div></Msg>
            <Modal visible={modalEliminarFoto} setVisible={()=>{}}>
                <ModalEliminar> 
                    <h3>¿Estas seguro de eliminar esta foto?</h3>
                    <p>Esta acción no se puede deshacer.</p>
                    {cargando && <CargandoCont><img src={cargandoImg}/></CargandoCont>}
                    <Btns>
                        <Button onClick={()=>{setIdFotoDel(""); setModalEliminarFoto(false)}} >No estoy seguro</Button>
                        <Button red={true} onClick={()=>borrarFoto()}>Estoy seguro</Button>
                    </Btns>
                </ModalEliminar>
            </Modal>
            <Modal visible={cargandoAddImg} setVisible={()=>{}}>
                <p style={{textAlign:"center"}}>Subiendo imagen..</p>
            </Modal>

            <h3>Modifica el hotel {newHotel.nombre[0]}</h3>
            <h4>Datos basicos</h4>
            <FilaModalHotel>
                <Campo>
                    <label>Nombre<span style={{color:"#eb1a1a"}}>*</span></label>
                    <SpanValidacion active={newHotel.nombre[1]}><span>{newHotel.nombre[1]}</span><DivLinea/></SpanValidacion>
                    <Input value={newHotel.nombre[0]}  error={newHotel.nombre[1]} onChange={(e)=>actualizarInput("nombre", e.target.value)} />
                </Campo>
                <Campo>
                <SpanValidacion active={newHotel.zonaHotelera[1]}><span>{newHotel.zonaHotelera[1]}</span><DivLinea/></SpanValidacion>
                    <label>Zona hotelera<span style={{color:"#eb1a1a"}}>*</span></label>
                    <Select defaultValue={newHotel.zonaHotelera[0]} onChange={(e)=>actualizarInput("zonaHotelera", e.target.value)} error={newHotel.zonaHotelera[1]}>
                        <option value={newHotel.zonaHotelera[0]}>{newHotel.zonaHotelera[0]}</option>
                        {newHotel.zonaHotelera[0] !== "Zona norte" && <option>Zona norte</option>}
                        {newHotel.zonaHotelera[0] !== "Zona este" && <option>Zona este</option>}
                    </Select>
                </Campo>
                <Campo>
                    {/* <SpanValidacion active={newHotel.fotoPerfil[1]}><span>{newHotel.fotoPerfil[1]}</span><DivLinea/></SpanValidacion> */}
                    <label>Foto de perfil</label>
                    <Input onChange={(e)=>setFotoPerfil(e.target.files[0])} id="imgPerfil" accept=".jpg, .png, .jpeg" type="file"/>
                </Campo>
            </FilaModalHotel>
            <h4>Fotos y detalles</h4>
            <FilaModalHotel style={{justifyContent:"flex-start"}}>
                <ImagenesCont>
                    <div style={{display:"flex", cursor:"pointer", backgroundColor:"#F5F5F5", flexDirection:"column", height:pc ? "10vw" : "25vw", justifyContent:"center", alignItems:"center", width:pc ? "15vw" : "30vw", margin: pc ? "1.5vw" : "5vw"}} onClick={()=>document.querySelector("#btnAddImg").click()}>
                        <p style={{fontWeight:"bold"}}>Añadir foto</p>
                        <img style={{width:pc ? "3vw" : "8vw", height:pc ? "3vw" : "8vw", margin:0}} src={addImg} />
                    </div>
                    {
                        Object.keys(hotel.imagenes).map((campo)=>(
                            <div>
                                <img src={hotel.imagenes[campo]} />
                                <span onClick={()=>{setIdFotoDel(campo); setModalEliminarFoto(true)}}>
                                    <img src={quitar} />
                                </span>
                            </div>
                        ))
                    }
                </ImagenesCont>
                <Campo>
                    <SpanValidacion active={newHotel.descripcion[1]}><span>{newHotel.descripcion[1]}</span><DivLinea/></SpanValidacion>
                    <label>Breve descripción</label>
                    <Textarea value={newHotel.descripcion[0]} error={newHotel.descripcion[1]} onChange={(e)=>actualizarInput("descripcion", e.target.value)}/>
                </Campo>
                <Campo>
                    {/* <SpanValidacion active={newHotel.imagenes[1]}><span>{newHotel.imagenes[1]}</span><DivLinea/></SpanValidacion> */}
                    {/* <label>Fotos</label>
                    <Input accept=".jpg, .png, .jpeg" multiple id="inputImagenes" type="file"/> */}
                </Campo>
            </FilaModalHotel>

            <h4>Ofertas</h4>
            { ofertasAdd.map((value, index)=>
                idOferta !== false ? 
                    index == idOferta && (<Oferta style={{backgroundColor: validarFecha(value.vencimientoOferta) !== true && "#eb1a1a"}}>
                        <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                            <span>Oferta {value.codigoOferta}</span>
                        </div>
                        { value.ofertas.map((oferta, indexSubOferta)=>(
                        <SubCampo style={{backgroundColor:"#fff", color:"#000"}} oferta={true} key={indexSubOferta}>
                            <p>Sub-oferta ({oferta.dias.map((dia, index)=>(dia+(index != oferta.dias.length-1 ? ", " : "")))})</p>
                            <img onClick={()=>btnSubOferta("update", index, indexSubOferta)} style={{marginLeft:"auto", width:pc ? "1.6vw" : ""}} src={edit}/>
                            <img onClick={()=>eliminarOferta(undefined, value.codigoOferta, indexSubOferta)} src={quitar1}/>
                        </SubCampo>
                        ))}
                        {value.ofertas.length == 0 && <p style={{color:"#fff", fontWeight:"200", textAlign:"center"}}>No hay sub-ofertas</p>}
                        <BtnAddOferta onClick={()=>btnSubOferta("add", index)}>Añadir sub-oferta</BtnAddOferta>
                    </Oferta>)
                :
                    (<Oferta style={{backgroundColor: validarFecha(value.vencimientoOferta) !== true && "#eb1a1a"}}>
                        <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                            <span>Oferta {value.codigoOferta}</span>
                            <img onClick={()=>{const ofertaEditNew = value; Object.keys(ofertaEditNew).map((campo)=>{if (campo != "ofertas") {ofertaEditNew[campo] = [ofertaEditNew[campo], ""]}}); setOfertaEdit(ofertaEditNew); setIdOfertaEdit(index)}} style={{marginLeft:"auto", cursor:"pointer", marginRight:pc ? "0.5vw" : "1vw", width:pc ? "1.6vw" : "5vw"}} src={edit1}/>
                            <img style={{cursor:"pointer", width:pc ? "2vw" : "6vw"}} onClick={()=>eliminarOferta(index)} src={quitar}/>
                        </div>
                        { value.ofertas.map((oferta, indexSubOferta)=>(
                        <SubCampo style={{backgroundColor:"#fff", color:"#000"}} oferta={true} key={indexSubOferta}>
                            <p>Sub-oferta ({oferta.dias.map((dia, index)=>(dia+(index != oferta.dias.length-1 ? ", " : "")))})</p>
                            <img onClick={()=>btnSubOferta("update", index, indexSubOferta)} style={{marginLeft:"auto", width:pc ? "1.6vw" : ""}} src={edit}/>
                            <img onClick={()=>eliminarOferta(undefined, value.codigoOferta, indexSubOferta)} src={quitar1}/>
                        </SubCampo>
                        ))}
                        {value.ofertas.length == 0 && <p style={{color:"#fff", fontWeight:"200", textAlign:"center"}}>No hay sub-ofertas</p>}
                        <BtnAddOferta onClick={()=>btnSubOferta("add", index)}>Añadir sub-oferta</BtnAddOferta>
                    </Oferta>)
            )}

            {ofertaEdit !== false && <Modal add={true} visible={ofertaEdit} setVisible={()=>{}}>
                <FilaModalHotel style={{backgroundColor:"#fff", flexWrap:"wrap", border:"1px solid #E4E4E4"}}>
                    <Campo style={{width:pc ? "40%" : ""}}>
                        <SpanValidacion active={ofertaEdit.codigoOferta[1]}><span>{ofertaEdit.codigoOferta[1]}</span><DivLinea/></SpanValidacion>
                        <label>Codigo de oferta<span style={{color:"#eb1a1a"}}>*</span></label>
                        <Input value={ofertaEdit.codigoOferta[0]} onChange={(e)=>actualizarInputOferta(idOferta, "codigoOferta", e.target.value)} error={ofertaEdit.codigoOferta[1]} />
                    </Campo>
                    <Campo style={{width:pc ? "40%" : ""}}>
                        <SpanValidacion active={ofertaEdit.habitacion[1]}><span>{ofertaEdit.habitacion[1]}</span><DivLinea/></SpanValidacion>   
                        <label>Habitación<span style={{color:"#eb1a1a"}}>*</span></label>
                        <Input value={ofertaEdit.habitacion[0]}  onChange={(e)=>actualizarInputOferta(idOferta, "habitacion", e.target.value)} error={ofertaEdit.habitacion[1]} />
                    </Campo>
                    <Campo style={{width:pc ? "40%" : ""}}>
                        <SpanValidacion active={ofertaEdit.desde[1]}><span>{ofertaEdit.desde[1]}</span><DivLinea/></SpanValidacion>
                        <label>Periodo (Desde)<span style={{color:"#eb1a1a"}}>*</span></label>
                        <Input value={cambiarFechaInput(ofertaEdit.desde[0])} onChange={(e)=>actualizarInputOferta(idOferta, "desde", e.target.value)} error={ofertaEdit.desde[1]}  type="date"/>
                    </Campo>
                    <Campo style={{width:pc ? "40%" : ""}}>
                        <SpanValidacion active={ofertaEdit.hasta[1]}><span>{ofertaEdit.hasta[1]}</span><DivLinea/></SpanValidacion>
                        <label>Periodo (Hasta)<span style={{color:"#eb1a1a"}}>*</span></label>
                        <Input value={cambiarFechaInput(ofertaEdit.hasta[0])} onChange={(e)=>actualizarInputOferta(idOferta, "hasta", e.target.value)} error={ofertaEdit.hasta[1]}  type="date"/>
                    </Campo>                          
                    <Campo style={{width:pc ? "40%" : ""}}>
                        <SpanValidacion active={ofertaEdit.niñoGratis[1]}><span>{ofertaEdit.niñoGratis[1]}</span><DivLinea/></SpanValidacion>
                        <label>Niño gratis<span style={{color:"#eb1a1a"}}>*</span></label>
                        <Select type="checkbox" defaultValue="DEFAULT" onChange={(e)=>actualizarInputOferta(idOferta, "niñoGratis", (e.target.value == "Primer niño") ? "1" : (e.target.value == "Segundo niño") ? "2" : "0")} error={ofertaEdit.niñoGratis[1]}>
                            <option value="DEFAULT">{(ofertaEdit.niñoGratis[0] == "1") ? "Primer niño" : (ofertaEdit.niñoGratis[0] == "2") ? "Segundo niño" : "Ninguno"}</option> 
                            {ofertaEdit.niñoGratis[0] !== "0" && <option>Ninguno</option>}
                            {ofertaEdit.niñoGratis[0] !== "1" && <option>Primer niño</option>}
                            {ofertaEdit.niñoGratis[0] !== "2" && <option>Segundo niño</option>}
                        </Select>
                    </Campo>
                    <Campo style={{width:pc ? "40%" : ""}}>
                        <SpanValidacion active={ofertaEdit.vencimientoOferta[1]}><span>{ofertaEdit.vencimientoOferta[1]}</span><DivLinea/></SpanValidacion>
                        <label>Vencimiento de oferta<span style={{color:"#eb1a1a"}}>*</span></label>
                        <Input value={cambiarFechaInput(ofertaEdit.vencimientoOferta[0])} type="date" onChange={(e)=>actualizarInputOferta(idOferta, "vencimientoOferta", e.target.value)} error={ofertaEdit.vencimientoOferta[1]} />
                    </Campo>
                    <Campo style={{width:pc ? "40%" : ""}}>
                        <SpanValidacion active={ofertaEdit.minimoNoches[1]}><span>{ofertaEdit.minimoNoches[1]}</span><DivLinea/></SpanValidacion>
                        <label>Minimo de noches<span style={{color:"#eb1a1a"}}>*</span></label>
                        <Input value={ofertaEdit.minimoNoches[0]} onChange={(e)=>actualizarInputOferta(idOferta, "minimoNoches", e.target.value)} error={ofertaEdit.minimoNoches[1]} />
                    </Campo>
                    <Btns style={{marginBottom:pc?"1vw":"1vw"}}>
                        <Button onClick={()=>{setOfertaEdit(false); const ofertasAddNew = ofertasAdd; Object.keys(ofertasAddNew[idOfertaEdit]).map((campo)=>{if (campo != "ofertas") {ofertasAddNew[idOfertaEdit][campo] = ofertasAddNew[idOfertaEdit][campo][0]}}); setOfertasAdd(JSON.parse(JSON.stringify(ofertasAddNew))); setIdOfertaEdit(false);}} style={{fontSize:pc ? "0.85vw" : "3vw", padding:pc ? "0.3vw" : "1vw"}}>Cerrar</Button>
                        <Button onClick={()=>validarOferta()} style={{fontSize:pc ? "0.85vw" : "3vw", padding:pc ? "0.3vw" : "1vw"}}>Modificar oferta</Button>
                    </Btns>
                </FilaModalHotel>
            </Modal>}

            { oferta !== false ?
                    tipoOferta == "new" ?
                        <FilaModalHotel style={{backgroundColor:"#fff", border:"1px solid #E4E4E4"}}>
                            <Campo>
                                <SpanValidacion active={oferta.codigoOferta[1]}><span>{oferta.codigoOferta[1]}</span><DivLinea/></SpanValidacion>
                                <label>Codigo de oferta<span style={{color:"#eb1a1a"}}>*</span></label>
                                <Input value={oferta.codigoOferta[0]} onChange={(e)=>actualizarInputOferta(idOferta, "codigoOferta", e.target.value)} error={oferta.codigoOferta[1]} />
                            </Campo>
                            <Campo>
                                <SpanValidacion active={oferta.habitacion[1]}><span>{oferta.habitacion[1]}</span><DivLinea/></SpanValidacion>   
                                <label>Habitación<span style={{color:"#eb1a1a"}}>*</span></label>
                                <Input value={oferta.habitacion[0]}  onChange={(e)=>actualizarInputOferta(idOferta, "habitacion", e.target.value)} error={oferta.habitacion[1]} />
                            </Campo>
                            <Campo>
                                <SpanValidacion active={oferta.desde[1]}><span>{oferta.desde[1]}</span><DivLinea/></SpanValidacion>
                                <label>Periodo (Desde)<span style={{color:"#eb1a1a"}}>*</span></label>
                                <Input value={cambiarFechaInput(oferta.desde[0])} onChange={(e)=>actualizarInputOferta(idOferta, "desde", e.target.value)} error={oferta.desde[1]}  type="date"/>
                            </Campo>
                            <Campo>
                                <SpanValidacion active={oferta.hasta[1]}><span>{oferta.hasta[1]}</span><DivLinea/></SpanValidacion>
                                <label>Periodo (Hasta)<span style={{color:"#eb1a1a"}}>*</span></label>
                                <Input value={cambiarFechaInput(oferta.hasta[0])} onChange={(e)=>actualizarInputOferta(idOferta, "hasta", e.target.value)} error={oferta.hasta[1]}  type="date"/>
                            </Campo>
                            <Campo>
                                <SpanValidacion active={oferta.SGL[1]}><span>{oferta.SGL[1]}</span><DivLinea/></SpanValidacion>
                                <label>SGL<span style={{color:"#eb1a1a"}}>*</span></label>
                                <Input value={oferta.SGL[0]}  onChange={(e)=>actualizarInputOferta(idOferta, "SGL", e.target.value)} error={oferta.SGL[1]} />
                            </Campo>
                            <Campo>
                                <SpanValidacion active={oferta.DBL[1]}><span>{oferta.DBL[1]}</span><DivLinea/></SpanValidacion>
                                <label>DBL<span style={{color:"#eb1a1a"}}>*</span></label>
                                <Input value={oferta.DBL[0]}  onChange={(e)=>actualizarInputOferta(idOferta, "DBL", e.target.value)} error={oferta.DBL[1]}  />
                            </Campo>
                            <Campo>
                                <SpanValidacion active={oferta.TPL[1]}><span>{oferta.TPL[1]}</span><DivLinea/></SpanValidacion>
                                <label>TPL<span style={{color:"#eb1a1a"}}>*</span></label>
                                <Input value={oferta.TPL[0]}  onChange={(e)=>actualizarInputOferta(idOferta, "TPL", e.target.value)} error={oferta.TPL[1]} />
                            </Campo>
                            <Campo>
                                <SpanValidacion active={oferta.niños[1]}><span>{oferta.niños[1]}</span><DivLinea/></SpanValidacion>
                                <label>Precio niños<span style={{color:"#eb1a1a"}}>*</span></label>
                                <Input value={oferta.niños[0]}  onChange={(e)=>actualizarInputOferta(idOferta, "niños", e.target.value)} error={oferta.niños[1]} />
                            </Campo>
                            <Campo>
                                <SpanValidacion active={oferta.niñoGratis[1]}><span>{oferta.niñoGratis[1]}</span><DivLinea/></SpanValidacion>
                                <label>Niño gratis<span style={{color:"#eb1a1a"}}>*</span></label>
                                <Select type="checkbox" defaultValue="DEFAULT" onChange={(e)=>actualizarInputOferta(idOferta, "niñoGratis", (e.target.value == "Primer niño") ? "1" : (e.target.value == "Segundo niño") ? "2" : "0")} error={oferta.niñoGratis[1]}>
                                    <option disabled={true} value="DEFAULT">Sin seleccionar</option>
                                    <option>Ninguno</option>
                                    <option>Primer niño</option>
                                    <option>Segundo niño</option>
                                </Select>
                            </Campo>
                            <Campo>
                                <SpanValidacion active={oferta.vencimientoOferta[1]}><span>{oferta.vencimientoOferta[1]}</span><DivLinea/></SpanValidacion>
                                <label>Vencimiento de oferta<span style={{color:"#eb1a1a"}}>*</span></label>
                                <Input value={cambiarFechaInput(oferta.vencimientoOferta[0])} type="date" onChange={(e)=>actualizarInputOferta(idOferta, "vencimientoOferta", e.target.value)} error={oferta.vencimientoOferta[1]} />
                            </Campo>
                            <Campo>
                                <SpanValidacion active={oferta.minimoNoches[1]}><span>{oferta.minimoNoches[1]}</span><DivLinea/></SpanValidacion>
                                <label>Minimo de noches<span style={{color:"#eb1a1a"}}>*</span></label>
                                <Input value={oferta.minimoNoches[0]} onChange={(e)=>actualizarInputOferta(idOferta, "minimoNoches", e.target.value)} error={oferta.minimoNoches[1]} />
                            </Campo>
                            <Campo style={{width:"100%", alignItems:"center"}}>
                                <SpanValidacion style={{marginRight: pc ? "2%" : "17%", marginBottom: pc ? "0.5%" : "1%", position:"relative"}} active={oferta.dias[1]}><span>{oferta.dias[1]}</span><DivLinea/></SpanValidacion>
                                <label>Dias<span style={{color:"#eb1a1a"}}>*</span></label>
                                <Dia>
                                    <label>Todos</label>
                                    <input defaultChecked={oferta.dias[0].includes("todos") ? true : false} onChange={(e)=>actualizarInputOferta(idOferta, "dias", "todos")} type="checkbox"/>
                                </Dia>
                                {oferta.dias[0][0] != "todos" && 
                                <Dias>                                                        
                                    <Dia>
                                        <label>Lunes</label>
                                        <input defaultChecked={oferta.dias[0].includes("lunes") ? true : false} onChange={(e)=>actualizarInputOferta(idOferta, "dias", "lunes")} type="checkbox"/>
                                    </Dia>
                                    <Dia>
                                        <label>Martes</label>
                                        <input defaultChecked={oferta.dias[0].includes("martes") ? true : false} onChange={(e)=>actualizarInputOferta(idOferta, "dias", "martes")} type="checkbox"/>
                                    </Dia>
                                    <Dia>
                                        <label>Miercoles</label>
                                        <input defaultChecked={oferta.dias[0].includes("miercoles") ? true : false} onChange={(e)=>actualizarInputOferta(idOferta, "dias", "miercoles")} type="checkbox"/>
                                    </Dia>
                                    <Dia>
                                        <label>Jueves</label>
                                        <input defaultChecked={oferta.dias[0].includes("jueves") ? true : false} onChange={(e)=>actualizarInputOferta(idOferta, "dias", "jueves")} type="checkbox"/>
                                    </Dia>
                                    <Dia>
                                        <label>Viernes</label>
                                        <input defaultChecked={oferta.dias[0].includes("viernes") ? true : false} onChange={(e)=>actualizarInputOferta(idOferta, "dias", "viernes")} type="checkbox"/>
                                    </Dia>
                                    <Dia>
                                        <label>Sabado</label>
                                        <input defaultChecked={oferta.dias[0].includes("sabado") ? true : false} onChange={(e)=>actualizarInputOferta(idOferta, "dias", "sabado")} type="checkbox"/>
                                    </Dia>
                                    <Dia>
                                        <label>Domingo</label>
                                        <input defaultChecked={oferta.dias[0].includes("domingo") ? true : false} onChange={(e)=>actualizarInputOferta(idOferta, "dias", "domingo")} type="checkbox"/>
                                    </Dia>
                                </Dias>}
                            </Campo>
                            <Btns style={{marginBottom:pc?"1vw":"1vw"}}>
                                <Button onClick={()=>{setTipoOferta(false); setIdSubOferta(false); setIdOferta(false); setOferta(false)}} style={{fontSize:pc ? "0.85vw" : "3vw", padding:pc ? "0.3vw" : "1vw"}}>Cerrar</Button>
                                <Button onClick={()=>validarOferta()} style={{fontSize:pc ? "0.85vw" : "3vw", padding:pc ? "0.3vw" : "1vw"}}>Agregar oferta</Button>
                            </Btns>
                        </FilaModalHotel>
                    :
                        <FilaModalHotel style={{backgroundColor:"#fff", border:"1px solid #E4E4E4"}}>
                            <Campo>
                                <SpanValidacion active={oferta.SGL[1]}><span>{oferta.SGL[1]}</span><DivLinea/></SpanValidacion>
                                <label>SGL<span style={{color:"#eb1a1a"}}>*</span></label>
                                <Input value={oferta.SGL[0]}  onChange={(e)=>actualizarInputOferta(idOferta, "SGL", e.target.value)} error={oferta.SGL[1]} />
                            </Campo>
                            <Campo>
                                <SpanValidacion active={oferta.DBL[1]}><span>{oferta.DBL[1]}</span><DivLinea/></SpanValidacion>
                                <label>DBL<span style={{color:"#eb1a1a"}}>*</span></label>
                                <Input value={oferta.DBL[0]}  onChange={(e)=>actualizarInputOferta(idOferta, "DBL", e.target.value)} error={oferta.DBL[1]}  />
                            </Campo>
                            <Campo>
                                <SpanValidacion active={oferta.TPL[1]}><span>{oferta.TPL[1]}</span><DivLinea/></SpanValidacion>
                                <label>TPL<span style={{color:"#eb1a1a"}}>*</span></label>
                                <Input value={oferta.TPL[0]}  onChange={(e)=>actualizarInputOferta(idOferta, "TPL", e.target.value)} error={oferta.TPL[1]} />
                            </Campo>
                            <Campo>
                                <SpanValidacion active={oferta.niños[1]}><span>{oferta.niños[1]}</span><DivLinea/></SpanValidacion>
                                <label>Precio niños<span style={{color:"#eb1a1a"}}>*</span></label>
                                <Input value={oferta.niños[0]}  onChange={(e)=>actualizarInputOferta(idOferta, "niños", e.target.value)} error={oferta.niños[1]} />
                            </Campo>
                            <Campo style={{width:"100%", alignItems:"center"}}>
                                <SpanValidacion style={{marginRight: pc ? "2%" : "17%", marginBottom: pc ? "0.5%" : "1%", position:"relative"}} active={oferta.dias[1]}><span>{oferta.dias[1]}</span><DivLinea/></SpanValidacion>
                                <label>Dias<span style={{color:"#eb1a1a"}}>*</span></label>
                                <Dia>
                                    <label>Todos</label>
                                    <input defaultChecked={oferta.dias[0].includes("todos") ? true : false} onChange={(e)=>actualizarInputOferta(idOferta, "dias", "todos")} type="checkbox"/>
                                </Dia>
                                {oferta.dias[0][0] != "todos" && 
                                <Dias>                                                        
                                    <Dia>
                                        <label>Lunes</label>
                                        <input defaultChecked={oferta.dias[0].includes("lunes") ? true : false} onChange={(e)=>actualizarInputOferta(idOferta, "dias", "lunes")} type="checkbox"/>
                                    </Dia>
                                    <Dia>
                                        <label>Martes</label>
                                        <input defaultChecked={oferta.dias[0].includes("martes") ? true : false} onChange={(e)=>actualizarInputOferta(idOferta, "dias", "martes")} type="checkbox"/>
                                    </Dia>
                                    <Dia>
                                        <label>Miercoles</label>
                                        <input defaultChecked={oferta.dias[0].includes("miercoles") ? true : false} onChange={(e)=>actualizarInputOferta(idOferta, "dias", "miercoles")} type="checkbox"/>
                                    </Dia>
                                    <Dia>
                                        <label>Jueves</label>
                                        <input defaultChecked={oferta.dias[0].includes("jueves") ? true : false} onChange={(e)=>actualizarInputOferta(idOferta, "dias", "jueves")} type="checkbox"/>
                                    </Dia>
                                    <Dia>
                                        <label>Viernes</label>
                                        <input defaultChecked={oferta.dias[0].includes("viernes") ? true : false} onChange={(e)=>actualizarInputOferta(idOferta, "dias", "viernes")} type="checkbox"/>
                                    </Dia>
                                    <Dia>
                                        <label>Sabado</label>
                                        <input defaultChecked={oferta.dias[0].includes("sabado") ? true : false} onChange={(e)=>actualizarInputOferta(idOferta, "dias", "sabado")} type="checkbox"/>
                                    </Dia>
                                    <Dia>
                                        <label>Domingo</label>
                                        <input defaultChecked={oferta.dias[0].includes("domingo") ? true : false} onChange={(e)=>actualizarInputOferta(idOferta, "dias", "domingo")} type="checkbox"/>
                                    </Dia>
                                </Dias>}
                            </Campo>
                            <Btns style={{marginBottom:pc?"1vw":"1vw"}}>
                                <Button onClick={()=>{setTipoOferta(false); setIdSubOferta(false); setIdOferta(false); setOferta(false)}} style={{fontSize:pc ? "0.85vw" : "3vw", padding:pc ? "0.3vw" : "1vw"}}>Cerrar</Button>
                                <Button onClick={()=>validarSubOferta()} style={{fontSize:pc ? "0.85vw" : "3vw", padding:pc ? "0.3vw" : "1vw"}}>{tipoOferta == "add" ? "Añadir sub-oferta" : tipoOferta == "update" && "Modificar sub-oferta"}</Button>
                            </Btns>
                        </FilaModalHotel>
                : oferta === false &&
                    <FilaModalHotel>
                        <Btns>
                            <Button onClick={()=>{setTipoOferta("new"); setOferta(ofertaDefault)}} style={{fontSize:pc ? "0.85vw" : "3vw", padding:pc ? "0.3vw" : "1vw"}}>Agregar Oferta</Button>
                        </Btns>
                    </FilaModalHotel>
            }
            <p style={{color:"#eb1a1a", margin:"0 auto"}}>{msgVal}</p>
            <Btns>
                <Button onClick={()=>{setClose();}}>Cancelar</Button>
                <Button style={{opacity:diferente ? "1" : "0.5", backgroundColor: cargando && "#fff"}} onClick={()=>diferente && !cargando && validarHotel()} yes={true}>
                    { cargando ?
                        cargando && <CargandoCont><img src={cargandoImg}/></CargandoCont>
                        :
                        "Modificar hotel"
                    }
                </Button>
            </Btns>
        </PlataformaContainer>
    );
};