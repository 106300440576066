import {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import {validarFecha, restaFechas, cambiarFecha, cambiarFechaInput, isNum} from "../utils/validar";
import styled from "styled-components";
import CargandoCont from "../utils/cargando";
import { addFotoPropiedad, eliminarFotoPropiedad, añadirVilla } from "../../config/auth";
import Modal from "../../components/utils/modal";

// imagenes
import cargandoImg from "../../images/cargando.gif";
import quitar from "../../images/quitar.png";
import quitar1 from "../../images/quitar1.png";
import edit1 from "../../images/edit1.png";
import edit from "../../images/edit.png";
import addImg from "../../images/addImg.png";

const pc = window.screen.width > 529 ? true : false;

const PlataformaContainer = styled.div`
    display: flex;
    padding: 3vw;
    padding-top: ${pc ? "0" : "18vw"};
    background-color: #fff;
    flex-direction:column;
    margin-left: ${pc && "0"};
    padding-left: ${pc && "0"};

    & h2 {
        font-size: ${pc ? "2vw" : "7vw"};
        font-weight: 700;
        margin-bottom: ${pc ? "1vw" : "3vw"};
    }

    & h3 {
        font-weight: 700;
        margin-bottom: ${pc ? "1vw" : ""};
        font-size: ${pc ? "1.9vw" : ""};
    }

    & h4 {
        font-weight: 600;
        font-size: ${pc ? "1.4vw" : ""};
        color:#004aad;
        margin-bottom: ${pc ? "0.3vw" : ""};
        margin-right: auto;
        border-bottom: 1px solid #C4C4C4;
        width: 100%;
    }
`;

const FilaModalHotel = styled.div`
    display: flex;
    flex-direction: ${pc ? "row" : "column"};
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    margin-bottom: ${pc ? "1.2vw" : "2vw"};
`;

const Campo = styled.div`
    display: flex;
    flex-direction: ${e => e.oferta ? "row" : "column"};
    margin-right: ${e => e ? pc && "0" : pc && "2vw"};
    width: ${e => pc ? e.oferta ? "100%" : "23%" : e.oferta ? "100%" : "90%"};
    margin:${!pc && "0 auto"};
    margin-bottom: ${e => e.oferta && pc ? "1vw" : "2vw"};
    position: relative;
    background-color: ${e => e.oferta && "#004aad"};
    color: ${e => e.oferta && "#fff"};
    padding: ${e => e.oferta && pc ? "0.5vw" : "1vw"};
    border-radius: ${e => e.oferta && pc ? "0.5vw" : "1vw"};
    padding-left: ${e => e.oferta ? "1vw" : "0"};
    align-items: ${e => e.oferta && "center"};

    & img {
        width: ${pc ? "1.8vw" : "6vw"};
        cursor: pointer;
    }

    & p {
        font-size: ${e => e.rango ? "12px" : ""};
    }

    & label {
        color:#000;
        font-weight: 500;
        font-size: ${pc ? "1.1vw" : ""};
    }

    & input {
        padding: ${pc ? "0.3vw" : "0.5vw 1vw"};
        border: 1px solid #999797;
        border-radius: 3px;
        font-size: ${pc ? "0.9vw" : "3.5vw"};
        color:#505050;        
    }
    & textarea {
        padding: ${pc ? "0.3vw" : "0.5vw 1vw"};
        border: 1px solid #999797;
        border-radius: 3px;
        font-size: ${pc ? "0.9vw" : "3.5vw"};
        color:#505050;
        resize: none;
    }

    & input:focus {
        outline:1px solid #B3B3B3;  
    }
    & textarea:focus {
        outline:1px solid #B3B3B3;  
    }
`;

const SpanValidacion = styled.div`
    background-color: #eb1a1a;
    display: ${e => e.active ? "block" : "none"};
    color: #fff;
    padding: ${pc ? "0.5vw!important" : "1vw"};
    position: absolute;
    bottom: ${e => e.rango ? "130%" : "65%"};
    right: 5%;
    border-radius: ${pc ? "0.5vw" : "1vw"};
    font-size: ${pc ? "0.9vw" : "2.5vw"};
    opacity: 0.91;

    & span {
        z-index: 50;
        position: relative;
    }
`;

const DivLinea = styled.div`
    position: absolute;
    right: 3%;
    bottom: -0.18vw;
    background-color: #eb1a1a;
    width: 1.3vw!important;
    height: 1.3vw!important;
    transform: rotate(45deg);
    z-index: 10;
`;

const Btns = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: ${pc ? "90%" : "100%"};
    margin-top: ${pc ? "1vw" : "3vw"};
`;

const Button = styled.button`
    width: 45%;
    background-color: ${props => props.red ? "#eb1a1a" : props.yes ? "#26AC20" : "#767676"};
    color:#fff;
    padding: ${pc ? "0.5vw" : "1.2vw"};
    border: none;
    font-size: ${pc ? "" : "3.2vw"};
    border-radius: ${pc ? "0.5vw" : "1vw"};
    align-items: center;
    justify-content: center;
    cursor: pointer;
    display: flex;

    & img {
        width: ${pc ? "" : "6.5vw"};
    }
`;

const Input = styled.input`
    border:1px solid ${props => props.error ? "#eb1a1a!important" : "#999797"};
`;

const Textarea = styled.textarea`
    border:1px solid ${props => props.error ? "#eb1a1a!important" : "#999797"};
    width: ${pc ? "70vw" : "85vw"};
    height: ${pc ? "35vh" : "20vh"};
    padding: 0.5vw!important;
`;

const Select = styled.select`
    border:1px solid ${props => props.error ? "#eb1a1a!important" : "#999797"};
`;

const RangosCont = styled.div`
    display:flex;
    align-items: center;
    justify-content: center;
    padding: ${pc ? "0.5vw" : ""};

    & div{
        margin: 0;
        padding: 0;
        width: ${pc ? "6vw" : "15vw"};
        margin: 0 3px;
    }
`;

const Dias = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
`;

const Dia = styled.div`
    width: ${pc ? "20%" : "33.33%"};
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: ${pc ? "0.8vw" : "2vw"};
`;

const ImagenesCont = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    & img {
        width: ${pc ? "15vw" : "30vw"} ;
        height: ${pc ? "10vw" : "25vw"};
        margin: ${pc ? "1.5vw" : "5vw"};
    }
    & div{
        position: relative;
    }

    & div:first-child:hover {
        background-color: #EDEDED!important;
    }

    & span {
        background-color: gray;
        position: absolute;
        border-radius: 2.5vw;
        height: ${pc ? "2.5vw" : "7vw"} ;
        width: ${pc ? "2.5vw" : "7vw"};
        display: flex;
        align-items: center;
        justify-content: center;
        right: ${pc ? "2%" : "5%"};
        top: ${pc ? "2%" : "5%"};
        cursor: pointer;
    }
    & span img {
        width: ${pc ? "1.8vw" : "5vw"};
        height: auto;
    }
    
`;

const ModalEliminar = styled.div`
    display: flex;
    padding: 1vw;
    align-items: center;
    flex-direction: column;

    & h3{
        font-weight: 600;
        text-align: center;
        font-size: ${pc ? "1.5vw" : "4.5vw"};
        width: ${!pc && "60vw"};
    }

    & p {
        color:#585858;
        font-size: ${pc ? "" : "4vw"};
    }
`;

const Msg = styled.div`
  position: fixed;
  display: ${props => props.activo ? "flex" : "none"};
  width: 100%;
  align-items: center;
  justify-content: center;
  bottom: 0;
  left: 0;
  z-index: 5000;

  & div {
    padding: ${pc ? "1vw 2vw" : "2vw 3vw"};
    margin-bottom: 3px;
    text-align: center;
    border-top-right-radius: 0.4vw;
    border-top-left-radius: 0.4vw;
    background-color: ${props => props.error ? "#26AC20" : "#eb1a1a"};
    color: #fff;
  }
`;

export default function EditarVilla ({setClose, villa}){

    const navigate = useNavigate();

    const ofertaDefault = {codigoOferta:["", ""], vencimientoOferta:["", ""], desde:["", ""], hasta:["", ""], rangos:[{rangoMin:["", ""], dias:[[], ""], rangoMax:["", ""], precio:["", ""]}]};
    const defaultInput = {nombre:["", ""], zona:["", ""], descripcion:["", ""]};
    let [newHotel, setNewHotel] = useState(defaultInput);

    let [fotoPerfil, setFotoPerfil] = useState(false);
    let [oferta, setOferta] = useState(false);
    let [editOferta, setEditOferta] = useState(false);
    let [ofertasAdd, setOfertasAdd] = useState([]);
    let [idEditOferta, setIdEditOferta] = useState(false);

    let [diferente, setDiferente] = useState(false);
    let [diferente1, setDiferente1] = useState(false);

    let [cargando, setCargando] = useState(false);
    let [msgVal, setMsgVal] = useState("");

    let [rango, setRango] = useState(false);
    let [editRango, setEditRango] = useState(false);
    let [idEditRango, setIdEditRango] = useState(false);
    let [idSubRango, setIdSubRango] = useState(false);

    let [cargandoAddImg, setCargandoAddImg] = useState(false);
    let [msgVal1, setMsgVal1] = useState([false, ""]);
    let [modalEliminarFoto, setModalEliminarFoto] = useState(false);
    let [idFotoDel, setIdFotoDel] = useState("");

    function validarOferta (oferta1, validar, edit){
        let result = true;
        const newInputs = {oferta:oferta1};

        Object.keys(oferta1).map((value, index)=>{
            if (value != "rangos") {
                newInputs.oferta[value][1] = "";
                if (newInputs.oferta[value][0].length > 0) {
                    if (value == "desde" || value == "hasta" || value == "vencimientoOferta") {
                        if (validarFecha(newInputs.oferta[value][0]) === true) {
                            if (value == "desde" || value == "hasta") {
                                if (restaFechas(newInputs.oferta.desde[0], newInputs.oferta.hasta[0]) == 0) {
                                    newInputs.oferta[value][1] = "Minimo un dia.";
                                    result = false;
                                } else if (restaFechas(newInputs.oferta.desde[0], newInputs.oferta.hasta[0]) < 0) {
                                    newInputs.oferta.hasta[1] = "Esta fecha tiene que ser despues que la fecha Periodo (desde)";
                                    result = false;
                                }
                            }
                        } else {
                            if (value != "vencimientoOferta") {
                                if (validarFecha(newInputs.oferta[value][0]) !== "El dia que ingresaste ya paso." && validarFecha(newInputs.oferta[value][0]) !== "El mes que ingresaste ya paso.") {
                                    newInputs.oferta[value][1] = validarFecha(newInputs.oferta[value][0]);
                                    result = false;
                                }
                            } else {
                                newInputs.oferta[value][1] = validarFecha(newInputs.oferta[value][0]);
                                result = false;
                            }
                        }
                    } else {
                        if (newInputs.oferta[value][0].length < 2) {
                            result = false;
                            newInputs.oferta[value][1] = "Minimo 2 caracteres.";
                        }
                    }
                } else {
                    if (value != "notificado") {
                        newInputs.oferta[value][1] = "Rellena este campo.";
                        result = false;
                    }
                }
            }
        });

        if (edit !== false) {
            setEditOferta(JSON.parse(JSON.stringify(newInputs.oferta)));
        } else {
            setOferta(JSON.parse(JSON.stringify(newInputs.oferta)));
        };

        if (newInputs.oferta.rangos.length == 0) {
            result = false;
            alert("La oferta debe tener minimo un rango. si no, eliminala.");
        };

        newInputs.oferta.rangos.map((rango)=>{
            if (rango.rangos.length < 1) {
                alert("Los rangos deben tener minimo un sub-rango. si no, eliminalo.");
                result = false;
            };

            if (rango.rangos.length > 0) {
                let valido = false;
                rango.rangos.map((subRango)=>{
                    if (subRango.dias[0].includes("todos")) {
                        valido = true;
                    }
                });
                if (!valido) {
                    result = false;
                    alert("Los rangos deben tener un sub-rango con todos los dias.");
                }
            }
        });

        if (!editRango && !rango) {
            const newOfertas = ofertasAdd;
            if (result) {
                setIdEditOferta(false);
                setDiferente1(true);

                if (edit !== false) {
                    newOfertas[edit] = oferta1;
                } else {
                    newOfertas.push(oferta1);
                };

                setEditOferta(false);
                setOferta(false);
                setOfertasAdd(JSON.parse(JSON.stringify(newOfertas)));   
            };
        } else {
            validarRango();
            result = false;
        };

        return result;
    }

    function eliminarOferta (id){
        const newOfertas = JSON.parse(JSON.stringify(ofertasAdd));
        newOfertas.splice(id, 1);
        setOfertasAdd(newOfertas);
    }

    function actualizarInput (tipo, valor){
        const newHotel1 = newHotel;
        newHotel1[tipo][0] = valor;
        setNewHotel(JSON.parse(JSON.stringify(newHotel1)));
    }

    function actualizarInputOferta (edit, tipo, valor){
        if (edit !== false) {
            const oferta1 = JSON.parse(JSON.stringify(editOferta));
            if (tipo == "desde" || tipo == "hasta" || tipo == "vencimientoOferta") {
                oferta1[tipo][0] = cambiarFecha(valor);
            } else {
                oferta1[tipo][0] = valor;
            }
            setEditOferta(JSON.parse(JSON.stringify(oferta1)));
        } else {
            const oferta1 = JSON.parse(JSON.stringify(oferta));
            if (tipo == "desde" || tipo == "hasta" || tipo == "vencimientoOferta") {
                oferta1[tipo][0] = cambiarFecha(valor);
            } else {
                oferta1[tipo][0] = valor;
            }
            setOferta(JSON.parse(JSON.stringify(oferta1)));
        }
    }

    function actualizarInputRango (tipo, valor, dia){
        if (rango) {
            const rangoNew = rango;
            if (tipo == "dias") {
                if (valor == true) {
                    if (dia == "todos"){
                        rangoNew.dias = [["todos"], ""];
                    } else {
                        rangoNew.dias[0].push(dia);
                    };
                } else {
                    rangoNew.dias[0].map((diaValue, index)=>{
                        if (diaValue == dia) {
                            rangoNew.dias[0].splice(index, 1);
                        };
                    });
                };
            } else {
                rangoNew[tipo][0] = valor;
            }
            setRango(JSON.parse(JSON.stringify(rangoNew)));

        } else if (editRango) {
            const editRangoNew = editRango;
            if (tipo == "dias") {
                if (valor == true) {
                    if (dia == "todos"){
                        editRangoNew.dias = [["todos"], ""];
                    } else {
                        editRangoNew.dias[0].push(dia);
                    };
                } else {
                    editRangoNew.dias[0].map((diaValue, index)=>{
                        if (diaValue == dia) {
                            editRangoNew.dias[0].splice(index, 1);
                        };
                    });
                };
            } else {
                editRangoNew[tipo][0] = valor;
            };
            setEditRango(JSON.parse(JSON.stringify(editRangoNew)));
        }
    }

    async function validarVilla (){
        let result = true;
        const newHotel1 = newHotel;

        newHotel1.nombre[1] = "";
        newHotel1.zona[1] = "";

        if (newHotel.nombre[0].length > 0){

        } else {
            newHotel1.nombre[1] = "Rellena este campo.";
            result = false;
        }

        if (newHotel.zona[0].length > 0){

        } else {
            newHotel1.zona[1] = "Rellena este campo.";
            result = false;
        }

        if (oferta !== false) {
            validarOferta(oferta, true, false)
            result = false;
        }
        if (editOferta !== false) {
            validarOferta(editOferta, true, idEditOferta);
            result = false;
        }

        setNewHotel(JSON.parse(JSON.stringify(newHotel1))); 

        newHotel1.ofertas = ofertasAdd;
        newHotel1.id = villa.id;
        if (fotoPerfil) {
            newHotel1.fotoPerfil = [fotoPerfil, ""];
        }

        if (result) {
            setCargando(true);
            const enviar = await añadirVilla({villa:newHotel1}, true);
            if (enviar === true) {
                navigate("/");
                setTimeout(()=>{
                    navigate("/admin?tab=villas");
                }, 40);
            } else {
                setMsgVal(enviar);
            }
            setCargando(false);
        }
    }

    function validarRango (){
        let valid = true;
        const newInputs = JSON.parse(JSON.stringify(rango || editRango));
        const ofertaNew = JSON.parse(JSON.stringify(oferta || editOferta));

        Object.keys(newInputs).map((campo)=>{
            newInputs[campo][1] = "";
            if (newInputs[campo][0].length > 0) {
                if (campo != "dias") {
                    if (isNum(newInputs[campo][0])) {
                        if (newInputs[campo][0] > 0) {
                            if (campo == "rangoMax" || campo == "rangoMin"){
                                if (rango) {
                                    if (parseInt(newInputs.rangoMin[0]) > parseInt(newInputs.rangoMax[0])) {
                                        newInputs.rangoMax[1] = "El rango maximo tiene que ser mayor al rango minimo.";
                                        valid = false;
                                    } else {
                                        ofertaNew.rangos.map((rango)=>{
                                            if (parseInt(newInputs.rangoMin[0]) <= parseInt(rango.rangoMax[0]) && parseInt(newInputs.rangoMax[0]) >= parseInt(rango.rangoMax[0])) {
                                                newInputs.rangoMin[1] = "Este rango ya existe en esta oferta.";
                                                newInputs.rangoMax[1] = "Este rango ya existe en esta oferta.";
                                                valid = false;
                                            } else if (parseInt(newInputs.rangoMin[0]) >= parseInt(rango.rangoMin[0]) && parseInt(newInputs.rangoMax[0]) <= parseInt(rango.rangoMax[0])){
                                                newInputs.rangoMin[1] = "Este rango ya existe en esta oferta.";
                                                newInputs.rangoMax[1] = "Este rango ya existe en esta oferta.";
                                                valid = false;
                                            } else if (parseInt(newInputs.rangoMin[0]) <= parseInt(rango.rangoMin[0]) && parseInt(newInputs.rangoMin[0]) >= parseInt(rango.rangoMax[0])){
                                                newInputs.rangoMin[1] = "Este rango ya existe en esta oferta.";
                                                newInputs.rangoMax[1] = "Este rango ya existe en esta oferta.";
                                                valid = false;
                                            } else if (parseInt(newInputs.rangoMin[0]) <= parseInt(rango.rangoMin[0]) && (parseInt(newInputs.rangoMax[0]) <= parseInt(rango.rangoMax[0]) && parseInt(newInputs.rangoMax[0]) >= parseInt(rango.rangoMin[0]))){
                                                newInputs.rangoMin[1] = "Este rango ya existe en esta oferta.";
                                                newInputs.rangoMax[1] = "Este rango ya existe en esta oferta.";
                                                valid = false;
                                            };
                                        });
                                    };
                                };
                            };
                        } else {
                            newInputs[campo][1] = "0 no es un valor aceptable.";
                            valid = false;
                        };
                    } else {
                        newInputs[campo][1] = "Solo se admiten numeros, sin puntos, comas, ni simbolos.";
                        valid = false;
                    };
                } else {
                    if (editRango) {
                        let dias = [];
                        ofertaNew.rangos[idEditRango].rangos.map((rango, index)=>{
                            if (idSubRango !== false) {
                                if (index != idSubRango) {
                                    dias = dias.concat(rango.dias[0]);    
                                };
                            } else {
                                dias = dias.concat(rango.dias[0]);
                            }
                        });

                        dias.map((e)=>{
                            newInputs.dias[0].map((a)=>{
                                if (a == e) {
                                    newInputs.dias[1] = newInputs.dias[0].length > 1 ? "Algunos de los dias que seleccionaste, ya existen." : "El dia que seleccionaste ya existe.";
                                    valid = false;
                                };
                            });
                        });
                    };
                };
            } else {
                if (editRango !== false) {
                    if (campo == "precio" || campo == "dias") {
                        newInputs[campo][1] = "Rellena este campo.";
                        valid = false;
                    }
                } else {
                    newInputs[campo][1] = "Rellena este campo.";
                    valid = false;
                };
            };
        });


        if (valid) {
            Object.keys(newInputs).map((campo)=>{
                newInputs[campo] = newInputs[campo][0];
            });

            if (rango) {
                ofertaNew.rangos.push({rangoMax:[newInputs.rangoMax, ""], rangoMin:[newInputs.rangoMin, ""], rangos:[{precio:newInputs.precio, dias:newInputs.dias}]});
            } else {
                delete newInputs.rangoMax;
                delete newInputs.rangoMin;
                if (idSubRango !== false) {
                    ofertaNew.rangos[idEditRango].rangos[idSubRango] = newInputs;
                } else {
                    ofertaNew.rangos[idEditRango].rangos.push(newInputs);
                };
            };

            if (oferta) {
                setOferta(ofertaNew);
            } else {
                setEditOferta(ofertaNew);
            };

            setEditRango(false);
            setIdEditRango(false);
            setIdSubRango(false)
            setRango(false); 
        } else {
            if (rango) {
                setRango(newInputs);
            } else {
                setEditRango(newInputs);
            }
        }

        return valid;
    }

    function delRango (index, indexSubRango){
        const oferta1 = oferta || editOferta;

        if (indexSubRango !== false && indexSubRango !== undefined) {
            oferta1.rangos[index].rangos.splice(indexSubRango, 1); 
        } else {
            oferta1.rangos.splice(index, 1);
        }

        if (oferta) {
            setOferta(JSON.parse(JSON.stringify(oferta1)));
        } else {
            setEditOferta(JSON.parse(JSON.stringify(oferta1)));
        }
    }

    async function borrarFoto (){
        setCargando(true);
        const result = await eliminarFotoPropiedad(idFotoDel, villa.id, "villas")
        if (result === true) {
            setMsgVal1([true, "Foto eliminada correctamente."]);
            delete villa.imagenes[idFotoDel];
        } else {
            setMsgVal1([false, result]);
        }
        setTimeout(()=>{
            setMsgVal1([false, ""]);
        }, 3800);
        setCargando(false);
        setModalEliminarFoto(false);
    }

    async function añadirFoto (img) {
        setCargandoAddImg(true);
        const result = await addFotoPropiedad(img, villa.id, "villas");
        if (result[0] === true) {
            setMsgVal1([true, "Foto añadida correctamente."]);
            let numMayor = 0;
            Object.keys(villa.imagenes).map((campo)=>{
                if (campo.charAt(7)+campo.charAt(8) > numMayor){
                    numMayor = parseInt(campo.charAt(7)+campo.charAt(8));
                }
            });
            let campo = "imagen_"+(numMayor+1);
            villa.imagenes[campo] = result[1];
        } else {
            setMsgVal1([false, result[1]]);
        }
        setTimeout(()=>{
            setMsgVal1([false, ""]);
        }, 3800);
        setCargandoAddImg(false);
    }

    useEffect(()=>{
        const newHotel1 = newHotel;
        const ofertas = JSON.parse(JSON.stringify(villa.ofertas));
        Object.keys(villa).map((value)=>{
            if (value == "nombre" || value == "zona" || value == "descripcion")  {
                newHotel1[value][0] = villa[value];
            } else if (value == "ofertas"){
                if (villa.ofertas.length > 0) {
                    villa.ofertas.map((value1, index)=>{                       
                        Object.keys(value1).map((campo)=>{
                            if (campo == "rangos"){
                                value1.rangos.map((valueRango, indexRango)=>{
                                    Object.keys(valueRango).map((campoRango)=>{
                                        if (campoRango != "rangos") {
                                            ofertas[index][campo][indexRango][campoRango] = [value1.rangos[indexRango][campoRango], ""];      
                                        }  
                                    });
                                });
                            } else if (campo != "periodo") {
                                ofertas[index][campo] = [value1[campo], ""];
                            }
                        });
                    });
                }
            }
        });

        setOfertasAdd(JSON.parse(JSON.stringify(ofertas)));
    },[]);

    useEffect(()=>{
        let diferente11 = false;
        if (ofertasAdd.length != villa.ofertas.length) {
            diferente11 = true;
        }
        
        Object.keys(newHotel).map((value)=>{
            if (value == "zona" || value == "descripcion" || value == "nombre") {
                if (newHotel[value][0] !== villa[value]) {
                    diferente11 = true;
                }
            } 
            if (fotoPerfil !== false){
                diferente11 = true;
            }
        });
        
        if (diferente1) {
            diferente11 = true;
        }

        setDiferente(diferente11);
    },[newHotel, ofertasAdd, fotoPerfil]);

    return (
        <PlataformaContainer>
            <input onChange={(e)=>añadirFoto(e.target.files[0])} style={{display:"none"}} id="btnAddImg" type="file" accept=".jpg, .png, .jpeg"/>
            <Msg activo={msgVal1[1]} error={msgVal1[0]}><div><p>{msgVal1[1]}</p></div></Msg>
            <Modal visible={modalEliminarFoto} setVisible={()=>{}}>
                <ModalEliminar> 
                    <h3>¿Estas seguro de eliminar esta foto?</h3>
                    <p>Esta acción no se puede deshacer.</p>
                    {cargando && <CargandoCont><img src={cargandoImg}/></CargandoCont>}
                    <Btns>
                        <Button onClick={()=>{setIdFotoDel(""); setModalEliminarFoto(false)}} >No estoy seguro</Button>
                        <Button red={true} onClick={()=>borrarFoto()}>Estoy seguro</Button>
                    </Btns>
                </ModalEliminar>
            </Modal>
            <Modal visible={cargandoAddImg} setVisible={()=>{}}>
                <p style={{textAlign:"center"}}>Subiendo imagen..</p>
            </Modal>

            <h3>Modifica la villa</h3>
            <h4>Datos basicos</h4>
            <FilaModalHotel>
                <Campo>
                    <label>Nombre<span style={{color:"#eb1a1a"}}>*</span></label>
                    <SpanValidacion active={newHotel.nombre[1]}><span>{newHotel.nombre[1]}</span><DivLinea/></SpanValidacion>
                    <Input value={newHotel.nombre[0]}  error={newHotel.nombre[1]} onChange={(e)=>actualizarInput("nombre", e.target.value)} />
                </Campo>
                <Campo>
                <SpanValidacion active={newHotel.zona[1]}><span>{newHotel.zona[1]}</span><DivLinea/></SpanValidacion>
                    <label>Zona<span style={{color:"#eb1a1a"}}>*</span></label>
                    <Select defaultValue={newHotel.zona[0]} onChange={(e)=>actualizarInput("zona", e.target.value)} error={newHotel.zona[1]}>
                        <option value={newHotel.zona[0]}>{newHotel.zona[0]}</option>
                        {newHotel.zona[0] !== "Zona norte" && <option>Zona norte</option>}
                        {newHotel.zona[0] !== "Zona este" && <option>Zona este</option>}
                    </Select>
                </Campo>
                <Campo>
                    {/* <SpanValidacion active={newHotel.fotoPerfil[1]}><span>{newHotel.fotoPerfil[1]}</span><DivLinea/></SpanValidacion> */}
                    <label>Foto de perfil</label>
                    <Input onChange={(e)=>setFotoPerfil(e.target.files[0])} id="imgPerfil" accept=".jpg, .png, .jpeg" type="file"/>
                </Campo>
            </FilaModalHotel>
            <h4>Fotos y detalles</h4>
            <ImagenesCont>
                <div style={{display:"flex", cursor:"pointer", backgroundColor:"#F5F5F5", flexDirection:"column", height:pc ? "10vw" : "25vw", justifyContent:"center", alignItems:"center", width:pc ? "15vw" : "30vw", margin: pc ? "1.5vw" : "5vw"}} onClick={()=>document.querySelector("#btnAddImg").click()}>
                    <p style={{fontWeight:"bold"}}>Añadir foto</p>
                    <img style={{width:pc ? "3vw" : "8vw", height:pc ? "3vw" : "8vw", margin:0}} src={addImg} />
                </div>
                {
                    Object.keys(villa.imagenes).map((campo)=>(
                        <div>
                            <img src={villa.imagenes[campo]} />
                            <span onClick={()=>{setIdFotoDel(campo); setModalEliminarFoto(true)}}>
                                <img src={quitar} />
                            </span>
                        </div>
                    ))
                }
            </ImagenesCont>
            <FilaModalHotel style={{justifyContent:"flex-start"}}>
                <Campo>
                    <SpanValidacion active={newHotel.descripcion[1]}><span>{newHotel.descripcion[1]}</span><DivLinea/></SpanValidacion>
                    <label>Breve descripción</label>
                    <Textarea value={newHotel.descripcion[0]} error={newHotel.descripcion[1]} onChange={(e)=>actualizarInput("descripcion", e.target.value)}/>
                </Campo>
                <Campo>
                    {/* <SpanValidacion active={newHotel.imagenes[1]}><span>{newHotel.imagenes[1]}</span><DivLinea/></SpanValidacion> */}
                    {/* <label>Fotos</label>
                    <Input accept=".jpg, .png, .jpeg" multiple id="inputImagenes" type="file"/> */}
                </Campo>
            </FilaModalHotel>
            <h4>Ofertas</h4>
            { ofertasAdd.map((value, index)=>(
                idEditOferta !== false ?
                    idEditOferta == index &&
                        <Campo style={{marginBottom: idEditOferta == index && "0"}} oferta={true} key={index}>
                            <p>Oferta {index+1}</p>
                            <div style={{marginLeft: "auto", display:"flex", alignItems:"center"}}>
                                <img style={{marginRight:"0.5vw", width:pc ? "1.4vw" : "3vw"}} onClick={()=>{setOferta(false); setEditOferta(false); setIdEditOferta(false)}} src={edit1}/>
                                {/* <img onClick={()=>{eliminarOferta(index); setIdEditOferta(false); setEditOferta(false)}} src={quitar}/> */}
                            </div>
                        </Campo>
                :
                <Campo oferta={true} key={index}>
                    <p>Oferta {index+1}</p>
                    <div style={{marginLeft: "auto", display:"flex", alignItems:"center"}}>
                        <img style={{marginRight:pc ? "0.5vw" : "2vw", width:pc ? "1.4vw" : "5.5vw"}} onClick={()=>{setEditOferta(JSON.parse(JSON.stringify(value))); setOferta(false); setIdEditOferta(index)}} src={edit1}/>
                        <img onClick={()=>eliminarOferta(index)} src={quitar}/>
                    </div>
                </Campo>
            ))}
            { editOferta !== false ?
                <FilaModalHotel style={{backgroundColor:"#fff", border:"1px solid #E4E4E4"}}>
                    <Campo>
                        <SpanValidacion active={editOferta.codigoOferta[1]}><span>{editOferta.codigoOferta[1]}</span><DivLinea/></SpanValidacion>
                        <label>Codigo oferta<span style={{color:"#eb1a1a"}}>*</span></label>
                        <Input value={editOferta.codigoOferta[0]} onChange={(e)=>actualizarInputOferta(idEditOferta, "codigoOferta", e.target.value)} error={editOferta.codigoOferta[1]}  type="text"/>
                    </Campo>
                    <Campo>
                        <SpanValidacion active={editOferta.vencimientoOferta[1]}><span>{editOferta.vencimientoOferta[1]}</span><DivLinea/></SpanValidacion>
                        <label>Vencimiento oferta<span style={{color:"#eb1a1a"}}>*</span></label>
                        <Input value={cambiarFechaInput(editOferta.vencimientoOferta[0])} onChange={(e)=>actualizarInputOferta(idEditOferta, "vencimientoOferta", e.target.value)} error={editOferta.vencimientoOferta[1]}  type="date"/>
                    </Campo>
                    <Campo>
                        <SpanValidacion active={editOferta.desde[1]}><span>{editOferta.desde[1]}</span><DivLinea/></SpanValidacion>
                        <label>Periodo (Desde)<span style={{color:"#eb1a1a"}}>*</span></label>
                        <Input value={cambiarFechaInput(editOferta.desde[0])} onChange={(e)=>actualizarInputOferta(idEditOferta, "desde", e.target.value)} error={editOferta.desde[1]}  type="date"/>
                    </Campo>
                    <Campo>
                        <SpanValidacion active={editOferta.hasta[1]}><span>{editOferta.hasta[1]}</span><DivLinea/></SpanValidacion>
                        <label>Periodo (Hasta)<span style={{color:"#eb1a1a"}}>*</span></label>
                        <Input value={cambiarFechaInput(editOferta.hasta[0])} onChange={(e)=>actualizarInputOferta(idEditOferta, "hasta", e.target.value)} error={editOferta.hasta[1]}  type="date"/>
                    </Campo>
                    <h4 style={{margin:"10px", width:"85%"}}>Rangos</h4>
                            { editOferta.rangos.length > 0 && editOferta.rangos.map((value, index)=>
                                !rango &&
                                ( 
                                <Campo style={{width:"85%", marginBottom:"3%", display:idEditRango !== false ? idEditRango === index ? "flex" : "none" : "flex", flexDirection:"column"}} rango={true} oferta={true} key={index}>
                                    <div style={{display:"flex", width:"100%", justifyContent:"space-between", alignItems:"center"}}>
                                        <p>{value.rangoMin}-{value.rangoMax} Personas</p>
                                        {!editRango && <img onClick={()=>delRango(index)} src={quitar}/>}
                                    </div>
                                    <div style={{display:"flex", margin:"2%", width:"100%",  flexDirection:"column", alignItems:"center"}}>
                                        { value.rangos.length > 0 ?
                                            value.rangos.map((subRango, indexSubRango)=>(
                                                <div style={{display:"flex", justifyContent:"space-between", marginTop:indexSubRango != 0 && "1.5%", padding:"0.5%", backgroundColor:"#fff", color:"#000", width:"80%", alignItems:"center"}}>
                                                    <p>Precio: ${subRango.precio} ({subRango.dias.map((dia, index)=>(dia+(index != subRango.dias.length-1 ? ", " : "")))})</p>
                                                    <div style={{display:"flex", alignItems:"center"}}>
                                                        <img onClick={()=>{setEditRango(JSON.parse(JSON.stringify({precio:[subRango.precio, ""], dias:[subRango.dias, ""]}))); setIdEditRango(index); setIdSubRango(indexSubRango)}} style={{width:pc ? "1.4vw" : "", height:pc ? "1.3vw" : "", marginRight:pc ? "2.5%" : ""}} src={edit}/>
                                                        <img onClick={()=>delRango(index, indexSubRango)} src={quitar1}/>
                                                    </div>
                                                </div>
                                            ))
                                        : 
                                        <p>No hay sub-rangos</p>
                                        }
                                    </div>
                                    <Btns>
                                        <Button onClick={()=>{setEditRango(ofertaDefault.rangos[0]); setIdEditRango(index)}} style={{fontSize:pc ? "0.85vw" : "3vw", padding:pc ? "0.3vw" : "1vw"}}>Agregar sub-rango</Button>
                                    </Btns>
                                </Campo>
                                )
                            )}
                            { editRango !== false ?
                                <div style={{width:"40%"}}>
                                    <RangosCont>
                                        <Campo>
                                            <SpanValidacion rango={true} active={editRango.precio[1]}><span>{editRango.precio[1]}</span><DivLinea/></SpanValidacion>
                                            <p>Precio</p>
                                            <Input value={editRango.precio[0]} placeholder="Precio" onChange={(e)=>actualizarInputRango("precio", e.target.value)} error={editRango.precio[1]}  type="text"/>
                                        </Campo>
                                    </RangosCont>
                                    <Campo style={{alignItems:"center", width:"100%"}}>
                                                <label>Dias<span style={{color:"#eb1a1a"}}>*</span></label>
                                                <SpanValidacion style={{marginRight: "44%", marginBottom: pc ? "8.5%" : "23%"}} active={editRango.dias[1]}><span>{editRango.dias[1]}</span><DivLinea/></SpanValidacion>
                                                <Dia>
                                                    <label>Todos</label>
                                                    <input defaultChecked={editRango.dias[0].includes("todos") ? true : false} onChange={(e)=>actualizarInputRango("dias", e.target.checked, "todos")} type="checkbox"/>
                                                </Dia>
                                                {editRango.dias[0][0] != "todos" && <Dias style={{flexDirection:"row!important"}}>                                                        
                                                    <Dia>
                                                        <label>Lunes</label>
                                                        <input defaultChecked={editRango.dias[0].includes("lunes") ? true : false} onChange={(e)=>actualizarInputRango("dias", e.target.checked, "lunes")} type="checkbox"/>
                                                    </Dia>
                                                    <Dia>
                                                        <label>Martes</label>
                                                        <input defaultChecked={editRango.dias[0].includes("martes") ? true : false} onChange={(e)=>actualizarInputRango("dias", e.target.checked, "martes")} type="checkbox"/>
                                                    </Dia>
                                                    <Dia>
                                                        <label>Miercoles</label>
                                                        <input defaultChecked={editRango.dias[0].includes("miercoles") ? true : false} onChange={(e)=>actualizarInputRango("dias", e.target.checked, "miercoles")} type="checkbox"/>
                                                    </Dia>
                                                    <Dia>
                                                        <label>Jueves</label>
                                                        <input defaultChecked={editRango.dias[0].includes("jueves") ? true : false} onChange={(e)=>actualizarInputRango("dias", e.target.checked, "jueves")} type="checkbox"/>
                                                    </Dia>
                                                    <Dia>
                                                        <label>Viernes</label>
                                                        <input defaultChecked={editRango.dias[0].includes("viernes") ? true : false} onChange={(e)=>actualizarInputRango("dias", e.target.checked, "viernes")} type="checkbox"/>
                                                    </Dia>
                                                    <Dia>
                                                        <label>Sabado</label>
                                                        <input defaultChecked={editRango.dias[0].includes("sabado") ? true : false} onChange={(e)=>actualizarInputRango("dias", e.target.checked, "sabado")} type="checkbox"/>
                                                    </Dia>
                                                    <Dia>
                                                        <label>Domingo</label>
                                                        <input defaultChecked={editRango.dias[0].includes("domingo") ? true : false} onChange={(e)=>actualizarInputRango("dias", e.target.checked, "domingo")} type="checkbox"/>
                                                    </Dia>
                                                </Dias>}
                                    </Campo>
                                    <Btns style={{marginBottom:pc?"1vw":"1vw"}}>
                                        <Button onClick={()=>{setEditRango(false); setIdSubRango(false); setIdEditRango(false)}} style={{fontSize:pc ? "0.85vw" : "3vw", padding:pc ? "0.3vw" : "1vw"}}>Cerrar</Button>
                                        <Button onClick={()=>validarRango()} style={{fontSize:pc ? "0.85vw" : "3vw", width:!pc && "50%", padding:pc ? "0.3vw" : "1vw"}}>{idSubRango !== false  ? "Modificar sub-rango" : "Agregar sub-rango"}</Button>
                                    </Btns>
                                </div>
                                :
                                rango === false &&
                                    <FilaModalHotel>
                                        <Btns>
                                            <Button onClick={()=>setRango(ofertaDefault.rangos[0])} style={{fontSize:pc ? "0.85vw" : "3vw", padding:pc ? "0.3vw" : "1vw"}}>Agregar rango</Button>
                                        </Btns>
                                    </FilaModalHotel>
                            }
                            { rango !== false &&
                                <div>
                                    <RangosCont>
                                        <Campo>
                                            <SpanValidacion rango={true} active={rango.rangoMin[1]}><span>{rango.rangoMin[1]}</span><DivLinea rango={true} /></SpanValidacion>
                                            {/* <label>Rango minimo<span style={{color:"#eb1a1a"}}>*</span></label> */}
                                            <Input value={rango.rangoMin[0]} placeholder="1" onChange={(e)=>actualizarInputRango("rangoMin", e.target.value)} error={rango.rangoMin[1]}  type="text"/>
                                        </Campo>
                                        <p>-</p>
                                        <Campo>
                                            <SpanValidacion rango={true} active={rango.rangoMax[1]}><span>{rango.rangoMax[1]}</span><DivLinea/></SpanValidacion>
                                            {/* <label>Rango Maximo<span style={{color:"#eb1a1a"}}>*</span></label> */}
                                            <Input value={rango.rangoMax[0]} placeholder="5" onChange={(e)=>actualizarInputRango("rangoMax", e.target.value)} error={rango.rangoMax[1]}  type="text"/>
                                        </Campo>
                                        <Campo>
                                            <SpanValidacion rango={true} active={rango.precio[1]}><span>{rango.precio[1]}</span><DivLinea/></SpanValidacion>
                                            {/* <label>Precio<span style={{color:"#eb1a1a"}}>*</span></label> */}
                                            <Input value={rango.precio[0]} placeholder="Precio" onChange={(e)=>actualizarInputRango("precio", e.target.value)} error={rango.precio[1]}  type="text"/>
                                        </Campo>
                                    </RangosCont>
                                    <Campo style={{alignItems:"center", width:"100%"}}>
                                                <label>Dias<span style={{color:"#eb1a1a"}}>*</span></label>
                                                <SpanValidacion style={{marginRight: "44%", marginBottom: pc ? "8.5%" : "23%"}} active={rango.dias[1]}><span>{rango.dias[1]}</span><DivLinea/></SpanValidacion>
                                                <Dia>
                                                    <label>Todos</label>
                                                    <input defaultChecked={rango.dias[0].includes("todos") ? true : false} onChange={(e)=>actualizarInputRango("dias", e.target.checked, "todos")} type="checkbox"/>
                                                </Dia>
                                                {rango.dias[0][0] != "todos" && <Dias style={{flexDirection:"row!important"}}>                                                        
                                                    <Dia>
                                                        <label>Lunes</label>
                                                        <input defaultChecked={rango.dias[0].includes("lunes") ? true : false} onChange={(e)=>actualizarInputRango("dias", e.target.checked, "lunes")} type="checkbox"/>
                                                    </Dia>
                                                    <Dia>
                                                        <label>Martes</label>
                                                        <input defaultChecked={rango.dias[0].includes("martes") ? true : false} onChange={(e)=>actualizarInputRango("dias", e.target.checked, "martes")} type="checkbox"/>
                                                    </Dia>
                                                    <Dia>
                                                        <label>Miercoles</label>
                                                        <input defaultChecked={rango.dias[0].includes("miercoles") ? true : false} onChange={(e)=>actualizarInputRango("dias", e.target.checked, "miercoles")} type="checkbox"/>
                                                    </Dia>
                                                    <Dia>
                                                        <label>Jueves</label>
                                                        <input defaultChecked={rango.dias[0].includes("jueves") ? true : false} onChange={(e)=>actualizarInputRango("dias", e.target.checked, "jueves")} type="checkbox"/>
                                                    </Dia>
                                                    <Dia>
                                                        <label>Viernes</label>
                                                        <input defaultChecked={rango.dias[0].includes("viernes") ? true : false} onChange={(e)=>actualizarInputRango("dias", e.target.checked, "viernes")} type="checkbox"/>
                                                    </Dia>
                                                    <Dia>
                                                        <label>Sabado</label>
                                                        <input defaultChecked={rango.dias[0].includes("sabado") ? true : false} onChange={(e)=>actualizarInputRango("dias", e.target.checked, "sabado")} type="checkbox"/>
                                                    </Dia>
                                                    <Dia>
                                                        <label>Domingo</label>
                                                        <input defaultChecked={rango.dias[0].includes("domingo") ? true : false} onChange={(e)=>actualizarInputRango("dias", e.target.checked, "domingo")} type="checkbox"/>
                                                    </Dia>
                                                </Dias>}
                                        </Campo>
                                    <Btns style={{marginBottom:pc?"1vw":"1vw"}}>
                                        <Button onClick={()=>{setRango(false); setIdEditRango(false); setIdEditRango(false)}} style={{fontSize:pc ? "0.85vw" : "3vw", padding:pc ? "0.3vw" : "1vw"}}>Cerrar</Button>
                                        <Button onClick={()=>validarRango()} style={{fontSize:pc ? "0.85vw" : "3vw", padding:pc ? "0.3vw" : "1vw"}}>Añadir rango</Button>
                                    </Btns>
                                </div>
                            }
                    <Btns style={{marginBottom:pc?"1vw":"1vw"}}>
                        <Button onClick={()=>{setEditOferta(false); setEditRango(false); setIdEditRango(false); setIdEditOferta(false); setRango(false)}} style={{fontSize:pc ? "0.85vw" : "3vw", padding:pc ? "0.3vw" : "1vw"}}>Cerrar</Button>
                        <Button onClick={()=>{validarOferta(editOferta, false, idEditOferta); setOferta(false)}} style={{fontSize:pc ? "0.85vw" : "3vw", padding:pc ? "0.3vw" : "1vw"}}>Modificar oferta</Button>
                    </Btns>
                </FilaModalHotel>
                : oferta === false &&
                    <FilaModalHotel>
                        <Btns>
                            <Button onClick={()=>{let ofertaDefault1 = ofertaDefault; ofertaDefault1.rangos = []; setOferta(ofertaDefault1); setEditRango(false); setRango(false); setIdEditRango(false); setIdSubRango(false); setEditOferta(false); setIdEditOferta(false);}} style={{fontSize:pc ? "0.85vw" : "3vw", padding:pc ? "0.3vw" : "1vw"}}>Agregar Oferta</Button>
                        </Btns>
                    </FilaModalHotel>
                
            }
            { oferta !== false &&
                    <FilaModalHotel style={{backgroundColor:"#fff", border:"1px solid #E4E4E4"}}>
                        <Campo>
                            <SpanValidacion active={oferta.codigoOferta[1]}><span>{oferta.codigoOferta[1]}</span><DivLinea/></SpanValidacion>
                            <label>Codigo oferta<span style={{color:"#eb1a1a"}}>*</span></label>
                            <Input value={oferta.codigoOferta[0]} onChange={(e)=>actualizarInputOferta(idEditOferta, "codigoOferta", e.target.value)} error={oferta.codigoOferta[1]}  type="text"/>
                        </Campo>
                        <Campo>
                            <SpanValidacion active={oferta.vencimientoOferta[1]}><span>{oferta.vencimientoOferta[1]}</span><DivLinea/></SpanValidacion>
                            <label>Vencimiento oferta<span style={{color:"#eb1a1a"}}>*</span></label>
                            <Input value={cambiarFechaInput(oferta.vencimientoOferta[0])} onChange={(e)=>actualizarInputOferta(idEditOferta, "vencimientoOferta", e.target.value)} error={oferta.vencimientoOferta[1]}  type="date"/>
                        </Campo>
                        <Campo>
                            <SpanValidacion active={oferta.desde[1]}><span>{oferta.desde[1]}</span><DivLinea/></SpanValidacion>
                            <label>Periodo (Desde)<span style={{color:"#eb1a1a"}}>*</span></label>
                            <Input value={cambiarFechaInput(oferta.desde[0])} onChange={(e)=>actualizarInputOferta(idEditOferta, "desde", e.target.value)} error={oferta.desde[1]}  type="date"/>
                        </Campo>
                        <Campo>
                            <SpanValidacion active={oferta.hasta[1]}><span>{oferta.hasta[1]}</span><DivLinea/></SpanValidacion>
                            <label>Periodo (Hasta)<span style={{color:"#eb1a1a"}}>*</span></label>
                            <Input value={cambiarFechaInput(oferta.hasta[0])} onChange={(e)=>actualizarInputOferta(idEditOferta, "hasta", e.target.value)} error={oferta.hasta[1]}  type="date"/>
                        </Campo>
                        <h4 style={{margin:"10px", width:"85%"}}>Rangos</h4>
                                { oferta.rangos.length > 0 && oferta.rangos.map((value, index)=>
                                    !rango &&
                                ( 
                                    <Campo style={{width:"85%", marginBottom:"3%", display:idEditRango !== false ? idEditRango === index ? "flex" : "none" : "flex", flexDirection:"column"}} rango={true} oferta={true} key={index}>
                                        <div style={{display:"flex", width:"100%", justifyContent:"space-between", alignItems:"center"}}>
                                            <p>{value.rangoMin}-{value.rangoMax} Personas</p>
                                            {!editRango && <img onClick={()=>delRango(index)} src={quitar}/>}
                                        </div>
                                        <div style={{display:"flex", margin:"2%", width:"100%",  flexDirection:"column", alignItems:"center"}}>
                                            { value.rangos.length > 0 ?
                                                value.rangos.map((subRango, indexSubRango)=>(
                                                    <div style={{display:"flex", justifyContent:"space-between", marginTop:indexSubRango != 0 && "1.5%", padding:"0.5%", backgroundColor:"#fff", color:"#000", width:"80%", alignItems:"center"}}>
                                                        <p>Precio: ${subRango.precio} ({subRango.dias.map((dia, index)=>(dia+(index != subRango.dias.length-1 ? ", " : "")))})</p>
                                                        <div style={{display:"flex", alignItems:"center"}}>
                                                            <img onClick={()=>{setEditRango(JSON.parse(JSON.stringify({precio:[subRango.precio, ""], dias:[subRango.dias, ""]}))); setIdEditRango(index); setIdSubRango(indexSubRango)}} style={{width:pc ? "1.4vw" : "", height:pc ? "1.3vw" : "", marginRight:pc ? "2.5%" : ""}} src={edit}/>
                                                            <img onClick={()=>delRango(index, indexSubRango)} src={quitar1}/>
                                                        </div>
                                                    </div>
                                                ))
                                            : 
                                            <p>No hay sub-rangos</p>
                                            }
                                        </div>
                                        <Btns>
                                            <Button onClick={()=>{setEditRango(ofertaDefault.rangos[0]); setIdEditRango(index)}} style={{fontSize:pc ? "0.85vw" : "3vw", padding:pc ? "0.3vw" : "1vw"}}>Agregar sub-rango</Button>
                                        </Btns>
                                    </Campo>
                                    )
                                )}
                                { editRango !== false ?
                                    <div style={{width:"40%"}}>
                                        <RangosCont>
                                            <Campo>
                                                <SpanValidacion rango={true} active={editRango.precio[1]}><span>{editRango.precio[1]}</span><DivLinea/></SpanValidacion>
                                                <p>Precio</p>
                                                <Input value={editRango.precio[0]} placeholder="Precio" onChange={(e)=>actualizarInputRango("precio", e.target.value)} error={editRango.precio[1]}  type="text"/>
                                            </Campo>
                                        </RangosCont>
                                        <Campo style={{alignItems:"center", width:"100%"}}>
                                                    <label>Dias<span style={{color:"#eb1a1a"}}>*</span></label>
                                                    <SpanValidacion style={{marginRight: "44%", marginBottom: pc ? "8.5%" : "23%"}} active={editRango.dias[1]}><span>{editRango.dias[1]}</span><DivLinea/></SpanValidacion>
                                                    <Dia>
                                                        <label>Todos</label>
                                                        <input defaultChecked={editRango.dias[0].includes("todos") ? true : false} onChange={(e)=>actualizarInputRango("dias", e.target.checked, "todos")} type="checkbox"/>
                                                    </Dia>
                                                    {editRango.dias[0][0] != "todos" && <Dias style={{flexDirection:"row!important"}}>                                                        
                                                        <Dia>
                                                            <label>Lunes</label>
                                                            <input defaultChecked={editRango.dias[0].includes("lunes") ? true : false} onChange={(e)=>actualizarInputRango("dias", e.target.checked, "lunes")} type="checkbox"/>
                                                        </Dia>
                                                        <Dia>
                                                            <label>Martes</label>
                                                            <input defaultChecked={editRango.dias[0].includes("martes") ? true : false} onChange={(e)=>actualizarInputRango("dias", e.target.checked, "martes")} type="checkbox"/>
                                                        </Dia>
                                                        <Dia>
                                                            <label>Miercoles</label>
                                                            <input defaultChecked={editRango.dias[0].includes("miercoles") ? true : false} onChange={(e)=>actualizarInputRango("dias", e.target.checked, "miercoles")} type="checkbox"/>
                                                        </Dia>
                                                        <Dia>
                                                            <label>Jueves</label>
                                                            <input defaultChecked={editRango.dias[0].includes("jueves") ? true : false} onChange={(e)=>actualizarInputRango("dias", e.target.checked, "jueves")} type="checkbox"/>
                                                        </Dia>
                                                        <Dia>
                                                            <label>Viernes</label>
                                                            <input defaultChecked={editRango.dias[0].includes("viernes") ? true : false} onChange={(e)=>actualizarInputRango("dias", e.target.checked, "viernes")} type="checkbox"/>
                                                        </Dia>
                                                        <Dia>
                                                            <label>Sabado</label>
                                                            <input defaultChecked={editRango.dias[0].includes("sabado") ? true : false} onChange={(e)=>actualizarInputRango("dias", e.target.checked, "sabado")} type="checkbox"/>
                                                        </Dia>
                                                        <Dia>
                                                            <label>Domingo</label>
                                                            <input defaultChecked={editRango.dias[0].includes("domingo") ? true : false} onChange={(e)=>actualizarInputRango("dias", e.target.checked, "domingo")} type="checkbox"/>
                                                        </Dia>
                                                    </Dias>}
                                        </Campo>
                                        <Btns style={{marginBottom:pc?"1vw":"1vw"}}>
                                            <Button onClick={()=>{setEditRango(false); setIdSubRango(false); setIdEditRango(false)}} style={{fontSize:pc ? "0.85vw" : "3vw", padding:pc ? "0.3vw" : "1vw"}}>Cerrar</Button>
                                            <Button onClick={()=>validarRango()} style={{fontSize:pc ? "0.85vw" : "3vw", width:!pc && "50%", padding:pc ? "0.3vw" : "1vw"}}>{idSubRango !== false  ? "Modificar sub-rango" : "Agregar sub-rango"}</Button>
                                        </Btns>
                                    </div>
                                    :
                                    rango === false &&
                                        <FilaModalHotel>
                                            <Btns>
                                                <Button onClick={()=>setRango(ofertaDefault.rangos[0])} style={{fontSize:pc ? "0.85vw" : "3vw", padding:pc ? "0.3vw" : "1vw"}}>Agregar rango</Button>
                                            </Btns>
                                        </FilaModalHotel>
                                }
                                { rango !== false &&
                                    <div>
                                        <RangosCont>
                                            <Campo>
                                                <SpanValidacion rango={true} active={rango.rangoMin[1]}><span>{rango.rangoMin[1]}</span><DivLinea rango={true} /></SpanValidacion>
                                                {/* <label>Rango minimo<span style={{color:"#eb1a1a"}}>*</span></label> */}
                                                <Input value={rango.rangoMin[0]} placeholder="1" onChange={(e)=>actualizarInputRango("rangoMin", e.target.value)} error={rango.rangoMin[1]}  type="text"/>
                                            </Campo>
                                            <p>-</p>
                                            <Campo>
                                                <SpanValidacion rango={true} active={rango.rangoMax[1]}><span>{rango.rangoMax[1]}</span><DivLinea/></SpanValidacion>
                                                {/* <label>Rango Maximo<span style={{color:"#eb1a1a"}}>*</span></label> */}
                                                <Input value={rango.rangoMax[0]} placeholder="5" onChange={(e)=>actualizarInputRango("rangoMax", e.target.value)} error={rango.rangoMax[1]}  type="text"/>
                                            </Campo>
                                            <Campo>
                                                <SpanValidacion rango={true} active={rango.precio[1]}><span>{rango.precio[1]}</span><DivLinea/></SpanValidacion>
                                                {/* <label>Precio<span style={{color:"#eb1a1a"}}>*</span></label> */}
                                                <Input value={rango.precio[0]} placeholder="Precio" onChange={(e)=>actualizarInputRango("precio", e.target.value)} error={rango.precio[1]}  type="text"/>
                                            </Campo>
                                        </RangosCont>
                                        <Campo style={{alignItems:"center", width:"100%"}}>
                                                    <label>Dias<span style={{color:"#eb1a1a"}}>*</span></label>
                                                    <SpanValidacion style={{marginRight: "44%", marginBottom: pc ? "8.5%" : "23%"}} active={rango.dias[1]}><span>{rango.dias[1]}</span><DivLinea/></SpanValidacion>
                                                    <Dia>
                                                        <label>Todos</label>
                                                        <input defaultChecked={rango.dias[0].includes("todos") ? true : false} onChange={(e)=>actualizarInputRango("dias", e.target.checked, "todos")} type="checkbox"/>
                                                    </Dia>
                                                    {rango.dias[0][0] != "todos" && <Dias style={{flexDirection:"row!important"}}>                                                        
                                                        <Dia>
                                                            <label>Lunes</label>
                                                            <input defaultChecked={rango.dias[0].includes("lunes") ? true : false} onChange={(e)=>actualizarInputRango("dias", e.target.checked, "lunes")} type="checkbox"/>
                                                        </Dia>
                                                        <Dia>
                                                            <label>Martes</label>
                                                            <input defaultChecked={rango.dias[0].includes("martes") ? true : false} onChange={(e)=>actualizarInputRango("dias", e.target.checked, "martes")} type="checkbox"/>
                                                        </Dia>
                                                        <Dia>
                                                            <label>Miercoles</label>
                                                            <input defaultChecked={rango.dias[0].includes("miercoles") ? true : false} onChange={(e)=>actualizarInputRango("dias", e.target.checked, "miercoles")} type="checkbox"/>
                                                        </Dia>
                                                        <Dia>
                                                            <label>Jueves</label>
                                                            <input defaultChecked={rango.dias[0].includes("jueves") ? true : false} onChange={(e)=>actualizarInputRango("dias", e.target.checked, "jueves")} type="checkbox"/>
                                                        </Dia>
                                                        <Dia>
                                                            <label>Viernes</label>
                                                            <input defaultChecked={rango.dias[0].includes("viernes") ? true : false} onChange={(e)=>actualizarInputRango("dias", e.target.checked, "viernes")} type="checkbox"/>
                                                        </Dia>
                                                        <Dia>
                                                            <label>Sabado</label>
                                                            <input defaultChecked={rango.dias[0].includes("sabado") ? true : false} onChange={(e)=>actualizarInputRango("dias", e.target.checked, "sabado")} type="checkbox"/>
                                                        </Dia>
                                                        <Dia>
                                                            <label>Domingo</label>
                                                            <input defaultChecked={rango.dias[0].includes("domingo") ? true : false} onChange={(e)=>actualizarInputRango("dias", e.target.checked, "domingo")} type="checkbox"/>
                                                        </Dia>
                                                    </Dias>}
                                            </Campo>
                                        <Btns style={{marginBottom:pc?"1vw":"1vw"}}>
                                            <Button onClick={()=>{setRango(false); setIdEditRango(false); setIdEditRango(false)}} style={{fontSize:pc ? "0.85vw" : "3vw", padding:pc ? "0.3vw" : "1vw"}}>Cerrar</Button>
                                            <Button onClick={()=>validarRango()} style={{fontSize:pc ? "0.85vw" : "3vw", padding:pc ? "0.3vw" : "1vw"}}>Añadir rango</Button>
                                        </Btns>
                                    </div>
                                }
                        <Btns style={{marginBottom:pc?"1vw":"1vw"}}>
                            <Button onClick={()=>{setEditOferta(false); setOferta(false); setEditRango(false); setIdEditRango(false); setIdSubRango(false); setIdEditOferta(false); setRango(false)}} style={{fontSize:pc ? "0.85vw" : "3vw", padding:pc ? "0.3vw" : "1vw"}}>Cerrar</Button>
                            <Button onClick={()=>validarOferta(oferta, false, false)} style={{fontSize:pc ? "0.85vw" : "3vw", padding:pc ? "0.3vw" : "1vw"}}>Añadir oferta</Button>
                        </Btns>
                    </FilaModalHotel>
                }

            <p style={{color:"#eb1a1a", margin:"0 auto"}}>{msgVal}</p>
            <Btns>
                <Button onClick={()=>setClose()}>Cancelar</Button>
                <Button style={{opacity:diferente ? "1" : "0.5", backgroundColor: cargando && "#fff"}} onClick={()=>diferente && !cargando && validarVilla()} yes={true}>
                    { cargando ?
                        cargando && <CargandoCont><img src={cargandoImg}/></CargandoCont>
                        :
                        "Modificar villa"
                    }
                </Button>
            </Btns>
        </PlataformaContainer>
    );
};