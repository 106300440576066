import styled from "styled-components";
import { useContext, useEffect, useState } from "react";
import sessionContext from "../contexts/sessionContext";
import { useNavigate } from "react-router-dom";
import { getOfertas } from "../config/auth";
import CargandoCont from "./utils/cargando";

//imagenes
import cargandoImg from "../images/cargando.gif";
import descargar from "../images/descargar.png";

const pc = window.screen.width > 529 ? true : false;

const PlataformaContainer = styled.div`
    display: flex;
    padding: 3vw;
    padding-top: ${pc ? "6vw" : "18vw"};
    margin-bottom: 5vw;
    height: 100vh;
    background-color: #fff;
    flex-direction:column;
    margin-left: ${pc && "17vw"};
    
    & h2 {
        font-size: ${pc ? "2vw" : "7vw"};
        font-weight: 700;
        margin-bottom: ${pc ? "1vw" : "3vw"};
    }
`;

const OfertasTotalContenedor = styled.div`
    display: flex;
    flex-direction: ${pc ? "row" : "column"};
    flex-wrap: wrap;
`;

const OfertasContenedor = styled.div`
    display: flex;
    align-items: center;
    justify-content: ${pc ? "flex-start" : "center"};
    /* width: ${pc && "25vw"}; */
    margin-bottom: ${pc ? "3vw" : "8.5vw"};
    flex-wrap: wrap;
`;

const OfertaContenedor = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: ${pc ? "10vw" : "40%"};
    margin-right: ${pc && "3vw"};
    margin-bottom: ${pc ? "" : "7vw"};
`;

const BtnDescargar = styled.button`
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${pc ? "80%" : "75%"};
    padding: ${pc ? "0.4vw" : "1.5vw"};
    background-color: #004aad;
    margin-top: ${pc ? "1.3vw" : "3vw"};
    border-radius: ${pc ? "0.6vw" : "2vw"};
    cursor: pointer;

    & p {
        color:#fff;
        font-size: ${pc ? "0.9vw" : "3.3vw"};
        margin-right: ${pc ? "0.1vw" : "0.5vw"};
        text-decoration: none;
    }

    & img {
        width: ${pc ? "19%" : "22%"};
    }
`;

const Img = styled.div`
    width: ${pc ? "92%" : "60%"};
    border-color: red;
    height: ${pc ? "10vw" : "13vh"};
    background-image: url(${props => props.img});
    background-size: contain;
`;

export default function Plataforma (){

    const navigate = useNavigate();

    const { session } = useContext(sessionContext);

    let [ofertas, setOfertas] = useState(undefined);
    let [msgVal, setMsgVal] = useState("");


    async function getDatosOfertas () {
        const datos = await getOfertas();
        if (datos[0]) {
            setOfertas(datos[1]);
            if (datos[1].length < 1) {
                setMsgVal("No hay ofertas");
            }
        } else {
            setOfertas(false);
            setMsgVal(datos[1]);
        }
    }

    async function downloadImage(url){
        try {
            await fetch(url)
            .then(resp => resp.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = "Oferta.";
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            });
        } catch {
            
        }
    }



    useEffect(()=>{
        if (session.session == false) {
            navigate("/");
        }
    },[]);
    useEffect(()=>{
        if (session.user) {
            getDatosOfertas();
        }
    },[session.user]);


    return (
        <PlataformaContainer>
            <h2>OFERTAS</h2>
            <OfertasTotalContenedor>
                <OfertasContenedor msgVal={msgVal}>
                    { ofertas === undefined && <CargandoCont><img src={cargandoImg}/></CargandoCont>}
                    {(ofertas !== undefined && ofertas !== false) ? ofertas.length > 0 ? ofertas.map((value, index)=>(
                        <OfertaContenedor key={index}>
                            <Img img={value.img}/>
                            <BtnDescargar>
                                <p onClick={()=>downloadImage(value.img)}>Descargar</p>
                                <img src={descargar}/>
                            </BtnDescargar>
                        </OfertaContenedor>
                    ))
                    : <span>{msgVal}</span> : <span>{msgVal}</span>
                    }
                </OfertasContenedor>
            </OfertasTotalContenedor>   
        </PlataformaContainer>
    );
}