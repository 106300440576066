import styled from "styled-components";
import logo from "../images/logo22.webp";

const pc = window.screen.width > 529 ? true : false;

const PoliticasContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 4vw;
    justify-content: center;
    padding-top: ${pc ? "6vw" :"17vw"};
    flex-direction: column;
    margin-left: ${pc && "17vw" };

    & h2 {
        color:#004aad;
        font-weight: bold;
        margin-bottom: 2vw;
        margin-top: ${!pc && "4vw"};
        font-size: ${pc ? "2vw" : "5.5vw"};
    }
    & span {
        font-weight: bold;
        font-size: ${pc ? "1.2vw" : "" };
    }
    & a {
        color: #004aad;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }
`
const Logo = styled.img`
    width: ${pc ? "12vw" : "35vw"};
    margin-bottom: 2.5vw;
`;

export default function Politicas (){
    return (
        <PoliticasContainer>
            <Logo src={logo} />
            <h2>POLITICAS DE PRIVACIDAD</h2>
            <p>
            Las Políticas de Privacidad de Turisfreelance establecen los términos en que Turisfreelance.com usa y protege la información que es proporcionada por sus usuarios al momento de utilizar su sitio web. Estamos comprometidos con la seguridad de los datos de los usuarios. Cuando le pedimos llenar los campos de información personal con la cual usted pueda ser identificado, lo hacemos asegurando que sólo se empleará de acuerdo con los términos de este documento.
            <br/>
            <br/>
            Los datos pueden ser obtenidos a través de los siguientes medios:
            <br/>
            <br/>
            Formulario de contacto
            <br/>
            Formulario de suscripción
            <br/>
            Comentarios en los posts
            <br/>
            Formulario de venta
            <br/>
            Envios de mails
            <br/>
            Cookies.
            <br/>
            <br/>
            Nuestro sitio web podrá recoger información personal, por ejemplo: Nombre, información de contacto como su dirección de correo electrónico e información demográfica. Así mismo cuando sea necesario podrá ser requerida información específica para procesar algún pedido o realizar una entrega o facturación.
            <br/>
            <br/>
            El usuario declara que las informaciones proporcionadas al llenar cualquier formulario son reales y facilitadas bajo su consentimiento.
            <br/>
            <br/>
            Nuestro sitio web emplea la información con el fin de proporcionar el mejor servicio posible, mejorar su usabilidad, para mantener un registro de usuarios, de pedidos (en caso que aplique) y mejorar nuestros productos y servicios. Es posible que sean enviados correos electrónicos a través de nuestro sitio con notificaciones y ofertas que consideremos relevantes para usted, estos correos electrónicos serán enviados a la dirección que usted proporcione y podrán ser cancelados en cualquier momento.
            <br/>
            <br/>
            Estamos comprometidos a cumplir con el compromiso de mantener su información segura y confidencial bajo Las Políticas de Privacidad de Turisfreelance.
            Cualquier persona tiene derecho a obtener confirmación sobre si estamos tratando sus datos personales.
            <br/>
            <br/>
            Las personas interesadas tienen derecho a:
            <br/>
            <br/>
            Solicitar el acceso a los datos personales relativos al interesado.
            <br/>
            Solicitar su rectificación o supresión.
            <br/>
            Solicitar la limitación de su tratamiento.
            <br/>
            Oponerse al tratamiento,
            <br/>
            solicitar la portabilidad de los datos.
            <br/>
            <br/>
            Los datos personales proporcionados se conservarán:
            <br/>
            <br/>
            Mientras se mantenga la relación.
            <br/>
            Hasta que no se solicite su supresión por el interesado.
            <br/>
            En el caso de la cookies, según el tiempo de vigencia de las mismas o su definición de eliminarlas.
            <br/>
            <br/>
            <span>Aceptación y consentimiento</span>
            <br/>
            El usuario declara haber sido informado de las condiciones sobre protección de datos de carácter personal, aceptando y consintiendo el tratamiento de los mismos en la forma y para las finalidades indicadas en esta política de privacidad.
            <br/>
            La base legal para el tratamiento de sus datos el consentimiento, por lo cual requerimos el suyo a estas políticas de privacidad. Adicionalmente la contratación de productos y/o servicios se basan en los términos y condiciones que constan en el aviso legal.
            <br/>
            <br/>
            <span>Responsable del tratamiento de datos</span>
            <br/>
            Identidad del Responsable: Turisfreelance.com
            <br/>
            Nombre Comercial: Turisfreelance
            <br/>
            Dirección: Urbanización El ejecutivo, Bávaro, La Altagracia, Rep. Dominicana
            <br/>
            Correo Electrónico: ventas1@turisfreelance.com
            <br/>
            <br/>
            <span>Enlaces a Terceros</span>
            <br/>
            Este sitio web pudiera contener enlaces a otros sitios que pudieran ser de su interés. Una vez que usted de clic en estos enlaces y abandone nuestra página, ya no tenemos control sobre el sitio al que es redirigido y por lo tanto no somos responsables de los términos o privacidad ni de la protección de sus datos en esos otros sitios terceros. Dichos sitios están sujetos a sus propias políticas de privacidad por lo cual es recomendable que los consulte para confirmar que usted está de acuerdo con estas.
            <br/>
            <br/>
            Anuncios de Google
            <br/> 
            Los proveedores, incluido Google, utilizan cookies para mostrar anuncios relevantes ateniéndose las visitas anteriores de un usuario a al sitio web.
            El uso de cookies de publicidad permite a Google y a sus socios mostrar anuncios basados en las visitas realizadas por los usuarios a sus sitios web o a otros sitios web de Internet.
            <br/>
            Los usuarios pueden inhabilitar la publicidad personalizada. Para ello, deberán acceder a Preferencias de anuncios. Si no se desea que otros proveedores utilicen las cookies para la publicidad personalizada, deberán acceder a <a onClick={()=>window.open("https://www.aboutads.info")}>www.aboutads.info</a>.
            <br/>
            Si no ha inhabilitado la publicación de anuncios de terceros, las cookies de otros proveedores o redes publicitarias también pueden utilizarse para publicar anuncios:
            <br/>
            <br/>
            Este sitio web se muestran anuncios de otros proveedores y redes publicitarias.
            Enlaces a los sitios web de los proveedores y de las redes publicitarias correspondientes.
            <br/>
            Se puede visitar esos sitios web para inhabilitar el uso de cookies para la publicidad personalizada (en caso de que el proveedor o la red publicitaria ofrezcan esta opción). También se puede acceder a www.aboutads.info para inhabilitar el uso de cookies para la publicidad personalizada por parte de otros proveedores.

            </p>
        </PoliticasContainer>
    )
}