import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { iniciarSesion, recuperarPass } from "../config/auth";
import sessionContext from "../contexts/sessionContext";
import Modal from "./utils/modal";

//imagenes
import flecha from "../images/flecha.png";
import logo from "../images/logo22.webp";
import userInput2 from "../images/userInput2.png";
import userInput1 from "../images/userInput1.png";
import userInput from "../images/userInput.png";
import candado2 from "../images/candado2.png";
import candado1 from "../images/candado1.png";
import candado from "../images/candado.png";
import verPassActivo from "../images/verPassActivo.png";
import verPassImg from "../images/verPass.png";
import cargandoImg from "../images/cargando1.gif";
import { useEffect } from "react";

const pc = window.screen.width > 529 ? true : false;

const LoginContainer = styled.div`
    
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-left: ${pc && "17vw"};

    & h2 {
        font-size: ${pc ? "2vw" : "7vw"};
        font-weight: 500;
        color: #000;
        margin-top: ${pc ? "2%" : "10%"};
        line-height: ${pc ? "3vw" : "6vw"};
    }

    & img {
        width: ${pc ? "13%" : "38%"};
        margin-top: 2%;
    }
    & p {
        color: #41A3B0;
        font-size:3.3vw;
        cursor: pointer;
        padding: 2vw;
        border-radius: 1vw;
        margin-bottom: 3vw;
        background-color: #F4F4F4;
        margin-bottom: 9vh;
    }

    & p:hover {
        text-decoration: underline;
    }
`;

const FormLogin = styled.form`
    display: flex;
    flex-direction: column;
    width: ${pc ? "40%" : "80%"};
    margin-bottom: 3vw;
    padding: 2vw;
    padding-top: ${pc && 0};
    background-color: #fff;
    border-radius: 1.5vw;

`;

const ContenedorInput = styled.div`
    display: flex;
    align-items: center;
    padding: ${pc ? "0" : "1.5vw"};
    width: 100%;
    margin-bottom: ${props => props.marginBottom ? "0.5vw" : "0"};
    border-bottom: ${pc ? " 0.2vw" : "0.5vw "} ${ props => props.focus ? props.validacion ? "solid #eb1a1a" : "solid #004aad" : props.validacion ? "solid #eb1a1a" : "solid #939393"};

    & input {
        border:none;
        background-color: transparent;
        padding: ${pc ? "0.5vw" : "1.5vw"};
        
        width: 100%;
        font-size: ${pc && "1vw"};
    }
    & input:focus {
        outline: none;
    }

    & input::placeholder {
        color:#939393;
        opacity: 0.7;
    }
    & img {
        width: ${pc ? "5.5%" : "9%"};
        margin-bottom: ${pc && "0.4vw"};
    }
`

const ButtonEntrar = styled.button`
    background-color: #004aad;
    height: ${pc ? "3vw" : "12vw"};
    cursor: pointer;
    border-radius: ${pc ? "0.3vw" : "2vw"};
    margin: ${pc && "0 auto"};
    margin-top: ${pc ? "2.5vw" : "5vw"};
    border:none;
    width: ${pc ? "100%" : "100%"};
    display: flex;
    justify-content: center;
    align-items: center;

    & p {
        padding: 0;
        margin: 0;
        background-color: transparent;
        color:#fff;
        font-size: ${pc ? "1.3vw" : "4vw"};
    }
    & p:hover{
        text-decoration: none;
    }

    & img {
        width: 8%;
        margin-bottom: ${pc && "0.5vw"};
    }
`;

const TxtValidacion = styled.b`
    color:#eb1a1a;
    z-index: ${props => (props.validacion ? "100" : "-300")};
    font-size: ${pc ? "1vw" : "3.5vw"};
    margin-right: auto;
    margin-left: 2vw;
`

const ButtonContacto = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    & b {
        cursor: pointer;
        color: #004aad;
        font-weight: 500;
        padding: ${pc ? "0.5vw" : "2vw"};
        background-color: #F4F4F4;
        border-radius: ${pc ? "0.3vw" : "1vw"}; 
    }
`;

const BtnDevolver = styled.div`
    position: absolute;
    left: ${pc ? "19vw" : "1vw"};
    top: ${pc ? "0.5vw" : "1.3vw"};
    padding: 0.5vw;
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${pc ? "4vw" : "10vw"};
    cursor: pointer;
    z-index: 950;

    & img {
        width: 100%;
    }
`;

const OlvidePass = styled.span`
    font-size: 1vw;
    cursor: pointer;
    margin-left: auto;
    margin-top: 0.2vw;
    color:#1877f2;

    &:hover {
        text-decoration: underline;
    }
`;

const ModalRecuperarPass = styled.form`
    padding: 1vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: ${!pc && "75vw!important"};

    & b {
        margin-bottom: 0.5vw;
        font-weight: 400;
        text-align: center;
    }
    & input {
        border: 1px solid #A9A9A9;
        border-radius: ${pc ? "0.3vw" : "1vw"};
        padding: ${pc ? "0.5vw" : "1.4vw"};
        margin-top: ${pc ? "0.2vw" : "2.5vw"};
        width: ${pc ? "70%" : "90%"};
    }
    & input:focus {
        outline:none;
    }

    & button {
        padding: ${pc ? "0.5vw" : "1.4vw"};
        margin-top: ${pc ? "1vw" : "4.5vw"};
        background-color: #004aad;
        color:#fff;
        cursor: pointer;
        border: none;
        width: ${pc ? "30%" : "70%"};
    }
    & img {
        width: 2vw;
    }
`;

export let verificarEmail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default function Login (){

    const navigate = useNavigate();

    const { session, setSession } = useContext(sessionContext);

    let [focusUser, setFocusUser] = useState(false);
    let [focusPass, setFocusPass] = useState(false);

    let [readonly, setReadonly] = useState(false);

    let [cargando, setCargando] = useState(false);
    let [cargando1, setCargando1] = useState(false);
    let [recuperarPassModal, setRecuperarPassModal] = useState(false);

    let [verPass, setVerPass] = useState(false);

    let [inputEmail, setInputEmail] = useState("");
    let [inputPass, setInputPass] = useState("");
    let [email, setEmail] = useState("");

    let [msgValidacion, setMsgValidacion] = useState({email:"null", pass:"null", error:"null"});
    let [msgVal, setMsgVal] = useState("");

    async function validarForm (e) {
        e.preventDefault();
        setReadonly(true);
        setCargando(true);
        setMsgValidacion({email:"null", pass:"null", error:"null"});

        let email = "null";
        let pass = "null";
        let error = "null";

        if (inputEmail) {
            email = "null";
            if (verificarEmail.test(inputEmail)) {
                email = "null";
                setMsgValidacion({email:email, pass:pass, error:error});
            } else {
                email = "Ingresa un correo electronico verdadero";
                setMsgValidacion({email:email, pass:pass, error:error});
            }
        } else {
            email = "Ingresa un correo.";
            setMsgValidacion({email:email, pass:pass, error:error});
        }

        if (inputPass) {
            pass = "null";
            if (inputPass.length > 5) {
                setMsgValidacion({email:email, pass:pass, error:error});
            } else {
                pass = "Minimo 6 caracteres la contraseña.";
                setMsgValidacion({email:email, pass:pass, error:error});
            }
        } else {
            pass = "Ingresa una contraseña.";
            setMsgValidacion({email:email, pass:pass, error:error});
        }

        if (pass == "null" && email == "null" && error == "null") {
            const login = await iniciarSesion(inputEmail, inputPass);
            if (login[0] == "auth/user-not-found") {
                email = "Usuario no encontrado";
                setMsgValidacion({email:email, pass:pass, error:error});
            } else if (login[0] == "auth/wrong-password") {
                pass = "Contraseña incorrecta";
                setMsgValidacion({email:email, pass:pass, error:error});
            } else if (login[0] === true) {
                navigate(-1);
                setSession({session:true, admin:login[1].admin, user:{name:login[1].name, uid:login[1].uid, imgPerfil:login[1].imgPerfil, email:login[1].email}});
            }
        }

        setTimeout(()=>{
            setReadonly(false);
        },100);
        setCargando(false);
    }

    async function recuperarContraseña (e) {
        e.preventDefault();
        setCargando1(true);
        if (email.length > 0) {
            const result = await recuperarPass(email);
            if (result !== true) {
                if (result == "auth/invalid-email") {
                    setMsgVal("Ingresa un correo electronico verdadero.");
                } else if (result == "auth/user-not-found"){
                    setMsgVal("El correo que ingresaste no pertenece a ninguna cuenta.");
                } else {
                    setMsgVal(result);
                }
            } else {
                setMsgVal("Enviado correctamente!");
                setEmail("");
                setTimeout(()=>{
                    setRecuperarPassModal(false);
                    setMsgVal("");
                }, 1100);
            }
        } else {
            setMsgVal("Ingresa un correo electronico.");
        }
        setCargando1(false);
    }

    useEffect(()=>{
        if (session.user) {
            navigate("/");
        }
    },[session.user]);

    return (
        <LoginContainer>
            <Modal visible={recuperarPassModal} setVisible={()=>setRecuperarPassModal(false)}>
                <ModalRecuperarPass>
                    <h3>Restablece tu contraseña</h3>
                    <b>Se enviara un mensaje a tu correo electronico para que recuperes tu cuenta</b>
                    <b style={{color:msgVal == "Enviado correctamente!" ? "#26AC20" : "#eb1a1a", fontSize:"0.95vw", marginBottom:0}}>{msgVal}</b>
                    <input value={email} onChange={(e)=>setEmail(e.target.value)} placeholder="Ingresa tu correo electronico"/>
                    <button type="submit" onClick={(e)=>recuperarContraseña(e)}>
                        { cargando1 ?
                            <img src={cargandoImg}/>
                            : "Enviar"
                        }
                    </button>
                </ModalRecuperarPass>
            </Modal>
            <BtnDevolver onClick={()=>navigate(-1)}>
                <img src={flecha}/>
            </BtnDevolver>
            <img src={logo}/>
            <h2>Inicia sesión</h2>
            <FormLogin>
                <TxtValidacion validacion={(msgValidacion.email !== "null" || msgValidacion.error !== "null") && true}>
                    {( msgValidacion.email !== "null" || msgValidacion.error !== "null") ? 
                        msgValidacion.email !== "null" ? msgValidacion.email !== "errorUsuario" ? msgValidacion.email : msgValidacion.error :
                        msgValidacion.error !== "null" && msgValidacion.error
                        : "null"
                    }
                </TxtValidacion>
                <ContenedorInput marginBottom={true} validacion={msgValidacion.email !== "null" && true} focus={focusUser}>
                    <img src={ focusUser ? msgValidacion.email !== "null" ? userInput2 : userInput1 : msgValidacion.email !== "null" ? userInput2 : userInput}/>
                    <input onChange={(e)=>setInputEmail(e.target.value.replace(/\s+/g, ""))} autoComplete="email" onFocus={()=>setFocusUser(true)} onBlur={()=>setFocusUser(false)} placeholder="Ingresa tu correo electronico"/>
                </ContenedorInput>
                <TxtValidacion validacion={msgValidacion.pass !== "null" && true}>
                    {msgValidacion.pass}
                </TxtValidacion>
                <ContenedorInput validacion={(msgValidacion.pass !== "null") && true} focus={focusPass}>
                    <img src={ focusPass ? msgValidacion.pass !== "null" ? candado2 : candado1 : msgValidacion.pass !== "null" ? candado2 : candado}/>
                    <input autoComplete="current-password" readOnly={readonly ? true : false} onChange={(e)=>setInputPass(e.target.value)} type={verPass ? "text" : "password"} onFocus={()=>setFocusPass(true)} onBlur={()=>setFocusPass(false)} placeholder="Ingresa tu contraseña"/>
                    <img style={{cursor: "pointer"}} onClick={()=>setVerPass(!verPass)} src={verPass ? verPassActivo : verPassImg}/>
                </ContenedorInput>
                {pc &&
                    <OlvidePass onClick={()=>setRecuperarPassModal(true)}>¿Olvidaste tu contraseña?</OlvidePass>
                }
                <ButtonEntrar type="submit" onClick={(e)=>validarForm(e)}>
                    { cargando ? 
                        <img src={cargandoImg}/>
                        :
                        <p>Entrar</p>
                    }
                </ButtonEntrar>
            </FormLogin>
            { !pc && <p onClick={()=>setRecuperarPassModal(true)}>¿Olvidaste tu contraseña?</p>}
            <ButtonContacto>
                <span>¿No estas registrado?</span>
                <b onClick={()=>navigate("/#contacto")}>Contactanos</b>
            </ButtonContacto>
        </LoginContainer>
    )
}