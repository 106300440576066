import { useState, useEffect, useContext} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import sessionContext from "../../../contexts/sessionContext";
import styled from "styled-components";
import ContenedorOpcion from "../contenedorOpcion";
import { validarFecha, restaFechas, cambiarFecha, isNum } from "../../utils/validar";
import { confirmarSolicitud, getSolicitudesCliente, solicitarCotizacion, subirVoucher } from "../../../config/auth";
import CargandoCont from "../../utils/cargando";
import Modal, { BtnCerrar, DetallesReserva } from "../../utils/modal";
import {CuadroContenedor, TopCuadroContenedor, CuadroCont, Cuadro, BottomCuadroContenedor} from "../../utils/recuadro";
import { Formulario, TopFormulario, FormularioCont, Columna, BtnEnviar, Input, Select, SpanValidacion } from "../../utils/formulario";
import { descargarArchivo } from "../../utils/funciones";
import { BtnConfirmar } from "../../utils/componentes";

//imagenes
import quitar from "../../../images/quitar1.png";
import cargandoImg from "../../../images/cargando.gif";
import subir from "../../../images/subir.png";
import descargar from "../../../images/descargar.png";

const pc = window.screen.width > 529 ? true : false;

const VuelosContainer = styled.div`
    display:flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    & h2 {
        margin-right: auto;
    }
`;


const SpanValidacion1 = styled.span`
    text-align: right;
    font-size: ${pc ? "1vw" : "3.4vw"};
    background-color: ${props => props.error ? "#26AC20" : "#eb1a1a"};
    border-radius: ${pc ? "0.3vw" : "1vw"};
    margin-bottom: ${pc && "1vw"};
    padding: ${pc ? "0.6vw" : "2.3vw"};
    color:#fff;
    display: ${props => props.activo ? "block" : "none"};
`;

const Msg = styled.div`
  position: fixed;
  display: ${props => props.activo ? "flex" : "none"};
  width: 100%;
  align-items: center;
  justify-content: center;
  bottom: 0;
  left: 0;

  & div {
    padding: ${pc ? "1vw 2vw" : "2vw 3vw"};
    margin-bottom: 3px;
    text-align: center;
    border-top-right-radius: 0.4vw;
    border-top-left-radius: 0.4vw;
    background-color: ${props => props.error ? "#26AC20" : "#eb1a1a"};
    color: #fff;
  }
`;

const BtnDescargar = styled.div`
    padding: ${pc ? "0.5vw" : "1vw"};
    cursor: pointer;
    border-radius: ${pc ? "0.3vw" : "1vw"};
    background-color: #E7971D;
    text-decoration: none;

    & img {
        width: ${pc ? "1.8vw" : "4vw"};
    }
    & p{
        color:#fff
    }
`;

const BtnSubir = styled.div`
    padding: ${pc ? "0.5vw" : "1vw"};
    cursor: pointer;
    border-radius: ${pc ? "0.3vw" : "1vw"};
    background-color: #8C8C8C;
    
    & img {
        width: ${pc ? "1.8vw" : "4vw"};
    }
    & p{
        color:#fff
    }
`;

const BtnToPoliticas = styled.span`
    color: #fff;
    font-weight: 500;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
`;
const CheckboxCont = styled.div`
    display: flex;
    align-items: center;
    width: ${pc ? "80%" : "100%"};
    margin-bottom: ${ e => e.vueloIda ? "0.5vw" : "2vw"};
    
    & p {
        font-size: ${pc ? "1.1vw" : "3vw"};
        margin-left: ${!pc && "2vw"};
        font-weight: 200;
    }

    & input {
        width: ${pc ? "10%" : "15%"};
        height: ${!pc && "4.5vw"};
        margin: 0!important;
        padding: 0!important;
        margin-right: ${ e => e.vueloIda ? "0.5vw" : "1vw!important"};
    }
`;

export default function Vuelos (){

    const location = useLocation();
    const navigate = useNavigate();

    const { session } = useContext(sessionContext);

    const edades = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1];

    let [solicitudes, setSolicitudes] = useState(undefined);
    let [ultimoElemento, setUltimoElemento] = useState(undefined);
    let [id, setId] = useState("");

    let [inputs, setInputs] = useState({nombreTitular:["", ""], fechaIda:["", ""], fechaRegreso:["", ""], aeropuertoSalida:["", ""], aeropuertoDestino:["", ""], horarioPreferible:["", ""], adultos:["", ""], niños:["", ""], edades:[], observaciones:["", ""]});
    let [msgVal, setMsgVal] = useState("");
    let [msgVal1, setMsgVal1] = useState("");
    let [msgVal2, setMsgVal2] = useState("");

    let [modal, setModal] = useState(false);
    
    let [cargando, setCargando] = useState(false);
    let [cargando1, setCargando1] = useState(false);
    let [cargandoSubirArchivo, setCargandoSubirArchivo] = useState(false);

    let [errorUploadFile, setErrorUploadFile] = useState("");
    
    let [checkbox, setCheckbox] = useState(false);
    let [checkboxMsg, setCheckboxMsg] = useState(false);

    let [soloVueloIda, setSoloVueloIda] = useState(false);

    function updateValueInput(tipo, valor, index){
        const newInputs = JSON.parse(JSON.stringify(inputs));
        if (tipo != "edades") {
            if (tipo == "fechaIda" || tipo == "fechaRegreso") {
                newInputs[tipo][0] = cambiarFecha(valor);
            } else {
                newInputs[tipo][0] = valor;
            }
        } else {
            newInputs.edades[index][0] = valor;
        }
        if (tipo == "niños") {
            newInputs.niños[1] = "";
            if (isNum(valor)) {
                if (valor <= 20) {
                    newInputs.edades = [];
                    for(let i = 0; i < newInputs.niños[0]; i++){
                        if (inputs.edades[i]) {
                            if (inputs.edades[i][0]) {
                                newInputs.edades.push(inputs.edades[i]);
                            } else {
                                newInputs.edades.push(["", ""]);
                            }
                        } else {
                            newInputs.edades.push(["", ""]);
                        }
                    }
                } else {
                    newInputs.niños[1] = "Maximo 20 niños.";
                }
            } else {
                newInputs.niños[1] = "Solo se admiten numeros.";
            }
        }
        
        setInputs(newInputs);
    }

    function validarForm (){
        const newInputs = JSON.parse(JSON.stringify(inputs));
        const inputsName = Object.keys(inputs);
        let result = true;

        inputsName.map((value)=>{
            const input = newInputs[value][0];

            if (value == "fechaRegreso") {
                if (soloVueloIda) {
                    newInputs[value][0] = "";
                }
            }
            if (value != "edades") {
                newInputs[value][1] = "";
                if (input.length > 0) { 

                    if (input.length < 800) {
                        if (value == "fechaIda" || value == "fechaRegreso") {
                            const valFecha = validarFecha(input);
                            if (valFecha !== true) {
                                newInputs[value][1] = valFecha;
                                result = false;
                            }
                            if (!soloVueloIda) {
                                if (restaFechas(newInputs.fechaIda[0], newInputs.fechaRegreso[0]) == 0) {
                                    result = false;
                                    newInputs[value][1] = "Reserva minimo un dia.";
                                }
                                if (value == "fechaRegreso") {
                                    if (restaFechas(newInputs.fechaIda[0], newInputs.fechaRegreso[0]) < 0) {
                                        result = false;
                                        newInputs[value][1] = "La fecha de regreso no puede ser antes que la de ida.";
                                    }
                                }
                            }
                        }
    
                        if (value == "nombreTitular" || value == "aeropuertoSalida" || value == "aeropuertoDestino") {
                            if (input.length < 4) {
                                newInputs[value][1] = "Minimo 4 caracteres.";
                                result = false;
                            }
                        }
    
                        if (value == "niños") {
                            if (!isNum(input)) {
                                newInputs[value][1] = "Solo se admiten numeros. (sin puntos, comas, ni simbolos)";
                                result = false;
                            }
                        }

                        if (value == "adultos") {
                            if (isNum(input)) {
                                if (input < 1) {
                                    newInputs.adultos[1] = "Minimo un adulto.";
                                    result = false;
                                }
                            } else {
                                newInputs.adultos[1] = "Solo se admiten numeros. (sin puntos, comas, ni simbolos)";
                                result = false;
                            }
                        }
    
                        if (value == "observaciones") {
                            if (input.length > 700) {
                                newInputs[value][1] = "Maximo 700 caracteres.";
                                result = false;
                            }
                        }
                    } else {
                        newInputs[value][1] = "Maximo 799 caracteres.";
                        result = false;
                    }
    
                } else {
                    if (value != "observaciones" && value != "fechaRegreso") {
                        newInputs[value][1] = "Rellena este campo.";
                        result = false;
                    } else if (value == "fechaRegreso"){
                        if (!soloVueloIda) {
                            newInputs[value][1] = "Rellena este campo.";
                            result = false;
                        }
                    }
                }
            } else {
                newInputs.edades.map((edad, indexEdad)=>{
                    newInputs.edades[indexEdad][1] = "";
                    if (edad[0].length > 0) {
                        if (isNum(edad[0])){
                            if (edad[0] > 17) {
                                newInputs.edades[indexEdad][1] = "Solo se admiten hasta los 17 años.";
                                result = false;
                            }
                        } else {
                            newInputs.edades[indexEdad][1] = "Solo se admiten numeros, sin puntos, comas ni simbolos.";
                            result = false;
                        }
                    } else {
                        newInputs.edades[indexEdad][1] = "Rellena este campo.";
                        result = false;
                    }
                });
            }

        });

        setCheckboxMsg("");
        if (!checkbox) {
            setCheckboxMsg("Confirma que estas de acuerdo.");
            result = false;
        }

        setInputs(newInputs);

        if (result) {
            enviarDatos(newInputs);
        }
    }

    async function enviarDatos (datos){
        setCargando(true);
        const enviarReserva = await solicitarCotizacion(datos, "vuelos");
        if (enviarReserva === true) {
            setMsgVal("Cotización enviada correctamente.");
            setTimeout(()=>{
                navigate("/");
            },4070);
            setTimeout(()=>{
                navigate("/plataforma/vuelos");
            },4080);
        } else if (enviarReserva === false) {
            setMsgVal("Sucedio un error, disculpanos");
        } else {
            setMsgVal(enviarReserva);
        }
        setCargando(false);
    }

    async function getData (update){
        if (solicitudes === undefined || update) {
            const result = await getSolicitudesCliente({tipo:"vuelos"});
            if (result[0]) {
                setSolicitudes(result[1].datos);
                setUltimoElemento(result[1].ultimoElemento);
            } else {
                setMsgVal1(result[1]);
                setSolicitudes(result[0]);
            }
        } else {
            if (ultimoElemento !== false) {
                setCargando1(true);
                const result = await getSolicitudesCliente({tipo:"vuelos", ultimoElemento:ultimoElemento});
                setUltimoElemento(result[1].ultimoElemento);
                if (result[0]) {
                    const newSeguros1 = solicitudes;
                    result[1].datos.map((value, index)=>{
                        let si = true;
                        newSeguros1.map((old)=>{
                            if (value.id == old.id) {
                                si = false;
                            }
                        })
                        if (si) {
                            newSeguros1.push(value);
                        }
                    });
                    setSolicitudes(newSeguros1);
                } else {
                    setSolicitudes(false);
                    setMsgVal1(result[1]);
                }
            }
        }
        setCargando1(false);
    }

    async function confirmarSoli (){
        setCargando(true);
        const result = await confirmarSolicitud({id:modal.id, tipo:"vuelos"});
        if (result === true) {
            const newModal = modal;
            newModal.confirmado = true;
            setModal(newModal);
            const newSolicitudes = solicitudes;
            newSolicitudes.map((value, index)=>{
                if (value.id == modal.id) {
                    newSolicitudes[index].confirmado = true;
                }
            });
            setSolicitudes(newSolicitudes);
        } else {
            if (result == "error-04") {
                setMsgVal2("La solicitud fue eliminada debido a que ya pasaron 72 horas sin confirmarla.");
                setTimeout(()=>{
                    setMsgVal2("");
                }, 3000);
                const newSolicitudes = solicitudes;
                newSolicitudes.map((value, index)=>{
                    if (value.id == modal.id) {
                        newSolicitudes.splice(index, 1);
                    }
                });
                setSolicitudes(newSolicitudes);
                setModal("");
            } else {
                setMsgVal2("Comprueba tu conexión a internet");
                setTimeout(()=>{
                    setMsgVal2("");
                }, 3000);
            }
        }
        setCargando(false);
    }

    function comprobarLink (link){
        const httpIsTrue = link.charAt(0) + link.charAt(1) + link.charAt(2) + link.charAt(3);
        if (httpIsTrue == "http") {
            return true;
        } else {
            return false;
        }
    }

    async function enviarVoucher (archivo){
        setCargandoSubirArchivo(true);
        const result = await subirVoucher(archivo, solicitudes[id].id, "vuelos");
        if (result[0]) {
            const newModal = modal;
            newModal.transferenciaVoucher = result[1];
            getData(true);
            setModal(newModal);
            setId("");
        } else {
            setErrorUploadFile(result[1]);
            setTimeout(()=>{
                setErrorUploadFile("");
            },3500);
        }
        setCargandoSubirArchivo(false);
    }

    useEffect(()=>{
        if (session.user) {
            getData();
        }
    },[session.user]);

    return (
        <ContenedorOpcion>
            { !location.search ?
                <VuelosContainer>
                    <h2 style={{margin:0}}>Vuelos</h2>
                    <Formulario>
                        <FormularioCont>
                            <TopFormulario>
                                <h2>COTIZACIÓN DE VUELO</h2>
                            </TopFormulario>
                            <Columna>
                                <label>Nombre del titular</label>
                                <SpanValidacion>{inputs.nombreTitular[1]}</SpanValidacion>
                                <Input error={inputs.nombreTitular[1]} onChange={(valor)=>updateValueInput("nombreTitular", valor.target.value)}/>
                            </Columna>
                            <Columna>
                                <label>Fecha de ida</label>
                                <SpanValidacion>{inputs.fechaIda[1]}</SpanValidacion>
                                <Input type="date" error={inputs.fechaIda[1]} onChange={(valor)=>updateValueInput("fechaIda", valor.target.value)} placeholder="dia/mes/año"/>
                                <CheckboxCont vueloIda={true} style={{display:"flex", alignItems:"center"}}>
                                    <Input style={{cursor:"pointer", width:pc && "8%"}} type="checkbox" onChange={(e)=>setSoloVueloIda(e.target.checked)} />
                                    <p>Solo vuelo de Ida</p>
                                </CheckboxCont>
                            </Columna>
                            {   !soloVueloIda &&
                                <Columna>
                                     <label>Fecha de regreso</label>
                                    <SpanValidacion>{inputs.fechaRegreso[1]}</SpanValidacion>
                                    <Input type="date" error={inputs.fechaRegreso[1]} onChange={(valor)=>updateValueInput("fechaRegreso", valor.target.value)} placeholder="dia/mes/año"/>
                                </Columna>
                            }
                            <Columna>
                                <label>Aeropuerto de salida</label>
                                <SpanValidacion>{inputs.aeropuertoSalida[1]}</SpanValidacion>
                                <Input error={inputs.aeropuertoSalida[1]} onChange={(valor)=>updateValueInput("aeropuertoSalida", valor.target.value)}/>
                                <label>Horario preferible</label>
                                <SpanValidacion>{inputs.horarioPreferible[1]}</SpanValidacion>
                                <Input error={inputs.horarioPreferible[1]} onChange={(valor)=>updateValueInput("horarioPreferible", valor.target.value)}/>
                            </Columna>
                            <Columna>
                                <label>Aeropuerto de destino</label>
                                <SpanValidacion>{inputs.aeropuertoDestino[1]}</SpanValidacion>
                                <Input error={inputs.aeropuertoDestino[1]} onChange={(valor)=>updateValueInput("aeropuertoDestino", valor.target.value)}/>
                                <label>Cantidad de adultos</label>
                                <SpanValidacion>{inputs.adultos[1]}</SpanValidacion>
                                <Select  defaultValue="DEFAULT" error={inputs.adultos[1]} onChange={(valor)=>updateValueInput("adultos", valor.target.value)}>
                                    <option disabled value="DEFAULT">Cantidad de adultos</option>
                                    { edades.map((val, index)=>(
                                            <option key={index}>{index+1}</option>
                                        ))
                                    }
                                </Select>
                            </Columna>
                            <Columna>
                                <label>Cantidad de niños</label>
                                <SpanValidacion>{inputs.niños[1]}</SpanValidacion>
                                <Select defaultValue="DEFAULT" error={inputs.niños[1]} onChange={(valor)=>updateValueInput("niños", valor.target.value)}>
                                    <option disabled value="DEFAULT">Cantidad de niños</option>
                                    { edades.map((val, index)=>(
                                            <option key={index}>{index}</option>
                                        ))
                                    }
                                </Select>
                                {   inputs.edades.map((val, indexEdad)=>(
                                    <div key={indexEdad}>
                                        <label>Edad de niño {indexEdad+1} </label>
                                        <SpanValidacion>{inputs.edades[indexEdad][1]}</SpanValidacion>
                                        <Input style={{width:"100%"}} error={inputs.edades[indexEdad][1]} onChange={(valor)=>updateValueInput("edades", valor.target.value, indexEdad)}/>
                                    </div>
                                    ))
                                }
                            </Columna>
                            <Columna>
                                <label>Observaciones</label>
                                <SpanValidacion>{inputs.observaciones[1]}</SpanValidacion>
                                <textarea onChange={(valor)=>updateValueInput("observaciones", valor.target.value)}></textarea>
                                <SpanValidacion>{checkboxMsg}</SpanValidacion>
                                <CheckboxCont style={{display:"flex", alignItems:"center"}}>
                                    <Input style={{cursor:"pointer", width:pc && "8%"}} error={checkboxMsg} type="checkbox" onChange={(e)=>setCheckbox(e.target.checked)} />
                                    <p>He leido y acepto los <BtnToPoliticas onClick={()=>navigate("/terminos-y-condiciones")}>terminos y condiciones.</BtnToPoliticas></p>
                                </CheckboxCont>
                            </Columna>
                        </FormularioCont>
                    </Formulario>
                    <SpanValidacion1 activo={msgVal} error={msgVal == "Cotización enviada correctamente." ? true : false} style={{textAlign:"center"}}>{msgVal}</SpanValidacion1>
                    <BtnEnviar onClick={()=>validarForm()}>
                        { cargando ?
                            <CargandoCont><img src={cargandoImg}/></CargandoCont>
                            :
                            <p>Solicitar</p>
                        }
                    </BtnEnviar>
                </VuelosContainer>
                : location.search == "?tab=mis-solicitudes" && 
                <VuelosContainer>
                    <h2>Mis solicitudes</h2>
                    {   solicitudes !== undefined ?
                                    solicitudes !== false ?
                                        solicitudes.length > 0 ? 
                                            <CuadroContenedor>
                                                <CuadroCont>
                                                    <TopCuadroContenedor>
                                                        <Cuadro>
                                                            <p>Cliente</p>
                                                        </Cuadro>
                                                        <Cuadro>
                                                            <p>Detalles</p>
                                                        </Cuadro>
                                                        <Cuadro>
                                                            <p>Estado</p>
                                                        </Cuadro>
                                                    </TopCuadroContenedor>
                                                    { solicitudes.map((value, index)=>(
                                                        <BottomCuadroContenedor key={value.id}>
                                                            <Cuadro><p>{value.nombreTitular}</p></Cuadro>
                                                            <Cuadro style={{cursor:"pointer"}} onClick={()=>{setModal(value); setId(index)}}><p style={{textDecoration:"underline"}}>Ver</p></Cuadro>
                                                            <Cuadro><p>{value.estado}</p></Cuadro>
                                                        </BottomCuadroContenedor>
                                                    ))
                                                    }                                                    
                                                </CuadroCont>
                                            </CuadroContenedor>
                                        : <p>No tienes solicitudes de vuelos</p> 
                                    : <p>{msgVal1}</p>
                                : <CargandoCont><img src={cargandoImg}/></CargandoCont> 
                            }
                            { ultimoElemento !== undefined && ultimoElemento !== false ? 
                                cargando1 ? 
                                    <CargandoCont><img src={cargandoImg}/></CargandoCont> 
                                    : 
                                    <p onClick={()=>getData()} style={{cursor:"pointer", padding:"0.5vw", textDecoration:"underline"}}>Ver mas</p> 
                                : <p></p>
                            }
                    <Msg activo={msgVal2} error={false}><div><p>{msgVal2}</p></div></Msg>
                </VuelosContainer>
            }
            <Modal visible={modal} setVisible={()=>!cargandoSubirArchivo && setModal(false)}>
               { modal &&
                <DetallesReserva>
                    <BtnCerrar onClick={()=>!cargandoSubirArchivo && setModal(false)}><img src={quitar}/></BtnCerrar>
                    <h2>Detalles de la cotizacion</h2>
                    <div><h3>Estado:</h3><span style={{color: modal.estado == "Cotizado" && "#26AC20"}}>{modal.estado}</span></div>
                    <div>
                        <h3>Info cotización:</h3>
                        <span>
                            { modal.infoCotizacion ? 
                                comprobarLink(modal.infoCotizacion) 
                                    ? 
                                    <BtnDescargar onClick={()=>descargarArchivo(modal.infoCotizacion)}>
                                        <p>Descargar</p>
                                        <img src={descargar} />
                                    </BtnDescargar>   
                                    : 
                                    modal.infoCotizacion
                                :
                                "No hay info"
                            }
                        </span>
                    </div>
                    <div>
                        <h3>Transferencia Voucher:</h3>
                        <span>
                            { modal.transferenciaVoucher == "none" 
                                ? <BtnSubir onClick={()=>document.querySelector("#btnArchivoVuelos").click()}>
                                        { !cargandoSubirArchivo
                                            ? <div style={{display:"flex", alignItems:"center", justifyContent:"center", border:"none", margin:0}}>
                                                <p>Subir Voucher</p>
                                                 <img src={subir}/>
                                            </div>
                                            : <p>Subiendo..</p>
                                        }
                                    </BtnSubir> 
                                :   <BtnDescargar onClick={()=>descargarArchivo(modal.transferenciaVoucher)}>
                                        <p>Voucher</p>
                                        <img src={descargar} />
                                    </BtnDescargar> 
                            }
                        </span>
                    </div>
                    { modal.estado == "Cotizado" && <div><h3>Confirmado:</h3><span>{modal.confirmado ? "Si" : cargando ? <CargandoCont><img src={cargandoImg}/></CargandoCont>  : <BtnConfirmar onClick={()=>confirmarSoli()}>Confirmar</BtnConfirmar>}</span></div>}
                    <div><h3>Monto total:</h3><span>{modal.totalUs ? "USD $ "+modal.totalUs : "Todavia no esta disponible"}</span></div>
                    <div><h3>Comision:</h3><span>{modal.comisionUs ? "USD $ "+modal.comisionUs : "Todavia no esta disponible"}</span></div>
                    <div><h3>Estado de pago de comisión:</h3><span style={{color: modal.estadoComision == true && "#26AC20"}}>{modal.estadoComision ? "Pagado" : "Pendiente"}</span></div>
                    <div><h3>Titular:</h3><span>{modal.nombreTitular}</span></div>
                    <div><h3>Fecha de ida:</h3><span>{modal.fechaIda}</span></div>
                    <div><h3>Fecha de regreso:</h3><span>{modal.fechaRegreso || "Sin fecha de regreso"}</span></div>
                    <div><h3>Aeropuerto de salida:</h3><span>{modal.aeropuertoSalida}</span></div>
                    <div><h3>Aeropuerto de destino:</h3><span>{modal.aeropuertoDestino}</span></div>
                    <div><h3>Horario preferible:</h3><span>{modal.horarioPreferible}</span></div>
                    <div><h3>Adultos:</h3><span>{modal.adultos}</span></div>
                    <div><h3>Niños:</h3><span>{modal.niños}</span></div>
                    { modal && modal.edades.map((value, index)=>(
                        <div key={index+1}><h3>Edad de niño {index+1}:</h3><span>{value}</span></div>
                    ))}
                    <div><h3>Observaciones:</h3><span>{modal.observaciones}</span></div>
                    
                </DetallesReserva>
               }
            </Modal>
            <Msg activo={errorUploadFile} error={false}><div><p>{errorUploadFile}</p></div></Msg>
            <input style={{display:"none"}} type="file" accept=".jpg, .png, .jpeg, .pdf" onChange={(e)=>enviarVoucher(e.target.files[0])} id="btnArchivoVuelos"/>
        </ContenedorOpcion>    
    );
}