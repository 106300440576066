import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { validarFecha, cambiarFecha,  restaFechas, isNum, calcularTotal } from "../../utils/validar";
import {CuadroContenedor, TopCuadroContenedor, CuadroCont, Cuadro, BottomCuadroContenedor} from "../../utils/recuadro";
import CotizacionPDF from "./cotizacionPdf";
import { PDFDownloadLink } from "@react-pdf/renderer";
import sessionContext from "../../../contexts/sessionContext";

// imagenes
import btnDescargar from "../../../images/descargar.png";
import quitar from "../../../images/quitar2.png";
import mas from "../../../images/mas.png"
import cargandoImg from "../../../images/cargando.gif";

const pc = window.screen.width > 529 ? true : false;

const TarifasContenedor = styled.div`
    display: flex;
    flex-direction: column;

    & h2 {
        font-weight: 600;
    }
`;

const BtnAddHabitacion = styled.button`
    border:none;
    background-color: #43A917;
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${pc ? "10%" : "15%"};
    height: ${pc ? "3vw" : "6vw"};
    border-radius: 1vw;
    margin: ${pc ? "1vw" : "2.5vw"} auto;
    cursor:pointer;
    

    & img {
        width: ${pc ? "2vw" : "6vw"};
        
    }
`;

const BtnQuitar = styled.button`
    background-color: transparent;
    border: none;
    display: flex;
    justify-content: center;
    margin-left: 1vw;
    width: ${pc ? "4vw" : "9vw"};
    cursor:pointer;

    & img {
        width: 100%;
    }
`;

const MsgValidacion = styled.div`
    position: absolute;
    bottom: ${pc ? "3.2vw" : "7.3vw"};
    padding: ${pc ? "0.3vw" : "1vw"};
    width: 105%;
    background-color: #eb1a1a;
    display:${props => props.display ? "block" : "none"};

    & p {
        font-size: ${pc ? "0.8vw" : "2.5vw"};
        line-height: ${pc ? "1vw" : "2.4vw"};
        color:#fff;
    }
`;

const BtnCotizar = styled.div`
    border:none; 
    margin-left:${pc ? "1vw" : "3vw"};
    border-radius:${pc ?"0.3vw":"0.8vw"};
    cursor:pointer;
    display: flex;
    color:#fff;
    padding:${pc ?"0.5vw":"1vw"};
    background-color:#FECC3D;
    transition: background-color 0.2s;
    justify-content: center;
    text-decoration: none; 
    opacity: ${p => p.opacity ? "0.5" : 1};

    &:hover {
        background-color: ${p => !p.opacity && "#FEBC00"};
    }
`;

export default function TarifasYOfertas ({tarifas, hotel}){

    const {session} = useContext(sessionContext);

    let codigosOfertas = [];

    let [cargando, setCargando] = useState(false);
    
    let [total, setTotal] = useState("");
    let [cotizacionForm, setCotizacionForm] = useState([1]);

    let [defaultInput, setDefaultInput] = useState({codigoTarifa:"", cliente:["", ""], habitacion:"", fechaEntrada:["", ""], fechaSalida:["", ""], adultos:["", ""], niños:["", ""]});
    let [inputsCotizacion, setInputsCotizacion] = useState([defaultInput]);

    let [infoPrecios, setInfoPrecios] = useState("");
    let [pdf, setPdf] = useState(false);

    const tarifasYOfertas = tarifas;
    
    if (tarifasYOfertas) {
        tarifasYOfertas.forEach((value, index)=>{
            if (validarFecha(value.vencimientoOferta) === true) {
                codigosOfertas.push(value.codigoOferta);
            }
        });
    }

    function addCotizacionForm(){
        setCotizacionForm([...cotizacionForm, cotizacionForm.length+1])
        setInputsCotizacion([...inputsCotizacion, defaultInput]);
    }

    function delCotizacionForm (index){
        let newCotizacionForm = JSON.parse(JSON.stringify(cotizacionForm));
        let newInputs = JSON.parse(JSON.stringify(inputsCotizacion));
        newInputs.splice(index, 1);
        newCotizacionForm.splice(index, 1);
        setCotizacionForm(newCotizacionForm);
        setInputsCotizacion(newInputs);
    }

    function updateValueInputCotizacion (index, input, value) {
        let newValueInput = JSON.parse(JSON.stringify(inputsCotizacion));
        if (input == "codigoTarifa") {
            tarifasYOfertas.forEach((valueTarifas)=>{
                if (value == valueTarifas.codigoOferta) {
                    newValueInput[index]["habitacion"] = valueTarifas.habitacion;
                }
            });
            newValueInput[index]["codigoTarifa"] = value;
        } else {
            newValueInput[index][input][0] = value;
            
            newValueInput[index].fechaEntrada[1] = "";
            newValueInput[index].fechaSalida[1] = "";
            newValueInput[index].adultos[1] = "";
            newValueInput[index].niños[1] = "";
            newValueInput[index].cliente[1] = "";
            
            if (input == "fechaEntrada") {
                newValueInput[index].fechaEntrada[0] = cambiarFecha(newValueInput[index].fechaEntrada[0]);
            } else if (input == "fechaSalida"){
                newValueInput[index].fechaSalida[0] = cambiarFecha(newValueInput[index].fechaSalida[0]);
            }

            if (newValueInput[index].cliente[0].length > 0) {
                if (newValueInput[index].cliente[0].length == 1) {
                    newValueInput[index].cliente[1] = "Minimo 2 caracteres.";
                }
            }

            if (newValueInput[index].fechaEntrada[0].length > 0) {
                if (validarFecha(newValueInput[index].fechaEntrada[0]) !== true) {
                    newValueInput[index].fechaEntrada[1] = validarFecha(newValueInput[index].fechaEntrada[0]);
                }
                if (restaFechas(newValueInput[index].fechaEntrada[0], newValueInput[index].fechaSalida[0]) == 0) {
                    newValueInput[index].fechaEntrada[1] = "Minimo un dia.";
                    newValueInput[index].fechaSalida[1] = "Minimo un dia.";
                }
            }

            if (newValueInput[index].adultos[0].length > 0) {
                if (newValueInput[index].adultos[0] == 0) {
                    newValueInput[index].adultos[1] = "Minimo un adulto.";
                }
            }
            
            if (newValueInput[index].fechaSalida[0].length > 0) {
                if (validarFecha(newValueInput[index].fechaSalida[0]) !== true) {
                    newValueInput[index].fechaSalida[1] = validarFecha(newValueInput[index].fechaSalida[0]);
                }
                if (restaFechas(newValueInput[index].fechaEntrada[0], newValueInput[index].fechaSalida[0]) < 0) {
                    newValueInput[index].fechaSalida[1] = "La fecha de salida tiene que ser despues que la fecha de entrada.";
                }
            }

            if (input != "fechaEntrada" && input != "fechaSalida" && input != "cliente") {
                if (newValueInput[index][input][0].length > 0) {
                    if (!isNum(newValueInput[index][input][0])) {
                        newValueInput[index][input][1] = "Solo se admiten numeros.";
                    }
                }
            }
        }
        
        
       /*  tarifas.map((value, index1)=>{
            if (newValueInput[index].codigoTarifa == value.codigoOferta) {
                if (restaFechas(newValueInput[index].fechaEntrada[0], newValueInput[index].fechaSalida[0]) < tarifas[index1].minimoNoches) {
                    if (!newValueInput[index].fechaSalida[1]) {
                        newValueInput[index].fechaSalida[1] = "La tarifa que escogiste son minimo "+tarifas[index1].minimoNoches+" noches";
                    }
                } else {
                    newValueInput[index].fechaSalida[1] = "";
                }
            }
        }); */

        setInputsCotizacion(newValueInput);
    }

    function validarForm (){
        let newValueInput = inputsCotizacion;
        let result = true;
        inputsCotizacion.map((value, index)=>{
            Object.keys(defaultInput).map((input)=>{
                if (input != "habitacion" && input != "codigoTarifa" && input != "niños" && input != "cliente") {
                    if (newValueInput[index][input][0].length > 0) {
                        if (input == "fechaEntrada" || input == "fechaSalida") {
                            if (validarFecha(newValueInput[index][input][0]) !== true) {
                                result = false;
                            }
                            if (newValueInput[index].fechaEntrada[0].length > 0 && newValueInput[index].fechaSalida[0].length > 0) {
                                if (restaFechas(newValueInput[index].fechaEntrada[0], newValueInput[index].fechaSalida[0]) < 1) {
                                    result = false;
                                }
                            }
                        }
                        if (input == "adultos") {
                            if (!isNum(newValueInput[index][input][0])) {
                            result = false; 
                            }
                        }
                    } else {
                        result = false;
                    }
                } else {
                    if (input == "cliente") {
                        if (index == 0) {
                            if (newValueInput[index][input][0].length < 2) {
                                result = false;
                            }
                        }
                    }                        
                }
            });
        });

        return result;
    }

    useEffect(()=>{
        const calculoTotal = calcularTotal(inputsCotizacion, tarifasYOfertas, true);
        setTotal(calculoTotal[0]);
        setInfoPrecios(calculoTotal[1]);
    },[inputsCotizacion]);

    useEffect(()=>{
        if (tarifasYOfertas.length > 0) {
            const newInput = inputsCotizacion;
            newInput[0].codigoTarifa = codigosOfertas[0];
            newInput[0].habitacion = tarifasYOfertas[0].habitacion;
            setInputsCotizacion(newInput);
            const newDefault = defaultInput;
            newDefault.codigoTarifa = codigosOfertas[0];
            setDefaultInput(newDefault);
        }
    },[]);

    return (
        <div style={{marginLeft:pc && "17vw"}}>
        {(tarifasYOfertas) && tarifasYOfertas.length > 0 ? <TarifasContenedor>
           <h2>Tarifas & Ofertas</h2>
           <CuadroContenedor style={{marginBottom:"2vw", overflowX:"scroll", maxWidth:pc ? "78vw" : ""}} tarifas={true}>
                <CuadroCont tarifas={true}>
                    <TopCuadroContenedor tarifas={true}>
                        <Cuadro tarifas={true}>
                            <p>Codigo de Oferta</p>
                        </Cuadro>
                        <Cuadro tarifas={true}>
                            <p>Habitación</p>
                        </Cuadro>
                        <Cuadro tarifas={true}>
                            <p>Niño gratis</p>
                        </Cuadro>
                        <Cuadro tarifas={true}>
                            <p>Minimo de noches</p>
                        </Cuadro>
                        <Cuadro style={{width:"40vw"}} tarifas={true}>
                            <p>Periodo</p>
                        </Cuadro>
                        <Cuadro tarifas={true}>
                            <p>Vencimiento de oferta</p>
                        </Cuadro>
                        <Cuadro tarifas={true}>
                            <p>Dias</p>
                        </Cuadro>
                        <Cuadro tarifas={true}>
                            <p>SGL</p>
                        </Cuadro>
                        <Cuadro tarifas={true} >
                            <p>DBL</p>
                        </Cuadro>
                        <Cuadro tarifas={true}>
                            <p>TPL</p>
                        </Cuadro>
                        <Cuadro tarifas={true}>
                            <p>Niños</p>
                        </Cuadro>
                       
                    </TopCuadroContenedor>
                    
                       { tarifasYOfertas && tarifasYOfertas.map((value, index)=>(
                            <BottomCuadroContenedor tarifas={true} bloqueado={validarFecha(value.vencimientoOferta) !== true && true} key={value.SGL+value.TPL+value.habitacion}>
                                <Cuadro tarifas={true}><p>{value.codigoOferta}</p></Cuadro>
                                <Cuadro tarifas={true}><p>{value.habitacion}</p></Cuadro>
                                <Cuadro tarifas={true}><p>{value.niñoGratis == 0 ? "Ninguno" : value.niñoGratis == 1 ? "Primer niño gratis" : "Segundo niño gratis"}</p></Cuadro>
                                <Cuadro tarifas={true}><p>{value.minimoNoches}</p></Cuadro>
                                <Cuadro style={{width:"40vw"}} tarifas={true}><p>{value.desde + " - " + value.hasta}</p></Cuadro>
                                <Cuadro tarifas={true} bloqueado={validarFecha(value.vencimientoOferta) !== true && true}><p>{value.vencimientoOferta}</p></Cuadro>
                                <div style={{display:"flex", flexDirection:"column"}}>
                                    { value.ofertas.map((subOferta, indexSubOferta)=>(
                                        <div style={{display:"flex", borderTop:"none", height: value.ofertas.length == 1 && "100%", borderBottom:( value.ofertas.length == 1 || value.ofertas.length-1 != indexSubOferta) && "1px solid #fff"}}>
                                            <Cuadro tarifas={true}><p>{subOferta.dias.map((dia, indexDia)=>(dia+(subOferta.dias.length-2 == indexDia ? " y " : subOferta.dias.length > 0 && indexDia != subOferta.dias.length-1 ? ", " : "")))}</p></Cuadro>
                                            <Cuadro tarifas={true}><p>${subOferta.datos.SGL}</p></Cuadro>
                                            <Cuadro tarifas={true}><p>${subOferta.datos.DBL}</p></Cuadro>
                                            <Cuadro tarifas={true}><p>${subOferta.datos.TPL}</p></Cuadro>
                                            <Cuadro tarifas={true}><p>${subOferta.datos.niños}</p></Cuadro>
                                        </div>
                                    ))}
                                </div>
                            </BottomCuadroContenedor>
                       ))

                       }
                    
                </CuadroCont>
            </CuadroContenedor>
            {pdf && <div style={{cursor:"wait", position:"absolute", zIndex:5000, left:0, top:0, width:"100vw", height:"100vh"}} />}
            {pdf && <PDFDownloadLink fileName="Hotel-Cotizacion.pdf" style={{display:"none"}} className="btnPDF" document={<CotizacionPDF info={{form:inputsCotizacion, infoPrecios:infoPrecios, hotel:hotel, total:total, fecha:new Date().toLocaleDateString()}}/>} />}
            <div style={{display:"flex", alignItems:"center"}}>
                <h2>Cotizar</h2>
                { validarForm() ?
                    <BtnCotizar onClick={()=>{if(!cargando){setCargando(true); setPdf(true); setTimeout(()=>{document.querySelector(".btnPDF").click(); setPdf(false); setCargando(false)}, 1500); }}}>
                        {   cargando ?
                            <img style={{width:"2vw", margin:"0 2vw"}} src={cargandoImg} />
                            :
                            <div style={{display:"flex"}}>
                                <p style={{fontSize:pc ?"1vw":"3vw", textDecoration:"none"}}>Cotización PDF</p>
                                <img src={btnDescargar} style={{width:pc ? "1.4vw" : "4.5vw"}} />
                            </div>
                        }
                    </BtnCotizar>
                    : 
                    <BtnCotizar opacity={true}>
                        <p style={{fontSize:pc ?"1vw":"3vw"}}>Cotización PDF</p>
                        <img src={btnDescargar} style={{width:pc ? "1.4vw" : "4.5vw"}} />
                    </BtnCotizar>
                }
              
            </div>
            <CuadroContenedor tarifas={true} style={{width:pc && "69.5vw", marginBottom:"6vw"}}>
                <CuadroCont tarifas={true}>
                    <TopCuadroContenedor tarifas={true}>
                            <Cuadro tarifas={true}>
                                <p>Codigo de Oferta</p>
                            </Cuadro>
                            <Cuadro tarifas={true}>
                                <p>Cliente</p>
                            </Cuadro>
                            <Cuadro tarifas={true}>
                                <p>Habitación</p>
                            </Cuadro>
                            <Cuadro tarifas={true}>
                                <p>Fecha de entrada</p>
                            </Cuadro>
                            <Cuadro tarifas={true}>
                                <p>Fecha de salida</p>
                            </Cuadro>
                            <Cuadro tarifas={true}>
                                <p>Adultos</p>
                            </Cuadro>
                            <Cuadro tarifas={true}>
                                <p>Niños</p>
                            </Cuadro>
                    </TopCuadroContenedor>
                    { cotizacionForm.map((value, index)=>(
                        <BottomCuadroContenedor tarifas={true} key={index}>
                            <Cuadro tarifas={true}>
                                <select onChange={(e)=>updateValueInputCotizacion(index, "codigoTarifa", e.target.value)}>
                                    { codigosOfertas.map((value, index)=>(
                                        <option key={value}>{value}</option>
                                    ))}
                                </select>
                            </Cuadro>
                            { index < 1 ?
                                <Cuadro tarifas={true} style={{position:"relative"}}>
                                    <MsgValidacion display={inputsCotizacion[index].cliente[1]}>
                                        <p>{inputsCotizacion[index].cliente[1]}</p>
                                    </MsgValidacion>
                                    <input style={{paddingLeft:"0.7vw", outline:inputsCotizacion[index].cliente[1] && "1px solid #eb1a1a"}} onChange={(e)=>updateValueInputCotizacion(index, "cliente", e.target.value)} placeholder="Nombre del cliente"/>
                                </Cuadro>
                                :
                                <Cuadro tarifas={true}><p>{inputsCotizacion[0].cliente[0]}</p></Cuadro>
                            }
                            <Cuadro tarifas={true}>
                                <p>{inputsCotizacion[index].habitacion}</p>
                            </Cuadro>
                            <Cuadro tarifas={true} style={{position:"relative"}}>
                                <MsgValidacion display={inputsCotizacion[index].fechaEntrada[1]}>
                                    <p>{inputsCotizacion[index].fechaEntrada[1]}</p>
                                </MsgValidacion>
                                <input type="date" style={{outline:inputsCotizacion[index].fechaEntrada[1] && "1px solid #eb1a1a"}} onChange={(e)=>updateValueInputCotizacion(index, "fechaEntrada", e.target.value)} placeholder="dia/mes/año"/>
                            </Cuadro>
                            <Cuadro tarifas={true} style={{position:"relative"}}>
                                <MsgValidacion display={inputsCotizacion[index].fechaSalida[1]}>
                                    <p>{inputsCotizacion[index].fechaSalida[1]}</p>
                                </MsgValidacion>
                                <input type="date" style={{outline:inputsCotizacion[index].fechaSalida[1] && "1px solid #eb1a1a"}} onChange={(e)=>updateValueInputCotizacion(index, "fechaSalida", e.target.value)} placeholder="dia/mes/año"/>
                            </Cuadro>
                            <Cuadro tarifas={true} style={{position:"relative"}}>
                                <MsgValidacion display={inputsCotizacion[index].adultos[1]}>
                                    <p>{inputsCotizacion[index].adultos[1]}</p>
                                </MsgValidacion>
                                <input style={{outline:inputsCotizacion[index].adultos[1] && "1px solid #eb1a1a"}} onChange={(e)=>updateValueInputCotizacion(index, "adultos", e.target.value)}/>
                            </Cuadro>
                            <Cuadro tarifas={true} style={{position:"relative"}}>
                                <MsgValidacion display={inputsCotizacion[index].niños[1]}>
                                    <p>{inputsCotizacion[index].niños[1]}</p>
                                </MsgValidacion>
                                <input style={{outline:inputsCotizacion[index].niños[1] && "1px solid #eb1a1a"}} onChange={(e)=>updateValueInputCotizacion(index, "niños", e.target.value)}/>
                                { index > 0 &&
                                    <BtnQuitar onClick={()=>delCotizacionForm(index)}>
                                        <img src={quitar}/>
                                    </BtnQuitar>
                                }
                            </Cuadro>
                        </BottomCuadroContenedor>
                    ))}
                    <BottomCuadroContenedor tarifas={true}>
                        <BtnAddHabitacion onClick={()=>addCotizacionForm()}>
                            <img src={mas}/>
                        </BtnAddHabitacion>
                        <BtnAddHabitacion onClick={()=>addCotizacionForm()}>
                            <img src={mas}/>
                        </BtnAddHabitacion>
                    </BottomCuadroContenedor>
                   <TopCuadroContenedor  tarifas={true}>
                        <Cuadro><p>Total</p></Cuadro>
                        <Cuadro><p>USD $ {total ? total : "0"}</p></Cuadro>
                   </TopCuadroContenedor>
                </CuadroCont>
            </CuadroContenedor>
       </TarifasContenedor>
       : <div><p>Este hotel no tiene tarifas y ofertas</p></div>
       }
       </div>
    )
}