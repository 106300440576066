import styled from "styled-components";

const pc = window.screen.width > 529 ? true : false;

export default styled.div`
    display: flex;
    border:none!important;
    width: ${pc ? "2.7vw" : "10vw"};
    margin: ${pc ? "" : "2vw 0"};

    & img {
        width: 100%;
    }
`;