import { useContext } from "react";
import {useState, useEffect} from "react";
import styled from "styled-components";
import sessionContext from "../../contexts/sessionContext";
import {CuadroContenedor, TopCuadroContenedor, CuadroCont, Cuadro, BottomCuadroContenedor} from "../utils/recuadro";
import Modal, {DetallesReserva, BtnCerrar} from "../utils/modal";
import CargandoCont from "../utils/cargando";
import { addUser, eliminarUserOsolicitud, getInfoUser, getUsers } from "../../config/auth";
import { verificarEmail } from "../login";

//imagenes 
import cargandoImg from "../../images/cargando.gif";
import quitar1 from "../../images/quitar1.png";
import quitar from "../../images/quitar.png";

const pc = window.screen.width > 529 ? true : false;

const BtnAdd = styled.button`
    display: flex;
    padding: ${pc ? "0.5vw" : "2vw"};
    border-radius: ${pc ? "0.4vw" : "0.5vw"};
    background-color: #26AC20;
    border:none;
    color:#fff;
    font-size: ${pc ? "1vw" : "3.2vw"};
    cursor: pointer;
    width: ${pc ? "10vw" : "35vw"};
    justify-content: center;
`;

const Btns = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: ${pc ? "1vw" : "6vw"};

    & span {
        width: ${pc ? "10vw" : "35vw"};
        justify-content: center;
        display: flex;
        padding: ${pc ? "0.5vw" : "2vw"};
        border-radius: ${pc ? "0.4vw" : "0.5vw"};
        background-color: #767676;
        border:none;
        color:#fff;
        cursor: pointer;
        font-size: ${pc ? "1vw" : "3.2vw"};
    }
`;

const ModalCont = styled.div`
    padding: ${pc ? "1vw" : ""};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & h3 {
        margin-bottom: ${pc ? "" : "3vw"};
    }

    & input {
        border:1px solid #AFAFAF;
        width: ${pc ? "20vw" : "60vw"};
        padding: ${pc ? "0.3vw" : "1vw"};
    }
    & input:focus {
        border:1px solid #004aad;
        outline: none;
    }

    & h3 {
        font-weight: 600;
    }

    & div {
        margin-bottom: 1vw;
    }
    & b {
        color:#eb1a1a;
        text-align: center;
        font-size: ${pc ? "" : "3.7vw"};
        margin: 0;
        width: ${!pc && "50vw"};
    }
`;

const ModalEliminar = styled.div`
    display: flex;
    padding: 1vw;
    align-items: center;
    flex-direction: column;

    & h3{
        font-weight: 600;
        text-align: center;
        font-size: ${pc ? "" : "4.5vw"};
        width: ${!pc && "60vw"};
    }

    & p {
        color:#585858;
        font-size: ${pc ? "" : "4vw"};
    }
`;
const Msg = styled.div`
  position: fixed;
  display: ${props => props.activo ? "flex" : "none"};
  width: 100%;
  align-items: center;
  justify-content: center;
  bottom: ${pc ? 0 : "1%"};
  left: 0;
  z-index: 5000;

  & div {
    padding: ${pc ? "1vw 2vw" : "2vw 3vw"};
    margin-bottom: 3px;
    text-align: center;
    border-top-right-radius: 0.4vw;
    border-top-left-radius: 0.4vw;
    background-color: ${props => props.error ? "#26AC20" : "#eb1a1a"};
    color: #fff;
    font-size: ${!pc && "4vw"};
  }
`;

const Button = styled.button`
    width: 45%;
    background-color: ${props => props.red ? "#eb1a1a" : props.yes ? "#26AC20" : "#767676"};
    color:#fff;
    padding: ${pc ? "0.5vw" : "1.2vw"};
    border: none;
    font-size: ${pc ? "" : "3.2vw"};
    border-radius: ${pc ? "0.5vw" : "1vw"};
    align-items: center;
    justify-content: center;
    cursor: pointer;
    display: flex;
    opacity: ${ p => p.opacity && "0.5"};

    &:hover {
        background-color: ${e => e.red ? "#D31A1A" : e.yes ? "#2CA026" : "#646363"};
    }
`;

const BtnQuitar = styled.div`
    width: ${pc ? "3vw" : "8vw"};
    position: absolute;
    right: ${pc ? "-2.5vw" : "-6.5vw"};
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;

    & img {
        background-color: #EB1A1A;
        width: 58%;
        padding: 0.1vw;
        cursor: pointer;
    }
    & img:hover {
        background-color: #CE1818;
    }
`;

export default function Users (){

    const { session } = useContext(sessionContext);

    let [users, setUsers] = useState(undefined);
    let [usersVentas, setUsersVentas] = useState(undefined);
    let [msgVal, setMsgVal] = useState("");
    let [msgValVentas, setMsgValVentas] = useState("");
    let [msgVal1, setMsgVal1] = useState(["", false]);
    let [modal, setModal] = useState(false);

    let [pageToken, setPageToken] = useState(undefined);
    
    let [modalUser, setModalUser] = useState(false);

    let [cargando, setCargando] = useState(false);
    let [cargando1, setCargando1] = useState(false);
    let [cargandoPageUsers, setCargandoPageUsers] = useState(false);

    let [email, setEmail] = useState("");
    let [pass, setPass] = useState("");
    let [name, setName] = useState("");

    let [modalDel, setModalDel] = useState(false);

    async function getUsuarios (usersPageToken){
        if (usersPageToken) {
            setCargandoPageUsers(true);
        }
        const result = await getUsers({pageToken:usersPageToken});
        if (result[0]) {
            let newUsers = users;
            if (users === undefined || users === false) {
                newUsers = [];
            }
            result[1].users.map((user)=>{
                let diferente = true;
                if (users) {
                    users.map((oldUser)=>{
                        if (oldUser.uid == user.uid) {
                            diferente = false;
                        }
                    });
                }
                if (diferente) {
                    newUsers.push(user);
                }

            })
            setUsers(newUsers);
            setPageToken(result[1].pageToken || false);
        } else {
            setUsers(false);
            setMsgVal(result[1]);
        }
        setCargandoPageUsers(false);
    };

    async function AddUsuario (){
        setCargando(true);
        if (email.length > 0 && pass.length > 0 && email.length > 0) {
            if (verificarEmail.test(email)) {
                const result = await addUser({email:email, password:pass, name:name});
                if (result[0]) {
                    setUsers(undefined);    
                    getUsuarios();
                    setModal(false);
                    setEmail("");
                    setPass("");
                    setName("");
                    setMsgVal("");
                } else {
                    setMsgVal(result[1]);
                }
            } else {
                setMsgVal("El correo ingresado es incorrecto.");
            }
        } else {
            setMsgVal("Rellena los campos faltantes.");
        };
        setCargando(false);
    }

    async function getDataUser (uid){
        setModalUser({});
        setCargando1(true);
        const result = await getInfoUser(uid);
        if (typeof result === "string") {
            setModalUser(false);
        } else {
            setModalUser(result);
        }
        setCargando1(false);
    }

    async function getUsuariosVentas (){
        const result = await getUsers({masVentas:true});
        if (result[0]) {    
            setUsersVentas(result[1]);
        } else {
            setUsersVentas(false);
            setMsgValVentas(result[1]);
        }
    };

    async function eliminarUsuario (){
        setCargando(true);
        const result = await eliminarUserOsolicitud({delUserUid:modalDel, tipo:"users"});

        if (result === true) {
            setMsgVal1(["Usuario eliminado correctamente.", true]);
            const usersNew = users;
            const index = usersNew.findIndex(e => e.uid == modalDel);
            usersNew.splice(index, 1);
        } else {
            setMsgVal1(JSON.parse(JSON.stringify([result, false])));
        }
        
        setTimeout(()=>{
            setMsgVal1(["", false]);
        },2000);

        setModalDel(false);
        setCargando(false);
    }

    useEffect(()=>{
        if (session.user) {
            getUsuarios();
            getUsuariosVentas();
        }
    },[session.user]);

    return (
        <div>
            <Msg activo={msgVal1[0]} error={msgVal1[1]}><div><p>{msgVal1[0]}</p></div></Msg>
            <Modal visible={modalDel} setVisible={()=>{}}>
                <ModalEliminar> 
                    <h3>¿Estas seguro de eliminar este usuario?</h3>
                    <p>Esta acción no se puede deshacer.</p>
                    {cargando && <CargandoCont><img src={cargandoImg}/></CargandoCont>}
                    <Btns style={{width:"100%", justifyContent:"space-around"}}>
                        <Button opacity={cargando} onClick={()=>!cargando && setModalDel(false)} >No estoy seguro</Button>
                        <Button opacity={cargando} red={true} onClick={()=>!cargando && eliminarUsuario()}>Estoy seguro</Button>
                    </Btns>
                </ModalEliminar>
            </Modal>

            <h3 style={{fontSize:pc ? "2vw" : "7vw", fontWeight: "700", marginBottom: pc ? "0" : "1vw"}}>Usuarios</h3>
            { users === undefined ?
                <CargandoCont><img src={cargandoImg} /></CargandoCont>
                :
                users !== false ?
                <CuadroContenedor style={{marginTop:0, paddingTop:0}}>
                    <CuadroCont>
                        <TopCuadroContenedor>
                            <Cuadro><p>Nombre</p></Cuadro>
                            <Cuadro style={{width:"50%"}}><p>Correo</p></Cuadro>
                            <Cuadro><p>Numero telefonico</p></Cuadro>
                        </TopCuadroContenedor>
                            { users.map((value)=>(
                                <BottomCuadroContenedor style={{position:"relative"}} key={value.uid}>
                                    <Cuadro onClick={()=>getDataUser(value.uid)} style={{cursor:"pointer"}}><p style={{textDecoration:"underline"}}>{value.displayName}</p></Cuadro>
                                    <Cuadro style={{width:"50%"}}><p>{value.email}</p></Cuadro>
                                    <Cuadro><p>{value.phoneNumber}</p></Cuadro>
                                    <BtnQuitar>
                                        <img onClick={()=>setModalDel(value.uid)} src={quitar} />
                                    </BtnQuitar>
                                </BottomCuadroContenedor>
                            ))}
                    </CuadroCont>
                </CuadroContenedor>
                : <p>{msgVal}</p>
            }
            { pageToken &&
                <div style={{display:"flex", alignItems:"center", width: pc ? "7%" : "30%", justifyContent:"center", padding:"3px", margin: pc ? "5px 26.5%" : "0 32%", padding:"2px"}}>
                    { cargandoPageUsers ?
                        <CargandoCont style={{marginTop:"6px"}}>
                            <img src={cargandoImg} />
                        </CargandoCont>
                        :
                        <p style={{cursor:"pointer", textDecoration:"underline"}} onClick={()=>getUsuarios(pageToken)}>Ver mas</p>
                    }
                </div>
            }
            <BtnAdd style={{marginTop:pc ? "2vw" : "6vw"}} onClick={()=>setModal(true)}>
                <p>Añadir usuario</p>
            </BtnAdd>
            <h3 style={{fontSize:pc ? "1.7vw" : "7vw", fontWeight: "700", marginTop:pc ? "2.5vw" : "5vw", marginBottom: 0}}>Usuarios con mas ventas</h3>
            { usersVentas === undefined ?
                <CargandoCont><img src={cargandoImg} /></CargandoCont>
                :
                usersVentas !== false ?
                    usersVentas.length > 0 ?
                        <CuadroContenedor style={{paddingTop:0, marginBottom:"5vw", marginTop:pc ? "0.5vw" : "1vw"}}>
                            <CuadroCont>
                                <TopCuadroContenedor>
                                    <Cuadro style={{width:"15%"}}><p>Clasificación</p></Cuadro>
                                    <Cuadro><p>Nombre</p></Cuadro>
                                    {/* <Cuadro style={{width:"50%"}}><p>Correo</p></Cuadro> */}
                                    <Cuadro><p>Total neto</p></Cuadro>
                                    <Cuadro><p>Total comisión</p></Cuadro>
                                </TopCuadroContenedor>
                                    { usersVentas.map((value, index)=>(
                                        <BottomCuadroContenedor key={value.uid}>
                                            <Cuadro style={{width:"15%"}}><p style={{fontWeight: index == 0 && "600"}}>{index+1}.</p></Cuadro>
                                            <Cuadro onClick={()=>getDataUser(value.uid)} style={{cursor:"pointer"}}><p style={{fontWeight: index == 0 && "600", textDecoration:"underline"}}>{value.nombre}</p></Cuadro>
                                            {/* <Cuadro style={{width:"50%"}}><p>{value.email}</p></Cuadro> */}
                                            <Cuadro><p style={{fontWeight: index == 0 && "600"}}>{value.total ? "RD $"+value.total : "RD $0"}</p></Cuadro>
                                            <Cuadro><p style={{fontWeight: index == 0 && "600"}}>{value.totalComision ? "RD $"+value.totalComision : "RD $0"}</p></Cuadro>
                                        </BottomCuadroContenedor>
                                    ))}
                            </CuadroCont>
                        </CuadroContenedor>
                        :
                        <p>Todavia no hay usuarios con ventas</p>
                : <p>{msgValVentas}</p>
            }

            <Modal visible={modal} setVisible={()=>{}}>
                <ModalCont>
                    <h3>Añadir usuario</h3>
                    <b>{msgVal}</b>
                    <div>
                        <p>Nombre</p>
                        <input value={name} onChange={(e)=>setName(e.target.value)}/>
                    </div>
                    <div>
                        <p>Correo</p>   
                        <input value={email} onChange={(e)=>setEmail(e.target.value)}/>
                    </div>
                    <div>
                        <p>Contraseña</p>
                        <input value={pass} onChange={(e)=>setPass(e.target.value)}/>
                    </div>
                    { cargando ?
                        <CargandoCont><img src={cargandoImg} /></CargandoCont>
                        :
                        <Btns>
                            <span onClick={()=>{if(!cargando){setModal(false); setMsgVal("")}}}>Cancelar</span>
                            <BtnAdd onClick={()=>!cargando && AddUsuario()} style={{marginLeft:pc ? "1vw" : "5vw"}}>
                                <p>Añadir usuario</p>
                            </BtnAdd>
                        </Btns>        
                    }
                </ModalCont>
            </Modal>

            { modalUser !== false &&
                <Modal visible={true} setVisible={()=>{}} >
                    { cargando1 ?
                        <CargandoCont><img src={cargandoImg} /></CargandoCont>
                        :
                        <div>
                            <BtnCerrar onClick={()=>!cargando && setModalUser(false)}><img src={quitar1}/></BtnCerrar>
                            <DetallesReserva>
                                <h2>Información del usuario</h2>
                                <div><h3>No. documento de identidad:</h3><span>{modalUser.documentoIdentidad || "Falta"}</span></div>
                                <div><h3>Ciudad:</h3><span>{modalUser.ciudad || "Falta"}</span></div>
                                <div><h3>Dirección de residencia:</h3><span>{modalUser.direccion || "Falta"}</span></div>
                                <div><h3>Nombre del banco:</h3><span>{modalUser.nombreBanco || "Falta"}</span></div>
                                <div><h3>No. de cuenta bancaria:</h3><span>{modalUser.numeroCuenta || "Falta"}</span></div>
                                <div><h3>Total neto:</h3><span>{modalUser.total ? "RD $"+modalUser.total : "No disponible"}</span></div>
                                <div><h3>Total de comisión:</h3><span>{modalUser.totalComision ? "RD $"+modalUser.totalComision : "No disponible"}</span></div>
                            </DetallesReserva>
                        </div>
                    }
                </Modal>
            }
        </div>
    )
}