import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import ContenedorOpcion from "../contenedorOpcion";
import { validarFecha, restaFechas, cambiarFecha, isNum } from "../../utils/validar";
import {CuadroContenedor, TopCuadroContenedor, CuadroCont, Cuadro, BottomCuadroContenedor} from "../../utils/recuadro";
import { Formulario, TopFormulario, FormularioCont, Columna, BtnEnviar, Input, Select, SpanValidacion } from "../../utils/formulario";
import Modal, { BtnCerrar, DetallesReserva } from "../../utils/modal";
import CargandoCont from "../../utils/cargando";
import sessionContext from "../../../contexts/sessionContext";
import { descargarArchivo } from "../../utils/funciones";

//imagenes
import cargandoImg from "../../../images/cargando.gif";
import { getSeguros, getSolicitudesCliente, solicitarCotizacion, subirVoucher } from "../../../config/auth";
import quitar from "../../../images/quitar1.png";
import subir from "../../../images/subir.png";
import descargar from "../../../images/descargar.png";

const pc = window.screen.width > 529 ? true : false;

const SegurosDeViajesContenedor = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
`;

const SegurosContenedor = styled.div`
    display: flex;
    margin-top: ${pc ? "3vw" : "6vw"};
    flex-direction: column;
    align-items: center;

    & h3 {
        color:#004aad;
        font-weight: 700;
        font-size: ${ pc ? "2.3vw" : "6vw"};
    }
    & p {
        width: 95%;
        font-size: ${pc ? "1.2vw" : "4vw"};
        text-align: center;
    }
`;

const BtnsContenedor = styled.div`
    display:flex;
    width: 100%;
    margin-top: ${pc ? "5vw" : "10vw"};
    justify-content: space-evenly;
`;

const Btn = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${pc ? "4vw" : "12vw"};
    width: ${pc ? "25%" : "45%"};
    background-color: #1E1919;
    cursor: pointer;

    & p {
        color:#fff;
        font-size: ${pc ? "1.2vw" : " 3.5vw"};
    }
`;

const SpanValidacion1 = styled.span`
    text-align: right;
    font-size: ${pc ? "1vw" : "3.4vw"};
    background-color: ${props => props.error ? "#26AC20" : "#eb1a1a"};
    border-radius: ${pc ? "0.3vw" : "1vw"};
    margin-bottom: ${pc && "1vw"};
    padding: ${pc ? "0.6vw" : "2.3vw"};
    color:#fff;
    display: ${props => props.activo ? "block" : "none"};
`;

const BtnSubir = styled.div`
    padding: ${pc ? "0.5vw" : "1vw"};
    cursor: pointer;
    border-radius: ${pc ? "0.3vw" : "1vw"};
    background-color: #8C8C8C;
    
    & img {
        width: ${pc ? "1.8vw" : "4vw"};
    }
    & p{
        color:#fff
    }
`;

const BtnDescargar = styled.div`
    padding: ${pc ? "0.5vw" : "1vw"};
    cursor: pointer;
    border-radius: ${pc ? "0.3vw" : "1vw"};
    background-color: #E7971D;
    text-decoration: none;

    & img {
        width: ${pc ? "1.8vw" : "4vw"};
    }
    & p{
        color:#fff
    }
`;

const Msg = styled.div`
  position: fixed;
  display: ${props => props.activo ? "flex!important" : "none!important"};
  width: 100%;
  align-items: center!important;
  justify-content: center!important;
  bottom: 0;
  left: 0;
    margin-bottom: 0!important;

  & div {
    padding: ${pc ? "1vw 2vw" : "2vw 3vw"};
    margin-bottom: 3px;
    text-align: center;
    border-top-right-radius: 0.4vw;
    border-top-left-radius: 0.4vw;
    background-color: ${props => props.error ? "#26AC20" : "#eb1a1a"};
    color: #fff;
  }
`;

const BtnToPoliticas = styled.span`
    color: #fff;
    font-weight: 500;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
`;
const CheckboxCont = styled.div`
    display: flex;
    align-items: center;
    width: ${pc ? "80%" : "100%"};
    margin-bottom: 2vw;
    
    & p {
        font-size: ${pc ? "1.2vw" : "3vw"};
        margin-left: ${!pc && "2vw"};
        font-weight: 300;
    }

    & input {
        width: ${pc ? "10%" : "15%"};
        height: ${!pc && "4.5vw"};
        margin: 0!important;
        padding: 0!important;
        margin-right: 1vw!important;
    }
`;

export default function SegurosDeViajes (){

    const {session} = useContext(sessionContext);
    const navigate = useNavigate();
    const location = useLocation();

    let [inputs, setInputs] = useState({nombreTitular:["", ""], destino:["", ""], cantidadPersonas:["", ""], desdeFecha:["", ""], hastaFecha:["", ""], nombres:[], observaciones:["", ""]});
    let [ultimoElemento, setUltimoElemento] = useState(undefined);

    let [cargando, setCargando] = useState(false);
    let [cargando1, setCargando1] = useState(false);
    let [cargando2, setCargando2] = useState(false);
    let [id, setId] = useState("");
    let [errorUploadFile, setErrorUploadFile] = useState(false);

    let [modal, setModal] = useState(false);

    let [solicitar, setSolicitar] = useState({seguro:"none", activo:false});
    let [seguros, setSeguros] = useState(undefined);
    let [segurosInfo, setSegurosInfo] = useState({});

    const nombres = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,];

    let [msgVal, setMsgVal] = useState("");
    let [msgVal1, setMsgVal1] = useState("");

    
    let [checkbox, setCheckbox] = useState(false);
    let [checkboxMsg, setCheckboxMsg] = useState(false);

    function updateValueInput (tipo, valor, indexNombre){
        const newInputs = JSON.parse(JSON.stringify(inputs));
        if (tipo == "nombres") {
            newInputs.nombres[indexNombre][0] = valor;
        } else {
            if (tipo == "desdeFecha" || tipo == "hastaFecha") {
                newInputs[tipo][0] = cambiarFecha(valor);
            } else {
                newInputs[tipo][0] = valor;
            }
        }

        if (tipo == "cantidadPersonas") {
            newInputs.cantidadPersonas[1] = "";
            if (isNum(valor)) {
                if (valor <= 20) {
                    newInputs.nombres = [];
                    for(let i = 0; i < newInputs.cantidadPersonas[0]; i++){
                        if (inputs.nombres[i]) {
                            if (inputs.nombres[i][0]) {
                                newInputs.nombres.push(inputs.nombres[i]);
                            } else {
                                newInputs.nombres.push(["", ""]);
                            }
                        } else {
                            newInputs.nombres.push(["", ""]);
                        }
                    }
                } else {
                    newInputs.cantidadPersonas[1] = "Maximo 20 cantidadPersonas.";
                }
            } else {
                newInputs.cantidadPersonas[1] = "Solo se admiten numeros.";
            }
        }

        setInputs(newInputs);
    }


    function validarForm (){
        const newInputs = JSON.parse(JSON.stringify(inputs));
        const inputsName = Object.keys(inputs);
        let result = true;

        inputsName.map((value)=>{
            const input = newInputs[value][0];
            if (value != "nombres") {
                newInputs[value][1] = "";
            } else {
                newInputs.nombres.map((value, index)=>{
                    newInputs.nombres[index][1] = "";
                });
            }

            if (value != "nombres") {
                if (input.length > 0) {

                    if (input.length < 800) {
                        if (value == "desdeFecha" || value == "hastaFecha") {
                            const valFecha = validarFecha(input);
                            if (valFecha !== true){
                                newInputs[value][1] = valFecha;
                                result = false;
                            } else {
                                if (restaFechas(newInputs.desdeFecha[0], newInputs.hastaFecha[0]) == 0) {
                                    newInputs[value][1] = "Reserva minimo un dia.";
                                    result = false;
                                } else if (restaFechas(newInputs.desdeFecha[0], newInputs.hastaFecha[0]) < 0){
                                    newInputs[value][1] = "La fecha de salida no puede ser antes que la fecha de entrada.";
                                    result = false;
                                }
                            }
                        }
    
                        if (value == "nombreTitular") {
                            if (input.length < 4) {
                                newInputs[value][1] = "Minimo 4 caracteres.";
                                result = false;
                            }
                        }
    
                        if (value == "destino") {
                            if (input.length < 2) {
                                newInputs[value][1] = "Minimo 2 caracteres.";
                                result = false;
                            }
                        }
    
                        if (value == "cantidadPersonas") {
                            if (isNum(input)) {
                                if (input < 1) {
                                    newInputs[value][1] = "Minimo una persona.";
                                    result = false;
                                }
                            } else {
                                newInputs[value][1] = "Solo se admiten numeros. (sin puntos, comas, ni simbolos)";
                                result = false;
                            }
                        }

    
                        if (value == "observaciones") {
                            if (input.length > 700) {
                                newInputs[value][1] = "Maximo 700 caracteres.";
                                result = false;
                            }
                        }
                    } else {
                        newInputs[value][1] = "Maximo 799 caracteres.";
                        result = false;
                    }
    
                } else {
                    if (value != "observaciones") {
                        newInputs[value][1] = "Rellena este campo.";
                        result = false;
                    }
                }
            } else {
                newInputs.nombres.map((value, index)=>{
                    if (value[0].length > 0) {
                        if (value[0].length < 4) {
                            newInputs.nombres[index][1] = "Minimo 4 caracteres.";
                            result = false;
                        }
                    } else {
                        newInputs.nombres[index][1] = "Rellena este campo.";
                        result = false;
                    }
                });
            }

        });

        setCheckboxMsg("");
        if (!checkbox) {
            setCheckboxMsg("Confirma que estas de acuerdo.");
            result = false;
        }

        if (result) {
            enviarSeguro();
        }
        
        setInputs(newInputs);
    }

    function toggleFormSeguro (seguro, activo){
        setCheckbox(false);
        const newSolicitar = JSON.parse(JSON.stringify(solicitar));
        newSolicitar.seguro = seguro;
        newSolicitar.activo = activo;

        setSolicitar(newSolicitar);
    }

    async function enviarSeguro (){
        setCargando(true);
        const result = await solicitarCotizacion(inputs, "seguros", solicitar.seguro);
        if (result !== true) {
            setMsgVal(result);
        } else {
            setMsgVal("Solicitud de seguro enviado correctamente.");
            setTimeout(()=>{
                const newSolicitar = solicitar;
                newSolicitar.activo = false;
                setSolicitar(newSolicitar);
                getData(true);
                setMsgVal("");
            }, 4080);
        }
        setCargando(false);
    }

    async function enviarVoucher (archivo){
        setCargando1(true);
        const result = await subirVoucher(archivo, seguros[id].id, "seguros");
        if (result[0]) {
            const newModal = modal;
            newModal.transferenciaVoucher = result[1];
            getData(true);
            setModal(newModal);
        } else {
            setErrorUploadFile(result[1]);
            setTimeout(()=>{
                setErrorUploadFile("");
            },3500);
        }
        setCargando1(false);
    }

    async function getData (update){
        if (seguros === undefined || update) {
            const result = await getSolicitudesCliente({tipo:"seguros"});
            if (result[0]) {
                setSeguros(result[1].datos);
                setUltimoElemento(result[1].ultimoElemento);
            } else {
                setMsgVal1(result[1]);
            }
        } else {
            if (ultimoElemento !== false) {
                setCargando2(true);
                const result = await getSolicitudesCliente({tipo:"seguros", ultimoElemento:ultimoElemento});
                if (result[0]) {
                    setUltimoElemento(result[1].ultimoElemento);
                    const newSeguros1 = seguros;
                    result[1].datos.map((value, index)=>{
                        let si = true;
                        newSeguros1.map((old)=>{
                            if (value.id === old.id) {
                                si = false;
                            }
                        });
                        if (si) {
                            newSeguros1.push(value);
                        }
                    });
                    setSeguros(newSeguros1);
                } else {
                    setSeguros(false);
                    setMsgVal1(result[1]);
                }
            }
        }
        setCargando2(false);
    }

    async function obtenerSeguros(){
        const result = await getSeguros();
        if (result[0] === true) {
            setSegurosInfo(result[1]);
        }
    }

    useEffect(async()=>{
        if (session.user) {
            getData();
            obtenerSeguros();
        }
    },[session.user]);

    useEffect(()=>{
        setSolicitar({seguro:"none", activo:false});
    },[location.search]);

    return (
        <ContenedorOpcion>
            {  solicitar.activo == true ?
                <SegurosDeViajesContenedor>
                    <h2>{solicitar.seguro.split("").map((value, index)=>{
                        if (index == 0) {
                            return value.toUpperCase();
                        } else {
                            return value;
                        }
                    })}</h2>
                    <Formulario>
                        <FormularioCont>
                            <TopFormulario>
                                <h2>SOLICITUD DE SEGURO</h2>
                            </TopFormulario>
                            <Columna>
                                <label>Nombre del titular</label>
                                <SpanValidacion>{inputs.nombreTitular[1]}</SpanValidacion>
                                <Input error={inputs.nombreTitular[1]} onChange={(valor)=>updateValueInput("nombreTitular", valor.target.value)}/>
                            </Columna>
                            <Columna>
                                <label>Destino</label>
                                <SpanValidacion>{inputs.destino[1]}</SpanValidacion>
                                <Input error={inputs.destino[1]} onChange={(valor)=>updateValueInput("destino", valor.target.value)}/>
                                <label>Cantidad de personas</label>
                                <SpanValidacion>{inputs.cantidadPersonas[1]}</SpanValidacion>
                                <Select defaultValue="DEFAULT" error={inputs.cantidadPersonas[1]} onChange={(valor)=>updateValueInput("cantidadPersonas", valor.target.value)}>
                                    <option disabled value="DEFAULT">Cantidad de personas</option>
                                    { nombres.map((value, index)=>(
                                        <option key={index+1}>{index+1}</option>
                                    ))}
                                </Select>
                                {inputs.nombres.map((value, index)=>(
                                    <div key={index} style={{display:"flex", flexDirection:"column"}}>
                                        <label>Nombres y apellidos</label>
                                        <SpanValidacion>{inputs.nombres[index][1]}</SpanValidacion>
                                        <Input error={inputs.nombres[index][1]} onChange={(valor)=>updateValueInput("nombres", valor.target.value, index)}/>
                                    </div>
                                ))}
                            </Columna>
                            <Columna>
                                <label>Desde fecha</label>
                                <SpanValidacion>{inputs.desdeFecha[1]}</SpanValidacion>
                                <Input type="date" error={inputs.desdeFecha[1]} onChange={(valor)=>updateValueInput("desdeFecha", valor.target.value)} placeholder="dia/mes/año"/>
                            </Columna>
                            <Columna>
                                <label>Hasta fecha</label>
                                <SpanValidacion>{inputs.hastaFecha[1]}</SpanValidacion>
                                <Input type="date" error={inputs.hastaFecha[1]} onChange={(valor)=>updateValueInput("hastaFecha", valor.target.value)} placeholder="dia/mes/año"/>

                            </Columna>
                            <Columna>
                                <label>Observaciones</label>
                                <SpanValidacion>{inputs.observaciones[1]}</SpanValidacion>
                                <textarea onChange={(valor)=>updateValueInput("observaciones", valor.target.value)}></textarea>
                                <SpanValidacion>{checkboxMsg}</SpanValidacion>
                                <CheckboxCont style={{display:"flex", alignItems:"center"}}>
                                    <Input style={{cursor:"pointer", width:pc && "8%"}} error={checkboxMsg} type="checkbox" onChange={(e)=>setCheckbox(e.target.checked)} />
                                    <p>He leido y acepto los <BtnToPoliticas onClick={()=>navigate("/terminos-y-condiciones")}>terminos y condiciones.</BtnToPoliticas></p>
                                </CheckboxCont>
                            </Columna>
                        </FormularioCont>
                    </Formulario>
                    <SpanValidacion1 activo={msgVal} error={msgVal == "Solicitud de seguro enviado correctamente." ? true : false}>{msgVal}</SpanValidacion1>
                    <BtnEnviar onClick={()=>!cargando && validarForm()}>
                        { cargando ?
                            <CargandoCont><img src={cargandoImg}/></CargandoCont>
                            : <p>Solicitar</p>
                        }
                    </BtnEnviar>
                </SegurosDeViajesContenedor>
                :  
                <SegurosDeViajesContenedor>
                <h2>Seguros de viajes</h2>
                { !location.search ?
                        <SegurosContenedor>
                            <h3>Euroschengen</h3>
                            <p>Con la cobertura que necesitas para viajar a los países miembro de la Comunidad Europea y Reino Unido. Viaja confiado, nosotros te respaldamos. Cumple con todos los requisitos para obtener una visa Schengen.</p>
                            <BtnsContenedor>
                                <Btn onClick={()=>window.open(segurosInfo.euroschengen)}><p>Ver cobertura y tarifa</p></Btn>
                                <Btn onClick={()=>toggleFormSeguro("euroschengen", true)}><p>Solicitar</p></Btn>
                            </BtnsContenedor>
                        </SegurosContenedor>
                    : location.search == "?tab=platinum" ?
                        <SegurosContenedor>
                            <h3>Platinum</h3>
                            <p>Para viajeros exigentes, con las más amplias coberturas en todo el mundo, le garantiza un viaje tranquilo. Cómprelo por módulo de días o anual, recomendada para viajeros frecuentes, familias, corporativos o estudiantes.</p>
                            <BtnsContenedor>
                                <Btn onClick={()=>window.open(segurosInfo.platinum)}><p>Ver cobertura y tarifa</p></Btn>
                                <Btn onClick={()=>toggleFormSeguro("platinum", true)}><p>Solicitar</p></Btn>
                            </BtnsContenedor>
                        </SegurosContenedor>
                    : location.search == "?tab=gold" ?
                        <SegurosContenedor>
                            <h3>Gold</h3>
                            <p>Cubre las necesidades de los viajeros de placer que buscan excelente cobertura en todo el mundo al mejorprecio.</p>
                            <BtnsContenedor>
                                <Btn onClick={()=>window.open(segurosInfo.gold)}><p>Ver cobertura y tarifa</p></Btn>
                                <Btn onClick={()=>toggleFormSeguro("gold", true)}><p>Solicitar</p></Btn>
                            </BtnsContenedor>
                        </SegurosContenedor>
                    : location.search == "?tab=silver" ?
                        <SegurosContenedor>
                            <h3>Silver</h3>
                            <p>Cubre las necesidades de los viajeros de placer. Cobertura en todo el mundo, ideal para excursiones, circuitos, tours culturales, eventos y convenciones.</p>
                            <BtnsContenedor>
                                <Btn onClick={()=>window.open(segurosInfo.silver)}><p>Ver cobertura y tarifa</p></Btn>
                                <Btn onClick={()=>toggleFormSeguro("silver", true)}><p>Solicitar</p></Btn>
                            </BtnsContenedor>
                        </SegurosContenedor>
                    : location.search == "?tab=plus" ?
                        <SegurosContenedor>
                            <h3>Plus</h3>
                            <p>Si buscas la mejor y mayor cobertura en todo el mundo, este es el plan diseñado para tí. Podrás disfrutar a plenitud de tus viajes sin preocupaciones.</p>
                            <BtnsContenedor>
                                <Btn onClick={()=>window.open(segurosInfo.plus)}><p>Ver cobertura y tarifa</p></Btn>
                                <Btn onClick={()=>toggleFormSeguro("plus", true)}><p>Solicitar</p></Btn>
                            </BtnsContenedor>
                        </SegurosContenedor>
                    : location.search == "?tab=national" ?
                        <SegurosContenedor>
                            <h3>National</h3>
                            <p>En cualquier rincón del país, en la playa o en la montana, si son vacaciones o viajes de estudiantes, convenciones de profesionales en la República Dominicana le acompañaremos Solamente para residentes en la República Dominicana.</p>
                            <BtnsContenedor>
                                <Btn onClick={()=>window.open(segurosInfo.national)}><p>Ver cobertura y tarifa</p></Btn>
                                <Btn onClick={()=>toggleFormSeguro("national", true)}><p>Solicitar</p></Btn>
                            </BtnsContenedor>
                        </SegurosContenedor>
                    : location.search == "?tab=student" ?
                        <SegurosContenedor>
                            <h3>Student</h3>
                            <p>Producto especialmente diseñado para estudiantes y profesionales que viajan por becas, carreras de grado, postgrado e intercambios.</p>
                            <BtnsContenedor>
                                <Btn onClick={()=>window.open(segurosInfo.student)}><p>Ver cobertura y tarifa</p></Btn>
                                <Btn onClick={()=>toggleFormSeguro("student", true)}><p>Solicitar</p></Btn>
                            </BtnsContenedor>
                        </SegurosContenedor>
                    : location.search == "?tab=mis-solicitudes" &&
                        <SegurosContenedor>
                            <h3>Mis solicitudes</h3>
                            {   seguros !== undefined ?
                                    seguros !== false ?
                                        seguros.length > 0 ? 
                                            <CuadroContenedor>
                                                <CuadroCont>
                                                    <TopCuadroContenedor>
                                                        <Cuadro>
                                                            <p>Cliente</p>
                                                        </Cuadro>
                                                        <Cuadro>
                                                            <p>Detalles</p>
                                                        </Cuadro>
                                                        <Cuadro>
                                                            <p>Estado</p>
                                                        </Cuadro>
                                                    </TopCuadroContenedor>
                                                    { seguros.map((value, index)=>(
                                                        <BottomCuadroContenedor key={value.id}>
                                                            <Cuadro><p>{value.form.nombreTitular}</p></Cuadro>
                                                            <Cuadro style={{cursor:"pointer"}} onClick={()=>{setModal(value); setId(index)}}><p style={{textDecoration:"underline"}}>Ver</p></Cuadro>
                                                            <Cuadro><p>{value.estado}</p></Cuadro>
                                                        </BottomCuadroContenedor>
                                                    ))
                                                    }                                                    
                                                </CuadroCont>
                                            </CuadroContenedor> 
                                        : <p>No tienes solicitudes de seguros</p>
                                    : <p>{msgVal1}</p>
                                : <CargandoCont><img src={cargandoImg}/></CargandoCont> 
                            }
                            { ultimoElemento !== undefined && ultimoElemento !== false ? 
                                cargando2 ? 
                                    <CargandoCont><img src={cargandoImg}/></CargandoCont> 
                                    : 
                                    <p onClick={()=>getData()} style={{cursor:"pointer", padding:"0.5vw", textDecoration:"underline"}}>Ver mas</p> 
                                : <p></p>
                            }
                        </SegurosContenedor>
                }
                {modal && <Modal visible={modal} setVisible={()=>!cargando1 && setModal(false)}>
                    <DetallesReserva>
                        <BtnCerrar onClick={()=>!cargando1 && setModal(false)}><img src={quitar}/></BtnCerrar>
                        <h2>Detalles de la solicitud</h2>
                        <div><h3>Estado</h3><span style={{color: modal.estado == "Confirmado" && "#26AC20"}}>{modal.estado}</span></div>
                        <div>
                            <h3>Transferencia Voucher:</h3>
                            <span>
                                { modal.transferenciaVoucher == "none" 
                                    ? <BtnSubir onClick={()=>document.querySelector("#btnArchivo").click()}>
                                            { !cargando1
                                                ? <div style={{display:"flex", alignItems:"center", justifyContent:"center", border:"none", margin:0}}>
                                                    <p>Subir Voucher</p>
                                                    <img src={subir}/>
                                                </div>
                                                : <p>Subiendo..</p>
                                            }
                                        </BtnSubir> 
                                    :   <BtnDescargar onClick={()=>descargarArchivo(modal.transferenciaVoucher)}>
                                            <p>Voucher</p>
                                            <img src={descargar} />
                                        </BtnDescargar> 
                                }
                            </span>
                        </div>
                        <div>
                            <h3>Documento de confirmación:</h3>
                            <span>
                                {modal.documentoConfirmacion == "none" ? "Sin documento" : 
                                    <BtnDescargar onClick={()=>descargarArchivo(modal.documentoConfirmacion)}>
                                        <p>Descargar</p>
                                        <img src={descargar} />
                                    </BtnDescargar>
                                }
                            </span>
                        </div>
                        <div><h3>Monto total:</h3><span>{modal.totalUs ? "USD $ "+modal.totalUs : "Todavia no esta disponible"}</span></div>
                        <div><h3>Comision:</h3><span>{modal.comisionUs ? "USD $ "+modal.comisionUs : "Todavia no esta disponible"}</span></div>
                        <div><h3>Estado de pago de comisión:</h3><span style={{color: modal.estadoComision == true && "#26AC20"}}>{modal.estadoComision ? "Pagado" : "Pendiente"}</span></div>
                        <div><h3>Titular:</h3><span>{modal.form.nombreTitular}</span></div>
                        <div><h3>Destino:</h3><span>{modal.form.destino}</span></div>
                        <div><h3>Fecha de ida:</h3><span>{modal.form.desdeFecha}</span></div>
                        <div><h3>Fecha de regreso:</h3><span>{modal.form.hastaFecha}</span></div>
                        <div><h3>Cantidad de personas:</h3><span>{modal.form.cantidadPersonas}</span></div>
                        <div><h3>Seguro:</h3><span>{modal.seguro}</span></div>
                        <Msg activo={errorUploadFile} error={false}><div><p>{errorUploadFile}</p></div></Msg>
                    </DetallesReserva>
                </Modal>}
            </SegurosDeViajesContenedor>
            }
            <input style={{display:"none"}} type="file" accept=".jpg, .png, .jpeg, .pdf" onChange={(e)=>enviarVoucher(e.target.files[0])} id="btnArchivo"/>
        </ContenedorOpcion>
    );
}