import { useEffect, useState } from "react";
import styled from "styled-components";
import { validarFecha, cambiarFecha,  restaFechas, calcularTotalVilla } from "../../utils/validar";
import {CuadroContenedor, TopCuadroContenedor, CuadroCont, Cuadro, BottomCuadroContenedor} from "../../utils/recuadro";
import CotizacionPDF from "./cotizacionPdf";
import { PDFDownloadLink } from "@react-pdf/renderer";

// imagenes
import btnDescargar from "../../../images/descargar.png";
import quitar from "../../../images/quitar2.png";
import cargandoImg from "../../../images/cargando.gif";

const pc = window.screen.width > 529 ? true : false;

const TarifasContenedor = styled.div`
    display: flex;
    flex-direction: column;

    & h2 {
        font-weight: 600;
    }
`;

const BtnQuitar = styled.button`
    background-color: transparent;
    border: none;
    display: flex;
    justify-content: center;
    margin-left: 1vw;
    width: ${pc ? "4vw" : "9vw"};
    cursor:pointer;

    & img {
        width: 100%;
    }
`;

const MsgValidacion = styled.div`
    position: absolute;
    bottom: ${pc ? "3.2vw" : "7.3vw"};
    padding: ${pc ? "0.3vw" : "1vw"};
    width: 105%;
    background-color: #eb1a1a;
    display:${props => props.display ? "block" : "none"};

    & p {
        font-size: ${pc ? "0.8vw" : "2.5vw"};
        line-height: ${pc ? "1vw" : "2.4vw"};
        color:#fff;
    }
`;

export default function TarifasYOfertas ({tarifas, villa}){

    let [codigosOfertas, setCodigosOfertas] = useState([]);

    let [total, setTotal] = useState("");
    let [cotizacionForm, setCotizacionForm] = useState([1]);

    let [defaultInput, setDefaultInput] = useState({codigoTarifa:"", cliente:["", ""], fechaEntrada:["", ""], fechaSalida:["", ""], rangoPersonas:["", ""]});
    let [inputsCotizacion, setInputsCotizacion] = useState([defaultInput]);

    let [infoPrecios, setInfoPrecios] = useState("");
    let [indexOferta, setIndexOferta] = useState();

    let [pdf, setPdf] = useState(false);
    let [cargando, setCargando] = useState(false);

    let [widthRango, setWidthRango] = useState("20vw");

    function delCotizacionForm (index){
        let newCotizacionForm = JSON.parse(JSON.stringify(cotizacionForm));
        let newInputs = JSON.parse(JSON.stringify(inputsCotizacion));
        newInputs.splice(index, 1);
        newCotizacionForm.splice(index, 1);
        setCotizacionForm(newCotizacionForm);
        setInputsCotizacion(newInputs);
    }

    function updateValueInputCotizacion (index, input, value) {
        let newValueInput = JSON.parse(JSON.stringify(inputsCotizacion));
        if (input == "codigoTarifa") {
            newValueInput[index]["codigoTarifa"] = value;
            const indexOfertaNew = tarifas.findIndex(e => e.codigoOferta == value);
            newValueInput[index].rangoPersonas[0] = "";
            if (indexOfertaNew > -1) {
                setIndexOferta(undefined);
                setTimeout(()=>setIndexOferta(indexOfertaNew), 50);
            }
        } else {
            newValueInput[index][input][0] = value;
            
            newValueInput[index].fechaEntrada[1] = "";
            newValueInput[index].fechaSalida[1] = "";
            newValueInput[index].rangoPersonas[1] = "";
            newValueInput[index].cliente[1] = "";
            
            if (input == "fechaEntrada") {
                newValueInput[index].fechaEntrada[0] = cambiarFecha(newValueInput[index].fechaEntrada[0]);
            } else if (input == "fechaSalida"){
                newValueInput[index].fechaSalida[0] = cambiarFecha(newValueInput[index].fechaSalida[0]);
            }

            if (newValueInput[index].cliente[0].length > 0) {
                if (newValueInput[index].cliente[0].length == 1) {
                    newValueInput[index].cliente[1] = "Minimo 2 caracteres.";
                }
            }

            if (newValueInput[index].fechaEntrada[0].length > 0) {
                if (validarFecha(newValueInput[index].fechaEntrada[0]) !== true) {
                    newValueInput[index].fechaEntrada[1] = validarFecha(newValueInput[index].fechaEntrada[0]);
                }
                if (restaFechas(newValueInput[index].fechaEntrada[0], newValueInput[index].fechaSalida[0]) == 0) {
                    newValueInput[index].fechaEntrada[1] = "Minimo un dia.";
                    newValueInput[index].fechaSalida[1] = "Minimo un dia.";
                }
            }
            
            if (newValueInput[index].fechaSalida[0].length > 0) {
                if (validarFecha(newValueInput[index].fechaSalida[0]) !== true) {
                    newValueInput[index].fechaSalida[1] = validarFecha(newValueInput[index].fechaSalida[0]);
                }
                if (restaFechas(newValueInput[index].fechaEntrada[0], newValueInput[index].fechaSalida[0]) < 0) {
                    newValueInput[index].fechaSalida[1] = "La fecha de salida tiene que ser despues que la fecha de entrada.";
                }
                
            }

            if (input == "rangoPersonas") {
                newValueInput[index].rangoPersonas[0] = value;
            }
        }


        setInputsCotizacion(newValueInput);
    }

    useEffect(()=>{
        if (tarifas.length > 0) {
            const newInput = inputsCotizacion;
            newInput[0].codigoTarifa = codigosOfertas[0];
            setInputsCotizacion(newInput);
            const newDefault = defaultInput;
            newDefault.codigoTarifa = codigosOfertas[0];
            setDefaultInput(newDefault);
        }

        if (tarifas) {
            const newCodigosOfertas = codigosOfertas;
            tarifas.forEach((value, index)=>{    
                if (validarFecha(value.vencimientoOferta) === true) {
                    newCodigosOfertas.push(value.codigoOferta);
                }
            });
            setCodigosOfertas(JSON.parse(JSON.stringify(newCodigosOfertas)));
        }

        let widthMayor = 0;
        tarifas.map((tarifa)=>{
            if (widthMayor < tarifa.rangos.length) {
                widthMayor = tarifa.rangos.length;
            }
        });

        const widthRangoDefault = pc ? 15 : 20 ;
        const widthRangoNew = pc ? 4.5*widthMayor : 11*widthMayor;

        setWidthRango(widthRangoDefault > widthRangoNew ? widthRangoDefault+"vw" : widthRangoNew+"vw");
        
    },[]);

    useEffect(()=>{
        let calculoTotal = inputsCotizacion[0].codigoTarifa ? calcularTotalVilla([inputsCotizacion[0]], tarifas, true) : 0;
        setTotal(calculoTotal);
        setInfoPrecios(0);
    },[inputsCotizacion[0].codigoTarifa, inputsCotizacion[0].fechaEntrada[0], inputsCotizacion[0].fechaSalida[0], inputsCotizacion[0].rangoPersonas[0]]);


    return (
        <div style={{marginLeft:pc && "17vw"}}>
        {(tarifas) && tarifas.length > 0 ? <TarifasContenedor>
           <h2>Tarifas & Ofertas</h2>
           <CuadroContenedor style={{marginBottom:"2vw"}} tarifas={true}>
                <CuadroCont tarifas={true}>
                    <TopCuadroContenedor tarifas={true}>
                        <Cuadro tarifas={true}>
                            <p>Codigo de Oferta</p>
                        </Cuadro>
                        <Cuadro width={widthRango} tarifas={true}>
                            <p>Precio por cantidad de personas</p>
                        </Cuadro>
                        <Cuadro tarifas={true}>
                            <p>Vencimiento de oferta</p>
                        </Cuadro>
                    </TopCuadroContenedor>
                    
                       { tarifas && tarifas.map((value, index)=>(
                            <BottomCuadroContenedor tarifas={true} bloqueado={validarFecha(value.vencimientoOferta) !== true && true} key={index}>
                                <Cuadro tarifas={true}><p>{value.codigoOferta}</p></Cuadro>
                                <Cuadro width={widthRango} style={{padding:0, justifyContent:"flex-start"}} tarifas={true}>

                                    {   value.rangos.map((subRango)=>(
                                        <div style={{display:"flex", color:"#fff", width:pc ? "4.5vw" : "11vw", fontSize:pc ? "1vw" : "2.7vw", flexDirection:"column"}}>
                                            <span style={{borderRight:"1px solid #fff", borderBottom:"1px solid #fff", padding:pc ? "0.1vw 0.4vw" : "0.2vw 0.5vw", textAlign:"center"}}>{subRango.rangoMin+"-"+subRango.rangoMax}</span>
                                            <span style={{borderRight:"1px solid #fff", padding:pc ? "0.1vw 0.4vw" : "0.2vw 0.5vw", textAlign:"center"}}>{subRango.rangos.findIndex(e => e.dias[0] == "todos") > -1 ? "$"+subRango.rangos[subRango.rangos.findIndex(e => e.dias[0] == "todos")].precio : "$"+subRango.rangos[0].precio} </span>    
                                        </div>
                                    ))}                                         
                                   
                                </Cuadro>
                                <Cuadro tarifas={true} bloqueado={validarFecha(value.vencimientoOferta) !== true && true}><p>{value.vencimientoOferta}</p></Cuadro>
                            </BottomCuadroContenedor>
                       ))

                       }
                
                </CuadroCont>
            </CuadroContenedor>
            <div style={{display:"flex", alignItems:"center"}}>
                <h2>Cotizar</h2>
                {pdf && <div style={{cursor:"wait", position:"absolute", zIndex:5000, left:0, top:0, width:"100vw", height:"100vh"}} />}
                {pdf && <PDFDownloadLink style={{display:"none"}} fileName="Villa-Cotizacion.pdf" className="btnPDF" document={<CotizacionPDF info={{form:inputsCotizacion, infoPrecios:infoPrecios, villa:villa, total:total}}/>} />}
                { total > 0 && inputsCotizacion[0].cliente[0].length > 2 ?
                    <button onClick={()=>{if(!cargando){setCargando(true); setPdf(true); setTimeout(()=>{document.querySelector(".btnPDF").click(); setPdf(false); setCargando(false)}, 1500); }}}  style={{ marginLeft:pc ?"1vw":"3vw", border:"none", textDecoration:"none", borderRadius:pc ?"0.3vw":"0.8vw", cursor:"pointer", display:"flex", color:"#fff", padding:pc ?"0.5vw":"1vw", backgroundColor:"#F1BD29"}}>
                        {   cargando ?
                            <img style={{width:"2vw", margin:"0 2vw"}} src={cargandoImg} />
                            :
                            <div style={{display:"flex"}}>
                                <p style={{fontSize:pc ?"1vw":"3vw", textDecoration:"none"}}>Cotización PDF</p>
                                <img src={btnDescargar} style={{width:pc ? "1.4vw" : "4.5vw"}} />
                            </div>
                        }
                    </button>
                    : 
                    <button style={{border:"none", marginLeft:pc ?"1vw":"3vw", opacity:"0.5", borderRadius:pc ?"0.3vw":"0.8vw", cursor:"pointer", display:"flex", color:"#fff", padding:pc ?"0.5vw":"1vw", backgroundColor:"#F1BD29"}}>
                        <p style={{fontSize:pc ?"1vw":"3vw"}}>Cotización PDF</p>
                        <img src={btnDescargar} style={{width:pc ? "1.4vw" : "4.5vw"}} />
                    </button>
                }
              
            </div>
            <CuadroContenedor tarifas={true} style={{width:pc && "69.5vw"}}>
                <CuadroCont tarifas={true}>
                    <TopCuadroContenedor tarifas={true}>
                            <Cuadro tarifas={true}>
                                <p>Codigo de Oferta</p>
                            </Cuadro>
                            <Cuadro tarifas={true}>
                                <p>Cliente</p>
                            </Cuadro>
                            <Cuadro tarifas={true}>
                                <p>Fecha de entrada</p>
                            </Cuadro>
                            <Cuadro tarifas={true}>
                                <p>Fecha de salida</p>
                            </Cuadro>
                            <Cuadro tarifas={true}>
                                <p>Rango de personas</p>
                            </Cuadro>
                    </TopCuadroContenedor>
                    { cotizacionForm.map((value, index)=>(
                        <BottomCuadroContenedor tarifas={true} key={index}>
                            <Cuadro tarifas={true}>
                                { index < 1 ?
                                    <select defaultValue="0" onChange={(e)=>updateValueInputCotizacion(index, "codigoTarifa", e.target.value)}>
                                        <option disabled value="0">Sin seleccionar</option>
                                        { codigosOfertas.map((value, indexOferta)=>(
                                            <option key={value}>{value}</option>
                                        ))}
                                    </select>
                                    :
                                    <p>{inputsCotizacion[index].codigoTarifa}</p>
                                }
                            </Cuadro>
                            { index < 1 ?
                                <Cuadro tarifas={true} style={{position:"relative"}}>
                                    <MsgValidacion display={inputsCotizacion[index].cliente[1]}>
                                        <p>{inputsCotizacion[index].cliente[1]}</p>
                                    </MsgValidacion>
                                    <input style={{paddingLeft:"0.7vw", outline:inputsCotizacion[index].cliente[1] && "1px solid #eb1a1a"}} onChange={(e)=>updateValueInputCotizacion(index, "cliente", e.target.value)} placeholder="Nombre del cliente"/>
                                </Cuadro>
                                :
                                <Cuadro tarifas={true}><p>{inputsCotizacion[0].cliente[0]}</p></Cuadro>
                            }
                            <Cuadro tarifas={true} style={{position:"relative"}}>
                                <MsgValidacion display={inputsCotizacion[index].fechaEntrada[1]}>
                                    <p>{inputsCotizacion[index].fechaEntrada[1]}</p>
                                </MsgValidacion>
                                <input type="date" style={{outline:inputsCotizacion[index].fechaEntrada[1] && "1px solid #eb1a1a"}} onChange={(e)=>updateValueInputCotizacion(index, "fechaEntrada", e.target.value)} placeholder="dia/mes/año"/>
                            </Cuadro>
                            <Cuadro tarifas={true} style={{position:"relative"}}>
                                <MsgValidacion display={inputsCotizacion[index].fechaSalida[1]}>
                                    <p>{inputsCotizacion[index].fechaSalida[1]}</p>
                                </MsgValidacion>
                                <input type="date" style={{outline:inputsCotizacion[index].fechaSalida[1] && "1px solid #eb1a1a"}} onChange={(e)=>updateValueInputCotizacion(index, "fechaSalida", e.target.value)} placeholder="dia/mes/año"/>
                            </Cuadro>
                            <Cuadro tarifas={true} style={{position:"relative"}}>
                                <MsgValidacion display={inputsCotizacion[index].rangoPersonas[1]}>
                                    <p>{inputsCotizacion[index].rangoPersonas[1]}</p>
                                </MsgValidacion>
                                { indexOferta !== undefined ? 
                                    <select defaultValue="0" style={{outline:inputsCotizacion[index].rangoPersonas[1] && "1px solid #eb1a1a"}} onChange={(e)=>updateValueInputCotizacion(index, "rangoPersonas", e.target.value)}>
                                        <option disabled value="0">Sin seleccionar</option>
                                        {   tarifas[indexOferta].rangos.map((rango)=>(
                                            <option key={rango.rangoMin+rango.rangoMax}>{rango.rangoMin}-{rango.rangoMax}</option>
                                        ))}                                   
                                    </select>
                                    : <p>Elige un codigo de oferta</p>
                                }
                                { index > 0 &&
                                    <BtnQuitar onClick={()=>delCotizacionForm(index)}>
                                        <img src={quitar}/>
                                    </BtnQuitar>
                                }
                            </Cuadro>
                        </BottomCuadroContenedor>
                    ))}
                   <TopCuadroContenedor  tarifas={true}>
                        <Cuadro tarifas={true}><p>Total</p></Cuadro>
                        <Cuadro tarifas={true}><p>USD $ {total ? total : "0"}</p></Cuadro>
                   </TopCuadroContenedor>
                </CuadroCont>
            </CuadroContenedor>
       </TarifasContenedor>
       : <div><p>Este hotel no tiene tarifas y ofertas</p></div>
       }
       </div>
    )
}