import styled from "styled-components";

const pc = window.screen.width > 529 ? true : false;

export const Formulario = styled.form`
    display: flex;
    margin-bottom: ${pc ? "1vw" : "3.5vw"};
    position: relative;

    & input {
        padding: ${pc ? "0.3vw" : "1.3vw"};
        margin-bottom: ${pc ? "0.5vw" : "2vw"};
        font-size: ${pc && "0.8vw"}
    }
    & input:focus {
        outline: none;
    }

    & select {
        margin-bottom: ${pc && "0.5vw"};
        font-size: ${pc && "0.9vw"};
    }
    & select:focus {
        outline: none;
    }
`;

export const TopFormulario = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: ${pc ? "0.5vw" : "2.5vw"};
    flex-direction: column;

    & span {
        display: ${props => props.display ? "block" : "none"};
        padding: ${pc ? "0.5vw" : "1vw"};
        background-color: #eb1a1a;
        color: #fff;
        border-radius: 1vw;
        text-align: center;
        width: 90%;
    }

    & h2 {
        font-size: ${pc ? "1.5vw!important" : "4.6vw!important"};
        margin-top: 0.5vw;
    }
`;

export const FormularioCont = styled.div`
    display: flex;
    flex-direction: ${props => props.hoteles ? "row" : "column"};
    justify-content: center;
    align-items: ${props => props.hoteles ? "" : "center"};
    padding: ${pc ? "0.3vw" : "2vw"};
    width: ${pc ? "40vw" : "85vw"};
    border-radius: ${pc ? "2vw" : "4vw"};
    color: #fff;
    background-color: #004aad;

    & textarea {
        margin-bottom: ${pc ? "1vw" : "5vw"} ;
    }
`;

export const Columna = styled.div`
    display: flex;
    flex-direction: column;
    width: ${props => props.hoteles ? "" : "80%"};

    & label {
        font-size: ${pc && "1vw"};
    }
`;

export const BottomReserva = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const BtnAgregar = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #838383;
    padding: ${pc ? "0.3vw" : "1.2vw"};
    border-radius: ${pc ? "0.5vw" : "2vw"};
    margin-left: ${props => props.marginLeft};
    cursor:pointer;

    & img {
        width: ${pc ? "2vw" : "5vw"};
    }
    & p {
        font-size: ${pc ? "1vw" : "3.5vw"};
        color: #fff;
    }
`;

export const BtnCerrar = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    width: ${pc ? "2.15vw" : "6.5vw"};
    margin-right: 1.5vw;
    left: ${props => !props.right && (pc == true ? "1vw" : "2.5vw")};
    right: ${props => props.right && pc ? "-0.7vw" : "1vw"} ;
    justify-content: center;
    height: ${pc ? "2.15vw" : "7vw"};
    transform: rotate(${props => props.rotar});
    transition: transform 0.3s;
    cursor: pointer;

    & img {
        width: 100%;
    }
`;

export const DivInput = styled.div`
    display: flex;
    margin: ${pc ? "0 0" : "1vw 0"};
    justify-content: flex-start;
    width: 100%;
    justify-content: center;    
    padding: 1vw 0;
    outline: none;

    & p {
        display: flex;
        align-items: center;
        justify-content: center;
        color:#393939;
        font-size: ${pc ? "1.2vw" : "3.5vw"};
        font-weight: 600;
        width: ${pc ? "10%" : "40%"};
        margin-right: 3vw;
        text-align: center;
    }
    & b {
        display:flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #666666;
        border-radius: ${pc ? "0.5vw" : "1vw"};
        padding: ${pc ? "0.7vw" : "0 2vw"};
        height: ${pc ? "auto" : "8vw"};
        color:#409008;
        font-weight: 600;
        font-size: ${pc && "0.9vw"};
    }
    & input {
        width: ${pc ? "20%" : "40%"};
        padding: 0.5vw;
    }
`;

export const BtnEnviar = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1.5vw;
    width: ${pc ? "25vw" : "40vw"};
    margin: 0 auto;
    margin-top: ${pc ? "1px" : "3vw"};
    border: none;
    margin-bottom: 1px;
    cursor: pointer;
    padding: ${pc ? "0.7vw" : "1.5vw"};
    box-shadow: 0px 0px 3.5px 1px #818181;

    & p {
        color:#004aad;
    }
`;

export const BtnEliminar = styled.div`
     position: absolute;
    display: flex;
    padding: 0.5vw;
    /* background-color: #fff; */
    width: 7vw;
    bottom: 2.5vw;
    right: 0.5vw;
    & img {
        width: 100%;
    }
`;

export const SpanValidacion = styled.span`
    text-align: ${props => props.msg ? "right" : "left"};
    width: ${props => props.msg && "65vw"};
    color: ${props => props.error ? "#26AC20" : "#eb1a1a"};
    display: block;
    font-size: ${pc ? "1vw" : "3.5vw"};
`;

export const Input = styled.input`
    border: ${props => props.error && (pc ? "0.15vw solid #eb1a1a" : "0.58vw solid #eb1a1a")};
`;

export const Select = styled.select`
    border: ${props => props.error && (pc ? "0.15vw solid #eb1a1a" : "0.58vw solid #eb1a1a")};
`;

