import "../styles/pantallaCarga.css";
import Logo from  "../images/logo22.webp";
import Cargando from "../images/cargando.gif";

export default function pantallaCarga (){
    return (
        <div className="cargaCont">
            <div className="LogoPantallaCarga">
                <img alt="Logo Turis Freelance" src={Logo}/>
            </div>
            <img src={Cargando}/>
        </div>
    )
}