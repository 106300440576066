import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import perfilHotelContext from "../../../contexts/perfilHotelContext";
import sessionContext from "../../../contexts/sessionContext";
import styled from "styled-components";
import { reservarHotel } from "../../../config/auth";
import CargandoCont from "../../utils/cargando";
import { validarFecha, calcularTotal, restaFechas, cambiarFechaInput, cambiarFecha, isNum } from "../../utils/validar";
import { Formulario, TopFormulario, FormularioCont, Columna, BottomReserva, BtnAgregar, BtnCerrar, DivInput, BtnEnviar, BtnEliminar, Input, Select, SpanValidacion } from "../../utils/formulario";

//imagenes 
import flecha from "../../../images/arrow.png";
import quitar from "../../../images/quitar.png";
import mas from "../../../images/mas.png";
import cargandoImg from "../../../images/cargando.gif";

const pc = window.screen.width > 529 ? true : false;

const ReservaContenedor = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: ${pc && "17vw"};
    padding-bottom: 5vw;

    & h1 {
        margin-bottom: ${pc ? "0" : "2vw"};
        font-weight: 600;
        font-size: ${pc ? "2.1vw" : "7.5vw"};
    }
`;

const TopReserva = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: ${pc ? "0.1vw" : "1vw"};
    margin-bottom: ${pc ? "1.5vw" : "3vw"};

    & p {
        color: #2E2E2E;
        font-size: ${pc ? "1.4vw" : "5.4vw"};
        font-weight: 600;
        margin-right: 2vw;
    }
    & input {
        background-color: #fff;
        width: ${pc ? "40%" : "60%"};
        border: ${pc ? "0.1vw" : "0.5vw"} solid #A4A4A4;
        border-radius: ${pc ? "0.5vw" : "1vw"};
        padding: ${pc ? "0.5vw" : "1vw"};
        font-size: ${pc && "0.9vw"};
    }

    & input:focus {
        outline: none;
    }
`;

const BtnToPoliticas = styled.span`
    color: #fff;
    font-weight: 500;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
`;
const CheckboxCont = styled.div`
    display: flex;
    align-items: center;
    width: ${pc ? "80%" : "100%"};
    margin-bottom: 2vw;
    
    & p {
        font-size: ${pc ? "1.2vw" : "3vw"};
        margin-left: ${!pc && "2vw"};
        font-weight: 300;
    }

    & input {
        width: ${pc ? "10%" : "15%"};
        height: ${!pc && "4.5vw"};
        margin: 0!important;
        padding: 0!important;
        margin-right: 1vw!important;
    }
`;

export default function Reserva ({tarifas, id, name}){

    const navigate = useNavigate();
    const { session } = useContext(sessionContext);

    const edades = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1];
    let inputsValuesFormDefault = {fechaEntrada:[""], cantidadAdultos:[""], codigoTarifa:[""], fechaSalida:[""], niños:[""], edades:[], categoriaHabitacion:[""], nombresAdultos:[], observaciones:[""]};

    const search = useLocation().search;
    const { setPerfil, perfil } = useContext(perfilHotelContext);
    let [modificar, setModificar] = useState(false); 

    let [categoriaHabitacion, setCategoriaHabitacion] = useState(0);

    let [formularios, setFormularios] = useState([{id:0, visible:true, msgValidacion:inputsValuesFormDefault}]);

    let [titular, setTitular] = useState("");
    let [checkbox, setCheckbox] = useState(false);
    let [totalUs, setTotalUs] = useState(0);
    let [comisionUs, setComisionUs] = useState(0);
    let [msgVal, setMsgVal] = useState({titular:"", checkbox:"", totalUs:"", comisionUs:"", msg:""});

    let [cargando, setCargando] = useState(false);

    function toggleShowFormulario(e){
        let newForm = JSON.parse(JSON.stringify(formularios));
        newForm[e].visible = !newForm[e].visible;
        setFormularios(newForm);
    }

    function addFormulario (){
        const newForm = {id:formularios.length+1, visible:false, msgValidacion:inputsValuesFormDefault};   
        setFormularios([...formularios, newForm]);
    }

    function delFormulario (e){
        let newForm = JSON.parse(JSON.stringify(formularios));
        newForm.splice(e, 1);
        setFormularios(JSON.parse(JSON.stringify(newForm)));
    }

    function validarForm (){
        let result = true;
        const newForm = formularios;

        for( let i = 0; i < newForm.length; i++) {

            const val = newForm[i].msgValidacion;

            let claves = Object.keys(val);
            claves.map((clave, index)=>{
                if (clave != "edades" && clave != "nombresAdultos") {
                    val[clave][1] = "";
                } else {
                    val[clave].map((value, index)=>{
                        val[clave][index][1] = "";
                    })
                }    
            });

            //VALIDACION FECHA DE ENTRADA
            if (val.fechaEntrada[0].length > 0) {
                let valFecha = validarFecha(val.fechaEntrada[0]);
                if (valFecha !== true){
                    if (modificar) {
                        if (valFecha != "El dia que ingresaste ya paso." && valFecha != "El mes que ingresaste ya paso.") {
                            val.fechaEntrada[1] = valFecha;
                            result = false;
                        }
                    } else {
                        val.fechaEntrada[1] = valFecha;
                        result = false;
                    }
                   
                }
            } else {
                val.fechaEntrada[1] = "Rellena este campo.";
                result = false;
            }

            //VALIDACION CANTIDAD DE ADULTOS
            if (val.cantidadAdultos[0]) {
                if(val.cantidadAdultos[0] > 0){
                    if (isNum(val.cantidadAdultos[0])) {
                        if (val.cantidadAdultos[0] > 20) {
                            val.cantidadAdultos[1] = "Maximo 20 adultos.";
                            result = false;
                        }
                    } else {
                        val.cantidadAdultos[1] = "Solo se admiten numeros."
                        result = false;
                    }
                } else{
                    result = false;
                    val.cantidadAdultos[1] = "Minimo un adulto.";
                }
            } else {
                val.cantidadAdultos[1] = "Rellena este campo.";
                result = false;
            }

            //VALIDACION CODIGO TARIFA
            if (val.codigoTarifa[0].length > 0) {
                if (val.codigoTarifa[0].length < 50 && val.codigoTarifa[0].length > 0) {
                    if (val.codigoTarifa[0] != "DEFAULT") {
                        
                    } else {
                        val.codigoTarifa[1] = "Selecciona una opción.";
                        result = false;
                    }
                } else {
                    val.codigoTarifa[1] = "Maximo 50 caracteres.";
                    result = false;
                }
            } else {
                if (i < 1) {
                    val.codigoTarifa[1] = "Rellena este campo";
                    result = false;
                }
            }

            //VALIDACION FECHA DE SALIDA
            if (val.fechaSalida[0].length > 0) {
                let valFecha = validarFecha(val.fechaSalida[0]);
                if (valFecha == true) {
                    if (validarFecha(val.fechaEntrada[0])) {
                        if (restaFechas(val.fechaEntrada[0], val.fechaSalida[0]) == 0) {
                            result = false;
                            val.fechaSalida[1] = "Reserva al menos una noche.";
                            val.fechaEntrada[1] = "Reserva al menos una noche.";
                        } else if (restaFechas(val.fechaEntrada[0], val.fechaSalida[0]) < 0){
                            result = false;
                            val.fechaSalida[1] = "La fecha de salida tiene que ser despues de la fecha de entrada";
                        }

                       /*  tarifas.map((value, index)=>{
                            if (val.codigoTarifa[0] == value.codigoOferta) {
                                if (restaFechas(val.fechaEntrada[0], val.fechaSalida[0]) < tarifas[index].minimoNoches) {
                                    result = false;
                                    val.fechaSalida[1] = "La tarifa que escogiste son minimo "+tarifas[index].minimoNoches+" noches";
                                }
                            }
                        }); */
                    }
                } else {
                    if (valFecha !== true){
                        if (modificar) {
                            if (valFecha != "El dia que ingresaste ya paso." && valFecha != "El mes que ingresaste ya paso.") {
                                val.fechaSalida[1] = valFecha;
                                result = false;
                            }
                        } else {
                            val.fechaSalida[1] = valFecha;
                            result = false;
                        }
                       
                    }
                }
            } else {
                val.fechaSalida[1] = "Rellena este campo";
                result = false;
            }

            //VALIDACION NIÑOS
            if (val.niños[0].length > 0) {
                if (val.niños[0] < 21) {
                    if (isNum(val.niños[0])) {
                    } else {
                        val.niños[1] = "Solo se admiten numeros. (sin puntos, comas, ni simbolos)";
                        result = false;
                    }
                } else{
                    result = false;
                    val.niños[1] = "Maximo 20 niños";
                }
            } else {
                result = false;
                val.niños[1] = "Rellena este campo.";
            }

            //VALIDACION EDADES
            for (let indexEdades = 0; indexEdades < val.edades.length; indexEdades++) {
                if (val.edades[indexEdades][0].length > 0) {
                    if (isNum(val.edades[indexEdades][0])) {
                        if (val.edades[indexEdades][0] < 1 || val.edades[indexEdades][0] > 17) {
                            val.edades[indexEdades][1] = "Hasta los 17 años.";
                            result = false;
                        }
                    } else {
                        val.edades[indexEdades][1] = "Solo se admiten numeros. (sin puntos, comas, ni simbolos)";
                        result = false;
                    }
                    
                } else {
                    val.edades[indexEdades][1] = "Rellena este campo";
                    result = false;
                }
            }

            //VALIDACION CATEGORIA DE HABITACION
            if (val.categoriaHabitacion[0].length > 0) {
                if (val.categoriaHabitacion[0].length < 50 && val.categoriaHabitacion[0].length > 0) {
                    if (val.categoriaHabitacion[0] != "DEFAULT") {
                        
                    } else {
                        val.categoriaHabitacion[1] = "Selecciona una opción.";
                        result = false;
                    }
                } else {
                    val.categoriaHabitacion[1] = "Maximo 50 caracteres.";
                    result = false;
                }
            } else {
                val.categoriaHabitacion[1] = "Rellena este campo";
                result = false;
            }

            //VALIDACION NOMBRES DE ADULTOS
            for (let indexAdultos = 0; indexAdultos < val.nombresAdultos.length; indexAdultos++) {  
                if (val.nombresAdultos[indexAdultos][0].length > 0) {
                    if (val.nombresAdultos[indexAdultos][0].length < 4) {
                        val.nombresAdultos[indexAdultos][1] = "Minimo 4 caracteres.";
                        result = false;
                    } if (val.nombresAdultos[indexAdultos][0].length > 90){
                        val.nombresAdultos[indexAdultos][1] = "Maximo 90 caracteres.";
                        result = false;
                    }
                } else {
                    val.nombresAdultos[indexAdultos][1] = "Rellena este campo";
                    result = false;
                }
            }

            //VALIDACION CATEGORIA DE HABITACION
            if (val.observaciones[0].length > 0) {
                if (val.observaciones[0].length > 600) {
                    val.observaciones[1] = "Maximo 500 carateres";
                    result = false;
                }
            }
            
            newForm[i].msgValidacion = val;
        }
        setFormularios(newForm);


        return result;
    }

    async function validarYEnviar (){
        setCargando(true);
        const result = validarForm();
        let resultForm = true;
        const newMsgVal = {titular:"", msg:"", checkbox:"", comisionUs:"", totalUs:""};

        if (!result) {
            newMsgVal.msg = "Los formularios de las habitaciones contienen errores.";
        }

        if (titular.length > 0) {
            if (titular.length > 3) {
                if (titular.length < 91) {
                } else {
                    newMsgVal.titular = "Maximo 90 caracteres.";
                    resultForm = false;
                }
            } else {
                newMsgVal.titular = "Minimo 4 caracteres.";
                resultForm = false;
            } 
        } else {
            resultForm = false;
            newMsgVal.msg = "Ingresa el nombre del titular de la reserva.";
            newMsgVal.titular = "Rellena este campo.";
        }

        if (!checkbox) {
            newMsgVal.checkbox = "Confirma que estas de acuerdo.";
            resultForm = false;
        }

        if (resultForm && result) {
            let form = [];
            formularios.map((value)=>form.push(value.msgValidacion));
            const reservaResult = await reservarHotel({form:form, id:modificar ? perfil.reserva.id : undefined, hotel: name, transferenciaVoucher:"none", modificar:modificar ? true : undefined, hotelId:id, campos:{titular:titular, comisionUs:comisionUs, totalUs:totalUs}});
            if (reservaResult !== undefined) {
                if (reservaResult.result === true) {
                    newMsgVal.msg = modificar ? "Reserva modificada correctamente" : "Reserva enviada correctamente";
                    setTimeout(()=>{
                        navigate("/hotel/"+ name);
                    },4070);
                    setTimeout(()=>{
                        if (modificar) {
                            if (session.admin) {
                                navigate("/admin?tab=solicitudes=reservasHoteles");
                            } else {
                                navigate("/plataforma/hoteles?tab=mis-solicitudes");
                            }
                        } else {
                            navigate("/hotel/"+name+"?tab=solicitar-reserva");
                        }
                    }, 4080);
                } else {
                    if (reservaResult.result === false){
                        newMsgVal.msg = "Sucedio un error, Disculpanos";
                    } else {
                        newMsgVal.msg = reservaResult.result;
                    }
                }
            }
        }

        setMsgVal(newMsgVal);
        setCargando(false);
    }


    function updateValueInput (index, tipo, valor, indexNombreAdulto){
        let newValuesInputs = JSON.parse(JSON.stringify(formularios));
        if (tipo == "nombresAdultos" || tipo == "edades") {
            newValuesInputs[index].msgValidacion[tipo][indexNombreAdulto][0] = valor;
        } else if (tipo == "niños" || tipo == "cantidadAdultos"){
            newValuesInputs[index].msgValidacion[tipo][0] = valor;
            const tipo1 = tipo == "niños" ? "edades" : "nombresAdultos";
            newValuesInputs[index].msgValidacion[tipo][1] = "";
            if (isNum(valor)) {
                if (valor <= 20) {
                    newValuesInputs[index].msgValidacion[tipo1] = [];
                    for(let i = 0; i < valor; i++){
                        if (formularios[index].msgValidacion[tipo1][i]) {
                            if (formularios[index].msgValidacion[tipo1][i][0]) {
                                newValuesInputs[index].msgValidacion[tipo1].push(formularios[index].msgValidacion[tipo1][i]);
                            } else {
                                newValuesInputs[index].msgValidacion[tipo1].push(["", ""]);
                            }
                        } else {
                            newValuesInputs[index].msgValidacion[tipo1].push(["", ""]);
                        }
                    }
                } else {
                    newValuesInputs[index].msgValidacion[tipo][1] = "Maximo 20 " + (tipo == "niños" ? "niños" : "adultos");
                }
            } else {
                newValuesInputs[index].msgValidacion[tipo][1] = "Solo se admiten numeros.";
            }
        } else if (tipo == "fechaSalida" || tipo == "fechaEntrada"){
            const newFecha = cambiarFecha(valor);
            newValuesInputs[index].msgValidacion[tipo][0] = newFecha;
        } else {
            newValuesInputs[index].msgValidacion[tipo][0] = valor;
            if (tipo == "codigoTarifa") {
                const num = tarifas.findIndex(element => element.codigoOferta == valor);
                if (num > -1) {
                    setCategoriaHabitacion(num);
                }
            }
       }

       setFormularios(newValuesInputs);
    }

    

    useEffect(()=>{
        if (calcularTotal(formularios, tarifas) != 0) {        
            setTotalUs(calcularTotal(formularios, tarifas));
            setComisionUs(calcularTotal(formularios, tarifas)*8/100);
        } else {
            if (totalUs > 0 || comisionUs > 0){
                setTotalUs(0);
                setComisionUs(0);
            }
        }

    },[formularios]);


    useEffect(()=>{
        const newForm = formularios;
        if (perfil.reserva && search == "?tab=modificar-reserva") {
            setModificar(true);
            setTitular(perfil.reserva.campos.titular);
            setTotalUs(perfil.reserva.campos.totalUs);
            setComisionUs(perfil.reserva.campos.comisionUs);
            let arr = [];
            perfil.reserva.form.map((val, index)=>{
                if (index > 0) {
                    newForm.push({visible:false, id:index, msgValidacion:inputsValuesFormDefault});

                    for (let i = 0; i < perfil.reserva.form[index].niños; i++){
                        newForm[index].msgValidacion.edades.push(["", ""]);
                    };
    
                    for (let i = 0; i < perfil.reserva.form[index].cantidadAdultos; i++){
                        newForm[index].msgValidacion.nombresAdultos.push(["", ""]);
                    };
                };
            });

            newForm.map((value, indexForm)=>{
                newForm[indexForm].msgValidacion.fechaEntrada[0] = perfil.reserva.form[indexForm].fechaEntrada;
                newForm[indexForm].msgValidacion.cantidadAdultos[0] = perfil.reserva.form[indexForm].cantidadAdultos;
                newForm[indexForm].msgValidacion.codigoTarifa[0] = perfil.reserva.form[indexForm].codigoTarifa;
                newForm[indexForm].msgValidacion.fechaSalida[0] = perfil.reserva.form[indexForm].fechaSalida;
                newForm[indexForm].msgValidacion.niños[0] = perfil.reserva.form[indexForm].niños;
                perfil.reserva.form[indexForm].edades.map((edad, indexEdad)=>{
                    newForm[indexForm].msgValidacion.edades[indexEdad] = [edad, ""];
                });
                newForm[indexForm].msgValidacion.categoriaHabitacion[0] = perfil.reserva.form[indexForm].categoriaHabitacion;
                perfil.reserva.form[indexForm].nombresAdultos.map((nombre, indexAdulto)=>{
                    newForm[indexForm].msgValidacion.nombresAdultos[indexAdulto] = [nombre, ""];
                });
                newForm[indexForm].msgValidacion.observaciones[0] = perfil.reserva.form[indexForm].observaciones;
            });

            setFormularios(newForm);
        } else {
            if (search == "?tab=modificar-reserva"){
                navigate("/plataforma/hoteles?tab=mis-solicitudes");
            }
        }

        return ()=>{
            setPerfil({name:perfil.name, imgUrl:perfil.imgUrl});
        }
    },[]);

    return (
        <div>
            { tarifas.length > 0 ?
                <ReservaContenedor>
                <h1>{modificar ? "Modifica tu reserva" : "Solicita una Reserva"}</h1>
                <SpanValidacion style={{marginLeft:pc ? "0" : "20%"}}>{msgVal.titular}</SpanValidacion>
                <TopReserva>
                    <p>Titular:</p>
                    <input defaultValue={titular} style={{border:msgVal.titular ? pc ? "0.15vw solid #eb1a1a" : "0.5vw solid #eb1a1a" : (pc ? "0.15vw solid gray" : "0.4vw solid gray")}} onChange={(e)=>setTitular(e.target.value)} placeholder="Nombre del cliente"/>
                </TopReserva>
                { formularios.map((value, e)=>(
                <Formulario key={value.id}>
                    <FormularioCont hoteles={true}>
                        <BtnCerrar rotar={formularios[e].visible ? "0deg" : "90deg"} onClick={()=>toggleShowFormulario(e)}>
                            <img src={flecha}/>
                        </BtnCerrar>
        
                        {!formularios[e].visible && <span style={{fontSize:pc ? "1.3vw" : "4.5vw"}} onClick={()=>toggleShowFormulario(e)}>Habitación {e+1}</span>}
        
                        { e > 0 && 
                            <BtnCerrar right={true} onClick={()=>delFormulario(e)}>
                                <img src={quitar}/>
                            </BtnCerrar>
                        }
        
                        <div style={{display:formularios[e].visible ? "block" : "none", width:pc ? "80%" : "70%"}}>
                            <TopFormulario onClick={()=>toggleShowFormulario(e)}>
                                <h2>Habitación {e+1}</h2>
                                {/* <span>{value.msgValidacion}</span> */}
                            </TopFormulario>
                            <Columna hoteles={true}>
                                <label>Fecha de entrada</label>
                                <SpanValidacion>{value.msgValidacion.fechaEntrada[1]}</SpanValidacion>
                                <Input type="date" defaultValue={modificar ? cambiarFechaInput(formularios[e].msgValidacion.fechaEntrada[0]) : ""} error={value.msgValidacion.fechaEntrada[1]} onChange={(valor)=>updateValueInput(e, "fechaEntrada", valor.target.value)} placeholder="dia/mes/año"/>
                                <label>Cantidad de adultos</label>
                                <SpanValidacion >{value.msgValidacion.cantidadAdultos[1]}</SpanValidacion>
                                { modificar ? 
                                <Select defaultValue="DEFAULT" error={value.msgValidacion.cantidadAdultos[1]} onChange={(valor)=>updateValueInput(e, "cantidadAdultos", valor.target.value)}>
                                    <option disabled value="DEFAULT">{formularios[e].msgValidacion.cantidadAdultos[0]}</option>
                                    { edades.map((val, index)=>(
                                            <option key={index}>{index+1}</option>
                                        ))
                                    }
                                </Select>
                                :
                                <Select defaultValue="DEFAULT" error={value.msgValidacion.cantidadAdultos[1]} onChange={(valor)=>updateValueInput(e, "cantidadAdultos", valor.target.value)}>
                                    <option disabled value="DEFAULT">Cantidad de adultos</option>
                                    { edades.map((val, index)=>(
                                            <option key={index}>{index+1}</option>
                                        ))
                                    }
                                </Select>
                                }
                                { formularios[e].msgValidacion.nombresAdultos.map((valueNombreAdulto, indexNombreAdulto)=>(
                                     <Columna hoteles={true} style={{position:"relative"}} key={indexNombreAdulto+1}>
                                        <label>Nombre de adulto {indexNombreAdulto+1}</label>
                                        <SpanValidacion >{value.msgValidacion.nombresAdultos[indexNombreAdulto][1]}</SpanValidacion>
                                        {/* { indexNombreAdulto > 0 &&
                                            <BtnEliminar onClick={()=>delNombreAdulto(e, indexNombreAdulto, "nombresAdultos")}>
                                                <img src={require("../../../images/quitar1.png")}/>
                                            </BtnEliminar>
                                        } */}
                                        <Input defaultValue={modificar ? formularios[e].msgValidacion.nombresAdultos[indexNombreAdulto][0] : ""} error={value.msgValidacion.nombresAdultos[indexNombreAdulto][1]} onChange={(valor)=>updateValueInput(e, "nombresAdultos", valor.target.value, indexNombreAdulto)}/>
                                     </Columna>
                                ))}
                                 {/* <BtnAgregar marginLeft="auto" onClick={()=>addNombreAdulto(e, "nombresAdultos")}>
                                    <img src={require("../../../images/mas.png")}/>
                                    <p>Agregar</p>
                                </BtnAgregar> */}
                                <label>Codigo de tarifa</label>
                                <SpanValidacion>{value.msgValidacion.codigoTarifa[1]}</SpanValidacion>
                                { modificar ? perfil.reserva.estado == "Pendiente" ?
                                    <Select defaultValue={value.msgValidacion.codigoTarifa[0]} error={value.msgValidacion.codigoTarifa[1]} onChange={(valor)=>updateValueInput(e, "codigoTarifa", valor.target.value)}>
                                        { tarifas && tarifas.map((tarifa)=>(
                                            <option value={tarifa.codigoOferta} key={tarifa.codigoOferta}>{tarifa.codigoOferta}</option>
                                        ))}
                                    </Select>
                                    :
                                    <p style={{backgroundColor:"#fff", color:"#000", marginBottom:pc ? "0.7vw" : "2vw"}}>{value.msgValidacion.codigoTarifa[0]}</p>
                                    :
                                    <Select defaultValue="DEFAULT" error={value.msgValidacion.codigoTarifa[1]} onChange={(valor)=>updateValueInput(e, "codigoTarifa", valor.target.value)}>
                                        <option disabled value="DEFAULT">Codigo Tarifa</option>
                                        { tarifas && tarifas.map((value, index)=>(
                                            <option key={value.codigoOferta}>{value.codigoOferta}</option>
                                        ))}
                                    </Select>
                                }
                            </Columna>
                            <Columna hoteles={true}>
                                <label>Fecha de salida</label>
                                <SpanValidacion >{value.msgValidacion.fechaSalida[1]}</SpanValidacion>
                                <Input type="date" defaultValue={modificar ? cambiarFechaInput(formularios[e].msgValidacion.fechaSalida[0]) : ""} error={value.msgValidacion.fechaSalida[1]} onChange={(valor)=>updateValueInput(e, "fechaSalida", valor.target.value)} placeholder="dia/mes/año"/>
                                <label>Cantidad de niños</label>
                                <SpanValidacion>{value.msgValidacion.niños[1]}</SpanValidacion>
                                { modificar ?
                                    <Select defaultValue={formularios[e].msgValidacion.niños[0]} error={value.msgValidacion.niños[1]} onChange={(valor)=>updateValueInput(e, "niños", valor.target.value)}>
                                    <option defaultValue={formularios[e].msgValidacion.niños[0]}>{formularios[e].msgValidacion.niños[0]}</option>
                                        { edades.map((val, index)=>(
                                                <option key={index}>{index}</option>
                                            ))
                                        }
                                    </Select>
                                :
                                <Select defaultValue="DEFAULT" error={value.msgValidacion.niños[1]} onChange={(valor)=>updateValueInput(e, "niños", valor.target.value)}>
                                    <option disabled value="DEFAULT">Cantidad de niños</option>
                                    { edades.map((val, index)=>(
                                            <option key={index}>{index}</option>
                                        ))
                                    }
                                </Select>
                                }
                                { formularios[e].msgValidacion.edades.map((valueNiño, indexNiño)=>(
                                    <Columna hoteles={true} key={indexNiño} style={{position:"relative"}}>
                                        {/* { indexNiño > 0 &&
                                            <BtnEliminar onClick={()=>delNombreAdulto(e, indexNiño, "edades")}>
                                                <img src={require("../../../images/quitar1.png")}/>
                                            </BtnEliminar>
                                        } */}
                                        <label>Edad de Niño {indexNiño+1}</label>
                                        <SpanValidacion>{value.msgValidacion.edades[indexNiño][1]}</SpanValidacion>
                                        <Input defaultValue={modificar ? formularios[e].msgValidacion.edades[indexNiño][0] : ""} error={value.msgValidacion.edades[indexNiño][1]} onChange={(valor)=>updateValueInput(e, "edades", valor.target.value, indexNiño)}/>
                                    </Columna>
                                ))}
                                 {/* <BtnAgregar marginLeft="auto" onClick={()=>addNombreAdulto(e, "edades")}>
                                    <img src={require("../../../images/mas.png")}/>
                                    <p>Agregar</p>
                                </BtnAgregar> */}
                            </Columna>
                            <Columna hoteles={true}>
                                <label>Categoria de habitación</label>
                                <SpanValidacion>{value.msgValidacion.categoriaHabitacion[1]}</SpanValidacion>
                                { modificar ?
                                    <Select defaultValue={formularios[e].msgValidacion.categoriaHabitacion[0]} error={value.msgValidacion.categoriaHabitacion[1]} onChange={(valor)=>updateValueInput(e, "categoriaHabitacion", valor.target.value)}>
                                        <option value={formularios[e].msgValidacion.categoriaHabitacion[0]} >{formularios[e].msgValidacion.categoriaHabitacion[0]}</option>
                                        <option>{tarifas && tarifas[categoriaHabitacion].habitacion}</option>
                                    </Select>
                                    :
                                    <Select defaultValue="DEFAULT" error={value.msgValidacion.categoriaHabitacion[1]} onChange={(valor)=>updateValueInput(e, "categoriaHabitacion", valor.target.value)}>
                                        <option disabled value="DEFAULT">Categoria</option>
                                        <option>{tarifas && tarifas[categoriaHabitacion].habitacion}</option>
                                    </Select>   
                                }
                                <label>Observaciones</label>
                                <SpanValidacion>{value.msgValidacion.observaciones[1]}</SpanValidacion>
                                <textarea defaultValue={modificar ? formularios[e].msgValidacion.observaciones[0] : ""} style={{border:value.msgValidacion.observaciones[1] && "0.8vw solid #eb1a1a"}} onChange={(valor)=>updateValueInput(e, "observaciones", valor.target.value)}></textarea>
                                <SpanValidacion>{msgVal.checkbox}</SpanValidacion>
                                <CheckboxCont style={{display:"flex", alignItems:"center"}}>
                                    <Input style={{cursor:"pointer", width:pc && "8%"}} error={msgVal.checkbox} type="checkbox" onChange={(e)=>setCheckbox(e.target.checked)} />
                                    <p>He leido y acepto los <BtnToPoliticas onClick={()=>navigate("/terminos-y-condiciones")}>terminos y condiciones.</BtnToPoliticas></p>
                                </CheckboxCont>
                            </Columna>
                        </div>
                    </FormularioCont>
                </Formulario>
                ))}
        
                <BottomReserva>
                    <BtnAgregar style={{marginBottom:pc ? "0.5vw" : "3vw"}} onClick={()=>addFormulario()}>
                        <img src={mas}/>
                        <p>Agregar habitación</p>
                    </BtnAgregar>
                    <div style={{display:"flex", flexDirection:"column", alignItems:"center", width:"100%"}}>
                        <DivInput>
                            <p>Total</p>
                            <b>${totalUs} USD</b>
                        </DivInput>
                    </div>
                    <div style={{display:"flex", marginBottom:pc ? "1vw" : "5vw", flexDirection:"column", width:"100%", alignItems:"center",}}>
                        <DivInput>
                            <p>Comisión</p>
                            <b>${comisionUs} USD</b>
                        </DivInput>
                    </div>
                    <SpanValidacion style={{marginBottom:pc && "1vw", textAlign:"center"}} msg={true} error={msgVal.msg == "Reserva enviada correctamente" || msgVal.msg == "Reserva modificada correctamente" ? true : false}>{msgVal.msg}</SpanValidacion>
                    <BtnEnviar onClick={()=>!cargando && validarYEnviar()}>
                        { cargando ?
                            <CargandoCont>
                                <img src={cargandoImg} />
                            </CargandoCont>
                            : 
                            <p>Enviar</p>
                        }
                    </BtnEnviar>
                </BottomReserva>
            </ReservaContenedor>
            : <div><p style={{textAlign:"center"}}>No puedes reservar porque este hotel no tiene tarifas y ofertas</p></div>
              
            }
        </div>
    );
}