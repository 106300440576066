import react, { createContext, useEffect, useContext, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import {auth} from "../config/firebase";
import PantallaCarga from "../components/pantallaDeCarga";

const sessionContext = createContext({
    session: {session:false, user:undefined},
    setSession: ()=>{}
});

export default sessionContext;

export function ProviderSessionContext ({children}){

    let [session, setSession] = useState({session:undefined, user:undefined});

    useEffect(()=>{
        onAuthStateChanged(auth, (user)=>{
            // user.getIdToken()
            // .then((s)=>{
            //     console.log(s);
            // })
            if (user) {
                setSession(JSON.parse(JSON.stringify({session:true, admin:user.uid == "viqknVtNHzVNDKl8dZCMjiielij1" ? true : user.uid == "XoHbQbdBPycD7qcdNRidUGAtnWG3" ? true : user.uid == "EzeHrWEu4OQTdyBOoTgTsS3WHHL2" ? true : false, user:{name:user.displayName, uid:user.uid, imgPerfil:user.photoURL, email:user.email, telefono:user.phoneNumber}})));
            } else {
                setSession(JSON.parse(JSON.stringify({session:false, user:undefined})));
            }
        });
        
    },[])

    return <sessionContext.Provider value={{session, setSession}}>
        { session.session === undefined &&
            <PantallaCarga/>
        }
        {children}
    </sessionContext.Provider>
}