import { auth } from "./firebase";
import { signInWithEmailAndPassword, signOut, sendPasswordResetEmail } from "firebase/auth";
import { onAuthStateChanged } from "firebase/auth";
import url from "./url";
import axios from "axios"

async function getToken (){
    const user = auth.currentUser;
    let token = false;

    try {
        token = await user.getIdToken();
    } catch {
        token = false;
    }

    return token;
}

export async function iniciarSesion (email, pass) {
    let result = [false, undefined];
    try {
        await signInWithEmailAndPassword(auth, email, pass)
        .then((user)=>{
            result = [true, {name:user.user.displayName, uid:user.user.uid, admin:user.user.uid == "viqknVtNHzVNDKl8dZCMjiielij1" ? true : user.user.uid == "XoHbQbdBPycD7qcdNRidUGAtnWG3" ? true : false, imgPerfil:user.user.photoURL, email:user.user.email}];
        })
    } catch (err){
        result = [err.code, undefined];
    }

    return result;
}

export async function recuperarPass (email){
    let result = false;
    await sendPasswordResetEmail(auth, email)
    .then(()=>{
        result = true;
    })
    .catch((err)=>{
        result = err.code;
    })

    return result;
}

export async function cerrarSession (){
    await signOut(auth);
}

export async function actualizarPerfil (datos){
    let result = [];

    const newDatos = datos;
    newDatos.token = await getToken();

    await fetch(url+"/updatePerfil", {
        method:"post",
        headers:{
            "Content-Type": "application/json; charset=utf-8"
        },
        body: JSON.stringify(newDatos)
    })
    .then((res)=>{
        if (res.ok) {
            return res.json();
        }
    })
    .then((respuesta)=>{
        result = [respuesta.result !== true ? false : true, respuesta.result !== true ? respuesta.result : undefined];
    })

    return result;
}

export async function getInfoUser(uid){

    let result = "";
    let token = await getToken();

    if (token) {
        try {
            await fetch(url+"/getInfoUser", {
                method:"post",
                headers:{
                    "Content-Type": "application/json; charset=utf-8"
                },
                body:JSON.stringify({token:token, uid:uid})
            })
            .then((result)=>{
                if (result.ok) {
                    return result.json()
                }
            })
            .then((res)=>{
                result = res.result;
            });
        } catch {
            result = "Comprueba tu conexión a internet.";
        }
    }

    return result;
}

export async function comprobarHotelOVilla (username, tipo) {
    let result = "";
    const token = await getToken();

    try {
        await fetch(url+"/comprobarHotelOVilla", {
            method:"post",
            headers:{
                "Content-Type":"application/json; charseft=utf-8"
            },
            body: JSON.stringify({token:token, nombre:username, tipo:tipo})
        })
        .then((res)=>{
            if (res.ok) {
                return res.json();
            }
        })
        .then((datos)=>{
            result = datos.result;
        });
    } catch {
        result = false;
    }

    return result;
}

export async function reservarHotel (info) {

    let result = {result:"Sucedio un error, comprueba tu conexión a internet."};
    const datos = info;
    datos.token = await getToken();

    try {
        await fetch (url+"/reservarHotel",{
            method:"post",
            headers:{
                "Content-Type":"application/json; charseft=utf-8"
            },
            body:JSON.stringify(datos)
        })
        .then((res)=>{
            if (res.ok) {
                return res.json();
            }
        })
        .then((datos)=>{
            result = datos;
        });
    } catch (err) {
        result = {result:"Comprueba tu conexión a internet."};
    }

    return result;
}

export async function reservarVilla (info) {

    let result = {result:"Sucedio un error, comprueba tu conexión a internet."};
    const datos = info;
    datos.token = await getToken();
    console.log(datos);

    try {
        await fetch (url+"/reservarVilla",{
            method:"post",
            headers:{
                "Content-Type":"application/json; charseft=utf-8"
            },
            body:JSON.stringify(datos)
        })
        .then((res)=>{
            if (res.ok) {
                return res.json();
            }
        })
        .then((datos)=>{
            result = datos;
        });
    } catch (err) {
        result = {result:"Comprueba tu conexión a internet."};
    }

    return result;
}

export async function solicitarCotizacion (form, tipo, seguro){
    let result = "Error";
    const datos = {form:form};
    datos.token = await getToken();
    datos.tipo = tipo;
    if (seguro) {
        datos.seguro = seguro;
    }

    await axios.post(url+"/solicitarCotizacion", JSON.stringify(datos),{
        headers:{
            "Content-Type":"application/json; charseft=utf-8",
        }
    })
    .then((res)=>{
        result = res.data.result;
    })
    .catch(()=>{
        result = "Comprueba tu conexión a internet.";
    });

    return result;
};

export async function getSolicitudesCliente (datos){
    let result = [false, "error"];
    const token = await getToken();
    datos.token = token;
    if (token) {
        await axios.post(url+"/getSolicitudesCliente", JSON.stringify(datos),{
            headers:{
                "Content-Type":"application/json; charseft=utf-8"
            }
        })
        .then((res)=>{
            result = res.data.result;
        })
        .catch(()=>{
            result = [false, "Comprueba tu conexión a internet."];
        });
    } else {
        result = [false, "Cierra sesión, logueate y vuelve a intentarlo."];
    }

    return result;
}

export async function getOfertas (){
    let result = [false, "Error"];
    const token = await getToken();

    if (token) {
        try {
            await fetch(url+"/getOfertas",{
                method:"post",
                headers:{
                    "Content-Type":"application/json; chaseft=utf-8"
                },
                body:JSON.stringify({token:token})
            })
            .then((res)=>{
                if (res.ok) {
                    return res.json();
                }
            })
            .then((datos)=>{
                result = datos.result;
            })
        } catch {
            result = [false, "Comprueba tu conexión a internet."];
        }
    }

    return result;
}

export async function actualizarImgPerfil (img){
    let result = [false, "Error"];

    const token = await getToken();

    const formData = new FormData();
    formData.set("image", img);
    formData.set("token", token);
    
    try {
        if (token) {
            await fetch(url+"/actualizarImgPerfil", {
                method:"post",
                body:formData
            })
            .then((res)=>{
                if (res.ok) {
                    return res.json();
                }
            })
            .then((datos)=>{
                result = datos.result;
            });
        } else {
            result = [false, "Error, cierra sesión, logueate de nuevo y vuelve a intentarlo."];
        }
    } catch {
        result = [false, "Sucedio un error, Comprueba tu conexión a internet"];
    }

    return result;
}

export async function subirVoucher (archivo, id, tipo){
    let result = [false, "Error"];

    const token = await getToken();

    const formData = new FormData();
    formData.set("archivo", archivo);
    formData.set("id", id)
    formData.set("token", token);
    formData.set("tipo", tipo);

    try {
        if (token) {
            await fetch(url+"/subirVoucherSeguro", {
                method:"post",
                body:formData
            })
            .then((res)=>{
                if (res.ok) {
                    return res.json();
                }
            })
            .then((datos)=>{
                result = datos.result;
            });
        } else {
            result = [false, "Error, cierra sesión, logueate de nuevo y vuelve a intentarlo."];
        }
    } catch {
        result = [false, "Sucedio un error, Comprueba tu conexión a internet"];
    }

    console.log(result);

    return result;
}

export async function subirInfoArchivo (archivo, id, tipo, infoCotizacion, num){
    let result = [false, "Error"];

    const token = await getToken();

    const formData = new FormData();
    const datos = {id:id, tipo:tipo, token:token, infoCotizacion: infoCotizacion};
    if (archivo) {
        formData.set("archivo", archivo);
        formData.set("id", id);
        formData.set("tipo", tipo);
        formData.set("token", token);
        formData.set("num", num);
    }

    const configFetch = {
        method:"post",
        body: archivo ? formData : JSON.stringify(datos)        
    }
    
    if (!archivo) {
        configFetch.headers = {"Content-Type":"application/json; charseft=utf-8"};
    }

    try {
        if (token) {
            await fetch(url+(tipo == "seguros" ? "/subirDocumentoConfirmacion" : archivo ? "/subirInfoArchivo" : "/addInfoCotizacion"), configFetch)
            .then((res)=>{
                if (res.ok) {
                    return res.json();
                }
            })
            .then((datos)=>{
                result = datos.result;
            });
        } else {
            result = [false, "Error, cierra sesión, logueate de nuevo y vuelve a intentarlo."];
        }
    } catch {
        result = [false, "Sucedio un error, Comprueba tu conexión a internet"];
    };

    return result;
}

export async function delInfoArchivo (datos) {
    let result = "Error.";
    const token = await getToken();
    datos.token = token;

    try {
        if (token) {
            await axios.post(url+"/delInfoArchivo", JSON.stringify(datos), {
                headers:{
                    "Content-Type":"application/json; charseft=utf-8"
                }
            })
            .then((res)=>{
                if (res.data.result) {
                    result = res.data.result;
                }
            });
        } else {
            result = "Sucedio un error. Cierra sesión, logueate y vuelve a intentarlo.";
        }
    } catch (error) {
        result = "Sucedio un error. comprueba tu conexión a internet.";
    }

    return result;
}

export async function getSeguros(){
    let result = "Error";
    const token = await getToken();

    try {
        await axios.post(url+"/getSeguros", JSON.stringify({token:token}), {
            headers:{
                "Content-Type":"application/json; charseft=utf-8"
            }
        })
        .then((res)=>{
            result = res.data.result;
        })
    } catch {
        result = "Comprueba tu conexión a internet.";
    }

    return result;
}

export async function modificarSeguro(datos){
    let result = "Error";
    const token = await getToken();

    const formData = new FormData();
    formData.set("token", token);
    formData.set("seguro", datos.seguro);
    formData.set("archivo", datos.archivo);

    try {
        await axios.post(url+"/modificarSeguro", formData)
        .then((res)=>{
            result = res.data.result;
        })
    } catch {
        result = "Comprueba tu conexión a internet.";
    }

    return result;
}

export async function enviarFormContacto (form, imagen){
    let result = true;

    const formData = new FormData();
    if (imagen) {
        formData.append("cedula", imagen);
    }
    
    const newForm = JSON.parse(JSON.stringify(form));

    delete newForm.checkbox;
    formData.append("form", JSON.stringify(newForm));

    try {
        await axios.post(url+"/enviarFormContacto", formData)
        .then((res)=>{
            result = res.data.result;
        });
    } catch {
        result = "Sucedio un error, comprueba tu conexión a internet.";
    }

    return result;
}

export async function searchHotelOVilla (nombre, tipo){
    let result = [false, "Error"];
    const token = await getToken();

    try {
        await axios.post(url+"/buscarHotelOVilla", {nombre:nombre, token:token, tipo:tipo}, {
            headers:{
                "Content-Type":"application/json; charseft=utf-8"
            }
        })
        .then((res)=>{
            result = res.data.result;
        });
    } catch {
        result = [false, "Comprueba tu conexión a internet."];
    }

    return result;
}

// admin

export async function agregarOferta (img){
    let result = [false, "Error"];

    const token = await getToken();

    const formData = new FormData();
    formData.set("image", img);
    formData.set("token", token);

    try {
        if (token) {
            await fetch(url+"/agregarOferta", {
                method:"post",
                body:formData
            })
            .then((res)=>{
                if (res.ok) {
                    return res.json();
                }
            })
            .then((datos)=>{
                result = datos.result;
            });
        } else {
            result = [false, "Error, cierra sesión, logueate de nuevo y vuelve a intentarlo."];
        }
    } catch {
        result = [false, "Sucedio un error, Comprueba tu conexión a internet"];
    }

    return result;
};

export async function deleteItem (datos, item){
    let result = [false, "Error"];
    const token = await getToken();
    datos.token = token;

    if (item == "hotel" || item == "villa") {
        datos.tipo = item == "hotel" ? "hoteles" : "villas";
    }

    try {
        if (token) {
            await fetch(url+(item == "oferta" ? "/borrarOferta" : (item == "hotel" || item == "villa") ? "/borrarHotelOVilla" : "/"), {
                method:"delete",
                headers:{
                    "Content-Type":"application/json; charseft=utf-8"
                },
                body:JSON.stringify(datos)
            })
            .then((res)=>{
                if (res.ok) {
                    return res.json();
                }
            })
            .then((datos)=>{
                result = datos.result;
            });
        } else {
            result = [false, "Error, cierra sesión, logueate de nuevo y vuelve a intentarlo."];
        }
    } catch {
        result = [false, "Sucedio un error, Comprueba tu conexión a internet"];
    }

    return result;
}

export async function añadirHotel (datos, modificar){
    let result = "Sucedio un error inesperado..";
    const token = await getToken();

    const formData = new FormData();

    if (!modificar) {
        datos.imagenes.map((value)=>{
            formData.append("imagenes", value, value.name);
        });
    } else {
        if (datos.hotel.fotoPerfil) {
            formData.append("imagenes", datos.hotel.fotoPerfil[0], datos.hotel.fotoPerfil[0].name);
            delete datos.hotel.fotoPerfil;
        }
    }
    
    let newData = JSON.parse(JSON.stringify(datos.hotel));
    
    Object.keys(newData).map((value)=>{
        if (value != "id") {
            if (value != "ofertas") {
                newData[value] = newData[value][0];
            } else {
                if (!modificar) {
                    newData[value].map((value2, index2)=>{
                        value2.ofertas.map((value3, index3)=>{
                            Object.keys(value3.datos).map((campo)=>{
                                newData[value][index2].ofertas[index3].datos[campo] = newData[value][index2].ofertas[index3].datos[campo][0];
                            });
                            value3.datos.periodo = [newData[value][index2].desde[0], newData[value][index2].hasta[0]];
                        });
                    });
                }
            };
        }
    });

    formData.set("datos", JSON.stringify(newData));
    formData.set("token", token);
    if (modificar) {
        formData.set("modificar", true);
    }

    if (token) {
        try {
            await axios.post(url+"/agregarHotel", formData, {
                headers:{
                    "Content-Type": "multipart/form-data"
                }
            })
            .then((datos)=>{
                result = datos.data.result;
            });
        } catch {
            result = "Comprueba tu conexión a internet.";
        }
    } else {
        result = "Error, cierra sesión, logueate de nuevo y vuelve a intentarlo.";
    }

    return result;
}

export async function añadirVilla (datos, modificar){
    let result = "Error.";
    const token = await getToken();

    const formData = new FormData();

    if (!modificar) {
        datos.imagenes.map((value)=>{
            formData.append("imagenes", value, value.name);
        });
    } else {
        if (datos.villa.fotoPerfil) {
            formData.append("imagenes", datos.villa.fotoPerfil[0], datos.villa.fotoPerfil[0].name);
            delete datos.villa.fotoPerfil;
        }
    }

    const newData = JSON.parse(JSON.stringify(datos.villa));

    Object.keys(newData).map((campoVilla)=>{
        if (campoVilla != "id") {
            if (campoVilla != "ofertas") {
                newData[campoVilla] = newData[campoVilla][0];
            } else {
                newData[campoVilla].map((oferta, index)=>{
                    Object.keys(oferta).map((campoOferta)=>{
                        if (campoOferta != "rangos") {
                            newData[campoVilla][index][campoOferta] = newData[campoVilla][index][campoOferta][0];
                        } else {
                            newData[campoVilla][index][campoOferta].map((rango, indexRango)=>{
                                Object.keys(rango).map((campoRango)=>{
                                    if (campoRango != "rangos") {
                                        newData[campoVilla][index][campoOferta][indexRango][campoRango] = rango[campoRango][0];
                                    } else {
                                        if (!modificar) {
                                            rango.rangos.map((subRango, indexSubRango)=>{
                                                Object.keys(subRango).map((subCampo)=>{
                                                    newData[campoVilla][index][campoOferta][indexRango][campoRango][indexSubRango][subCampo] = subRango[subCampo][0];
                                                });
                                            });
                                        }
                                    }
                                });
                            });
                        }
                    });
                });
            };
        }
    });



    formData.set("datos", JSON.stringify(newData));
    formData.set("token", token);
    if (modificar) {
        formData.set("modificar", true);
    }

    if (token) {
        try {
            await axios.post(url+"/agregarVilla", formData, {
                headers:{
                    "Content-Type": "multipart/form-data"
                }
            })
            .then((datos)=>{
                result = datos.data.result;
            });
        } catch {
            result = "Comprueba tu conexión a internet.";
        }
    } else {
        result = "Error, cierra sesión, logueate de nuevo y vuelve a intentarlo.";
    }


    return result;
}

export async function getSolicitudes(datos1){
    let result = [false, "Error"];
    const token = await getToken();

    const datos = datos1;
    datos.token = token;

    try {
        if (token) {
            await axios.post(url+"/getSolicitudes", JSON.stringify(datos),{
                "headers":{
                    "Content-Type":"application/json; charseft=uft-8"
                }
            })    
            .then((datos)=>{
                result = datos.data.result;
            });
        } else {
            result = [false, "Error, cierra sesión, logueate de nuevo y vuelve a intentarlo."];
        }
    } catch {
        result = [false, "Sucedio un error, Comprueba tu conexión a internet"];
    }

    return result;
};

export async function confirmarSolicitud (data){
    let result = [false, "Error"];
    const token = await getToken();
    const datos = data;
    datos.token = token;

    await axios.post(url+"/confirmarSolicitud", JSON.stringify(datos), {
        headers:{
            "Content-Type":"application/json; charseft=utf-8"
        }
    })
    .then((res)=>{
        result = res.data.result;
    })
    .catch(()=>{
        result = "Comprueba tu conexión a internet.";
    })

    return result;
}

export async function addTotalSolicitud (data){
    let result = [false, "Error"];
    const token = await getToken();
    const datos = data;
    datos.token = token;

    await axios.post(url+"/addTotalSolicitud", JSON.stringify(datos), {
        headers:{
            "Content-Type":"application/json; charseft=utf-8"
        }
    })
    .then((res)=>{
        result = res.data.result;
    })
    .catch(()=>{
        result = [false, "Comprueba tu conexión a internet."];
    });

    return result;
}

export async function getUsers(datos1){
    let result = [false, "Error"];
    const token = await getToken();

    const datos = datos1 ? datos1 : {};
    datos.token = token;

    try {
        if (token) {
            await axios.post(url+"/getUsers", JSON.stringify(datos),{
                "headers":{
                    "Content-Type":"application/json; charseft=uft-8"
                }
            })    
            .then((datos)=>{
                result = datos.data.result;
            });
        } else {
            result = [false, "Error, cierra sesión, logueate de nuevo y vuelve a intentarlo."];
        }
    } catch {
        result = [false, "Sucedio un error, Comprueba tu conexión a internet."];
    }

    return result;
};

export async function addUser(datos1){
    let result = [false, "Error"];
    const token = await getToken();

    const datos = datos1;
    datos.token = token;

    try {
        if (token) {
            await axios.post(url+"/addUser", JSON.stringify(datos),{
                "headers":{
                    "Content-Type":"application/json; charseft=uft-8"
                }
            })    
            .then((datos)=>{
                result = datos.data.result;
            });
        } else {
            result = [false, "Error, cierra sesión, logueate de nuevo y vuelve a intentarlo."];
        }
    } catch {
        result = [false, "Sucedio un error, Comprueba tu conexión a internet"];
    }

    return result;
};

export async function toggleEstadoComision (tipo, valor, id){

    const token = await getToken();

    try {
        if (token) {
            return await axios.post(url+"/toggleEstadoComision", JSON.stringify({token: token, id:id, estadoComision:valor, tipo:tipo}), {
                headers:{
                    "Content-Type":"application/json; charseft=utf-8"
                }
            })
            .then((datos)=>{
                if (datos.data.result) {
                    return datos.data.result;
                }
            });
        } else {
            return "Error, cierra sesión, logueate de nuevo y vuelve a intentarlo."
        }
    } catch (error) {
        return "Sucedio un error.";
    }
}

export async function eliminarFotoPropiedad (idImg, idPropiedad, tipo){
    const token = await getToken();

    try {
        if (token) {
            return await axios.post(url+"/eliminarFotoPropiedad", JSON.stringify({token:token, tipo:tipo, idImg:idImg, idPropiedad:idPropiedad}), {
                headers:{
                    "Content-Type":"application/json; charseft=utf-8"
                }
            })
            .then((datos)=>{
                if (datos.data.result) {
                    return datos.data.result;
                }
            });
        } else {
            return "Error, cierra sesión, logueate de nuevo y vuelve a intentarlo.";
        }
    } catch {
        return "Sucedio un error al eliminar la foto.";
    }
}

export async function addFotoPropiedad (dataImg, idPropiedad, tipo){
    const token = await getToken();

    const formData = new FormData();
    formData.set("img", dataImg);
    formData.set("tipo", tipo);
    formData.set("token", token);
    formData.set("idPropiedad", idPropiedad);

    try {
        if (token) {
            return await axios.post(url+"/addFotoPropiedad", formData)
            .then((datos)=>{
                if (datos.data.result) {
                    return datos.data.result;
                }
            });
        } else {
            return [false, "Error, cierra sesión, logueate de nuevo y vuelve a intentarlo."];
        }
    } catch {
        return [false, "Sucedio un error."];
    }
}

export async function eliminarUserOsolicitud (datos){
    const token = await getToken();
    datos.token = token;

    try {
        if (token) {
            return await axios.post(url+"/eliminarUserOsolicitud", JSON.stringify(datos), {
                headers:{
                    "Content-Type":"application/json; charseft=utf-8"
                }
            })
            .then((datos)=>{
                if (datos.data.result) {
                   return datos.data.result; 
                }
            })
            .catch(()=>{
                return "Comprueba tu conexión a internet.";
            })
        } else {
            return "Ocurrio un error, Cierra sesión, logueate y vuelve a intentarlo."
        }
    } catch (error) {
        return "Comprueba tu conexión a internet."
    }
}

export async function searchTarifario (datos){
    const token = await getToken();
    datos.token = token;

    try {
        if (token) {
            return await axios.post(url+"/searchTarifario", JSON.stringify(datos), {
                headers:{
                    "Content-Type":"application/json; charseft=utf-8"
                }
            })
            .then((datos)=>{
                if (datos.data.result) {
                   return datos.data.result; 
                }
            })
            .catch(()=>{
                return "Comprueba tu conexión a internet.";
            })
        } else {
            return "Ocurrio un error, Cierra sesión, logueate y vuelve a intentarlo."
        }
    } catch (error) {
        return "Comprueba tu conexión a internet."
    }
}