import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getSolicitudesCliente, searchHotelOVilla } from "../../../config/auth";
import sesionContext from "../../../contexts/sessionContext";
import CargandoCont from "../../utils/cargando";
import styled from "styled-components";
import ContenedorOpcion from "../contenedorOpcion";
import {CuadroContenedor, TopCuadroContenedor, CuadroCont, Cuadro, BottomCuadroContenedor} from "../../utils/recuadro";

//imagenes
import hotelSinImg from "../../../images/hotelSinImg.png";
import buscar from "../../../images/search.png";
import cargandoImg from "../../../images/cargando.gif";
import quitar from "../../../images/quitar1.png";
import { validarFecha } from "../../utils/validar";

const pc = window.screen.width > 529 ? true : false;

const HotelesContenedor = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    padding-bottom: ${e => e.paddingBottom ? pc ? "0" : "10vw" : undefined};
`;

const BuscarContenedor = styled.form`
    display: flex;
    background-color: #E0E0E0;
    border-radius: 5vw;
    width: 85%;
    margin-top: 2vw;
    height: ${pc && "3vw"};

    & input {
        border: none;
        padding: ${!pc && "3vw"};
        padding-left: ${pc ? "1.3vw" : "3vw"};
        background-color: transparent;
        width: 90%;
    }
    & input:focus{
        outline: none;
    }
    & button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: ${pc ? "3.5vw" : "13%"};
        padding: ${pc ? "1vw" : "2.3vw"};
        background-color: #004aad;
        border-top-right-radius: 5vw;
        border-bottom-right-radius: 5vw;
        margin-left: auto;
        cursor: pointer;
        border: none;
    }
    & button img{
        width: 100%;
    }
`;

const HotelesCont = styled.div`
    margin-top:${pc ? "2vw" : "4.5vw"};
    display: flex;
    width: 100%;
    flex-wrap: wrap;
`;

const Hotel = styled.div`
    display: flex;
    margin: 0 1vw;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1vw;
    width: ${pc ? "10vw" : "30%"};
    margin-bottom: 2vw;
    cursor: pointer;

    & p {
        margin-top: 0.67vw;
        font-size: ${pc ? "1vw" : "2.75vw"};
        font-weight: 600;
        text-align: center;
    }
`;

const HotelImg = styled.div`
    width: ${pc ? "5vw" : "12vw"};
    height: ${pc ? "5vw" : "12vw"};
    background-image: url(${e => e.imagen ? e.imagen : hotelSinImg });
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 11vw;
`;
const HotelImgFondo = styled.div`
    width: ${pc ? "5vw" : "12vw"};
    height: ${pc ? "5vw" : "12vw"};
    background-image: url(${e => e.imagen ? e.imagen : hotelSinImg });
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 11vw;
`;


export default function TarifarioVillas (){

    const location = useLocation();
    const navigate = useNavigate()
    const {session} = useContext(sesionContext);

    let [hoteles, setHoteles] = useState(undefined);
    let [inputHotel, setInputHotel] = useState(undefined);
    let [search, setSearch] = useState(undefined);
    let [cargando, setCargando] = useState(false);
    let [cargando1, setCargando1] = useState(false);
    let [localidad, setLocalidad] = useState({"Zona este":undefined, "Zona norte":undefined});
    let [localidadInput, setLocalidadInput] = useState("Todos");
    let [ultimoElemento, setUltimoElemento] = useState(undefined);
    let [ultimoElementoLocalidad, setUltimoElementoLocalidad] = useState({"Zona este":undefined, "Zona norte":undefined});

    let [msgVal, setMsgVal] = useState("");

    async function obtenerHoteles (){
        if (hoteles === undefined) {
            setCargando(true);
            const result = await getSolicitudesCliente({tipo:"villas"});
            if (result[0]) {
                setHoteles(result[1].datos);
                setUltimoElemento(result[1].ultimoElemento);
            } else {
                setHoteles(result[0]);
                setMsgVal(result[1]);
            }
            setCargando(false);
        } else {
            if (ultimoElemento !== false) {
                setCargando1(true);
                const result = await getSolicitudesCliente({tipo:"villas", ultimoElemento:ultimoElemento});
                setUltimoElemento(result[1].ultimoElemento);
                if (result[0]) {
                    const newHoteles = hoteles;
                    result[1].datos.map((value)=>{
                        let si = true;
                        newHoteles.map((old)=>{
                            if (old.id == value.id) {
                                si = false;
                            }
                        })
                        if (si) {
                            newHoteles.push(value);
                        }
                    });
                    setHoteles(JSON.parse(JSON.stringify(newHoteles)));
                } else {
                    setHoteles(result[0]);
                    setMsgVal(result[1]);
                }
                setCargando1(false);
            }
        }
    }

    async function buscarHotel (e){
        e.preventDefault();
        if (inputHotel !== undefined) {
            if (inputHotel.replace(/\s+/g, " ").trim().length > 0) {
                setCargando(true);
                const result = await searchHotelOVilla(inputHotel.toLowerCase().replace(/\s+/g, " ").trim(), "villas");
                if (result[0]) {
                    setSearch(result[1]);
                } else {
                    setSearch(false);
                    setMsgVal(result[1]);
                }
                setCargando(false);
            }
        }
    }

    async function getHotelesLocalidad (local1, btn){
        const local = local1 || localidadInput;
        setSearch(undefined);
        setInputHotel("");
        if (local != "Todos") {
            setLocalidadInput(local);
            
            if (localidad[local] === undefined) {
                setCargando(true);
                const result = await getSolicitudesCliente({tipo:"villas", search:"zona", word:local});
                if (result[0]) {
                    const newLocalidad = localidad;
                    newLocalidad[local] = result[1].datos;
                    setLocalidad(JSON.parse(JSON.stringify(newLocalidad)));
                    const ultimoElementoLocalidad1 = ultimoElementoLocalidad;
                    ultimoElementoLocalidad1[local] = result[1].ultimoElemento;
                    setUltimoElementoLocalidad(ultimoElementoLocalidad1);
                } else {
                    const newLocalidad = localidad;
                    newLocalidad[local] = result[0];
                    setLocalidad(newLocalidad);
                    setMsgVal(result[1]);
                }
                setCargando(false);
            } else {
                if (btn) {
                    if (ultimoElementoLocalidad[local] !== false) {
                        setCargando1(true);
                        const result = await getSolicitudesCliente({tipo:"villas", search:"zona", word:local, ultimoElemento:ultimoElementoLocalidad[local]});
                        const ultimoElementoLocalidad1 = ultimoElementoLocalidad;
                        ultimoElementoLocalidad1[local] = result[1].ultimoElemento;
                        setUltimoElementoLocalidad(ultimoElementoLocalidad1);
                        if (result[0]) {
                            const newLocalidad = localidad;
                            result[1].datos.map((value)=>{
                                let si = true;
                                newLocalidad[local].map((old)=>{
                                    if (old.id == value.id) {
                                        si = false;
                                    }
                                })
                                if (si) {
                                    newLocalidad[local].push(value);
                                }
                            });
                            setLocalidad(newLocalidad);
                        } else {
                            const newLocalidad = localidad;
                            newLocalidad[local] = result[0];
                            setLocalidad(newLocalidad);
                            setMsgVal(result[1]);
                        }
                        setCargando1(false);
                    };
                }
            };
        } else {
            setLocalidadInput(local);
        }
    };    

    function widthRango (tarifas){
        let widthMayor = 0;
        tarifas.map((tarifa)=>{
            if (widthMayor < tarifa.rangos.length) {
                widthMayor = tarifa.rangos.length;
            }
        });

        const widthRangoDefault = pc ? 15 : 20 ;
        const widthRangoNew = pc ? 4.5*widthMayor : 11*widthMayor;

        return widthRangoDefault > widthRangoNew ? widthRangoDefault+"vw" : widthRangoNew+"vw";
    }

    useEffect(()=>{
        if(session.user){
            obtenerHoteles();
        }
    },[session.user])

    return (
        <ContenedorOpcion>
            <HotelesContenedor paddingBottom={true}>
                <h2>Tarifario de villas</h2>
                {hoteles !== undefined && hoteles !== false && hoteles.length > 0 &&
                    <BuscarContenedor>
                        <input value={inputHotel} onChange={(e)=>{setInputHotel(e.target.value)}} placeholder="Busca una villa"/>
                        {(inputHotel || search !== undefined) && <img onClick={()=>{setSearch(undefined); setInputHotel(""); setLocalidadInput("Todos"); document.querySelector("#optionLocal").value="Todos"}} style={{cursor:"pointer", width:pc ? "2.5vw" : "8vw", height: pc ? "2.5vw" : "8vw", margin:"auto 0"}} src={quitar}/>}
                        <button type="submit" onClick={(e)=>buscarHotel(e)}>
                            <img src={buscar}/>
                        </button>
                    </BuscarContenedor>
                }

                { hoteles !== undefined && hoteles !== false && hoteles.length > 0 &&
                    <div style={{display:"flex", alignItems:"center", marginLeft:"auto", marginRight:"10%", marginTop:pc ? "0.5vw" : "1.4vw"}}>
                        <p style={{marginRight:pc ? "0.5vw" : "1vw", color:"#515151", fontSize:pc ? "1.3vw" : "3.5vw"}}>Zona de villas:</p>
                        <select style={{cursor:"pointer", fontSize:!pc && "3.5vw"}} id="optionLocal" defaultValue="Todos" onChange={(e)=>getHotelesLocalidad(e.target.value)}>
                            <option defaultValue="Todos">Todos</option>
                            <option>Zona este</option>
                            <option>Zona norte</option>
                        </select>
                    </div>
                }

                { cargando ?
                    <CargandoCont>
                        <img src={cargandoImg} />
                    </CargandoCont>
                    : search !== undefined ? 
                    <HotelesContenedor>
                        <HotelesCont>
                            {(search !== false) ?
                                search.length > 0 ?
                                search.map((villa)=>(
                                    <div style={{marginBottom: pc ? "1.7vw" : "6vw"}}>
                                        <h3>{villa.nombre}</h3>
                                        <CuadroContenedor style={{marginBottom:"2vw"}} tarifas={true}>
                                            <CuadroCont tarifas={true}>
                                                <TopCuadroContenedor tarifas={true}>
                                                    <Cuadro tarifas={true}>
                                                        <p>Codigo de Oferta</p>
                                                    </Cuadro>
                                                    <Cuadro width={widthRango(villa.ofertas)} tarifas={true}>
                                                        <p>Precio por cantidad de personas</p>
                                                    </Cuadro>
                                                    <Cuadro tarifas={true}>
                                                        <p>Vencimiento de oferta</p>
                                                    </Cuadro>
                                                </TopCuadroContenedor>
                                                
                                                { villa.ofertas && villa.ofertas.map((value, index)=>(
                                                        <BottomCuadroContenedor tarifas={true} bloqueado={validarFecha(value.vencimientoOferta) !== true && true} key={index}>
                                                            <Cuadro tarifas={true}><p>{value.codigoOferta}</p></Cuadro>
                                                            <Cuadro width={widthRango(villa.ofertas)} style={{padding:0, justifyContent:"flex-start"}} tarifas={true}>

                                                                {   value.rangos.map((subRango)=>(
                                                                    <div style={{display:"flex", color:"#fff", width:pc ? "4.5vw" : "11vw", fontSize:pc ? "1vw" : "2.7vw", flexDirection:"column"}}>
                                                                        <span style={{borderRight:"1px solid #fff", borderBottom:"1px solid #fff", padding:pc ? "0.1vw 0.4vw" : "0.2vw 0.5vw", textAlign:"center"}}>{subRango.rangoMin+"-"+subRango.rangoMax}</span>
                                                                        <span style={{borderRight:"1px solid #fff", padding:pc ? "0.1vw 0.4vw" : "0.2vw 0.5vw", textAlign:"center"}}>{subRango.rangos.findIndex(e => e.dias[0] == "todos") > -1 ? "$"+subRango.rangos[subRango.rangos.findIndex(e => e.dias[0] == "todos")].precio : "$"+subRango.rangos[0].precio} </span>    
                                                                    </div>
                                                                ))}                                         
                                                            
                                                            </Cuadro>
                                                            <Cuadro tarifas={true} bloqueado={validarFecha(value.vencimientoOferta) !== true && true}><p>{value.vencimientoOferta}</p></Cuadro>
                                                        </BottomCuadroContenedor>
                                                ))

                                                }
                                                { villa.ofertas.length == 0 &&
                                                    <p style={{color:"#fff", margin:"1vw"}}>No hay tarifas disponibles.</p>
                                                }
                                            </CuadroCont>
                                        </CuadroContenedor>
                                    </div>
                                ))
                                : 
                                <p>No hay resultados para tu busqueda.</p> 
                            : <p>{msgVal}</p>
                            }
                        </HotelesCont>
                    </HotelesContenedor>
                    : localidadInput != "Todos" ?
                        localidad !== undefined ?
                            <HotelesContenedor>
                                <HotelesCont>
                                    {(localidad[localidadInput] !== false) ?
                                        localidad[localidadInput].length > 0 ?
                                        localidad[localidadInput].map((villa)=>(
                                            <div style={{marginBottom: pc ? "1.7vw" : "6vw"}}>
                                                <h3>{villa.nombre}</h3>
                                                <CuadroContenedor style={{marginBottom:"2vw"}} tarifas={true}>
                                                    <CuadroCont tarifas={true}>
                                                        <TopCuadroContenedor tarifas={true}>
                                                            <Cuadro tarifas={true}>
                                                                <p>Codigo de Oferta</p>
                                                            </Cuadro>
                                                            <Cuadro width={widthRango(villa.ofertas)} tarifas={true}>
                                                                <p>Precio por cantidad de personas</p>
                                                            </Cuadro>
                                                            <Cuadro tarifas={true}>
                                                                <p>Vencimiento de oferta</p>
                                                            </Cuadro>
                                                        </TopCuadroContenedor>
                                                        
                                                        { villa.ofertas && villa.ofertas.map((value, index)=>(
                                                                <BottomCuadroContenedor tarifas={true} bloqueado={validarFecha(value.vencimientoOferta) !== true && true} key={index}>
                                                                    <Cuadro tarifas={true}><p>{value.codigoOferta}</p></Cuadro>
                                                                    <Cuadro width={widthRango(villa.ofertas)} style={{padding:0, justifyContent:"flex-start"}} tarifas={true}>

                                                                        {   value.rangos.map((subRango)=>(
                                                                            <div style={{display:"flex", color:"#fff", width:pc ? "4.5vw" : "11vw", fontSize:pc ? "1vw" : "2.7vw", flexDirection:"column"}}>
                                                                                <span style={{borderRight:"1px solid #fff", borderBottom:"1px solid #fff", padding:pc ? "0.1vw 0.4vw" : "0.2vw 0.5vw", textAlign:"center"}}>{subRango.rangoMin+"-"+subRango.rangoMax}</span>
                                                                                <span style={{borderRight:"1px solid #fff", padding:pc ? "0.1vw 0.4vw" : "0.2vw 0.5vw", textAlign:"center"}}>{subRango.rangos.findIndex(e => e.dias[0] == "todos") > -1 ? "$"+subRango.rangos[subRango.rangos.findIndex(e => e.dias[0] == "todos")].precio : "$"+subRango.rangos[0].precio} </span>    
                                                                            </div>
                                                                        ))}                                         
                                                                    
                                                                    </Cuadro>
                                                                    <Cuadro tarifas={true} bloqueado={validarFecha(value.vencimientoOferta) !== true && true}><p>{value.vencimientoOferta}</p></Cuadro>
                                                                </BottomCuadroContenedor>
                                                        ))

                                                        }
                                                        { villa.ofertas.length == 0 &&
                                                            <p style={{color:"#fff", margin:"1vw"}}>No hay tarifas disponibles.</p>
                                                        }
                                                    </CuadroCont>
                                                </CuadroContenedor>
                                            </div>
                                        ))
                                        : 
                                        <p>No hay villas disponibles en esta localidad.</p> 
                                    : <p>{msgVal}</p>
                                    }
                                </HotelesCont>
                                {
                                    (ultimoElementoLocalidad[localidadInput] !== undefined && ultimoElementoLocalidad[localidadInput] !== false) ?
                                    cargando1 ? 
                                        <CargandoCont><img src={cargandoImg}/></CargandoCont>
                                        : 
                                        <p onClick={()=>getHotelesLocalidad(undefined, true)} style={{cursor:"pointer", textDecoration:"underline", padding:"0.5vw"}}>Ver mas</p>
                                    : 
                                    undefined   
                                }
                        </HotelesContenedor>
                        : undefined
                    : <HotelesContenedor>
                        <HotelesCont>
                            {(hoteles !== false && hoteles !== undefined) ?
                                hoteles.length > 0 ?
                                hoteles.map((villa)=>(
                                    <div style={{marginBottom: pc ? "1.7vw" : "6vw"}}>
                                        <h3>{villa.nombre}</h3>
                                        <CuadroContenedor style={{marginBottom:"2vw"}} tarifas={true}>
                                            <CuadroCont tarifas={true}>
                                                <TopCuadroContenedor tarifas={true}>
                                                    <Cuadro tarifas={true}>
                                                        <p>Codigo de Oferta</p>
                                                    </Cuadro>
                                                    <Cuadro width={widthRango(villa.ofertas)} tarifas={true}>
                                                        <p>Precio por cantidad de personas</p>
                                                    </Cuadro>
                                                    <Cuadro tarifas={true}>
                                                        <p>Vencimiento de oferta</p>
                                                    </Cuadro>
                                                </TopCuadroContenedor>
                                                
                                                { villa.ofertas && villa.ofertas.map((value, index)=>(
                                                        <BottomCuadroContenedor tarifas={true} bloqueado={validarFecha(value.vencimientoOferta) !== true && true} key={index}>
                                                            <Cuadro tarifas={true}><p>{value.codigoOferta}</p></Cuadro>
                                                            <Cuadro width={widthRango(villa.ofertas)} style={{padding:0, justifyContent:"flex-start"}} tarifas={true}>

                                                                {   value.rangos.map((subRango)=>(
                                                                    <div style={{display:"flex", color:"#fff", width:pc ? "4.5vw" : "11vw", fontSize:pc ? "1vw" : "2.7vw", flexDirection:"column"}}>
                                                                        <span style={{borderRight:"1px solid #fff", borderBottom:"1px solid #fff", padding:pc ? "0.1vw 0.4vw" : "0.2vw 0.5vw", textAlign:"center"}}>{subRango.rangoMin+"-"+subRango.rangoMax}</span>
                                                                        <span style={{borderRight:"1px solid #fff", padding:pc ? "0.1vw 0.4vw" : "0.2vw 0.5vw", textAlign:"center"}}>{subRango.rangos.findIndex(e => e.dias[0] == "todos") > -1 ? "$"+subRango.rangos[subRango.rangos.findIndex(e => e.dias[0] == "todos")].precio : "$"+subRango.rangos[0].precio} </span>    
                                                                    </div>
                                                                ))}                                         
                                                            
                                                            </Cuadro>
                                                            <Cuadro tarifas={true} bloqueado={validarFecha(value.vencimientoOferta) !== true && true}><p>{value.vencimientoOferta}</p></Cuadro>
                                                        </BottomCuadroContenedor>
                                                ))

                                                }
                                                { villa.ofertas.length == 0 &&
                                                    <p style={{color:"#fff", margin:"1vw"}}>No hay tarifas disponibles.</p>
                                                }
                                            </CuadroCont>
                                        </CuadroContenedor>
                                    </div>
                                ))
                                : 
                                <p>No hay villas disponibles.</p> 
                            : <p>{msgVal}</p>
                            }
                        </HotelesCont>
                        {
                            (ultimoElemento !== undefined && ultimoElemento !== false) ?
                            cargando1 ? 
                                <CargandoCont><img src={cargandoImg}/></CargandoCont>
                                : 
                                <p onClick={()=>obtenerHoteles()} style={{cursor:"pointer", textDecoration:"underline", padding:"0.5vw"}}>Ver mas</p>
                            : 
                            undefined   
                        }
                    </HotelesContenedor>        
                }
            </HotelesContenedor>
        
        </ContenedorOpcion>
    );
};