import { useEffect, useState, useContext } from "react";
import ContenedorOpcion from "../contenedorOpcion";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import { validarFecha, restaFechas, cambiarFecha, isNum } from "../../utils/validar";
import { Formulario, TopFormulario, FormularioCont, Columna, BtnEnviar, Input, Select, SpanValidacion } from "../../utils/formulario";
import {CuadroContenedor, TopCuadroContenedor, CuadroCont, Cuadro, BottomCuadroContenedor} from "../../utils/recuadro";
import sessionContext from "../../../contexts/sessionContext";
import CargandoCont from "../../utils/cargando";
import { confirmarSolicitud, getSolicitudesCliente, solicitarCotizacion } from "../../../config/auth";
import Modal, {DetallesReserva, BtnCerrar} from "../../utils/modal";
import { BtnConfirmar } from "../../utils/componentes";
import { descargarArchivo } from "../../utils/funciones";

//imagenes
import quitar from "../../../images/quitar1.png";
import cargandoImg from "../../../images/cargando.gif";
import descargar from "../../../images/descargar.png";

const pc = window.screen.width > 529 ? true : false;

const GruposContenedor = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const SpanValidacion1 = styled.span`
    text-align: right;
    font-size: ${pc ? "1vw" : "3.4vw"};
    background-color: ${props => props.error ? "#26AC20" : "#eb1a1a"};
    border-radius: ${pc ? "0.3vw" : "1vw"};
    margin-bottom: ${pc && "1vw"};
    padding: ${pc ? "0.6vw" : "2.3vw"};
    color:#fff;
    display: ${props => props.activo ? "block" : "none"};
`;

const Msg = styled.div`
  position: fixed;
  display: ${props => props.activo ? "flex" : "none"};
  width: 100%;
  align-items: center;
  justify-content: center;
  bottom: 0;
  left: 0;

  & div {
    padding: ${pc ? "1vw 2vw" : "2vw 3vw"};
    margin-bottom: 3px;
    text-align: center;
    border-top-right-radius: 0.4vw;
    border-top-left-radius: 0.4vw;
    background-color: ${props => props.error ? "#26AC20" : "#eb1a1a"};
    color: #fff;
  }
`;

const BtnDescargar = styled.div`
    padding: ${pc ? "0.5vw" : "1vw"};
    cursor: pointer;
    border-radius: ${pc ? "0.3vw" : "1vw"};
    background-color: #E7971D;
    text-decoration: none;

    & img {
        width: ${pc ? "1.8vw" : "4vw"};
    }
    & p{
        color:#fff
    }
`;

const BtnToPoliticas = styled.span`
    color: #fff;
    font-weight: 500;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
`;
const CheckboxCont = styled.div`
    display: flex;
    align-items: center;
    width: ${pc ? "80%" : "100%"};
    margin-bottom: 2vw;
    
    & p {
        font-size: ${pc ? "1.2vw" : "3vw"};
        margin-left: ${!pc && "2vw"};
        font-weight: 300;
    }

    & input {
        width: ${pc ? "10%" : "15%"};
        height: ${!pc && "4.5vw"};
        margin: 0!important;
        padding: 0!important;
        margin-right: 1vw!important;
    }
`;


export default function Grupos (){

    const location = useLocation();
    const navigate = useNavigate();

    const { session } = useContext(sessionContext);

    let [solicitudes, setSolicitudes] = useState(undefined);
    let [ultimoElemento, setUltimoElemento] = useState(undefined);
    let [id, setId] = useState("");
    let [msgVal1, setMsgVal1] = useState("");
    let [msgVal2, setMsgVal2] = useState("");
    let [modal, setModal] = useState(false);

    let [cargando, setCargando] = useState(false);
    let [cargando1, setCargando1] = useState(false);

    let [msgVal, setMsgVal] = useState("");
    let [inputs, setInputs] = useState({nombreGrupo:["", ""], fechaEntrada:["", ""], fechaSalida:["", ""], categoriaHabitacion:["", ""], cantidadHabitaciones:["", ""], adultos:["", ""], niños:["", ""], edades:[], observaciones:["", ""]});
    const edades = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1];

    let [checkbox, setCheckbox] = useState(false);
    let [checkboxMsg, setCheckboxMsg] = useState(false);

    function updateValueInput (tipo, valor, indexEdad){
        const newInputs = JSON.parse(JSON.stringify(inputs));

        if (tipo != "edades"){
            if (tipo == "fechaEntrada" || tipo == "fechaSalida") {
                newInputs[tipo][0] = cambiarFecha(valor);
            } else {
                newInputs[tipo][0] = valor;
            }
        } else {
            newInputs[tipo][indexEdad][0] = valor;
        }

        if (tipo == "niños") {
            newInputs.niños[1] = "";
            if (isNum(valor)) {
                if (valor <= 20) {
                    newInputs.edades = [];
                    for(let i = 0; i < newInputs.niños[0]; i++){
                        if (inputs.edades[i]) {
                            if (inputs.edades[i][0]) {
                                newInputs.edades.push(inputs.edades[i]);
                                
                            } else {
                                newInputs.edades.push(["", ""]);
                            }
                        } else {
                            newInputs.edades.push(["", ""]);
                        }
                    }
                } else {
                    newInputs.niños[1] = "Maximo 20 niños.";
                }
            } else {
                newInputs.niños[1] = "Solo se admiten numeros.";
            }
        }

        setInputs(newInputs);
    }

    async function validarForm(){
        const newInputs = JSON.parse(JSON.stringify(inputs));
        let result = true;

        Object.keys(inputs).map((value, index)=>{
            if (value != "edades") {

                newInputs[value][1] = "";
                if (newInputs[value][0].length > 0) {

                    if (value == "fechaEntrada" || value == "fechaSalida") {
                        const valFecha = validarFecha(newInputs[value][0]);
                        if (valFecha !== true){
                            newInputs[value][1] = valFecha;
                            result = false;
                        } else {
                            if (restaFechas(newInputs.fechaEntrada[0], newInputs.fechaSalida[0]) == 0) {
                                newInputs[value][1] = "Reserva minimo un dia.";
                                result = false;
                            } else if (restaFechas(newInputs.fechaEntrada[0], newInputs.fechaSalida[0]) < 0){
                                newInputs[value][1] = "La fecha de salida no puede ser antes que la fecha de entrada.";
                                result = false;
                            }
                        }
                    }
                    if (value == "cantidadHabitaciones" || value == "adultos") {
                        if (isNum(newInputs[value][0])) {
                            if (newInputs[value][0] < 1) {
                                newInputs[value][1] = "Minimo 1 "+(value=="adultos" ? "adulto." : "habitación.");
                                result = false;
                            }
                        } else {
                            newInputs[value][1] = "Solo se admiten numeros. (sin puntos, comas ni simbolos)";
                            result = false;
                        }
                    }
                    if (value == "categoriaHabitacion" || value == "nombreGrupo") {
                        if (newInputs[value][0].length < 2) {
                            newInputs[value][1] = "Minimo 2 caracteres.";
                            result = false;
                        }
                    }

                } else {
                    if (value != "observaciones") {
                        newInputs[value][1] = "Rellena este campo.";
                        result = false;
                    }
                }

            } else {

                newInputs[value].map((valueEdad, indexEdad)=>{
                    newInputs[value][indexEdad][1] = "";
                    if (valueEdad[0].length > 0) {
                        if (isNum(valueEdad[0])) {
                            if (valueEdad[0] > 17) {
                                newInputs[value][indexEdad][1] = "La edad tiene que ser menos de 18.";
                                result = false;
                            }
                        } else {
                            newInputs[value][indexEdad][1] = "Solo se admiten numeros. (sin puntos, comas ni simbolos)";
                            result = false;
                        }
                    } else {
                        newInputs[value][indexEdad][1] = "Rellena este campo.";
                        result = false;
                    }
                });

            } 
        });

        setInputs(newInputs);

        setCheckboxMsg("");
        if (!checkbox) {
            setCheckboxMsg("Confirma que estas de acuerdo.");
            result = false;
        }

        if (result) {
            enviarDatos(newInputs);
        }

    }
    
    async function enviarDatos (datos){
        setCargando(true);
        const enviarReserva = await solicitarCotizacion(datos, "grupos");
        if (enviarReserva === true) {
            setMsgVal("Cotización enviada correctamente.");
            setTimeout(()=>{
                navigate("/");
            },4070);
            setTimeout(()=>{
                navigate("/plataforma/grupos");
            },4080);
        } else if (enviarReserva === false) {
            setMsgVal("Sucedio un error, disculpanos");
        } else {
            setMsgVal(enviarReserva);
        }
        setCargando(false);
    }

    async function getData (){
        if (solicitudes === undefined) {
            const result = await getSolicitudesCliente({tipo:"grupos"});
            if (result[0]) {
                setSolicitudes(result[1].datos);
                setUltimoElemento(result[1].ultimoElemento);
            } else {
                setMsgVal1(result[1]);
                setSolicitudes(result[0]);
            }
        } else {
            if (ultimoElemento !== false) {
                setCargando1(true);
                const result = await getSolicitudesCliente({tipo:"grupos", ultimoElemento:ultimoElemento});
                if (result[0]) {
                    setUltimoElemento(result[1].ultimoElemento);
                    const newSeguros1 = solicitudes;
                    result[1].datos.map((value, index)=>{
                        let si = true;
                        newSeguros1.map((old)=>{
                            if (value.id == old.id) {
                                si = false;
                            }
                        })
                        if (si) {
                            newSeguros1.push(value);
                        }
                    });
                    setSolicitudes(newSeguros1);
                } else {
                    setSolicitudes(false);
                    setMsgVal1(result[1]);
                }
            }
        }
        setCargando1(false);
    }

    async function confirmarSoli (){
        setCargando(true);
        const result = await confirmarSolicitud({id:modal.id, tipo:"grupos"});
        if (result === true) {
            const newModal = modal;
            newModal.confirmado = true;
            setModal(newModal);
            const newSolicitudes = solicitudes;
            newSolicitudes.map((value, index)=>{
                if (value.id == modal.id) {
                    newSolicitudes[index].confirmado = true;
                }
            });
            setSolicitudes(newSolicitudes);
        } else {
            setMsgVal2("Comprueba tu conexión a internet.");
            setTimeout(()=>{
                setMsgVal2("");
            }, 3000);
        }
        setCargando(false);
    }

    function comprobarLink (link){
        const httpIsTrue = link.charAt(0) + link.charAt(1) + link.charAt(2) + link.charAt(3);
        if (httpIsTrue == "http") {
            return true;
        } else {
            return false;
        }
    }

    useEffect(()=>{
        if (session.user) {
            getData();
        }
    },[session.user])

    return (
        <ContenedorOpcion>
            { !location.search ?
                <GruposContenedor>
                    <h2>Grupos</h2>
                        <Formulario>
                            <FormularioCont>
                                <TopFormulario>
                                    <h2>COTIZACIÓN DE GRUPOS</h2>
                                </TopFormulario>
                                <Columna>
                                    <label>Nombre del grupo</label>
                                    <SpanValidacion>{inputs.nombreGrupo[1]}</SpanValidacion>
                                    <Input error={inputs.nombreGrupo[1]} onChange={(valor)=>updateValueInput("nombreGrupo", valor.target.value)}/>
                                </Columna>
                                <Columna>
                                    <label>Fecha de entrada</label>
                                    <SpanValidacion>{inputs.fechaEntrada[1]}</SpanValidacion>
                                    <Input type="date" error={inputs.fechaEntrada[1]} onChange={(valor)=>updateValueInput("fechaEntrada", valor.target.value)} placeholder="dia/mes/año"/>
                                    <label>Fecha de salida</label>
                                    <SpanValidacion>{inputs.fechaSalida[1]}</SpanValidacion>
                                    <Input type="date" error={inputs.fechaSalida[1]} onChange={(valor)=>updateValueInput("fechaSalida", valor.target.value)} placeholder="dia/mes/año"/>
                                    <label>Categoria de habitacion</label>
                                    <SpanValidacion>{inputs.categoriaHabitacion[1]}</SpanValidacion>
                                    <Input error={inputs.categoriaHabitacion[1]} onChange={(valor)=>updateValueInput("categoriaHabitacion", valor.target.value)}/>
                                </Columna>
                                <Columna>
                                    <label>Cantidad de adultos</label>
                                    <SpanValidacion>{inputs.adultos[1]}</SpanValidacion>
                                    <Input error={inputs.adultos[1]} onChange={(valor)=>updateValueInput("adultos", valor.target.value)}/>
                                    <label>Cantidad de niños</label>
                                    <SpanValidacion>{inputs.niños[1]}</SpanValidacion>
                                    <Select defaultValue="a" error={inputs.niños[1]} onChange={(valor)=>updateValueInput("niños", valor.target.value)}>
                                        <option defaultValue="a">Sin seleccionar</option>
                                        {edades.map((value, index)=>(
                                            <option key={index+1}>{index}</option>
                                        ))}
                                    </Select>
                                    <label>Cantidad de habitaciones</label>
                                    <SpanValidacion>{inputs.cantidadHabitaciones[1]}</SpanValidacion>
                                    <Input error={inputs.cantidadHabitaciones[1]} onChange={(valor)=>updateValueInput("cantidadHabitaciones", valor.target.value)}/>
                                </Columna>
                                { inputs.edades.map((edad, indexEdad)=>(
                                    <Columna key={indexEdad+1}>
                                        <label>Edad de niño {indexEdad+1} </label>
                                        <SpanValidacion>{inputs.edades[indexEdad][1]}</SpanValidacion>
                                        <Input value={edad[0]} error={inputs.edades[indexEdad][1]} onChange={(valor)=>updateValueInput("edades", valor.target.value, indexEdad)}/>
                                    </Columna>
                                ))}
                                <Columna>
                                    <label>Observaciones</label>
                                    <SpanValidacion>{inputs.observaciones[1]}</SpanValidacion>
                                    <textarea onChange={(valor)=>updateValueInput("observaciones", valor.target.value)}></textarea>
                                    <SpanValidacion>{checkboxMsg}</SpanValidacion>
                                    <CheckboxCont style={{display:"flex", alignItems:"center"}}>
                                        <Input style={{cursor:"pointer", width:pc && "8%"}} error={checkboxMsg} type="checkbox" onChange={(e)=>setCheckbox(e.target.checked)} />
                                        <p>He leido y acepto los <BtnToPoliticas onClick={()=>navigate("/terminos-y-condiciones")}>terminos y condiciones.</BtnToPoliticas></p>
                                    </CheckboxCont>
                                </Columna>
                            </FormularioCont>
                        </Formulario>
                        <SpanValidacion1 activo={msgVal} error={msgVal == "Cotización enviada correctamente." ? true : false} style={{textAlign:"center"}}>{msgVal}</SpanValidacion1>
                        <BtnEnviar onClick={()=>validarForm()}>
                            { cargando ?
                                <CargandoCont><img src={cargandoImg}/></CargandoCont>
                                :
                                <p>Solicitar</p>
                            }
                        </BtnEnviar>
                </GruposContenedor>
                : location.search == "?tab=mis-solicitudes" &&
                <GruposContenedor>
                    <h2>Mis solicitudes</h2>
                    {   solicitudes !== undefined ?
                                    solicitudes !== false ?
                                        solicitudes.length > 0 ? 
                                            <CuadroContenedor>
                                                <CuadroCont>
                                                    <TopCuadroContenedor>
                                                        <Cuadro>
                                                            <p>Cliente</p>
                                                        </Cuadro>
                                                        <Cuadro>
                                                            <p>Detalles</p>
                                                        </Cuadro>
                                                        <Cuadro>
                                                            <p>Estado</p>
                                                        </Cuadro>
                                                    </TopCuadroContenedor>
                                                    { solicitudes.map((value, index)=>(
                                                        <BottomCuadroContenedor key={value.id}>
                                                            <Cuadro><p>{value.nombreGrupo}</p></Cuadro>
                                                            <Cuadro style={{cursor:"pointer"}} onClick={()=>{setModal(value); setId(index)}}><p style={{textDecoration:"underline"}}>Ver</p></Cuadro>
                                                            <Cuadro><p>{value.estado}</p></Cuadro>
                                                        </BottomCuadroContenedor>
                                                    ))
                                                    }                                                    
                                                </CuadroCont>
                                            </CuadroContenedor> 
                                        : <p>No tienes solicitudes de grupos</p>
                                    : <p>{msgVal1}</p>
                                : <CargandoCont><img src={cargandoImg}/></CargandoCont> 
                            }
                            { ultimoElemento !== undefined && ultimoElemento !== false ? 
                                cargando1 ? 
                                    <CargandoCont><img src={cargandoImg}/></CargandoCont> 
                                    : 
                                    <p onClick={()=>getData()} style={{cursor:"pointer", padding:"0.5vw", textDecoration:"underline"}}>Ver mas</p> 
                                : <p></p>
                            }
                    <Msg activo={msgVal2} error={false}><div><p>{msgVal2}</p></div></Msg>
                </GruposContenedor>
            }
            
            <Modal visible={modal} setVisible={()=>setModal(false)}>
               { modal &&
                <DetallesReserva>
                    <BtnCerrar onClick={()=>setModal(false)}><img src={quitar}/></BtnCerrar>
                     <h2>Detalles de la cotizacion</h2>
                     <div><h3>Estado:</h3><span style={{color: modal.estado == "Cotizado" && "#26AC20"}}>{modal.estado}</span></div>
                     <div>
                        <h3>Info cotización:</h3>
                        <span>
                            { modal.infoCotizacion ? 
                                comprobarLink(modal.infoCotizacion) 
                                    ? 
                                    <BtnDescargar onClick={()=>descargarArchivo(modal.infoCotizacion)}>
                                        <p>Descargar</p>
                                        <img src={descargar} />
                                    </BtnDescargar>   
                                    : 
                                    modal.infoCotizacion
                                :
                                "No hay info"
                            }
                        </span>
                    </div>
                     { modal.estado == "Cotizado" && <div><h3>Confirmado:</h3><span>{modal.confirmado ? "Si" : cargando ? <CargandoCont><img src={cargandoImg}/></CargandoCont>  : <BtnConfirmar onClick={()=>confirmarSoli()}>Confirmar</BtnConfirmar>}</span></div>}
                     <div><h3>Monto total:</h3><span>{modal.totalUs ? "USD $ "+modal.totalUs : "Todavia no esta disponible"}</span></div>
                     <div><h3>Comision:</h3><span>{modal.comisionUs ? "USD $ "+modal.comisionUs : "Todavia no esta disponible"}</span></div>
                     <div><h3>Estado de pago de comisión:</h3><span style={{color: modal.estadoComision == true && "#26AC20"}}>{modal.estadoComision ? "Pagado" : "Pendiente"}</span></div>
                     <div><h3>Titular:</h3><span>{modal.nombreGrupo}</span></div>
                     <div><h3>Fecha de entrada:</h3><span>{modal.fechaEntrada}</span></div>
                     <div><h3>Fecha de salida:</h3><span>{modal.fechaSalida}</span></div>
                     <div><h3>Adultos:</h3><span>{modal.adultos}</span></div>
                     <div><h3>Niños:</h3><span>{modal.niños}</span></div>
                     <div><h3>Cantidad de habitaciones:</h3><span>{modal.cantidadHabitaciones}</span></div>
                     <div><h3>Categoria de habitaciones:</h3><span>{modal.categoriaHabitacion}</span></div>
                     { modal && modal.edades.map((value, index)=>(
                         <div key={index+1}><h3>Edad de niño {index+1}:</h3><span>{value}</span></div>
                     ))}
                     <div><h3>Observaciones:</h3><span>{modal.observaciones}</span></div>
 
                </DetallesReserva>
               }
            </Modal>
        </ContenedorOpcion>    
    );
}