import styled from "styled-components";
import { useLocation, useNavigate} from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import {CuadroContenedor, TopCuadroContenedor, CuadroCont, Cuadro, BottomCuadroContenedor} from "../utils/recuadro";
import CargandoCont from "../utils/cargando";
import sessionContext from "../../contexts/sessionContext";
import perfilHotelContext from "../../contexts/perfilHotelContext";
import { addTotalSolicitud, confirmarSolicitud, delInfoArchivo, eliminarUserOsolicitud, getSolicitudes, subirInfoArchivo, toggleEstadoComision } from "../../config/auth";
import Modal, {DetallesReserva, BtnCerrar} from "../utils/modal";

//imagenes 
import cargandoImg from "../../images/cargando.gif";
import cargandoImg1 from "../../images/cargando1.gif";
import quitar1 from "../../images/quitar1.png";
import quitar from "../../images/quitar.png";
import descargar from "../../images/descargar.png";
import subir from "../../images/subir.png";
import confirmar from "../../images/confirmar.png";
import editar from "../../images/edit1.png";
import cancelar from "../../images/cancelar.png";

const pc = window.screen.width > 529 ? true : false;

const SolicitudesCont = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: ${e => e.marginBottom && "5vw"};

    & h3 {
        font-weight: 600;
        font-size: ${pc ? "1.8vw" : ""};
    }
`;

const Val = styled.p`
    color:#515151;
`

const BtnDescargar = styled.div`
    padding: ${pc ? "0.5vw" : "1vw"};
    cursor: pointer;
    border-radius: ${pc ? "0.3vw" : "1vw"};
    background-color: #E7971D;
    text-decoration: none;
    margin: 0!important;
    border-bottom: none!important;
    width: 100%;

    & img {
        width: ${pc ? "1.3vw!important" : "4vw"};
    }
    & p{
        color:#fff;
        font-size: ${pc ? "0.8vw" : ""};
    }
`;

const BtnSubir = styled.div`
    padding: ${pc ? "0.5vw" : "1vw"};
    cursor: pointer;
    border-radius: ${pc ? "0.3vw" : "1vw"};
    background-color: #8C8C8C;
    margin: 0!important;
    width: 100%;

    & img {
        width: ${pc ? "1.3vw!important" : "4vw"};
    }
    & p{
        color:#fff;
        font-size: ${pc ? "0.8vw" : "2.2vw"};
        margin-right: ${pc ? "0.3vw" : ""};
    }
    &:first-child{
        margin-bottom: ${!pc && "2vw!important"};
    }
`;

const Btns = styled.div`
    display: flex;
    flex-direction: ${p => p.row ? "row" : "column"};
    margin-left: ${p => !p.row && "1vw"};
    border-bottom: none!important;
    width: 100%;
    margin-bottom: 0!important;
    
    &:nth-child(3){
        background-color: red;
    }
    
`;

const BtnQuitarArchivo = styled.div`
    width: 1.8vw;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    margin-bottom: 0!important;
    border-bottom: 0!important;
    margin-left: ${pc ? "0.15vw" : ""};
    opacity: ${p => p.opacity ? 1 : 0};

    & img {
        background-color: #EB1A1A;
        width: 100%;
        padding: 0.1vw;
        cursor: pointer;
    }
    & img:hover {
        background-color: #CE1818;
    }
`;

const Select = styled.select`
    font-size: ${pc ? "0.8vw!important" : ""};
    width: ${pc ? "10vw!important" : ""};
    cursor: pointer;
`;

const Msg = styled.div`
  position: fixed;
  display: ${props => props.activo ? "flex" : "none"};
  width: 100%;
  align-items: center;
  justify-content: center;
  bottom: 0;
  left: 0;

  & div {
    margin:${!pc && "0 auto"};
    padding: ${pc ? "1vw 2vw" : "2vw 3vw"};
    margin-bottom: 3px;
    text-align: center;
    width: ${!pc && "90%"};
    border-top-right-radius: ${pc ? "0.4vw" : "1.2vw"};
    border-top-left-radius: ${pc ? "0.4vw" : "1.2vw" };
    background-color: ${props => props.error ? "#26AC20" : "#eb1a1a"};
    color: #fff;
  }
`;

const Button = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${pc ? "0.5vw" : "1vw"};
    background-color: #8C8C8C;
    border:none;
    border-radius: ${pc ? "0.3vw" : "1vw"};
    color:#fff;
    cursor:pointer;
    font-size: ${pc ? "0.8vw" : ""};

    & img {
        width: ${pc ? "" : "7vw"};
    }
`;

const DivText = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border:none!important;

    & textarea {
        resize: none;
        border: 1px solid #AFAFAF;
        border-radius: ${pc ? "0.3vw" : ""};
        font-size: ${pc ? "0.8vw" : "2.7vw"};
        padding: ${pc ? "0.3vw" : "0.5vw"};
        margin-bottom: 1vw;
    }

    & textarea:focus {
        border: 1px solid #004aad;
        outline: none;
    }

    & input:focus {
        border: 1px solid #004aad;
        outline: none;
    }
    & input {
        width:${pc ? "12vw" : "100%"};
        border: 1px solid #AFAFAF;
        font-size: ${pc ? "0.8vw" : ""};
        padding: ${pc ? "0.3vw" : "0.5vw"};
        margin-bottom: ${pc ?"" : "1vw"};
    }
`;

const BtnsText = styled.div`
    display:flex;
    border:none!important;
    margin-top: ${pc ? "0.5vw" : ""};

    & img {
        width: ${pc ? "2vw" : ""};
    }

    & button {
        background-color: #fff!important;
        padding: 0;
    }
`;

const InputConfirmacion = styled.div`
    display:flex;
    align-items: center;

    & input {
        font-size: ${pc ? "0.8vw" : ""};
            padding: ${pc ? "0.5vw" : ""};
        }

    & img {
        margin-left: ${pc ? "1vw" : "3vw"};
        width: ${pc ? "2vw" : "6vw"};
        height: ${pc ? "2vw" : "6vw"};
    }
`;

const BtnEditar = styled.button`
    background-color: #F5B114;
    padding: ${pc ? "0.5vw" : "2vw"};
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    width: ${pc ? "45%" : "70%"};
    margin: 0 auto;
    margin-top: ${pc ? "1vw" : "3vw"};
    cursor:pointer;
    border-radius: ${pc ? "0.3vw" : "1vw"};

    & img {
        width: ${pc ? "1.4vw" : "5vw"};
        margin-left: ${pc ? "0.5vw" : "3vw"};
    }

    & p {
        font-weight:500;
        font-size: ${pc ? "1.2vw" : "4vw"};
        color:#fff;
    }

    &:hover {
        background-color: #F1AC0B;
    }
`;

const ModalEliminar = styled.div`
    display: flex;
    padding: 1vw;
    align-items: center;
    flex-direction: column;

    & h3{
        font-weight: 600;
        text-align: center;
        font-size: ${pc ? "" : "4.5vw"};
        width: ${!pc && "60vw"};
    }

    & p {
        color:#585858;
        font-size: ${pc ? "" : "4vw"};
    }
`;

const ButtonModal = styled.button`
    width: 45%;
    background-color: ${props => props.red ? "#eb1a1a" : props.yes ? "#26AC20" : "#767676"};
    color:#fff;
    padding: ${pc ? "0.5vw" : "1.2vw"};
    border: none;
    font-size: ${pc ? "" : "3.2vw"};
    border-radius: ${pc ? "0.5vw" : "1vw"};
    align-items: center;
    justify-content: center;
    cursor: pointer;
    display: flex;
    opacity: ${p => p.opacity && "0.5"};

    &:hover {
        background-color: ${e => e.red ? "#D31A1A" : e.yes ? "#2CA026" : "#646363"};
    }
`;

const BtnQuitar = styled.div`
    width: ${pc ? "3vw" : "9.5vw"};
    right: ${pc ? "-2.5vw" : "-8vw"};
    position: absolute;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;

    & img {
        background-color: #EB1A1A;
        width: 58%;
        padding: 0.1vw;
        cursor: pointer;
    }
    & img:hover {
        background-color: #CE1818;
    }
`;

const BtnsModal = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: ${pc ? "1vw" : "6vw"};

    & span {
        width: ${pc ? "10vw" : "35vw"};
        justify-content: center;
        display: flex;
        padding: ${pc ? "0.5vw" : "2vw"};
        border-radius: ${pc ? "0.4vw" : "0.5vw"};
        background-color: #767676;
        border:none;
        color:#fff;
        cursor: pointer;
        font-size: ${pc ? "1vw" : "3.2vw"};
    }
`;

export default function Solicitudes (){

    const location = useLocation();
    const navigate = useNavigate();

    const { session } = useContext(sessionContext);
    const { perfil, setPerfil } = useContext(perfilHotelContext);


    let [solicitud, setSolicitud] = useState("");
    let [solicitudes, setSolicitudes] = useState({vuelos:undefined, traslados:undefined, reservasVillas:undefined, grupos:undefined, reservasHoteles:undefined, seguros:undefined});
    let [ultimoElemento, setUltimoElemento] = useState({vuelos:undefined, traslados:undefined, reservasVillas:undefined, grupos:undefined, reservasHoteles:undefined, seguros:undefined});
    let [infoCotizacion, setInfoCotizacion] = useState("");
    let [monto, setMonto] = useState({totalUs: "", porcentaje:""});
    let [porcentaje, setPorcentaje] = useState(false);

    let [cargando, setCargando] = useState(false);
    
    let [cargando1, setCargando1] = useState(false);

    let [modal, setModal] = useState(false);
    let [btns, setBtns] = useState(false);

    let [msgVal, setMsgVal] = useState({vuelos:[false, ""], traslados:[false, ""], seguros:[false, ""], grupos:[false, ""], reservasVillas:[false, ""], reservasHoteles:[false, ""]});
    let [msgVal1, setMsgVal1] = useState({vuelos:[false, ""], traslados:[false, ""], seguros:[false, ""], grupos:[false, ""], reservasVillas:[false, ""], reservasHoteles:[false, ""]});
    let [confirmacion, setConfirmacion] = useState({});    

    let [modalDel, setModalDel] = useState(false);
    let [msgVal2, setMsgVal2] = useState(false);

    let [infoArchivoDel, setInfoArchivoDel] = useState({txt:true, num:""});

    async function getData(onlyTipo){
        const solicitud1 = solicitud;
        if (!onlyTipo) {
            if (solicitudes[solicitud] === undefined || solicitudes[solicitud] === false) {
                const result = await getSolicitudes({tipo:solicitud1});
                const newSolicitudes = solicitudes;
                if (result[0]) {
                    newSolicitudes[solicitud1] = result[1].datos;
                    setSolicitudes(JSON.parse(JSON.stringify(newSolicitudes)));
                    const newUltimoElemento = ultimoElemento;
                    newUltimoElemento[solicitud] = result[1].ultimoElemento;
                    setUltimoElemento(JSON.parse(JSON.stringify(newUltimoElemento)));

                } else {
                    const msg = msgVal1;
                    msg[solicitud1][1] = result[1];
                    setMsgVal1(JSON.parse(JSON.stringify(msg)));
                    newSolicitudes[solicitud1] = false;
                    setSolicitudes(JSON.parse(JSON.stringify(newSolicitudes)));
                }
            }
        } else {
            if (ultimoElemento[solicitud1] !== false && ultimoElemento[solicitud1] !== undefined) {
                setCargando1(true);
                const result = await getSolicitudes({tipo:solicitud1, ultimoElemento:ultimoElemento[solicitud]});
                const newSolicitudes = solicitudes;

                if (result[0]) {
                    result[1].datos.map((value, index)=>{
                        let igual = true;
                        newSolicitudes[solicitud1].map((value1)=>{                            
                            if (value.id === value1.id) {
                                igual = false;
                            }
                        });
                        if (igual) {
                            newSolicitudes[solicitud].push(value);
                        }
                    });
                    setSolicitudes(JSON.parse(JSON.stringify(newSolicitudes)));
                    const newUltimoElemento = ultimoElemento;
                    newUltimoElemento[solicitud] = result[1].ultimoElemento;
                    setUltimoElemento(JSON.parse(JSON.stringify(newUltimoElemento)));

                } else {
                    const msg = msgVal1;
                    msg[solicitud1][1] = result[1];
                    setMsgVal1(JSON.parse(JSON.stringify(msg)));
                    newSolicitudes[solicitud1] = false;
                    setSolicitudes(JSON.parse(JSON.stringify(newSolicitudes)));
                }
                setCargando1(false);         
            }
        }
    }

    function urlSoli (){
        let newSolicitud = "";
        location.search.split("").map((value, index)=>{
            if (index > 16) {
                newSolicitud = newSolicitud+value; 
            }
        });

        return newSolicitud;
    }

    if (urlSoli() !== solicitud) {
        setSolicitud(urlSoli());
    }

    function comprobarLink (link){
        if (typeof link == "string") {
            const httpIsTrue = link.charAt(0) + link.charAt(1) + link.charAt(2) + link.charAt(3);
            if (httpIsTrue == "http") {
                return true;
            } else {
                return false;
            }
        } else {
            return null;
        }
    }

    async function confirmarSoli (estado, id, index, confi){
        setCargando(true);
        const soli = solicitud;
        const result = await confirmarSolicitud(confi ? {confirmacion:confirmacion[id].confirmacion, tipo:soli, id:id} : {estado:estado, tipo:soli, id:id});
        if (result === true) {
            const solis = solicitudes;
            if (soli == "reservasHoteles" || soli == "reservasVillas") {
                if (confi) {
                    solis[soli][index].confirmacion = confirmacion[id].confirmacion;
                }
            }
            if (!confi) {
                solis[soli][index].estado = estado;
            }
            setSolicitudes(JSON.parse(JSON.stringify(solis)));
        } else {
            const msgVal1 = msgVal;
            msgVal1[soli] = [false, result]; 
            setMsgVal(JSON.parse(JSON.stringify(msgVal1)));
            setTimeout(()=>{
                msgVal1[soli] = [false, ""];
                setMsgVal(JSON.parse(JSON.stringify(msgVal1)));
            }, 1700);
        }
        setCargando(false);
    }

    async function subirArchivo (file, num){
        setCargando(true);
        let result = "";
        if (!file) {
            if (infoCotizacion.length < 1) {
                result = [false, "Rellena el campo."];
            } else {
                result = await subirInfoArchivo(file, modal.id, solicitud, infoCotizacion, num);
            }
        } else {
            result = await subirInfoArchivo(file, modal.id, solicitud, infoCotizacion, num);
        }

        if (result[0]) {
            const newSolicitudes = solicitudes;
            newSolicitudes[solicitud].map((value, index)=>{
                if (modal.id == value.id) {
                    if (num == 2) {
                        newSolicitudes[solicitud][index].infoCotizacion2 = result[1];  
                    } else {
                        newSolicitudes[solicitud][index][solicitud == "seguros" ? "documentoConfirmacion" : "infoCotizacion"] = file ? result[1] : infoCotizacion;  
                    }       
                }
            });
            setBtns("");
            setInfoCotizacion("");
            setSolicitudes(newSolicitudes);
        } else {
            const newMsgVal = msgVal;
            newMsgVal[solicitud] = [result[0], result[1]];
            setMsgVal(JSON.parse(JSON.stringify(newMsgVal)));
            setTimeout(()=>{
                const newMsgVal = msgVal;
                newMsgVal[solicitud] = [false, ""];
                setMsgVal(JSON.parse(JSON.stringify(newMsgVal)));    
            }, 2000);
        };
        setCargando(false);
    }

    async function eliminarInfoArchivo () {
        setCargando(true);
        const result = await delInfoArchivo({id:modal.id, num:infoArchivoDel.num, txt:infoArchivoDel.txt, tipo:solicitud});
        
        if (result === true) {
            const newSolicitudes = solicitudes;
            newSolicitudes[solicitud].map((value, index)=>{
                if (modal.id == value.id) {
                    if (infoArchivoDel.txt) {
                        newSolicitudes[solicitud][index].infoCotizacion = null;
                    } else {
                        newSolicitudes[solicitud][index]["infoCotizacion"+infoArchivoDel.num] = false;
                    }
                }
            });
            setInfoCotizacion("");
            setSolicitudes(newSolicitudes);
        } else {
            const newMsgVal = msgVal;
            newMsgVal[solicitud] = [false, result];
            setMsgVal(JSON.parse(JSON.stringify(newMsgVal)));
            setTimeout(()=>{
                const newMsgVal = msgVal;
                newMsgVal[solicitud] = [false, ""];
                setMsgVal(JSON.parse(JSON.stringify(newMsgVal)));    
            }, 2000);
        }

        setCargando(false);
        setModalDel(false);
    }

    async function addTotal (){
        setCargando(true);
        if (monto.totalUs > 0 && monto.porcentaje.length > 0) {
            let comision = "";
            if (porcentaje) {
                monto.porcentaje.split("").map((letra)=>{
                    if (letra != "%") {
                        comision = comision + letra;
                    }
                })
                comision = comision*monto.totalUs/100;
            } else {
                comision = monto.porcentaje;
            }
            const result = await addTotalSolicitud({tipo:solicitud, id:modal.id, totalUs:monto.totalUs, porcentaje:comision});
            if (result[0]) {
                const newSolicitudes = solicitudes;
                newSolicitudes[solicitud].map((value, index)=>{
                    if (modal.id == value.id) {
                        newSolicitudes[solicitud][index].totalUs = result[1].totalUs;
                        newSolicitudes[solicitud][index].comisionUs = result[1].comisionUs;   
                    }
                });
                setMonto({totalUs:"", porcentaje:""});
                setSolicitudes(newSolicitudes);
            } else {
                const newMsgVal = msgVal;
                newMsgVal[solicitud] = [false, result[1]];
                setMsgVal(JSON.parse(JSON.stringify(newMsgVal)));
                setTimeout(()=>{
                    const newMsgVal = msgVal;
                    newMsgVal[solicitud] = [false, ""];
                    setMsgVal(JSON.parse(JSON.stringify(newMsgVal)));    
                }, 2000);
            }
        } else {
            const newMsgVal = msgVal;
            newMsgVal[solicitud] = [false, "Solo se admiten numeros."];
            setMsgVal(JSON.parse(JSON.stringify(newMsgVal)));
            setTimeout(()=>{
                const newMsgVal = msgVal;
                newMsgVal[solicitud] = [false, ""];
                setMsgVal(JSON.parse(JSON.stringify(newMsgVal)));    
            }, 2000);
        }
        setCargando(false);
    }

    function validarInputConfirmacion (id, index){
        let result = false;

        if (confirmacion[id]) {
            if (confirmacion[id].confirmacion.length > 0) {
                if (confirmacion[id].confirmacion !== solicitudes.reservasHoteles[index].confirmacion) {
                    result = true;
                }
            }
        } else {
            result = false;
        }

        return result;
    }

    function updateInputComision (valor){
        let caracteres = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0, "%", "."];
        let valorLetras = valor.split("");
        let valido = true;
        valorLetras.map((letra, index)=>{
            if (index == 0) {
                if (letra == 0) {
                    if (valorLetras.length > 1) {
                        if (valorLetras[index+1] != "%") {
                            valido = false;
                        }
                    }
                }
            }
            if (letra == "%") {
                if (valorLetras[index+1]) {
                    valido = false;
                }
            }

            let letraVal = false;
            caracteres.map((caracter)=>{
                if (caracter == letra) {
                    letraVal = true;
                }
            });
            if (!letraVal) {
                valido = false;
            }
        });

        if (valor[valor.length-1] == "%") {
            setPorcentaje(true);
        } else {
            setPorcentaje(false);
        }

        if (valido) {
            const newMonto = monto;
            newMonto.porcentaje = valor;
            setMonto(JSON.parse(JSON.stringify(monto)));
        }
    }

    function montoNetoDepositar (num){
        let total = num-num*8/100;
        let totalFull = "";
        let index = false;

        total = total.toString().split("");

        total.map((num, index1)=>{
            if (num == ".") {
                index = index1;
            };
        });

        if (index) {
            total.map((num, index1)=>{
                if (index1 <= index+2) {
                    totalFull = totalFull+num;
                };
            });
        } else {
            totalFull = num;
        }

        return totalFull;
    }

    function modificarReserva (tipo){
        const newPerfil = perfil;
        newPerfil.reserva = modal;
        setPerfil(newPerfil);
        navigate(`/${tipo}/${modal.hotel}/?tab=modificar-reserva`);
    }

    async function cambiarEstadoComision (valor){
        setCargando(true);
        const result = await toggleEstadoComision(solicitud, valor == "Pendiente" ? false : true, modal.id);
        if (result === true) {
            const modalNew = modal;
            const solicitudesNew = solicitudes;
            const index = solicitudesNew[solicitud].findIndex(e => e.id == modal.id);
            if (index > -1) {
                if (solicitud == "reservasHoteles" || solicitud == "reservasVillas") {
                    modalNew.campos.estadoComision = valor == "Pendiente" ? false : true;
                    solicitudesNew[solicitud][index].campos.estadoComision = valor == "Pendiente" ? false : true;
                } else {
                    modalNew.estadoComision = valor == "Pendiente" ? false : true;
                    solicitudesNew[solicitud][index].estadoComision = valor == "Pendiente" ? false : true;
                }
            }
            
            setSolicitudes(JSON.parse(JSON.stringify(solicitudesNew)));
            setModal(JSON.parse(JSON.stringify(modalNew)));
        } else {
            if (typeof result === "string") {
                alert(result);
            }
        }
        setCargando(false);
    }

    async function eliminarSolicitud (){
        setCargando(true);
       const result = await eliminarUserOsolicitud({solicitudID:modalDel, tipo:solicitud});

        if (result === true) {
            setMsgVal2(["Solicitud eliminada correctamente.", true]);
            const solicitudesNew = solicitudes;
            const index = solicitudesNew[solicitud].findIndex(e=>e.id == modalDel);
            solicitudesNew[solicitud].splice(index, 1);
            setSolicitudes(JSON.parse(JSON.stringify(solicitudesNew)));
        } else {
            setMsgVal2(JSON.parse(JSON.stringify([result, false])));
        }
        
        setTimeout(()=>{
            setMsgVal2(["", false]);
        },2000);

        setModalDel(false);
        setCargando(false);
    }

    useEffect(()=>{
        if (session.user && solicitud) {
            getData();
        }
        setBtns();
    }, [session.user, location.search]);

    return (
        <div>
            { 
                <SolicitudesCont>
                    <input style={{display:"none"}} type="file" onChange={(e)=>subirArchivo(e.target.files[0])} id="btnSubirArchivoSolicitud" />
                    <input style={{display:"none"}} type="file" onChange={(e)=>subirArchivo(e.target.files[0], 2)} id="btnSubirArchivoSolicitud2" />
                    {solicitud && <Msg style={{zIndex:6000}} activo={msgVal[solicitud][1]} error={msgVal[solicitud][0]}><div><p>{msgVal[solicitud][1]}</p></div></Msg>}
                    <h3>{urlSoli() == "reservasHoteles" ? "Reservas de hoteles" : urlSoli() == "reservasVillas" ? "Reservas de villas" : "Solicitudes de "+urlSoli()}</h3>
                    { (solicitudes[solicitud] !== false && solicitudes[solicitud] !== undefined) ? solicitudes[solicitud].length > 0 ?
                            (solicitud == "reservasHoteles" || solicitud == "reservasVillas") ?
                            <SolicitudesCont marginBottom={true}>
                                <CuadroContenedor>
                                    <CuadroCont>
                                        <TopCuadroContenedor>
                                            <Cuadro>
                                                <p>{solicitud == "reservasHoteles" ? "Hotel" : "Villa"}</p>
                                            </Cuadro>
                                            <Cuadro>
                                                <p>Cliente</p>
                                            </Cuadro>
                                            <Cuadro>
                                                <p>Confirmación</p>
                                            </Cuadro>
                                            <Cuadro>
                                                <p>Estado del pago</p>
                                            </Cuadro>
                                        </TopCuadroContenedor>
                                        { solicitudes[solicitud].map((value, index)=>(
                                            <BottomCuadroContenedor style={{position:"relative"}} key={value.id}>
                                                <Cuadro style={{cursor:"pointer"}} onClick={()=>setModal(value)}> <p style={{textDecoration:"underline"}}>{value.hotel || value.villa}</p></Cuadro>
                                                <Cuadro><p>{value.campos.titular}</p></Cuadro>
                                                <Cuadro>
                                                    { cargando ?
                                                        <CargandoCont><img src={cargandoImg1} /></CargandoCont>    
                                                        :
                                                        <InputConfirmacion>
                                                            <input defaultValue={value.confirmacion} onChange={(e)=>{const confirmacion1 = confirmacion; if(!confirmacion1[value.id]){ confirmacion1[value.id] = {};};  confirmacion1[value.id].confirmacion = e.target.value; setConfirmacion(JSON.parse(JSON.stringify(confirmacion1)))}} />
                                                            <img style={{cursor:"pointer", opacity:validarInputConfirmacion(value.id, index) ? "1" : "0.5"}} src={confirmar} onClick={()=>validarInputConfirmacion(value.id, index) && confirmarSoli("", value.id, index, true)} />
                                                        </InputConfirmacion>
                                                    }
                                                </Cuadro>
                                                <Cuadro>
                                                    { cargando ?
                                                        <CargandoCont><img src={cargandoImg1} /></CargandoCont>    
                                                        :
                                                        <Select onChange={(e)=>{confirmarSoli(e.target.value, value.id, index)}} defaultValue={value.estado}>
                                                            <option value={value.estado}>{value.estado}</option>
                                                            {value.estado != "Pendiente" && <option>Pendiente</option>}
                                                            {value.estado != "Pagado" && <option>Pagado</option>}
                                                            {value.estado != "Cancelada" && <option>Cancelada</option>}
                                                        </Select>
                                                    }
                                                </Cuadro>
                                                <BtnQuitar>
                                                    <img onClick={()=>setModalDel(value.id)} src={quitar} />
                                                </BtnQuitar>
                                            </BottomCuadroContenedor>
                                        ))}     
                                    </CuadroCont>
                                </CuadroContenedor>
                                { (ultimoElemento[solicitud] !== undefined && ultimoElemento[solicitud] !== false) ? 
                                    cargando1 ? 
                                        <CargandoCont><img src={cargandoImg}/></CargandoCont> 
                                        : 
                                        <p onClick={()=>getData(true)} style={{ margin:"0 auto", cursor:"pointer", padding:"0.5vw", textDecoration:"underline"}}>Ver mas</p> 
                                    : <p></p>
                                }
                            </SolicitudesCont>
                            :
                            <SolicitudesCont>
                                <CuadroContenedor>
                                    <CuadroCont>
                                        <TopCuadroContenedor>
                                            <Cuadro>
                                                <p>{solicitud == "grupos" ? "Grupo" : "Cliente"}</p>
                                            </Cuadro>
                                            <Cuadro>
                                                <p>Detalles</p>
                                            </Cuadro>
                                            <Cuadro>
                                                <p>Estado</p>
                                            </Cuadro>
                                        </TopCuadroContenedor>
                                        { solicitudes[solicitud].map((value, index)=>(
                                            <BottomCuadroContenedor style={{position:"relative"}} key={value.id}>
                                                <Cuadro> <p>{ solicitud == "seguros" ? value.form.nombreTitular : solicitud == "grupos" ? value.nombreGrupo : value.nombreTitular}</p></Cuadro>
                                                <Cuadro style={{cursor:"pointer"}} onClick={()=>setModal(value)}><p style={{textDecoration:"underline"}}>Ver</p></Cuadro>
                                                <Cuadro>
                                                    { cargando ?
                                                        <CargandoCont><img src={cargandoImg1} /></CargandoCont>    
                                                    :
                                                        <Select onChange={(e)=>{confirmarSoli(e.target.value, value.id, index)}} defaultValue="DEFAULT">
                                                            <option value="DEFAULT">{value.estado}</option>
                                                            {value.estado != "Pendiente" && <option>Pendiente</option>}
                                                            {value.estado != (solicitud == "seguros" ? "Confirmado" : "Cotizado") && <option>{solicitud == "seguros" ? "Confirmado" : "Cotizado"}</option>}
                                                            {value.estado != "Cancelado" && <option>Cancelado</option>}
                                                        </Select>
                                                    }
                                                </Cuadro>
                                                <BtnQuitar>
                                                    <img onClick={()=>setModalDel(value.id)} src={quitar} />
                                                </BtnQuitar>
                                            </BottomCuadroContenedor>
                                        ))}     
                                    </CuadroCont>
                                </CuadroContenedor>
                                { (ultimoElemento[solicitud] !== undefined && ultimoElemento[solicitud] !== false) ? 
                                    cargando1 ? 
                                        <CargandoCont><img src={cargandoImg}/></CargandoCont> 
                                        : 
                                        <p onClick={()=>getData(true)} style={{margin:"0 auto", cursor:"pointer", padding:"0.5vw", textDecoration:"underline"}}>Ver mas</p> 
                                    : <p></p>
                                }
                            </SolicitudesCont>
                        : <Val>No tienes solicitudes de {solicitud == "reservasVillas" ? "reservas de villas" : solicitud == "reservasHoteles" ? "reservas de hoteles" : solicitud}</Val>
                        : solicitudes[solicitud] === undefined ?
                            <CargandoCont><img src={cargandoImg} /></CargandoCont>
                        : <Val>{msgVal1[solicitud]}</Val>
                    }
                </SolicitudesCont> 
            }
            <Modal visible={modal} setVisible={()=>{setModal(false); setMonto(JSON.parse(JSON.stringify({totalUs:"", porcentaje:""}))); setBtns()}}>
                <BtnCerrar onClick={()=>{ if (!cargando) {setModal(false); setMonto(JSON.parse(JSON.stringify({totalUs:"", porcentaje:""})));}}}><img src={quitar1}/></BtnCerrar>    
                {modal && 
                    (solicitud == "reservasHoteles" || solicitud == "reservasVillas") ? 
                        <DetallesReserva>
                            <h2>Detalles de la reserva</h2>
                            <div><h3>Nombre del agente:</h3><span>{modal.nombreAgente}</span></div>
                            <div><h3>Estado del pago de la reserva</h3><span style={{color: modal.estado == "Pagado" && "#26AC20"}}>{modal.estado}</span></div>
                            <div>
                                <h3>Voucher:</h3>
                                <span>
                                    {modal.transferenciaVoucher == "none" ? "Sin documento" : 
                                        <BtnDescargar onClick={()=>window.open(modal.transferenciaVoucher)}>
                                            <p>Descargar</p>
                                            <img src={descargar} />
                                        </BtnDescargar>
                                    }
                                </span>
                            </div>
                            <div><h3>Titular:</h3><span>{modal.campos.titular}</span></div>
                            { solicitud == "reservasHoteles" && <div><h3>Habitaciones</h3><span>{modal.form.length}</span></div>}
                            <div><h3>Monto total:</h3><span>{modal.campos.totalUs} USD</span></div>
                            <div><h3>Monto adicional por confirmar:</h3><span style={{color:modal.montoAdicional && modal.montoAdicional.toString().charAt(0) == "-" && "#1D9222"}}>{modal.montoAdicional ? modal.montoAdicional : 0} USD</span></div>
                            <div><h3>Monto neto a depositar:</h3><span>{montoNetoDepositar(modal.campos.totalUs)} USD</span></div>
                            {  session.user && session.user.uid == "viqknVtNHzVNDKl8dZCMjiielij1" ? 
                                    <div>
                                        <h3>Estado de pago de comisión:</h3>
                                        {   cargando ?
                                                <CargandoCont><img src={cargandoImg} /></CargandoCont>
                                            :
                                                <span>
                                                    <select onChange={(e)=>cambiarEstadoComision(e.target.value)} defaultValue="DEFAULT">
                                                        <option value="DEFAULT">{modal.campos.estadoComision !== true ? "Pendiente" : "Pagado"}</option>
                                                        {modal.campos.estadoComision !== true && <option>Pagado</option>}
                                                        {modal.campos.estadoComision === true && <option>Pendiente</option>}
                                                    </select>
                                                </span>
                                        }
                                    </div> 
                                :
                                    <div>
                                        <h3>Estado de pago de comisión</h3>
                                        <span style={{color: modal.campos.estadoComision == true && "#26AC20"}}>{modal.campos.estadoComision ? "Pagado" : "Pendiente"}</span>
                                    </div>
                            }
                            <div><h3>Comisión:</h3><span>{modal.campos.totalUs*8/100} USD</span></div>
                            { session.user && session.user.uid == "viqknVtNHzVNDKl8dZCMjiielij1" && 
                                <BtnEditar onClick={()=>modificarReserva("hotel")}>
                                    <p>Modificar Reserva</p>
                                    <img src={editar} />
                                </BtnEditar>
                            }
                        </DetallesReserva>
                    : solicitud == "seguros" ?
                        <DetallesReserva>
                            <h2>Detalles de la cotización</h2>
                            <div><h3>Nombre del agente:</h3><span>{modal.nombreAgente}</span></div>
                            <div><h3>Estado</h3><span style={{color: modal.estado == "Confirmado" && "#26AC20"}}>{modal.estado}</span></div>
                            <div>
                                <h3>Voucher:</h3>
                                <span>
                                    {modal.transferenciaVoucher == "none" ? "Sin documento" : 
                                        <BtnDescargar onClick={()=>window.open(modal.transferenciaVoucher)}>
                                            <p>Descargar</p>
                                            <img src={descargar} />
                                        </BtnDescargar>
                                    }
                                </span>
                            </div>
                            <div>
                                <h3>Documento de confirmación:</h3>
                                <span>
                                    {modal.documentoConfirmacion == "none" 
                                        ?
                                        <BtnsText>
                                            { cargando ?  
                                                <CargandoCont><img src={cargandoImg} /></CargandoCont>
                                                :
                                                <BtnSubir onClick={()=>document.querySelector("#btnSubirArchivoSolicitud").click()}>
                                                    <p>Subir</p>
                                                    <img src={subir} />
                                                </BtnSubir>
                                            }
                                        </BtnsText>
                                        : 
                                        <BtnsText>
                                            <BtnDescargar onClick={()=>window.open(modal.documentoConfirmacion)}>
                                                <p>Descargar</p>
                                                <img src={descargar} />
                                            </BtnDescargar>
                                        </BtnsText>
                                    }
                                </span>
                            </div>
                            <div>
                                <h3>Monto total:</h3>
                                <span>
                                    { modal.totalUs ? 
                                        "USD $ "+modal.totalUs
                                        :
                                        cargando ?
                                            <CargandoCont><img src={cargandoImg} /></CargandoCont>
                                        :
                                            <DivText>
                                                <input type="number" placeholder="Monto total" onChange={(e)=>{const newMonto = monto; newMonto.totalUs = e.target.value; setMonto(JSON.parse(JSON.stringify(newMonto)))}}/>
                                                <input value={monto.porcentaje} placeholder="Comisión" onChange={(e)=>updateInputComision(e.target.value)}/>
                                                <BtnsText>
                                                    <Button onClick={()=>addTotal()} style={{marginLeft:pc ? "1.2vw" : "2vw"}}><img src={confirmar} /></Button>
                                                </BtnsText>
                                            </DivText>
                                    }
                                </span>
                            </div>
                            <div><h3>Comision:</h3><span>{modal.comisionUs && "USD $ "+modal.comisionUs}</span></div>
                            {   session.user &&
                                    session.user.uid == "viqknVtNHzVNDKl8dZCMjiielij1" ?
                                        <div>
                                            <h3>Estado de pago de comisión:</h3>
                                            {   cargando ?
                                                    <CargandoCont><img src={cargandoImg} /></CargandoCont>
                                                :
                                                    <span>
                                                        <select onChange={(e)=>cambiarEstadoComision(e.target.value)} defaultValue="DEFAULT">
                                                            <option value="DEFAULT">{modal.estadoComision !== true ? "Pendiente" : "Pagado"}</option>
                                                            {modal.estadoComision !== true && <option>Pagado</option>}
                                                            {modal.estadoComision === true && <option>Pendiente</option>}
                                                        </select>
                                                    </span>
                                            }
                                        </div> 
                                    :
                                        <div>
                                            <h3>Estado de pago de comisión</h3>
                                            <span style={{color: modal.estadoComision == true && "#26AC20"}}>{modal.estadoComision ? "Pagado" : "Pendiente"}</span>
                                        </div>
                            }
                            <div><h3>Titular:</h3><span>{modal ? modal.form.nombreTitular : ""}</span></div>
                            <div><h3>Destino:</h3><span>{modal ? modal.form.destino : ""}</span></div>
                            <div><h3>Fecha de ida:</h3><span>{modal ? modal.form.desdeFecha : ""}</span></div>
                            <div><h3>Fecha de regreso:</h3><span>{modal ? modal.form.hastaFecha : ""}</span></div>
                            <div><h3>Cantidad de personas:</h3><span>{modal ? modal.form.cantidadPersonas : ""}</span></div>
                            <div><h3>Seguro:</h3><span>{modal.seguro}</span></div>
                        </DetallesReserva>
                    : solicitud == "grupos" ?
                        <DetallesReserva>
                            <h2>Detalles de la cotización</h2>
                            <div><h3>Nombre del agente:</h3><span>{modal.nombreAgente}</span></div>
                            <div><h3>Confirmado:</h3><span style={{color: modal.confirmado && "#26AC20"}}>{modal.confirmado ? "Si" : "Sin confirmar"}</span></div>
                            <div><h3>Estado:</h3><span style={{color: modal.estado == "Cotizado" && "#26AC20"}}>{modal.estado}</span></div>
                            <div>
                                <h3>Info cotización:</h3>
                                <span>
                                    {   !cargando ?
                                        (comprobarLink(modal.infoCotizacion) == false || btns == "text") ?
                                                btns == "text" ?
                                                <DivText>
                                                    <textarea onChange={(e)=>setInfoCotizacion(e.target.value)} value={infoCotizacion}></textarea>
                                                    <BtnsText>
                                                        <Button onClick={()=>{setBtns(); setInfoCotizacion("")}}><img src={cancelar} /></Button>
                                                        <Button onClick={()=>subirArchivo()} style={{marginLeft:pc ? "1.2vw" : "2vw"}}><img src={confirmar} /></Button>
                                                    </BtnsText>
                                                </DivText>
                                                :   modal.infoCotizacion
                                          :
                                                <div style={{borderBottom:0}}>
                                                    { cargando ?
                                                        <CargandoCont><img src={cargandoImg} /></CargandoCont>
                                                        :
                                                        <div style={{borderBottom:0, margin:!pc && "auto 0"}}>
                                                            <Button onClick={()=>setBtns("text")}>Texto</Button>
                                                            <Btns>
                                                                <Btns row={true}>
                                                                    {modal.infoCotizacion ? 
                                                                        <BtnDescargar style={{marginLeft:pc ? "0.5vw" : "2vw"}} onClick={()=>window.open(modal.infoCotizacion)}>
                                                                            <p>Descargar</p>
                                                                            <img src={descargar} />
                                                                        </BtnDescargar>
                                                                        :
                                                                        <BtnSubir onClick={()=>document.querySelector("#btnSubirArchivoSolicitud").click()} style={{marginLeft:pc ? "1.2vw" : "2vw"}}>
                                                                            <p>Archivo 1</p>
                                                                            <img src={subir} />
                                                                        </BtnSubir>
                                                                    }
                                                                    <BtnQuitarArchivo opacity={modal.infoCotizacion} onClick={()=>{setModalDel(true); setInfoArchivoDel({txt:false, num:""})}}>
                                                                        <img src={quitar} />
                                                                    </BtnQuitarArchivo>
                                                                </Btns>
                                                                <Btns style={{marginTop:pc ? "0.5vw" : ""}} row={true}>
                                                                    {modal.infoCotizacion2 ?
                                                                        <BtnDescargar style={{marginLeft:pc ? "0.5vw" : "2vw"}} onClick={()=>window.open(modal.infoCotizacion)}>
                                                                            <p>Descargar</p>
                                                                            <img src={descargar} />
                                                                        </BtnDescargar> 
                                                                        :
                                                                        <BtnSubir onClick={()=>document.querySelector("#btnSubirArchivoSolicitud2").click()} style={{marginLeft:pc ? "1.2vw" : "2vw"}}>
                                                                            <p>Archivo 2</p>
                                                                            <img src={subir} />
                                                                        </BtnSubir>
                                                                    }
                                                                    <BtnQuitarArchivo opacity={modal.infoCotizacion2} onClick={()=>{setModalDel(true); setInfoArchivoDel({txt:false, num:2})}}>
                                                                        <img src={quitar} />
                                                                    </BtnQuitarArchivo>
                                                                </Btns>
                                                            </Btns>
                                                        </div>
                                                    } 
                                                    {/* { modal.infoCotizacion && <Button style={{backgroundColor:"transparent"}} onClick={()=>setBtns("none")}><img style={{width:pc ? "2vw" : ""}} src={cancelar} /></Button>}
                                                    <Button onClick={()=>setBtns("archivo")} style={{marginLeft:pc ? "0.5vw" : "2vw"}}>Archivo</Button> */}
                                                </div>      
                                            
                                        : <CargandoCont><img src={cargandoImg} /></CargandoCont>
                                    }
                                </span>
                            </div>
                            <div>
                                <h3>Monto total:</h3>
                                <span>
                                    { modal.totalUs ? 
                                        "USD $ "+modal.totalUs
                                        :
                                        cargando ?
                                            <CargandoCont><img src={cargandoImg} /></CargandoCont>
                                        :
                                            <DivText>
                                                <input value={monto.totalUs} type="number" placeholder="Monto total" onChange={(e)=>{const newMonto = monto; newMonto.totalUs = e.target.value; setMonto(JSON.parse(JSON.stringify(newMonto)))}}/>
                                                <input value={monto.porcentaje} placeholder="Comisión" onChange={(e)=>updateInputComision(e.target.value)}/>
                                                <BtnsText>
                                                    <Button onClick={()=>addTotal()} style={{marginLeft:pc ? "1.2vw" : "2vw"}}><img src={confirmar} /></Button>
                                                </BtnsText>
                                            </DivText>
                                    }
                                </span>
                            </div>
                            <div><h3>Comision:</h3><span>{modal.comisionUs && "USD $ "+modal.comisionUs}</span></div>
                            {   session.user &&
                                    session.user.uid == "viqknVtNHzVNDKl8dZCMjiielij1" ?
                                        <div>
                                            <h3>Estado de pago de comisión:</h3>
                                            {   cargando ?
                                                    <CargandoCont><img src={cargandoImg} /></CargandoCont>
                                                :
                                                    <span>
                                                        <select onChange={(e)=>cambiarEstadoComision(e.target.value)} defaultValue="DEFAULT">
                                                            <option value="DEFAULT">{modal.estadoComision !== true ? "Pendiente" : "Pagado"}</option>
                                                            {modal.estadoComision !== true && <option>Pagado</option>}
                                                            {modal.estadoComision === true && <option>Pendiente</option>}
                                                        </select>
                                                    </span>
                                            }
                                        </div> 
                                    :
                                        <div>
                                            <h3>Estado de pago de comisión</h3>
                                            <span style={{color: modal.estadoComision == true && "#26AC20"}}>{modal.estadoComision ? "Pagado" : "Pendiente"}</span>
                                        </div>
                            }
                            <div><h3>Titular:</h3><span>{modal.nombreGrupo}</span></div>
                            <div><h3>Fecha de entrada:</h3><span>{modal.fechaEntrada}</span></div>
                            <div><h3>Fecha de salida:</h3><span>{modal.fechaSalida}</span></div>
                            <div><h3>Adultos:</h3><span>{modal.adultos}</span></div>
                            <div><h3>Niños:</h3><span>{modal.niños}</span></div>
                            <div><h3>Cantidad de habitaciones:</h3><span>{modal.cantidadHabitaciones}</span></div>
                            <div><h3>Categoria de habitaciones:</h3><span>{modal.categoriaHabitacion}</span></div>
                            { modal && modal.edades.map((value, index)=>(
                                <div key={index+1}><h3>Edad de niño {index+1}:</h3><span>{value}</span></div>
                            ))}
                            <div><h3>Observaciones:</h3><span>{modal.observaciones}</span></div>
        
                        </DetallesReserva>
                    :
                        <DetallesReserva>
                            <h2>Detalles de la cotización</h2>
                            <div><h3>Nombre del agente:</h3><span>{modal.nombreAgente}</span></div>
                            <div><h3>Confirmado:</h3><span style={{color: modal.confirmado && "#26AC20"}}>{modal.confirmado ? "Si" : "Sin confirmar"}</span></div>
                            <div><h3>Estado:</h3><span style={{color: modal.estado == "Cotizado" && "#26AC20"}}>{modal.estado}</span></div>
                            <div>
                                <h3>Info cotización:</h3>
                                <span>
                                    {   !cargando ?
                                        (comprobarLink(modal.infoCotizacion) == false || btns == "text") ?
                                                btns == "text" ?
                                                <DivText>
                                                    <textarea onChange={(e)=>setInfoCotizacion(e.target.value)} value={infoCotizacion}></textarea>
                                                    <BtnsText>
                                                        <Button onClick={()=>{setBtns(); setInfoCotizacion("")}}><img src={cancelar} /></Button>
                                                        <Button onClick={()=>subirArchivo()} style={{marginLeft:pc ? "1.2vw" : "2vw"}}><img src={confirmar} /></Button>
                                                    </BtnsText>
                                                </DivText>
                                                :   modal.infoCotizacion
                                          :
                                                <div style={{borderBottom:0}}>
                                                    { cargando ?
                                                        <CargandoCont><img src={cargandoImg} /></CargandoCont>
                                                        :
                                                        <div style={{borderBottom:0}}>
                                                            <Button onClick={()=>setBtns("text")}>Texto</Button>
                                                            <Btns>
                                                                <Btns row={true}>
                                                                    {modal.infoCotizacion ? 
                                                                        <BtnDescargar style={{marginLeft:pc ? "0.5vw" : "2vw"}} onClick={()=>window.open(modal.infoCotizacion)}>
                                                                            <p>Descargar</p>
                                                                            <img src={descargar} />
                                                                        </BtnDescargar>
                                                                        :
                                                                        <BtnSubir onClick={()=>document.querySelector("#btnSubirArchivoSolicitud").click()} style={{marginLeft:pc ? "1.2vw" : "2vw"}}>
                                                                            <p>Archivo 1</p>
                                                                            <img src={subir} />
                                                                        </BtnSubir>
                                                                    }
                                                                    <BtnQuitarArchivo opacity={modal.infoCotizacion} onClick={()=>{setModalDel(true); setInfoArchivoDel({txt:false, num:""})}}>
                                                                        <img src={quitar} />
                                                                    </BtnQuitarArchivo>
                                                                </Btns>
                                                                <Btns style={{marginTop:pc ? "0.5vw" : ""}} row={true}>
                                                                    {modal.infoCotizacion2 ?
                                                                        <BtnDescargar style={{marginLeft:pc ? "0.5vw" : "2vw"}} onClick={()=>window.open(modal.infoCotizacion)}>
                                                                            <p>Descargar</p>
                                                                            <img src={descargar} />
                                                                        </BtnDescargar> 
                                                                        :
                                                                        <BtnSubir onClick={()=>document.querySelector("#btnSubirArchivoSolicitud2").click()} style={{marginLeft:pc ? "1.2vw" : "2vw"}}>
                                                                            <p>Archivo 2</p>
                                                                            <img src={subir} />
                                                                        </BtnSubir>
                                                                    }
                                                                    <BtnQuitarArchivo opacity={modal.infoCotizacion2} onClick={()=>{setModalDel(true); setInfoArchivoDel({txt:false, num:2})}}>
                                                                        <img src={quitar} />
                                                                    </BtnQuitarArchivo>
                                                                </Btns>
                                                            </Btns>
                                                        </div>
                                                    } 
                                                    {/* { modal.infoCotizacion && <Button style={{backgroundColor:"transparent"}} onClick={()=>setBtns("none")}><img style={{width:pc ? "2vw" : ""}} src={cancelar} /></Button>}
                                                    <Button onClick={()=>setBtns("archivo")} style={{marginLeft:pc ? "0.5vw" : "2vw"}}>Archivo</Button> */}
                                                </div>      
                                            
                                        : <CargandoCont><img src={cargandoImg} /></CargandoCont>
                                    }
                                </span>
                            </div>
                            <div>
                                <h3>Voucher:</h3>
                                <span>
                                    {modal.transferenciaVoucher == "none" ? "Sin documento" : 
                                        <BtnDescargar onClick={()=>window.open(modal.transferenciaVoucher)}>
                                            <p>Descargar</p>
                                            <img src={descargar} />
                                        </BtnDescargar>
                                    }
                                </span>
                            </div>
                            <div>
                                <h3>Monto total:</h3>
                                <span>
                                    { modal.totalUs ? 
                                        "USD $ "+modal.totalUs
                                        :
                                        cargando ?
                                            <CargandoCont><img src={cargandoImg} /></CargandoCont>
                                        :
                                            <DivText>
                                                <input value={monto.totalUs} type="number" placeholder="Monto total" onChange={(e)=>{const newMonto = monto; newMonto.totalUs = e.target.value; setMonto(JSON.parse(JSON.stringify(newMonto)))}}/>
                                                <input value={monto.porcentaje} placeholder="Comisión" onChange={(e)=>updateInputComision(e.target.value)}/>
                                                <BtnsText>
                                                    <Button onClick={()=>addTotal()} style={{marginLeft:pc ? "1.2vw" : "2vw"}}><img src={confirmar} /></Button>
                                                </BtnsText>
                                            </DivText>
                                    }
                                </span>
                            </div>
                            <div><h3>Comision:</h3><span>{modal.comisionUs && "USD $ "+modal.comisionUs}</span></div>
                            {   session.user &&
                                    session.user.uid == "viqknVtNHzVNDKl8dZCMjiielij1" ?
                                        <div>
                                            <h3>Estado de pago de comisión:</h3>
                                            {   cargando ?
                                                    <CargandoCont><img src={cargandoImg} /></CargandoCont>
                                                :
                                                    <span>
                                                        <select onChange={(e)=>cambiarEstadoComision(e.target.value)} defaultValue="DEFAULT">
                                                            <option value="DEFAULT">{modal.estadoComision !== true ? "Pendiente" : "Pagado"}</option>
                                                            {modal.estadoComision !== true && <option>Pagado</option>}
                                                            {modal.estadoComision === true && <option>Pendiente</option>}
                                                        </select>
                                                    </span>
                                            }
                                        </div> 
                                    :
                                        <div>
                                            <h3>Estado de pago de comisión</h3>
                                            <span style={{color: modal.estadoComision == true && "#26AC20"}}>{modal.estadoComision ? "Pagado" : "Pendiente"}</span>
                                        </div>
                            }
                            <div><h3>Titular:</h3><span>{modal.nombreTitular}</span></div>
                            <div><h3>Fecha de ida:</h3><span>{modal.fechaIda}</span></div>
                            <div><h3>Fecha de regreso:</h3><span>{modal.fechaRegreso || "Sin fecha de regreso"}</span></div>
                            <div><h3>Aeropuerto de salida:</h3><span>{modal.aeropuertoSalida}</span></div>
                            <div><h3>Aeropuerto de destino:</h3><span>{modal.aeropuertoDestino}</span></div>
                            <div><h3>Horario preferible:</h3><span>{modal.horarioPreferible}</span></div>
                            <div><h3>Adultos:</h3><span>{modal.adultos}</span></div>
                            <div><h3>Niños</h3><span>{modal.niños}</span></div>
                            { modal && modal.edades.map((value, index)=>(
                                <div key={index+1}><h3>Edad de niño {index+1}:</h3><span>{value}</span></div>
                            ))}
                            <div><h3>Observaciones:</h3><span>{modal.observaciones}</span></div>
                        </DetallesReserva>

                }
            </Modal>

            <Modal visible={modalDel} setVisible={()=>{}}>
                <ModalEliminar> 
                    <h3>¿Estas seguro de eliminar {modal ? "este documento?" : "esta solicitud"}?</h3>
                    <p>Esta acción no se puede deshacer.</p>
                    {cargando && <CargandoCont><img src={cargandoImg}/></CargandoCont>}
                    <BtnsModal style={{width:"100%", justifyContent:"space-around"}}>
                        <ButtonModal disabled={cargando} opacity={cargando} style={{opacity:cargando && "0.5"}} onClick={()=>!cargando && setModalDel(false)} >No estoy seguro</ButtonModal>
                        <ButtonModal disabled={cargando} opacity={cargando} style={{opacity:cargando && "0.5"}} red={true} onClick={()=>{modal ? eliminarInfoArchivo() : eliminarSolicitud()}}>Estoy seguro</ButtonModal>
                    </BtnsModal>
                </ModalEliminar>
            </Modal>
            <Msg activo={msgVal2[0]} error={msgVal2[1]}><div><p>{msgVal2[0]}</p></div></Msg>            
        </div>
    )
}