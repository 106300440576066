import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import perfilHotelContext from "../../../contexts/perfilHotelContext";
import sessionContext from "../../../contexts/sessionContext";
import styled from "styled-components";
import { reservarVilla } from "../../../config/auth";
import CargandoCont from "../../utils/cargando";
import { validarFecha, calcularTotalVilla, restaFechas, cambiarFechaInput, cambiarFecha } from "../../utils/validar";
import { Formulario, TopFormulario, FormularioCont, Columna, BottomReserva, DivInput, BtnEnviar, Input, Select, SpanValidacion } from "../../utils/formulario";

//imagenes 
import flecha from "../../../images/arrow.png";
import quitar from "../../../images/quitar.png";
import mas from "../../../images/mas.png";
import cargandoImg from "../../../images/cargando.gif";

const pc = window.screen.width > 529 ? true : false;

const ReservaContenedor = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: ${pc && "17vw"};
    padding-bottom: 5vw;

    & h1 {
        margin-bottom: ${pc ? "0" : "2vw"};
        font-weight: 600;
        font-size: ${pc ? "2.1vw" : "7.5vw"};
    }
`;

const TopReserva = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: ${pc ? "0.1vw" : "1vw"};
    margin-bottom: ${pc ? "1.5vw" : "3vw"};

    & p {
        color: #2E2E2E;
        font-size: ${pc ? "1.4vw" : "5.4vw"};
        font-weight: 600;
        margin-right: 2vw;
    }
    & input {
        background-color: #fff;
        width: ${pc ? "40%" : "60%"};
        border: ${pc ? "0.1vw" : "0.5vw"} solid #A4A4A4;
        border-radius: ${pc ? "0.5vw" : "1vw"};
        padding: ${pc ? "0.5vw" : "1vw"};
        font-size: ${pc && "0.9vw"};
    }

    & input:focus {
        outline: none;
    }
`;

const BtnToPoliticas = styled.span`
    color: #fff;
    font-weight: 500;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
`;
const CheckboxCont = styled.div`
    display: flex;
    align-items: center;
    width: ${pc ? "80%" : "100%"};
    margin-bottom: 2vw;
    
    & p {
        font-size: ${pc ? "1.2vw" : "3vw"};
        margin-left: ${!pc && "2vw"};
        font-weight: 300;
    }

    & input {
        width: ${pc ? "10%" : "15%"};
        height: ${!pc && "4.5vw"};
        margin: 0!important;
        padding: 0!important;
        margin-right: 1vw!important;
    }
`;



export default function Reserva ({tarifas, id, name}){

    const navigate = useNavigate();
    const { session } = useContext(sessionContext);

    const edades = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1];
    let inputsValuesFormDefault = {fechaEntrada:[""], rangoPersonas:[""], codigoTarifa:[""], fechaSalida:[""], observaciones:[""]};

    const search = useLocation().search;
    const { setPerfil, perfil } = useContext(perfilHotelContext);
    let [modificar, setModificar] = useState(false); 

    let [categoriaHabitacion, setCategoriaHabitacion] = useState(0);

    let [formularios, setFormularios] = useState([{id:0, visible:true, msgValidacion:inputsValuesFormDefault}]);

    let [titular, setTitular] = useState("");
    let [totalUs, setTotalUs] = useState(0);
    let [comisionUs, setComisionUs] = useState(0);
    let [msgVal, setMsgVal] = useState({titular:"", totalUs:"", checkbox:"", comisionUs:"", msg:""});

    let [cargando, setCargando] = useState(false);
    let [checkbox, setCheckbox] = useState(false);

    function toggleShowFormulario(e){
        let newForm = JSON.parse(JSON.stringify(formularios));
        newForm[e].visible = !newForm[e].visible;
        setFormularios(newForm);
    }

    function addFormulario (){
        const newForm = {id:formularios.length+1, visible:false, msgValidacion:inputsValuesFormDefault};   
        setFormularios([...formularios, newForm]);
    }

    function delFormulario (e){
        let newForm = JSON.parse(JSON.stringify(formularios));
        newForm.splice(e, 1);
        setFormularios(JSON.parse(JSON.stringify(newForm)));
    }


    function validarForm (){
        let result = true;
        const newForm = formularios;

        for( let i = 0; i < newForm.length; i++) {

            const val = newForm[i].msgValidacion;

            let claves = Object.keys(val);
            claves.map((clave, index)=>{
                val[clave][1] = "";
            });

            //VALIDACION FECHA DE ENTRADA
            if (val.fechaEntrada[0].length > 0) {
                let valFecha = validarFecha(val.fechaEntrada[0]);
                if (valFecha !== true){
                    if (modificar) {
                        if (valFecha != "El dia que ingresaste ya paso." && valFecha != "El mes que ingresaste ya paso.") {
                            val.fechaEntrada[1] = valFecha;
                            result = false;
                        }
                    } else {
                        val.fechaEntrada[1] = valFecha;
                        result = false;
                    }
                   
                }
            } else {
                val.fechaEntrada[1] = "Rellena este campo.";
                result = false;
            }

            //VALIDACION RANGO DE PERSONAS
            if (val.rangoPersonas[0].length > 0) {
                
            } else {
                val.rangoPersonas[1] = "Rellena este campo.";
                result = false;
            }

            //VALIDACION CODIGO TARIFA
            if (val.codigoTarifa[0].length > 0) {
                if (val.codigoTarifa[0].length < 50 && val.codigoTarifa[0].length > 0) {
                    if (val.codigoTarifa[0] != "DEFAULT") {

                    } else {
                        val.codigoTarifa[1] = "Selecciona una opción.";
                        result = false;
                    }
                } else {
                    val.codigoTarifa[1] = "Maximo 50 caracteres.";
                    result = false;
                }
            } else {
                if (i < 1) {
                    val.codigoTarifa[1] = "Rellena este campo";
                    result = false;
                }
            }

            //VALIDACION FECHA DE SALIDA
            if (val.fechaSalida[0].length > 0) {
                let valFecha = validarFecha(val.fechaSalida[0]);
                if (valFecha == true) {
                    if (validarFecha(val.fechaEntrada[0])) {
                        if (restaFechas(val.fechaEntrada[0], val.fechaSalida[0]) == 0) {
                            result = false;
                            val.fechaSalida[1] = "Reserva al menos una noche.";
                            val.fechaEntrada[1] = "Reserva al menos una noche.";
                        } else if (restaFechas(val.fechaEntrada[0], val.fechaSalida[0]) < 0){
                            result = false;
                            val.fechaSalida[1] = "La fecha de salida tiene que ser despues de la fecha de entrada";
                        }

                        tarifas.map((value, index)=>{
                            if (val.codigoTarifa[0] == value.codigoOferta) {
                                if (restaFechas(val.fechaEntrada[0], val.fechaSalida[0]) < tarifas[index].minimoNoches) {
                                    result = false;
                                    val.fechaSalida[1] = "La tarifa que escogiste son minimo "+tarifas[index].minimoNoches+" noches";
                                }
                            }
                        });
                    }
                } else {
                    if (valFecha !== true){
                        if (modificar) {
                            if (valFecha != "El dia que ingresaste ya paso." && valFecha != "El mes que ingresaste ya paso.") {
                                val.fechaSalida[1] = valFecha;
                                result = false;
                            }
                        } else {
                            val.fechaSalida[1] = valFecha;
                            result = false;
                        }
                       
                    }
                }
            } else {
                val.fechaSalida[1] = "Rellena este campo";
                result = false;
            }

            //VALIDACION CATEGORIA DE HABITACION
            if (val.observaciones[0].length > 0) {
                if (val.observaciones[0].length > 600) {
                    val.observaciones[1] = "Maximo 500 carateres";
                    result = false;
                }
            }
            
            newForm[i].msgValidacion = val;
        }
        setFormularios(newForm);

        return result;
    }

    async function validarYEnviar (){
        setCargando(true);
        const result = validarForm();
        let resultForm = true;
        const newMsgVal = {titular:"", msg:"", comisionUs:"", checkbox:"", totalUs:""};

        if (!result) {
            newMsgVal.msg = "Los formularios de las habitaciones contienen errores.";
        }

        if (titular.length > 0) {
            if (titular.length > 3) {
                if (titular.length < 91) {
                } else {
                    newMsgVal.titular = "Maximo 90 caracteres.";
                    resultForm = false;
                }
            } else {
                newMsgVal.titular = "Minimo 4 caracteres.";
                resultForm = false;
            } 
        } else {
            resultForm = false;
            newMsgVal.titular = "Rellena este campo.";
        }

        if (!checkbox) {
            newMsgVal.checkbox = "Confirma que estas de acuerdo.";
            resultForm = false;
        }

        if (resultForm && result) {
            let form = [];
            formularios.map((value)=>form.push(value.msgValidacion));
            
            const reservaResult = await reservarVilla({form:form, id:modificar ? perfil.reserva.id : undefined, villa: name, transferenciaVoucher:"none", modificar:modificar ? true : undefined, villaId:id, campos:{titular:titular, comisionUs:comisionUs, totalUs:totalUs}});
            if (reservaResult !== undefined) {
                if (reservaResult.result === true) {
                    newMsgVal.msg = modificar ? "Reserva modificada correctamente" : "Reserva enviada correctamente";
                    setTimeout(()=>{
                        navigate("/villa/"+ name);
                    },4070);
                    setTimeout(()=>{
                        if (modificar) {
                            if (session.admin) {
                                navigate("/admin?tab=solicitudes=reservasVillas");
                            } else {
                                navigate("/plataforma/villas?tab=mis-solicitudes");
                            }
                        } else {
                            navigate("/villa/"+name+"?tab=solicitar-reserva");
                        }
                    }, 4080);
                } else {
                    if (reservaResult.result === false){
                     newMsgVal.msg = "Sucedio un error, Disculpanos";
                    } else {
                        newMsgVal.msg = reservaResult.result;
                    }
                }
            } else {
                newMsgVal.msg = "Sucedio un error, Comprueba tu conexión a internet.";
            }
        }

        setMsgVal(newMsgVal);
        setCargando(false);
    }


    function updateValueInput (index, tipo, valor){
        const newValuesInputs = JSON.parse(JSON.stringify(formularios));
        if (tipo == "fechaSalida" || tipo == "fechaEntrada"){
            const newFecha = cambiarFecha(valor);
            newValuesInputs[index].msgValidacion[tipo][0] = newFecha;
        } else {
            newValuesInputs[index].msgValidacion[tipo][0] = valor;
            if (tipo == "codigoTarifa") {
                newValuesInputs[index].msgValidacion[tipo][0] = "";
                const num = tarifas.findIndex(element => element.codigoOferta == valor);
                if (num > -1) {
                    setCategoriaHabitacion(num);
                }
            }
       }

        if (tipo == "codigoTarifa") {
            setFormularios(newValuesInputs);
            setTimeout(()=>{
                const newValuesInputs1 = JSON.parse(JSON.stringify(formularios));
                newValuesInputs1[index].msgValidacion.rangoPersonas[0] = "";
                newValuesInputs1[index].msgValidacion.codigoTarifa[0] = valor;
                setFormularios(newValuesInputs1);
            }, 50);
        } else {
            setFormularios(newValuesInputs);
        }
    }

    

    useEffect(()=>{
        
        if (calcularTotalVilla(formularios, tarifas) != 0) {            
            setTotalUs(calcularTotalVilla(formularios, tarifas));
            setComisionUs(calcularTotalVilla(formularios, tarifas)*8/100);
        } else {
            if (totalUs > 0 || comisionUs > 0){
                setTotalUs(0);
                setComisionUs(0);
            }
        }

    },[formularios]);


    useEffect(()=>{
        const newForm = formularios;
        if (perfil.reserva && search == "?tab=modificar-reserva") {
            setModificar(true);
            setTitular(perfil.reserva.campos.titular);
            setTotalUs(perfil.reserva.campos.totalUs);
            setComisionUs(perfil.reserva.campos.comisionUs);

            perfil.reserva.form.map((val, index)=>{
                if (index > 0) {
                    newForm.push({visible:false, id:index, msgValidacion:inputsValuesFormDefault});
                };
            });

            newForm.map((value, indexForm)=>{
                newForm[indexForm].msgValidacion.fechaEntrada[0] = perfil.reserva.form[indexForm].fechaEntrada;
                newForm[indexForm].msgValidacion.rangoPersonas[0] = perfil.reserva.form[indexForm].rangoPersonas;
                newForm[indexForm].msgValidacion.codigoTarifa[0] = perfil.reserva.form[0].codigoTarifa;
                newForm[indexForm].msgValidacion.fechaSalida[0] = perfil.reserva.form[indexForm].fechaSalida;
                newForm[indexForm].msgValidacion.observaciones[0] = perfil.reserva.form[indexForm].observaciones;
            });

            setFormularios(newForm);
        } else {
            if (search == "?tab=modificar-reserva"){
                navigate("/plataforma/villas?tab=mis-solicitudes");
            }
        }

        return ()=>{
            setPerfil({name:perfil.name, imgUrl:perfil.imgUrl});
        }
    },[]);

    return (
        <div>
            { tarifas.length > 0 ?
                <ReservaContenedor>
                <h1>{modificar ? "Modifica tu reserva" : "Solicita una Reserva"}</h1>
                <SpanValidacion style={{marginLeft:pc ? "0" : "20%"}}>{msgVal.titular}</SpanValidacion>
                <TopReserva>
                    <p>Titular:</p>
                    <input defaultValue={titular} style={{border:msgVal.titular ? pc ? "0.15vw solid #eb1a1a" : "0.5vw solid #eb1a1a" : (pc ? "0.15vw solid gray" : "0.4vw solid gray")}} onChange={(e)=>setTitular(e.target.value)} placeholder="Nombre del cliente"/>
                </TopReserva>
                { formularios.map((value, e)=>(
                <Formulario key={value.id}>
                    <FormularioCont hoteles={true}>
                        <div style={{display:formularios[e].visible ? "block" : "none", width:pc ? "80%" : "70%"}}>
                            <TopFormulario>
                                <h2>Villa</h2>
                                {/* <span>{value.msgValidacion}</span> */}
                            </TopFormulario>
                            <Columna hoteles={true}>
                                <label>Fecha de entrada</label>
                                <SpanValidacion>{value.msgValidacion.fechaEntrada[1]}</SpanValidacion>
                                <Input type="date" defaultValue={modificar ? cambiarFechaInput(formularios[e].msgValidacion.fechaEntrada[0]) : ""} error={value.msgValidacion.fechaEntrada[1]} onChange={(valor)=>updateValueInput(e, "fechaEntrada", valor.target.value)} placeholder="dia/mes/año"/>
                                <label>Fecha de salida</label>
                                <SpanValidacion >{value.msgValidacion.fechaSalida[1]}</SpanValidacion>
                                <Input type="date" defaultValue={modificar ? cambiarFechaInput(formularios[e].msgValidacion.fechaSalida[0]) : ""} error={value.msgValidacion.fechaSalida[1]} onChange={(valor)=>updateValueInput(e, "fechaSalida", valor.target.value)} placeholder="dia/mes/año"/>
                                { e < 1 && <label>Codigo de tarifa</label>}
                                {e < 1 && <SpanValidacion>{value.msgValidacion.codigoTarifa[1]}</SpanValidacion>}
                                { e < 1 ? modificar ? perfil.reserva.estado == "Pendiente" ?
                                    <Select defaultValue="xd" error={value.msgValidacion.codigoTarifa[1]} onChange={(valor)=>updateValueInput(e, "codigoTarifa", valor.target.value)}>
                                        <option value="xd">{value.msgValidacion.codigoTarifa[0]}</option>
                                        { tarifas && tarifas.map((value, index)=>(
                                            <option key={value.codigoOferta}>{value.codigoOferta}</option>
                                        ))}
                                    </Select>
                                    :
                                    <p style={{backgroundColor:"#fff", color:"#000", marginBottom:pc ? "0.7vw" : "2vw"}}>{value.msgValidacion.codigoTarifa[0]}</p>
                                    :
                                    <Select defaultValue="DEFAULT" error={value.msgValidacion.codigoTarifa[1]} onChange={(valor)=>updateValueInput(e, "codigoTarifa", valor.target.value)}>
                                        <option disabled value="DEFAULT">Codigo Tarifa</option>
                                        { tarifas && tarifas.map((value, index)=>(
                                            <option key={value.codigoOferta}>{value.codigoOferta}</option>
                                        ))}
                                    </Select>
                                    : <p></p>
                                }
                                <label>Rango de personas</label>
                                <SpanValidacion >{value.msgValidacion.rangoPersonas[1]}</SpanValidacion>
                                { formularios[0].msgValidacion.codigoTarifa[0] !== "" ? modificar ? 
                                <Select defaultValue="DEFAULT" error={value.msgValidacion.rangoPersonas[1]} onChange={(valor)=>updateValueInput(e, "rangoPersonas", valor.target.value)}>
                                    <option disabled value="DEFAULT">{formularios[e].msgValidacion.rangoPersonas[0]}</option>
                                    { tarifas[categoriaHabitacion].rangos.map((val, index)=>(
                                            <option key={index}>{val.rangoMin}-{val.rangoMax}</option>
                                        ))
                                    }
                                </Select>
                                :
                                <Select defaultValue="DEFAULT" error={value.msgValidacion.rangoPersonas[1]} onChange={(valor)=>updateValueInput(e, "rangoPersonas", valor.target.value)}>
                                    <option disabled value="DEFAULT">Rango de personas</option>
                                    { tarifas[categoriaHabitacion].rangos.map((val, index)=>(
                                            <option key={index}>{val.rangoMin}-{val.rangoMax}</option>
                                        ))
                                    }
                                </Select>
                                : <p>Selecciona un codigo de tarifa</p>
                                }
                            </Columna>
                            <Columna hoteles={true}>
                                <label>Observaciones</label>
                                <SpanValidacion>{value.msgValidacion.observaciones[1]}</SpanValidacion>
                                <textarea defaultValue={modificar ? formularios[e].msgValidacion.observaciones[0] : ""} style={{border:value.msgValidacion.observaciones[1] && "0.8vw solid #eb1a1a"}} onChange={(valor)=>updateValueInput(e, "observaciones", valor.target.value)}></textarea>
                                <SpanValidacion>{msgVal.checkbox}</SpanValidacion>
                                <CheckboxCont style={{display:"flex", alignItems:"center"}}>
                                    <Input style={{cursor:"pointer", width:pc && "8%"}} error={msgVal.checkbox} type="checkbox" onChange={(e)=>setCheckbox(e.target.checked)} />
                                    <p>He leido y acepto los <BtnToPoliticas onClick={()=>navigate("/terminos-y-condiciones")}>terminos y condiciones.</BtnToPoliticas></p>
                                </CheckboxCont>
                            </Columna>
                        </div>
                    </FormularioCont>
                </Formulario>
                ))}
        
                <BottomReserva>
                    <div style={{display:"flex", flexDirection:"column", alignItems:"center", width:"100%"}}>
                        <DivInput>
                            <p>Total</p>
                            <b>${totalUs} USD</b>
                        </DivInput>
                    </div>
                    <div style={{display:"flex", marginBottom:pc ? "1vw" : "5vw", flexDirection:"column", width:"100%", alignItems:"center",}}>
                        <DivInput>
                            <p>Comisión</p>
                            <b>${comisionUs} USD</b>
                        </DivInput>
                    </div>
                    <SpanValidacion style={{marginBottom:pc && "1vw", textAlign:"center"}} msg={true} error={msgVal.msg == "Reserva enviada correctamente" || msgVal.msg == "Reserva modificada correctamente" ? true : false}>{msgVal.msg}</SpanValidacion>
                    <BtnEnviar onClick={()=>!cargando && validarYEnviar()}>
                        { cargando ?
                            <CargandoCont>
                                <img src={cargandoImg} />
                            </CargandoCont>
                            : 
                            <p>Enviar</p>
                        }
                    </BtnEnviar>
                </BottomReserva>
            </ReservaContenedor>
            : <div><p style={{textAlign:"center"}}>No puedes reservar porque este hotel no tiene tarifas y ofertas</p></div>
              
            }
        </div>
    );
}