import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { SpanValidacion } from "./utils/formulario";
import styled from "styled-components";
import { verificarEmail } from "./login";
import CargandoCont from "./utils/cargando.jsx";
import { enviarFormContacto } from "../config/auth";
import {cambiarFecha} from "./utils/validar";
import sessionContext from "../contexts/sessionContext";

import "../styles/inicio.css";

//imagenes
import ilustracion1 from "../images/ilustracion1.png";
import ilustracion2 from "../images/ilustracion2.png";
import ventaja1 from "../images/ventaja1.png";
import ventaja2 from "../images/ventaja2.png";
import ventaja3 from "../images/ventaja3.png";
import ventaja4 from "../images/ventaja4.png";
import ventaja5 from "../images/ventaja5.png";
import ventaja6 from "../images/ventaja6.png";
import hotel from "../images/hotel.png";
import vuelos from "../images/vuelos.png";
import villas from "../images/villas.png";
import traslados from "../images/traslados.png";
import seguros from "../images/seguros.png";
import si from "../images/si.png";
import x from "../images/x.png";
import afiliate from "../images/trato.png";
import email from "../images/email.png";
import whatsapp from "../images/whatsapp.png";
import insta from "../images/insta.png";
import fb from "../images/fb.png";
import cargandoImg from "../images/cargando1.gif";
import comision from "../images/comision.png";
import star from "../images/star.png";
import imgAgente from "../images/agente.png";
import calendario from "../images/calendario.png";
import reloj from "../images/reloj.png";

const pc = window.screen.width > 529 ? true : false;

const Textarea = styled.textarea`
    border-bottom:1px solid ${e => e.error && "#eb1a1a!important"};
    margin-top: 0!important;
    margin-bottom: ${pc ? "1.2vw!important" : "6.5vw!important"};
`;

const Input = styled.input`
    border:none;
    border-bottom:${e => e.error && "1px solid #eb1a1a!important"};
    margin-top: 0!important;
    margin-bottom: ${pc ? "1.2vw!important" : "6.5vw!important"};
    height: ${e => e.date ? pc ? "2.5vw" : "10vw" : undefined};
`;

const CheckboxCont = styled.div`
    display: flex;
    align-items: center;
    width: ${pc ? "80%" : "100%"};
    
    & p {
        font-size: ${pc ? "1.2vw" : "3vw"};
        margin-left: ${!pc && "2vw"};
    }

    & input {
        width: ${pc ? "10%" : "15%"};
        height: ${!pc && "4.5vw"};
        margin: 0!important;
        padding: 0!important;
    }
`;

const MsgValCont = styled.span`
    display: flex;
    width: 80%;
    align-items: center;
    justify-content: center;
    margin-top: ${pc ? "1vw" : "5vw"};
    color: ${e => e.error !== true ? "#eb1a1a" : "#26AC20"};
`;

const BtnAfiliar = styled.button`
    background-color: #004aad;
    color:#fff;
    border: none;
    border-radius: ${pc ? "2vw" : "2vw"};
    cursor: pointer;
    padding: ${pc ? "0.5vw" : "2vw"};
    width: ${pc ? "25%" : "50%"};
    margin-bottom: ${pc ? "1vw" : "3.5vw"};
    font-size: ${pc ? "1.3vw" : ""};
    font-weight: 700;

    &:hover{
        background-color: #0246A1;
    }
`;

const BtnToPoliticas = styled.span`
    color: #004aad;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
`;

export default function Inicio (){
    
    const navigate = useNavigate();
    const location = useLocation();
    const { session } = useContext(sessionContext);
    
    let [form, setForm] = useState({nombre:["", ""], fechaNacimiento:["", ""], numeroDocumento:["", ""], email:["", ""], direccion:["", ""], ciudad:["", ""], nacionalidad:["", ""], telefono:["", ""], numeroBanco:["", ""], nombreBanco:["", ""], comoConocio:["", ""], trabajo:["", ""], checkbox:["", ""]});
    let [cedula, setCedula] = useState(undefined);
    let [cedulaVal, setCedulaVal] = useState("");

    let [msgVal, setMsgVal] = useState("");

    let [afiliar, setAfiliar] = useState(false);

    let [cargando, setCargando] = useState(false);

    function actualizarInput (tipo, valor){
        if (tipo != "cedula") {
            const form1 = form;
            if (tipo !== "comoConocio" && tipo !== "trabajo" && tipo !== "checkbox") {
                if (tipo == "fechaNacimiento") {
                    form1[tipo][0] = cambiarFecha(valor);
                } else {
                    form1[tipo][0] = valor.replace(/\s+/g, " ");
                }
            } else {
                form1[tipo][0] = valor;
            }
            setForm(JSON.parse(JSON.stringify(form1)));
        } else {
            setCedula(valor);
        }
    }

    function validarForm (){
        let result = true;
        const form1 = form;
        Object.keys(form1).map((value)=>{
            form1[value][1] = "";
            if (value != "checkbox") {
                if (form1[value][0].trim().length > 0) {
                    if (value != "email") {
                        if (form1[value][0].trim().length < 3) {
                            result = false;
                            form1[value][1] = "Minimo 3 caracteres.";
                        } else if (form1[value][0].trim().length > 599) {
                            result = false;
                            form1[value][1] = "Maximo 599 caracteres.";
                        }
                    } else {
                        if (!verificarEmail.test(form1[value][0].trim())) {
                            result = false;
                            form1[value][1] = "Escribe un correo electronico verdadero.";
                        }
                    }
                } else {
                    if (value !== "nombreBanco" && value !== "numeroBanco") {
                        form1[value][1] = "Rellena este campo.";
                        result = false;
                    }
                }
                if (form1.nombreBanco[0].length > 0 && form1.numeroBanco[0].length < 1) {
                    form1.numeroBanco[1] = "Si escribiste un nombre de banco debes escribir el no. de cuenta bancaria.";
                    result = false;
                }
                if (form1.numeroBanco[0].length > 0 && form1.nombreBanco[0].length < 1) {
                    form1.nombreBanco[1] = "Si escribiste un no. de cuenta bancaria debes escribir el nombre de banco.";
                    result = false;
                }
            } else {
                if (form1[value][0] === false || form1[value][0] === "") {
                    form1[value][1] = "Confirma que estas de acuerdo con la politica de privacidad.";
                    result = false;
                }
            }
        });
        setCedulaVal("");
        if (cedula === undefined) {
            result = false;
            setCedulaVal("Sube tu documento.");
        }

        setForm(JSON.parse(JSON.stringify(form1)));
        return result;
    }

    async function enviarForm (e){
        e.preventDefault();
        setCargando(true);
        const validar = validarForm();
        if (validar) {
            const result = await enviarFormContacto(form, cedula);
            setMsgVal(result);
            if (result === true) {
                setCargando(true);
                setTimeout(()=>{
                    navigate("/a");
                }, 450);
                setTimeout(()=>{
                    navigate("/");
                    setCargando(false);
                }, 470);
            }
        };
        setCargando(false);
    }

    useEffect(()=>{        
        if (location.hash == "#contacto") {
            if (document.querySelector(".btnToForm")) {
                document.querySelector(".btnToForm").click();
            }
        }
        
    },[location.hash]);

    return (
        <div className="inicioCont">
            <a href="#contacto" className="btnToForm" />
            <div className="section">
                <h2>{session.session ? "Mi " : "Tu propia"} agencia de viajes</h2>
                <img className="ilustracion1" src={ilustracion1} />
                { session.session ? <p>¡Bienvenido a tu oficina virtual! </p> : <p>Somos una plataforma digital para agentes de viajes independientes, que nos permite ser un motor de ingresos desde casa</p>}
            </div>
            <div className="section1"/>
            <div className="ventajasYbeneficios subTitulo">
                <h2>Ventajas y beneficios</h2>
                <div className="imagenesVentajas">
                    <div className="ventaja">
                        <div className="imgVentaja">
                            <img src={ventaja1}/>
                        </div>
                        <h3>Trabajas desde  cualquier lugar</h3>
                    </div>
                    <div className="ventaja">
                        <div className="imgVentaja">
                            <img src={ventaja2}/>
                        </div>
                        <h3>Oficina virtual personalizada</h3>
                    </div>
                    <div className="ventaja">
                        <div className="imgVentaja">
                            <img src={ventaja3}/>
                        </div>
                        <h3>Catalogo de hoteles con cotización automática</h3>
                    </div>
                    <div className="ventaja">
                        <div className="imgVentaja">
                            <img src={ventaja4}/>
                        </div>
                        <h3>Comisiones por ventas</h3>
                    </div>
                    <div className="ventaja">
                        <div className="imgVentaja">
                            <img src={ventaja5}/>
                        </div>
                        <h3>Vacacionas a un menor costo</h3>
                    </div>
                    <div className="ventaja">
                        <div className="imgVentaja">
                            <img src={ventaja6}/>
                        </div>
                        <h3>Material de marketing para tus redes sociales</h3>
                    </div>
                </div>
            </div>
            <div className="section2 subTitulo">
                <h2>¿Que podras vender?</h2>
                <div className="cosasVender">
                    <div>
                        <div>
                            <img src={hotel}/>
                        </div>
                        <h3>Hoteles & Resorts</h3>
                    </div>
                    <div>
                        <div>
                            <img src={vuelos}/>
                        </div>
                        <h3>Vuelos</h3>
                    </div>
                    <div>
                        <div>
                            <img src={villas}/>
                        </div>
                        <h3>Villas</h3>
                    </div>
                    <div>
                        <div>
                            <img src={traslados}/>
                        </div>
                        <h3>Traslados</h3>
                    </div>
                    <div>
                        <div>
                            <img src={seguros}/>
                        </div>
                        <h3>Seguros de viajes</h3>
                    </div>
                </div>
            </div>
          { session.session ?
                <div>
                    <div className="section8 subTitulo">
                        <div>
                            <h2>Tus comisiones por ventas</h2>
                            <p>Por cada servicio que vendas recibirás un porcentaje de comisión. Este pago lo recibirás los dias 07 y 21 de cada mes, despues de tu cliente usar el servicio.</p>
                        </div>
                        <div>
                            <img src={comision} />
                        </div>
                    </div>
                    <div className="section2 subTitulo">
                        <div className="cosasVender">
                            <div>
                                <div>
                                    <img src={hotel}/>
                                </div>
                                <h3>Hoteles & Resorts</h3>
                                <span>8% de comisión</span>
                            </div>
                            <div>
                                <div>
                                    <img src={vuelos}/>
                                </div>
                                <h3>Vuelos</h3>
                                <span>1%-8% de comisión</span>
                            </div>
                            <div>
                                <div>
                                    <img src={villas}/>
                                </div>
                                <h3>Villas</h3>
                                <span>8% de comisión</span>
                            </div>
                            <div>
                                <div>
                                    <img src={traslados}/>
                                </div>
                                <h3>Traslados</h3>
                                <span>15% de comisión</span>
                            </div>
                            <div>
                                <div>
                                    <img src={seguros}/>
                                </div>
                                <h3>Seguros de viajes</h3>
                                <span>10% de comisión</span>
                            </div>
                        </div>
                    </div>
                    <div className="section9 subTitulo">
                        <div className="titulo9">
                            <h2>Agente estrella</h2>
                            <img src={star} />
                        </div>
                        <div className="descripcion9">
                            <img src={imgAgente} />
                            <p>
                                Si estas entre los 10 agentes con el mayor volumen en ventas, te conviertes en un agente estrella de Turisfreelance. Esto te permite participar en sorteos de vacaciones y también ser elegido para Fam Trips, en donde podrás conocer diferentes hoteles hospedándote gratis durante un fin de semana o un pasadía, entre otros lugares turístico. 
                                <br/>
                                <br/>
                                Recibirás estos beneficios y más.
                            </p>
                        </div>

                    </div>
                    <div className="section10 subTitulo">
                        <div className="subSection10">
                            <div className="item10">
                                <h2>Consulta la disponibilidad</h2>
                                <div className="item10_info">
                                    <img src={calendario} />
                                    <p className="txtCalendario">Antes de confirmarle a tu cliente o enviar la solicitud de reserva de hotel o villa, no olvides solicitarnos la disponibilidad para la fecha requerida.</p>
                                </div>
                            </div>
                            <div className="item10">
                                <h2>Nuestro horario de atención</h2>
                                <div className="item10_info">
                                    <img src={reloj} />
                                    <div>
                                        <span>Lunes - Viernes</span>
                                        <p>9am - 10pm</p>
                                        <span>Sabados</span>
                                        <p>9am - 3pm</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            :
            <div>
                <div className="section4 subTitulo">
                    <h2>Beneficios al convertirte en un agente de viajes</h2>
                    <div className="subSection4">
                        <img src={ilustracion2}/>
                        <p>Nuestros agentes de viajes afiliados han desarollado una nueva habilidad en la industria del turismo y a la vez disfrutan de las comisiones por cada venta en los diferentes servicios de viajes.</p>
                    </div>
                </div>
                <div className="section5 subTitulo">
                    <div className="beneficio">
                        <img src={x}/>
                        <p>No necesitas tener experiencia como agente de viajes, nosotros te capacitamos.</p>
                    </div>
                    <div className="beneficio">
                        <img src={x}/>
                        <p>No tienes que pagar ninguna mensualidad por la afiliación.</p>
                    </div>
                    <div className="beneficio">
                        <img src={si}/>
                        <p>Puedes iniciar de inmediato.</p>
                    </div>
                    <div className="beneficio">
                        <img src={si}/>
                        <p>Siempre recibirás asistencia humana personalizada.</p>
                    </div>
                </div>
                <div className="section6 subTitulo">
                    <h2>¡Afiliate hoy mismo!</h2>
                    <div className="subSection6">
                        <img src={afiliate} />
                        <div>
                            <span>RD $2,000</span>
                            <p>Único pago para tener acceso de por vida.</p>
                        </div>
                    </div>
                </div>

                { afiliar ?
                    <div className="section3" id="contacto">
                        <h2>Solicitud de afiliación</h2>
                        <form>
                            <SpanValidacion>{form.nombre[1]}</SpanValidacion>
                            <Input value={form.nombre[0]} onChange={(e)=>actualizarInput("nombre", e.target.value)} error={form.nombre[1]} placeholder="Tu nombre*"/>
                            <label onClick={()=>document.querySelector("#date").click()}>Fecha de nacimiento*</label>
                            <SpanValidacion>{form.fechaNacimiento[1]}</SpanValidacion>
                            <Input id="date" date={true} onChange={(e)=>actualizarInput("fechaNacimiento", e.target.value)} type="date" error={form.fechaNacimiento[1]}/>
                            <SpanValidacion>{form.numeroDocumento[1]}</SpanValidacion>
                            <Input value={form.numeroDocumento[0]} onChange={(e)=>actualizarInput("numeroDocumento", e.target.value)} error={form.numeroDocumento[1]} placeholder="Documento de identidad*"/>
                            <SpanValidacion>{form.email[1]}</SpanValidacion>
                            <Input value={form.email[0]} onChange={(e)=>actualizarInput("email", e.target.value)} error={form.email[1]} placeholder="Correo electronico*"/>
                            <SpanValidacion>{form.direccion[1]}</SpanValidacion>
                            <Input value={form.direccion[0]} onChange={(e)=>actualizarInput("direccion", e.target.value)} error={form.direccion[1]} placeholder="Dirección de residencia*"/>
                            <SpanValidacion>{form.ciudad[1]}</SpanValidacion>
                            <Input value={form.ciudad[0]} onChange={(e)=>actualizarInput("ciudad", e.target.value)} error={form.ciudad[1]} placeholder="Ciudad*"/>
                            <SpanValidacion>{form.nacionalidad[1]}</SpanValidacion>
                            <Input value={form.nacionalidad[0]} onChange={(e)=>actualizarInput("nacionalidad", e.target.value)} error={form.nacionalidad[1]} placeholder="Nacionalidad*"/>
                            <SpanValidacion>{form.telefono[1]}</SpanValidacion>
                            <Input value={form.telefono[0]} onChange={(e)=>actualizarInput("telefono", e.target.value)} error={form.telefono[1]} placeholder="Numero movil*"/>
                            <SpanValidacion>{form.numeroBanco[1]}</SpanValidacion>
                            <Input value={form.numeroBanco[0]} onChange={(e)=>actualizarInput("numeroBanco", e.target.value)} error={form.numeroBanco[1]} placeholder="No. de cuenta bancaria (si posee)"/>
                            <SpanValidacion>{form.nombreBanco[1]}</SpanValidacion>
                            <Input value={form.nombreBanco[0]} onChange={(e)=>actualizarInput("nombreBanco", e.target.value)} error={form.nombreBanco[1]} placeholder="Nombre del banco"/>
                            <SpanValidacion>{form.comoConocio[1]}</SpanValidacion>
                            <Textarea value={form.comoConocio[0]} onChange={(e)=>actualizarInput("comoConocio", e.target.value)} error={form.comoConocio[1]} placeholder="¿Como nos conoció?*"/>
                            <SpanValidacion>{form.trabajo[1]}</SpanValidacion>
                            <Textarea value={form.trabajo[0]} onChange={(e)=>actualizarInput("trabajo", e.target.value)} error={form.trabajo[1]} placeholder="¿A que se dedica actualmente?*"/>
                            <label>Subir cedula*</label>
                            <SpanValidacion>{cedulaVal}</SpanValidacion>
                            <Input type="file" accept=".png, .jpg, .jpeg, .pdf" onChange={(e)=>actualizarInput("cedula", e.target.files[0])} error={cedulaVal} placeholder="Cedula*"/>
                            <SpanValidacion>{form.checkbox[1]}</SpanValidacion>
                            <CheckboxCont style={{display:"flex", alignItems:"center"}}>
                                <Input style={{cursor:"pointer", width:pc && "8%"}} error={form.checkbox[1]} type="checkbox" onChange={(e)=>actualizarInput("checkbox", e.target.checked)} />
                                <p>He leido y acepto las <BtnToPoliticas onClick={()=>navigate("/politicas")}>politicas de privacidad.</BtnToPoliticas></p>
                            </CheckboxCont>
                            <MsgValCont error={msgVal}>{msgVal !== true ? msgVal : "Información enviada correctamente!"}</MsgValCont>
                            <button type="submit" onClick={(e)=>cargando ? e.preventDefault() :enviarForm(e)} className="btnContacto">
                                { cargando ? 
                                    <CargandoCont><img src={cargandoImg} /></CargandoCont>
                                    :
                                    <p>Solicitar afiliación</p>
                                }
                            </button>
                        </form>
                    </div>
                    :
                    <div className="section3" id="contacto" style={{backgroundColor:"transparent", marginTop:pc ? "4vw" : "6vw"}}>
                        <BtnAfiliar onClick={()=>setAfiliar(true)} >Solicitar afiliación</BtnAfiliar>
                        <p style={{fontWeight:"400", fontSize:pc ? "1vw" : "3.5vw", textAlign:"center", width:pc ? "40%" : "75%"}}>Haz click para llenar el formulario y nosotros te estaremos contactando.</p>
                    </div>

                }
            </div>
            }

            <div className="section7 subTitulo">
                <h2>Contacto</h2>
                <div className="redes">
                    <div className="red" style={{margin:!pc && "0 2vw 0 1vw"}}>
                        <img src={email}/>
                        <p>ventas1@turisfreelance.com</p>
                    </div>
                    <div className="red" onClick={()=>window.open("https://api.whatsapp.com/send?phone=18298170640")}>
                        <img src={whatsapp}/>
                        <p>+1 (849) 465-2311</p>
                    </div>
                    <div className="red" onClick={()=>window.open("https://instagram.com/turisfreelance")}>
                        <img src={insta}/>
                        <p>@turisfreelance</p>
                    </div>
                    <div className="red" onClick={()=>window.open("https://www.facebook.com/profile.php?id=100083310442396")}>
                        <img src={fb}/>
                        <p>Turis Freelance</p>
                    </div>
                </div>
            </div>

        </div>
    )
};