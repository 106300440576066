import { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import sessionContext from "../../contexts/sessionContext";
import { agregarOferta, añadirHotel, deleteItem, getOfertas, getSolicitudesCliente, searchHotelOVilla } from "../../config/auth";
import CargandoCont from "../utils/cargando";
import styled from "styled-components";
import {validarFecha, restaFechas, cambiarFecha, isNum} from "../utils/validar";
import Modal, {BtnCerrar} from "../../components/utils/modal";
import Users from "./users";
import EditarHotel from "./editarHotel";
import Villas from "./villas";

//imagenes
import cargandoImg from "../../images/cargando.gif";
import descargar from "../../images/descargar.png";
import mas from "../../images/mas.png";
import eliminar from "../../images/eliminar.png";
import hotelSinImg from "../../images/hotelSinImg.png";
import buscar from "../../images/search.png";
import quitar1 from "../../images/quitar1.png";
import quitar from "../../images/quitar.png";
import Solicitudes from "./solicitudes";
import Seguros from "./seguros";

const pc = window.screen.width > 529 ? true : false;

const PlataformaContainer = styled.div`
    display: flex;
    padding: 3vw;
    padding-top: ${pc ? "6vw" : "18vw"};
    height: 100vh;
    background-color: #fff;
    flex-direction:column;
    margin-left: ${pc && "17vw"};
    
    & h2 {
        font-size: ${pc ? "2vw" : "7vw"};
        font-weight: 700;
        margin-bottom: ${pc ? "1vw" : "3vw"};
    }
`;

const OfertasTotalContenedor = styled.div`
    display: flex;
    flex-direction: ${pc ? "row" : "column"};
    flex-wrap: wrap;
`;

const OfertasContenedor = styled.div`
    display: flex;
    align-items: center;
    justify-content: ${pc ? "flex-start" : "center"};
    margin-bottom: ${pc ? "3vw" : "8.5vw"};
    flex-wrap: wrap;
`;

const OfertaContenedor = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: ${pc ? "10vw" : "40%"};
    margin-right: ${pc && "3vw"};
    margin-bottom: ${pc ? "2.8vw" : "7vw"};
    position: relative;
`;

const BtnDescargar = styled.button`
    display: flex;
    border: none;
    align-items: center;
    justify-content: center;
    width: ${pc ? "80%" : "75%"};
    padding: ${pc ? "0.4vw" : "1.5vw"};
    background-color: #004aad;
    margin-top: ${pc ? "1.3vw" : "3vw"};
    border-radius: ${pc ? "0.6vw" : "2vw"};
    cursor: pointer;

    & p {
        color:#fff;
        font-size: ${pc ? "0.9vw" : "3.3vw"};
        margin-right: ${pc ? "0.1vw" : "0.5vw"};
        text-decoration: none;
    }

    & img {
        width: ${pc ? "19%" : "22%"};
    }
`;

const Img = styled.div`
    width: ${pc ? "92%" : "60%"};
    border-color: red;
    height: ${pc ? "10vw" : "13vh"};
    background-image: url(${props => props.img});
    background-size: contain;
`;

const BtnAdd = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${pc ? "100%" : "95%"};
    padding: ${pc ? "0.5vw" : "1vw"};
    border-radius: ${pc ? "0.3vw" : "1vw"};
    background-color: #26AC20;
    color:#fff;
    font-size: ${pc ? "0.85vw" : "3.3vw"};
    cursor: pointer;

    & img {
        width: ${pc ? "1.8vw" : "7.5vw"};
    }
`;

const Msg = styled.div`
  position: fixed;
  display: ${props => props.activo ? "flex" : "none"};
  width: 100%;
  align-items: center;
  justify-content: center;
  bottom: 0;
  left: 0;
  z-index: 5000;

  & div {
    padding: ${pc ? "1vw 2vw" : "2vw 3vw"};
    margin-bottom: 3px;
    text-align: center;
    border-top-right-radius: 0.4vw;
    border-top-left-radius: 0.4vw;
    background-color: ${props => props.error ? "#26AC20" : "#eb1a1a"};
    color: #fff;
  }
`;

const ImgEliminar = styled.img`
    position: absolute;
    bottom: ${pc ? "26%" : "35%"};
    right: ${pc ? "2%" : "20%"};
    width: ${pc ? "3vw": "7.5vw"};
    height: ${pc ? "3vw": "7.5vw"};
    padding: 0.5vw;
    cursor: pointer;
`;

const ModalEliminar = styled.div`
    display: flex;
    padding: 1vw;
    align-items: center;
    flex-direction: column;

    & h3{
        font-weight: 600;
        text-align: center;
        font-size: ${pc ? "" : "4.5vw"};
        width: ${!pc && "60vw"};
    }

    & p {
        color:#585858;
        font-size: ${pc ? "" : "4vw"};
    }
`;

const Btns = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: ${pc ? "90%" : "100%"};
    margin-top: ${pc ? "1vw" : "3vw"};
`;

const Button = styled.button`
    width: 45%;
    background-color: ${props => props.red ? "#eb1a1a" : props.yes ? "#26AC20" : "#767676"};
    color:#fff;
    padding: ${pc ? "0.5vw" : "1.2vw"};
    border: none;
    font-size: ${pc ? "" : "3.2vw"};
    border-radius: ${pc ? "0.5vw" : "1vw"};
    align-items: center;
    justify-content: center;
    cursor: pointer;
    display: flex;
    opacity: ${p => p.opacity && "0.5"};

    &:hover {
        background-color: ${e => e.red ? "#D31A1A" : e.yes ? "#2CA026" : "#646363"};
    }
`;

const HotelesContenedor = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    padding-bottom: ${e => e.paddingBottom ? pc ? "4vw" : "7vw" : undefined};
`;

const BuscarContenedor = styled.form`
    display: flex;
    background-color: #E0E0E0;
    border-radius: 5vw;
    width: 85%;
    margin-top: 2vw;
    height: ${pc && "3vw"};

    & input {
        border: none;
        padding: ${!pc && "3vw"};
        padding-left: ${pc ? "1.3vw" : "3vw"};
        background-color: transparent;
        width: 90%;
    }
    & input:focus{
        outline: none;
    }
    & button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: ${pc ? "3.5vw" : "13%"};
        padding: ${pc ? "1vw" : "2.3vw"};
        background-color: #004aad;
        border-top-right-radius: 5vw;
        border-bottom-right-radius: 5vw;
        margin-left: auto;
        cursor: pointer;
        border: none;
    }
    & button img{
        width: 100%;
    }
`;

const HotelesCont = styled.div`
    margin-top:${pc ? "2vw" : "4.5vw"};
    display: flex;
    width: 100%;
    flex-wrap: wrap;
`;

const Hotel = styled.div`
    display: flex;
    position: relative;
    margin: 0 1vw;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1vw;
    width: ${pc ? "10vw" : "30%"};
    margin-bottom: 2vw;
    cursor: pointer;

    & p {
        margin-top: 0.67vw;
        font-size: ${pc ? "1vw" : "3.5vw"};
        font-weight: 600;
        text-align: center;
    }
`;

const HotelImg = styled.div`
    width: ${pc ? e => e.add ? "3.7vw" : "5vw" : "12vw"};
    height: ${pc ? e => e.add ? "3.7vw" : "5vw" : "12vw"};
    background-image: url(${e => e.imagen ? e.imagen : hotelSinImg });
    background-size: cover;
    background-repeat: no-repeat;
    background-color: ${e => e.add && "#26AC20"};
    border-radius: 11vw;
`;

const HotelImgFondo = styled.div`
    width: ${pc ? "5vw" : "12vw"};
    height: ${pc ? "5vw" : "12vw"};
    background-image: url(${e => e.imagen ? e.imagen : hotelSinImg });
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 11vw;
`;

const ModalAddHotel = styled.div`
    padding: ${pc ? "2vw" : ""};
    border-radius: ${pc ? "0.5vw" : "1vw"};
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: ${pc ? "50vw" : "70vw"};

    & h3 {
        font-weight: 700;
        margin-bottom: ${pc ? "1vw" : ""};
        font-size: ${pc ? "1.9vw" : ""};
    }
    & h4 {
        font-weight: 600;
        font-size: ${pc ? "1.4vw" : ""};
        color:#004aad;
        margin-bottom: ${pc ? "0.3vw" : ""};
        margin-right: auto;
        border-bottom: 1px solid #C4C4C4;
        width: 100%;
    }
`;

const FilaModalHotel = styled.div`
    display: flex;
    flex-direction: ${pc ? "row" : "column"};
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    margin-bottom: ${pc ? "1.2vw" : "2vw"};
`;

const Campo = styled.div`
    display: flex;
    flex-direction: ${e => e.oferta ? "row" : "column"};
    margin-right: ${e => e ? pc && "0" : pc && "2vw"};
    width: ${e => pc ? e.oferta ? "100%" : "43%" : e.oferta ? "100%" : "90%"};
    margin:${!pc && "0 auto"};
    margin-bottom: ${e => e.oferta && pc ? "1vw" : "2vw"};
    position: relative;
    background-color: ${e => e.oferta && "#004aad"};
    color: ${e => e.oferta && "#fff"};
    padding: ${e => e.oferta && pc ? "0.5vw" : "1vw"};
    border-radius: ${e => e.oferta && pc ? "0.5vw" : "1vw"};
    padding-left: ${e => e.oferta ? "1vw" : "0"};
    align-items: ${e => e.oferta && "center"};

    & img {
        width: ${pc ? "2vw" : "3vw"};
        margin-left: auto;
        cursor: pointer;
    }

    & label {
        color:#000;
        font-weight: 500;
        font-size: ${pc ? "1.1vw" : ""};
    }

    & input {
        padding: ${pc ? "0.3vw" : "0.5vw 1vw"};
        border: 1px solid #999797;
        border-radius: 3px;
        font-size: ${pc ? "0.9vw" : "3.5vw"};
        color:#505050;        
    }
    & input[type=checkbox] {
        cursor: pointer;
        width: ${!pc && "4.5vw"};
        height: ${!pc && "4.5vw"};
    }
    & textarea {
        padding: ${pc ? "0.3vw" : "0.5vw 1vw"};
        border: 1px solid #999797;
        border-radius: 3px;
        font-size: ${pc ? "0.9vw" : "3.5vw"};
        color:#505050;
        resize: none;
    }

    & input:focus {
        outline:1px solid #B3B3B3;  
    }
    & textarea:focus {
        outline:1px solid #B3B3B3;  
    }
`;

const Input = styled.input`
    border:1px solid ${props => props.error ? "#eb1a1a!important" : "#999797"};
`;

const SpanValidacion = styled.div`
    background-color: #eb1a1a;
    display: ${e => e.active ? "block" : "none"};
    color: #fff;
    padding: ${pc ? "0.5vw" : "1vw"};
    position: absolute;
    bottom: 70%;
    right: 5%;
    border-radius: ${pc ? "0.5vw" : "1vw"};
    font-size: ${pc ? "0.9vw" : "2.5vw"};
    opacity: 0.91;

    & span {
        z-index: 50;
        position: relative;
    }
`;
const DivLinea = styled.div`
    position: absolute;
    right: 3%;
    bottom: -0.18vw;
    background-color: #eb1a1a;
    width: 1.3vw;
    height: 1.3vw;
    transform: rotate(45deg);
    z-index: 10;
`;

const Textarea = styled.textarea`
    border:1px solid ${props => props.error ? "#eb1a1a!important" : "#999797"};
`;

const Select = styled.select`
    border:1px solid ${props => props.error ? "#eb1a1a!important" : "#999797"};
`;

const Dias = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`;

const Dia = styled.div`
    width: ${pc ? "20%" : "33.33%"};
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: ${pc ? "0.8vw" : "2vw"};
`;

const Oferta = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    background-color:#004aad;
    border-radius: ${pc ? "0.5vw" : "1vw"};
    padding: ${pc ? "0.5vw" : "2vw"};
    margin-top: ${pc ? "1vw" : "3vw"};

    & span {
        font-size: ${pc ? "1.2vw" : ""};
        color:#fff;
    }
`;

const SubCampo = styled.div`
    display: flex;
    margin-right: ${e => e ? pc && "0" : pc && "2vw"};
    width: 90%;
    margin: 0 auto;
    margin-top: ${pc ? "0.7vw" : "2vw"};
    position: relative;
    background-color: #fff;
    color: #000;
    padding: ${pc ? "0.3vw" : "1vw"};
    border-radius: ${pc ? "0.5vw" : "1vw"};
    align-items: center;

    & img {
        width: ${pc ? "2vw" : "6vw"};
        margin-left: auto;
        cursor: pointer;
    }

    & p {
        color:#000;
        font-weight: 500;
        font-size: ${pc ? "1vw" : "2.5vw"};
        margin-left: 0.2vw;
    }
`;

const BtnAddOferta = styled.div`
    background-color: #26AC20;
    width: ${pc ? "40%" : "50%"};
    margin: ${pc ? "1vw" : "2vw"} auto 0 auto;
    padding: ${pc ? "0.3vw" : "1vw"};
    font-size: ${pc ? "1vw" : "3.1vw"};
    color: #fff;
    font-weight: 500;
    cursor: pointer;
    border-radius: ${pc ? "0.2vw" : ""};
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background-color: #2CA026;
    }
`;

export default function Plataforma (){

    const navigate = useNavigate();
    const location = useLocation();

    const { session } = useContext(sessionContext);
    
    // OFERTAS

    let [ofertas, setOfertas] = useState(undefined);
    let [msgVal, setMsgVal] = useState("");
    let [cargando, setCargando] = useState(false);

    // HOTELES
    let [hoteles, setHoteles] = useState(undefined);    
    let [inputHotel, setInputHotel] = useState(undefined);
    let [search, setSearch] = useState(undefined);
    let [localidad, setLocalidad] = useState({"Zona este":undefined, "Zona norte":undefined});
    let [localidadInput, setLocalidadInput] = useState("Todos");
    let [ultimoElemento, setUltimoElemento] = useState(undefined);
    let [ultimoElementoLocalidad, setUltimoElementoLocalidad] = useState({"Zona este":undefined, "Zona norte":undefined});

    let [msgVal2, setMsgVal2] = useState("");
    let [msgVal1, setMsgVal1] = useState([false, ""]);
    let [id, setId] = useState("");
    let [idHotel, setIdHotel] = useState("");

    let [cargandoHotel, setCargandoHotel] = useState(false);
    let [cargando1, setCargando1] = useState(false);

    let [modal, setModal] = useState(false);
    let [modalHotel, setModalHotel] = useState(false);
    let [modalAddHotel, setModalAddHotel] = useState(false);
    let [editHotel, setEditHotel] = useState(false);

    const defaultInput = {nombre:["", ""], zonaHotelera:["", ""], descripcion:["", ""], fotoPerfil:["", ""], imagenes:["", ""], oferta:{minimoNoches:["", ""], dias:[[], ""], niñoGratis:["", ""], codigoOferta:["", ""], habitacion:["", ""], desde:["", ""], hasta:["", ""], SGL:["", ""], DBL:["", ""], TPL:["", ""], niños:["", ""], vencimientoOferta:["", ""]}};
    let [inputs, setInputs] = useState(defaultInput);

    let [oferta, setOferta] = useState(false);
    let [ofertasAdd, setOfertasAdd] = useState([]);

    let [codigoOferta, setCodigoOferta] = useState(false);

    //OFERTAS

    async function getDatosOfertas () {
        const datos = await getOfertas();
        if (datos[0]) {
            setOfertas(datos[1]);
            if (datos[1].length < 1) {
                setMsgVal("No hay ofertas");
            }
        } else {
            setOfertas(false);
            setMsgVal(datos[1]);
        }
    }

    async function addOferta(e){
        if (!cargando) {
            setCargando(true);
            const result = await agregarOferta(e);
            if (result[0]) {
                const newOfertas = ofertas;
                newOfertas.push({img:result[1].link, id:result[1].id});
                setOfertas(newOfertas);
                setMsgVal1([true, "Oferta agregada correctamente!"]);
                setTimeout(()=>{
                    setMsgVal1([false, ""]);
                }, 2700);
            } else {
                setMsgVal1([false, result[1]]);
                setTimeout(()=>{
                    setMsgVal1([false, ""]);
                }, 2700);
            }
            setCargando(false);
        }
    }

    async function borrarItem (){
        if (!cargando) {
            setCargando(true);
            const result = await deleteItem({id:id ? id : idHotel ? idHotel : ""}, (id ? "oferta" : idHotel ? "hotel" : ""));
            if (result[0]) {
                setMsgVal1([true, `${id ? "Oferta eliminada" : "Hotel eliminado"} correctamente!`]);
                const items = id ? ofertas : hoteles;
                let zona = "";
                items.map((value, index)=>{
                    if (value.id == (id ? id : idHotel)) {
                        items.splice(index, 1);
                        id ? setOfertas(items) : setHoteles(items);
                        if (idHotel) {
                            zona = value.zonaHotelera;
                        }
                    }
                });
                if (idHotel) {
                    if (search) {
                        if (search[0].id === idHotel) {
                            setSearch(undefined);
                            setInputHotel("");
                        }
                    }
                    if (localidadInput !== "Todos") {
                        const newLocalidad = localidad;
                        localidad[zona].map((value, index)=>{
                            if (idHotel === value.id) {
                                newLocalidad[zona].splice(index, 1);
                            }
                        });
                        setLocalidad(JSON.parse(JSON.stringify(newLocalidad)));
                    }
                }
            } else {
                setMsgVal1([false, result[1]]);
            }

            setTimeout(()=>{
                setMsgVal1([false, ""]);
            }, 2700);

            setId("");
            setIdHotel("");

            setModal(false);
            setCargando(false);
        }
    }

    async function downloadImage(url){
        try {
            await fetch(url)
            .then(resp => resp.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = "";
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            });
        } catch {
        
        }
    }

    function validarUrl (){
        let result = false;
        const tab = "?tab=solicitudes="
        const searchs = ["traslados", "grupos", "seguros", "reservasHoteles", "villas", "reservasVillas", "vuelos"];
        
        if (location.search == "?tab=solicitudes") {
            result = true;
        } else {
            searchs.map((value)=>{
                if (tab+value == location.search)  {
                    result = true;
                }
            });
        }

        return result;
    }

    // HOTELES
    function eliminarOferta (id, subOferta, indexSubOferta){
        if (subOferta) {
            const newOfertas = JSON.parse(JSON.stringify(ofertasAdd));
            newOfertas.map((oferta, index)=>{
                if (oferta.codigoOferta == subOferta) {
                    newOfertas[index].ofertas.splice(indexSubOferta, 1);
                }
            });
            setOfertasAdd(newOfertas);    
        } else {
            const newOfertas = JSON.parse(JSON.stringify(ofertasAdd));
            newOfertas.splice(id, 1);
            setOfertasAdd(newOfertas);
        }
    }

    function actualizarInput (tipo, valor, tipo2, tipo3){
        const newInputs = inputs;
        if (tipo2 == "oferta") {
            if (tipo == "desde" || tipo == "hasta" || tipo == "vencimientoOferta") {
                newInputs.oferta[tipo][0] = cambiarFecha(valor);
            } else if (tipo == "dias"){
                if (valor == true) {
                    if (tipo3 == "todos"){
                        newInputs.oferta.dias = [["todos"], ""];
                    } else {
                        newInputs.oferta.dias[0].push(tipo3);
                    }
                } else {
                    newInputs.oferta.dias[0].map((dia, index)=>{
                        if (dia == tipo3) {
                            newInputs.oferta.dias[0].splice(index, 1);
                        };
                    });
                }
                
            } else {
                newInputs.oferta[tipo][0] = valor;
            }
        } else {
            newInputs[tipo][0] = valor;
        }
        setInputs(JSON.parse(JSON.stringify(newInputs)));
    } 

    function validarOferta (oferta1, validar){
        let result = true;
        const newInputs = oferta1 ? {oferta:oferta1} : inputs;
        const ofertaSelect = oferta1 ? oferta1 : inputs.oferta;

        Object.keys(ofertaSelect).map((value, index)=>{
            newInputs.oferta[value][1] = "";
            if (newInputs.oferta[value][0].length > 0) {
                if (value == "codigoOferta" || value == "habitacion") {
                    if (newInputs.oferta[value][0].length < 4) {
                        newInputs.oferta[value][1] = "Minimo 4 caracteres.";
                        result = false;
                    } else {
                        if (value == "codigoOferta") {
                            ofertasAdd.map((oferta, index)=>{
                                if (oferta.codigoOferta == newInputs.oferta.codigoOferta[0]) {
                                    newInputs.oferta.codigoOferta[1] = "Este codigo de oferta ya esta en uso.";
                                    result = false;
                                };
                            });
                        }
                    }
                } else if (value == "desde" || value == "hasta" || value == "vencimientoOferta") {
                    if (validarFecha(newInputs.oferta[value][0]) === true) {
                        if (value == "desde" || value == "hasta") {
                            if (restaFechas(newInputs.oferta.desde[0], newInputs.oferta.hasta[0]) == 0) {
                                newInputs.oferta[value][1] = "Minimo un dia.";
                                result = false;
                            } else if (restaFechas(newInputs.oferta.desde[0], newInputs.oferta.hasta[0]) < 0) {
                                newInputs.oferta.hasta[1] = "Esta fecha tiene que ser despues que la fecha Periodo (desde)";
                                result = false;
                            }
                        }
                    } else {
                        newInputs.oferta[value][1] = validarFecha(newInputs.oferta[value][0]);
                        result = false;
                    }
                } else if (value == "minimoNoches" || value == "niños"){
                    if (!isNum(newInputs.oferta[value][0])) {
                        result = false;
                        newInputs.oferta[value][1] = "Solo se admiten numeros, sin puntos, comas ni simbolos.";
                    }
                } else if (value == "SGL" || value == "DBL" || value == "TPL"){
                    if (isNaN(newInputs.oferta[value][0])) {
                        result = false;
                        newInputs.oferta[value][1] = "Escribe un numero correcto.";
                    }
                } else if (value == "dias"){
                    if (codigoOferta) {
                        ofertasAdd.map((oferta)=>{
                            let dias = [];
                            if (oferta.codigoOferta == codigoOferta) {
                                oferta.ofertas.map((subOferta)=>{
                                    dias = dias.concat(subOferta.dias);
                                });
                            }
                            dias.map((diaOfertas)=>{
                                newInputs.oferta.dias[0].map((diaOferta)=>{
                                    if (diaOfertas == diaOferta) {
                                        newInputs.oferta.dias[1] = newInputs.oferta.dias[0].length > 1 ? "Algunos de los dias que seleccionaste, ya existen." : "El dia que seleccionaste ya existe.";
                                        result = false;
                                    };
                                });
                            });
                        });
                    };
                };
            } else {
                if (value == "codigoOferta" || value == "vencimientoOferta" || value == "habitacion" || value == "desde" || value == "hasta" || value == "minimoNoches" || value == "niñoGratis") {
                    if (!codigoOferta) {
                        newInputs.oferta[value][1] = "Rellena este campo.";
                        result = false;
                    }
                } else {
                    newInputs.oferta[value][1] = "Rellena este campo.";
                    result = false;
                }
            }
        });

        if (!validar) {
            if (result) {
                const newOfertas = ofertasAdd;
                const oferta = JSON.parse(JSON.stringify({dias:inputs.oferta.dias[0], datos:inputs.oferta}));
                delete oferta.datos.dias;
                delete oferta.datos.codigoOferta;
                delete oferta.datos.vencimientoOferta;
                delete oferta.datos.desde;
                delete oferta.datos.hasta;
                delete oferta.datos.habitacion;
                delete oferta.datos.niñoGratis;
                delete oferta.datos.minimoNoches;

                if (codigoOferta) {                    
                    newOfertas.map((oferta1, index)=>{
                        if (oferta1.codigoOferta == codigoOferta) {
                            newOfertas[index].ofertas.push(oferta);
                        };
                    });
                    setCodigoOferta(false);
                } else {
                    const ofertaGeneral = {codigoOferta:inputs.oferta.codigoOferta[0], vencimientoOferta:inputs.oferta.vencimientoOferta[0], desde:inputs.oferta.desde[0], hasta:inputs.oferta.hasta[0], habitacion:inputs.oferta.habitacion[0], niñoGratis:inputs.oferta.niñoGratis[0], minimoNoches:inputs.oferta.minimoNoches[0], ofertas:[]};
                    ofertaGeneral.ofertas.push(oferta);
                    newOfertas.push(ofertaGeneral);
                }

                setOfertasAdd(JSON.parse(JSON.stringify(newOfertas)));
                Object.keys(inputs.oferta).map((value)=>{
                    newInputs.oferta[value] = [value == "dias" ? [] : "", ""];
                });
                setOferta(false);
            }
            
            setInputs(JSON.parse(JSON.stringify(newInputs)));
        };
        

        return result;
    }
    
    function validarImagenes (){
        const archivosPermitidos = ["image/png", "image/jpg", "image/jpeg"];
        const newInputs = inputs;
        let result = true;

        newInputs.fotoPerfil[1] = "";
        newInputs.imagenes[1] = "";

        const imagen = document.querySelector("#imgPerfil").files[0];
        if (imagen) {
            if (imagen.size > 7242881) {
                result = false;
                newInputs.fotoPerfil[1] = "Cada imagen debe pesar menos de 7 MB.";
            }
            let valid = false;
            archivosPermitidos.map((formato)=>{
                if (imagen.type == formato) {
                    valid = true;
                }
            });
            if (!valid) {
                result = false;
                newInputs.fotoPerfil[1] = "Solo se admiten estos archivos: .png, .jpg y .jpeg.";
            }
        } else {
            result = false;
            newInputs.fotoPerfil[1] = "Rellena este campo.";
        }

        const imagenes = document.querySelector("#inputImagenes").files;
        if (imagenes.length < 31) {
            Object.keys(imagenes).map((imagen)=>{
                let valid = false;
                if (imagenes[imagen].size > 7242881) {
                    result = false;
                    newInputs.imagenes[1] = "Cada imagen debe pesar menos de 7 MB.";
                }
                archivosPermitidos.map((formato)=>{
                    if (imagenes[imagen].type == formato) {
                        valid = true;
                    }
                });
                if (!valid) {
                    result = false;
                    newInputs.imagenes[1] = "Solo se admiten estos archivos: .png, .jpg y .jpeg.";
                }
            });                                                        
        } else {
            result = false;
            newInputs.imagenes[1] = "Maximo 30 imagenes.";
        }

        setInputs(JSON.parse(JSON.stringify(newInputs)));

        return result;
    }
    
    function validarHotel (){
        let result = true;
        let newInputs = inputs;

        Object.keys(newInputs).map((value)=>{
            if (value == "zonaHotelera" || value == "nombre") {
                newInputs[value][1] = "";
                if (inputs[value][0].length > 0) {
                    if (value == "nombre") {
                        if (inputs[value][0].length < 4){
                            result = false;
                            newInputs[value][1] = "Minimo 4 caracteres.";
                        };
                    };
                } else {
                    result = false;
                    newInputs[value][1] = "Rellena este campo.";
                };
            }
        });

        ofertasAdd.map((oferta)=>{
            let valido = false;
            oferta.ofertas.map((subOferta)=>{
                if (subOferta.dias.includes("todos")) {
                    valido = true;
                }
            });
            if (!valido) {
                result = false;
                setMsgVal1([false, "Las ofertas deben tener una sub-oferta con todos los dias."]);
                setTimeout(()=>{
                    setMsgVal1("");
                }, 3500);
            }
        });

        setInputs(JSON.parse(JSON.stringify(newInputs)));

        return result;
    }

    async function agregarHotel (){
        const valForm = validarHotel();
        const imgVal = validarImagenes();

        if (valForm && (!oferta)) {
            const imagenes = [];
            const newImagenes = document.querySelector("#inputImagenes").files;
            const imgPerfil = document.querySelector("#imgPerfil").files[0];
            imagenes.push(imgPerfil);

            if (newImagenes.length > 0) {
                Object.keys(newImagenes).map((imagen)=>{
                    imagenes.push(newImagenes[imagen]);
                });
            };

            let inputs1 = inputs;
            delete inputs1.oferta;
            inputs1.ofertas = ofertasAdd;



            if (imgVal) {
                setCargandoHotel(true);
                if (!cargandoHotel) {
                    const result = await añadirHotel({hotel:inputs1, imagenes:imagenes});
                    if (result === true) {
                        setTimeout(()=>{
                            setCargandoHotel(true);
                        }, 20);
                        setTimeout(()=>{
                            setOfertasAdd([]);
                            setInputs(JSON.parse(JSON.stringify(defaultInput)));
                            setMsgVal1([true, "Hotel agregado correctamente!"]);
                            setModalAddHotel(false);
                            setTimeout(()=>{
                                navigate("/");
                            }, 400);
                            setTimeout(()=>{
                                navigate("/admin?tab=hoteles");
                            }, 500);
                            setCargandoHotel(false);
                        }, 4500);
                    } else {
                        setMsgVal1([false, result]);
                        setCargandoHotel(false);
                    };
                    setTimeout(()=>{
                        setMsgVal1("");
                    }, 4500);
                }
            }
        } else{
            if (oferta) {
                validarOferta();
            }
        }
    }

    async function obtenerHoteles (urg){
        if (hoteles === undefined || urg === true) {
            setCargando(true);
            const result = await getSolicitudesCliente({tipo:"hoteles"});
            if (result[0]) {
                setHoteles(result[1].datos);
                setUltimoElemento(result[1].ultimoElemento);
            } else {
                setHoteles(result[0]);
                setMsgVal2(result[1]);
            }
            setCargando(false);
        } else {
            if (ultimoElemento !== false) {
                setCargando1(true);
                const result = await getSolicitudesCliente({tipo:"hoteles", ultimoElemento:ultimoElemento});
                setUltimoElemento(result[1].ultimoElemento);
                if (result[0]) {
                    const newHoteles = hoteles;
                    result[1].datos.map((value)=>{
                        let si = true;
                        newHoteles.map((old)=>{
                            if (old.id == value.id) {
                                si = false;
                            }
                        })
                        if (si) {
                            newHoteles.push(value);
                        }
                    });
                    setHoteles(JSON.parse(JSON.stringify(newHoteles)));
                } else {
                    setHoteles(result[0]);
                    setMsgVal2(result[1]);
                }
                setCargando1(false);
            }
        }
    };

    async function buscarHotel (e){
        e.preventDefault();
        if (inputHotel !== undefined) {
            if (inputHotel.replace(/\s+/g, " ").trim().length > 0) {
                setCargando(true);
                const result = await searchHotelOVilla(inputHotel.toLowerCase().replace(/\s+/g, " ").trim(), "hoteles");
                if (result[0]) {
                    setSearch(result[1]);
                } else {
                    setSearch(false);
                    setMsgVal2(result[1]);
                }
                setCargando(false);
            }
        }
    };

    async function getHotelesLocalidad (local1, btn){
        const local = local1 || localidadInput;
        setSearch(undefined);
        setInputHotel("");
        if (local != "Todos") {
            setLocalidadInput(local);
            
            if (localidad[local] === undefined) {
                setCargando(true);
                const result = await getSolicitudesCliente({tipo:"hoteles", search:"zonaHotelera", word:local});
                if (result[0]) {
                    const newLocalidad = localidad;
                    newLocalidad[local] = result[1].datos;
                    setLocalidad(JSON.parse(JSON.stringify(newLocalidad)));
                    const ultimoElementoLocalidad1 = ultimoElementoLocalidad;
                    ultimoElementoLocalidad1[local] = result[1].ultimoElemento;
                    setUltimoElementoLocalidad(ultimoElementoLocalidad1);
                } else {
                    const newLocalidad = localidad;
                    newLocalidad[local] = result[0];
                    setLocalidad(newLocalidad);
                    setMsgVal2(result[1]);
                }
                setCargando(false);
            } else {
                if (btn) {
                    if (ultimoElementoLocalidad[local] !== false) {
                        setCargando1(true);
                        const result = await getSolicitudesCliente({tipo:"hoteles", search:"zonaHotelera", word:local, ultimoElemento:ultimoElementoLocalidad[local]});
                        const ultimoElementoLocalidad1 = ultimoElementoLocalidad;
                        ultimoElementoLocalidad1[local] = result[1].ultimoElemento;
                        setUltimoElementoLocalidad(ultimoElementoLocalidad1);
                        if (result[0]) {
                            const newLocalidad = localidad;
                            result[1].datos.map((value)=>{
                                let si = true;
                                newLocalidad[local].map((old)=>{
                                    if (old.id == value.id) {
                                        si = false;
                                    }
                                })
                                if (si) {
                                    newLocalidad[local].push(value);
                                }
                            });
                            setLocalidad(newLocalidad);
                        } else {
                            const newLocalidad = localidad;
                            newLocalidad[local] = result[0];
                            setLocalidad(newLocalidad);
                            setMsgVal2(result[1]);
                        }
                        setCargando1(false);
                    };
                }
            };
        } else {
            setLocalidadInput(local);
        }
    };

    function getDataHotelEdit (){
        let result = "";
        if (search) {
            search.map((hotel, index)=>{
                if (hotel.id == idHotel) {
                    result = hotel;
                }
            });
        }
        if (localidad[localidadInput]) {
            localidad[localidadInput].map((hotel, index)=>{
                if (hotel.id == idHotel) {
                    result = hotel;
                }
            });
        }
        if (hoteles) {
            hoteles.map((hotel, index)=>{
                if (hotel.id == idHotel) {
                    result = hotel;
                }
            });
        }
        return result;
    }

    useEffect(()=>{
        if (!location.search) {
            navigate("/admin?tab=usuarios");
        }
    },[]);

    if (location.search == "?tab=solicitudes") {
        navigate("/admin?tab=solicitudes=reservasHoteles");
    }

    useEffect(()=>{
        setEditHotel(false);
        setModalHotel(false);        
    },[location.search]);
  
    useEffect(()=>{
        if (session.user) {
            getDatosOfertas();
            obtenerHoteles();
        }
    },[session.user]);

    return (
        <div>
            { editHotel ?
                <EditarHotel hotel={getDataHotelEdit()} setClose={()=>{setEditHotel(false); setModalHotel(false); setIdHotel(""); setTimeout(()=>{document.querySelector("#optionLocal").value=localidadInput},50);}} />
                :
            <PlataformaContainer>
                <Msg activo={msgVal1[1]} error={msgVal1[0]}><div><p>{msgVal1[1]}</p></div></Msg>
                <Modal visible={modal} setVisible={()=>{}}>
                    <ModalEliminar> 
                        <h3>¿Estas seguro de eliminar {id ? "esta oferta" : "este hotel"} ?</h3>
                        <p>Esta acción no se puede deshacer.</p>
                        {cargando && <CargandoCont><img src={cargandoImg}/></CargandoCont>}
                        <Btns>
                            <Button opacity={cargando} onClick={()=>{if(!cargando){setModal(false); setId(""); setIdHotel("")}}} >No estoy seguro</Button>
                            <Button opacity={cargando} red={true} onClick={()=>!cargando && borrarItem()}>Estoy seguro</Button>
                        </Btns>
                    </ModalEliminar>
                </Modal>
                { modalAddHotel &&
                    <Modal add={true} visible={modalAddHotel} setVisible={()=>{}}>
                        <ModalAddHotel>
                            <BtnCerrar onClick={()=>!cargandoHotel && setModalAddHotel(false)}><img src={quitar1}/></BtnCerrar>
                            <h3>Agrega un hotel</h3>
                            <h4>Datos basicos</h4>
                            <FilaModalHotel>
                                            <Campo>
                                                <label>Nombre<span style={{color:"#eb1a1a"}}>*</span></label>
                                                <SpanValidacion active={inputs.nombre[1]}><span>{inputs.nombre[1]}</span><DivLinea/></SpanValidacion>
                                                <Input value={inputs.nombre[0]}  error={inputs.nombre[1]} onChange={(e)=>actualizarInput("nombre", e.target.value)} />
                                            </Campo>
                                            <Campo>
                                            <SpanValidacion active={inputs.zonaHotelera[1]}><span>{inputs.zonaHotelera[1]}</span><DivLinea/></SpanValidacion>
                                                <label>Zona hotelera<span style={{color:"#eb1a1a"}}>*</span></label>
                                                <Select defaultValue="DEFAULT" onChange={(e)=>actualizarInput("zonaHotelera", e.target.value)} error={inputs.zonaHotelera[1]}>
                                                    <option value="DEFAULT" disabled>Sin seleccionar</option>
                                                    <option>Zona este</option>
                                                    <option>Zona norte</option>
                                                </Select>
                                            </Campo>
                                            <Campo>
                                                <SpanValidacion active={inputs.fotoPerfil[1]}><span>{inputs.fotoPerfil[1]}</span><DivLinea/></SpanValidacion>
                                                <label>Foto de perfil<span style={{color:"#eb1a1a"}}>*</span></label>
                                                <Input id="imgPerfil" accept=".jpg, .png, .jpeg" type="file"/>
                                            </Campo>
                                        </FilaModalHotel>
                                        <h4>Fotos y detalles</h4>
                                        <FilaModalHotel style={{flexDirection:"column"}}>
                                            <Campo style={{width:"100%", marginBottom:0}}>
                                                <SpanValidacion active={inputs.descripcion[1]}><span>{inputs.descripcion[1]}</span><DivLinea/></SpanValidacion>
                                                <label>Descripción</label>
                                                <Textarea style={{height:"18vh"}} value={inputs.descripcion[0]} error={inputs.descripcion[1]} onChange={(e)=>actualizarInput("descripcion", e.target.value)}/>
                                            </Campo>
                                            <Campo style={{marginBottom:0}}>
                                                <SpanValidacion active={inputs.imagenes[1]}><span>{inputs.imagenes[1]}</span><DivLinea/></SpanValidacion>
                                                <label>Fotos</label>
                                                <Input accept=".jpg, .png, .jpeg" multiple id="inputImagenes" type="file"/>
                                            </Campo>
                            </FilaModalHotel>

                            <h4>Ofertas</h4>
                            { ofertasAdd.map((value, index)=>(
                                codigoOferta ? codigoOferta == value.codigoOferta && 
                                <Oferta>
                                    <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                                        <span>Oferta {value.codigoOferta}</span>
                                        <img style={{cursor:"pointer", width:pc ? "2vw" : "6vw"}} onClick={()=>eliminarOferta(index)} src={quitar}/>
                                    </div>
                                    { value.ofertas.map((oferta, indexOferta)=>(
                                        <SubCampo style={{backgroundColor:"#fff", color:"#000"}} oferta={true} key={indexOferta}>
                                            <p>Sub-oferta ({oferta.dias.map((dia, index)=>(dia+(index != oferta.dias.length-1 ? ", " : "")))})</p>
                                            <img onClick={()=>eliminarOferta(undefined, value.codigoOferta, indexOferta)} src={quitar1}/>
                                        </SubCampo>
                                    ))}
                                    {value.ofertas.length == 0 && <p style={{color:"#fff", fontWeight:"200", textAlign:"center"}}>No hay sub-ofertas</p>}
                                    <BtnAddOferta onClick={()=>{setCodigoOferta(value.codigoOferta); setOferta(true)}}>Añadir sub-oferta</BtnAddOferta>
                                </Oferta> : 
                                <Oferta>
                                    <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                                        <span>Oferta {value.codigoOferta}</span>
                                        <img style={{cursor:"pointer", width:pc ? "2vw" : "6vw"}} onClick={()=>eliminarOferta(index)} src={quitar}/>
                                    </div>
                                    { value.ofertas.map((oferta, indexOferta)=>(
                                        <SubCampo style={{backgroundColor:"#fff", color:"#000"}} oferta={true} key={indexOferta}>
                                            <p>Sub-oferta ({oferta.dias.map((dia, index)=>(dia+(index != oferta.dias.length-1 ? ", " : "")))})</p>
                                            <img onClick={()=>eliminarOferta(indexOferta, value.codigoOferta, indexOferta)} src={quitar1}/>
                                        </SubCampo>
                                    ))}
                                    {value.ofertas.length == 0 && <p style={{color:"#fff", fontWeight:"200", textAlign:"center"}}>No hay sub-ofertas</p>}
                                    <BtnAddOferta onClick={()=>{setCodigoOferta(value.codigoOferta); setOferta(true)}}>Añadir sub-oferta</BtnAddOferta>
                                </Oferta>
                                
                            ))}

                            { oferta ?
                                <FilaModalHotel style={{backgroundColor:"#FDFDFD", marginTop:ofertasAdd.length > 0 ? pc ? "1vw" : "" : pc ? "1" : "" , border:"1px solid #E4E4E4"}}>
                                                {!codigoOferta && <Campo>
                                                    <SpanValidacion active={inputs.oferta.codigoOferta[1]}><span>{inputs.oferta.codigoOferta[1]}</span><DivLinea/></SpanValidacion>
                                                    <label>Codigo de oferta<span style={{color:"#eb1a1a"}}>*</span></label>
                                                    <Input value={inputs.oferta.codigoOferta[0]} onChange={(e)=>actualizarInput("codigoOferta", e.target.value, "oferta")} error={inputs.oferta.codigoOferta[1]} />
                                                </Campo>}
                                                {!codigoOferta && <Campo>
                                                    <SpanValidacion active={inputs.oferta.habitacion[1]}><span>{inputs.oferta.habitacion[1]}</span><DivLinea/></SpanValidacion>   
                                                    <label>Habitación<span style={{color:"#eb1a1a"}}>*</span></label>
                                                    <Input value={inputs.oferta.habitacion[0]}  onChange={(e)=>actualizarInput("habitacion", e.target.value, "oferta")} error={inputs.oferta.habitacion[1]} />
                                                </Campo>}
                                               {!codigoOferta && <Campo>
                                                    <SpanValidacion active={inputs.oferta.desde[1]}><span>{inputs.oferta.desde[1]}</span><DivLinea/></SpanValidacion>
                                                    <label>Periodo (Desde)<span style={{color:"#eb1a1a"}}>*</span></label>
                                                    <Input onChange={(e)=>actualizarInput("desde", e.target.value, "oferta")} error={inputs.oferta.desde[1]}  type="date"/>
                                                </Campo>}
                                               {!codigoOferta && <Campo>
                                                    <SpanValidacion active={inputs.oferta.hasta[1]}><span>{inputs.oferta.hasta[1]}</span><DivLinea/></SpanValidacion>
                                                    <label>Periodo (Hasta)<span style={{color:"#eb1a1a"}}>*</span></label>
                                                    <Input onChange={(e)=>actualizarInput("hasta", e.target.value, "oferta")} error={inputs.oferta.hasta[1]}  type="date"/>
                                                </Campo>}
                                                <Campo>
                                                    <SpanValidacion active={inputs.oferta.SGL[1]}><span>{inputs.oferta.SGL[1]}</span><DivLinea/></SpanValidacion>
                                                    <label>SGL<span style={{color:"#eb1a1a"}}>*</span></label>
                                                    <Input value={inputs.oferta.SGL[0]}  onChange={(e)=>actualizarInput("SGL", e.target.value, "oferta")} error={inputs.oferta.SGL[1]} />
                                                </Campo>
                                                <Campo>
                                                    <SpanValidacion active={inputs.oferta.DBL[1]}><span>{inputs.oferta.DBL[1]}</span><DivLinea/></SpanValidacion>
                                                    <label>DBL<span style={{color:"#eb1a1a"}}>*</span></label>
                                                    <Input value={inputs.oferta.DBL[0]}  onChange={(e)=>actualizarInput("DBL", e.target.value, "oferta")} error={inputs.oferta.DBL[1]}  />
                                                </Campo>
                                                <Campo>
                                                    <SpanValidacion active={inputs.oferta.TPL[1]}><span>{inputs.oferta.TPL[1]}</span><DivLinea/></SpanValidacion>
                                                    <label>TPL<span style={{color:"#eb1a1a"}}>*</span></label>
                                                    <Input value={inputs.oferta.TPL[0]}  onChange={(e)=>actualizarInput("TPL", e.target.value, "oferta")} error={inputs.oferta.TPL[1]} />
                                                </Campo>
                                                <Campo>
                                                    <SpanValidacion active={inputs.oferta.niños[1]}><span>{inputs.oferta.niños[1]}</span><DivLinea/></SpanValidacion>
                                                    <label>Precio niños<span style={{color:"#eb1a1a"}}>*</span></label>
                                                    <Input value={inputs.oferta.niños[0]}  onChange={(e)=>actualizarInput("niños", e.target.value, "oferta")} error={inputs.oferta.niños[1]} />
                                                </Campo>
                                                {!codigoOferta && <Campo>
                                                    <SpanValidacion active={inputs.oferta.niñoGratis[1]}><span>{inputs.oferta.niñoGratis[1]}</span><DivLinea/></SpanValidacion>
                                                    <label>Niño gratis<span style={{color:"#eb1a1a"}}>*</span></label>
                                                    <Select type="checkbox" defaultValue="DEFAULT" onChange={(e)=>actualizarInput("niñoGratis", (e.target.value == "Primer niño") ? "1" : (e.target.value == "Segundo niño") ? "2" : "0", "oferta")} error={inputs.oferta.niñoGratis[1]}>
                                                        <option value="DEFAULT" disabled>Sin seleccionar</option>
                                                        <option>Ninguno</option>
                                                        <option>Primer niño</option>
                                                        <option>Segundo niño</option>
                                                    </Select>
                                                </Campo>}
                                                {!codigoOferta && <Campo>
                                                    <SpanValidacion active={inputs.oferta.vencimientoOferta[1]}><span>{inputs.oferta.vencimientoOferta[1]}</span><DivLinea/></SpanValidacion>
                                                    <label>Vencimiento de oferta<span style={{color:"#eb1a1a"}}>*</span></label>
                                                    <Input type="date"  onChange={(e)=>actualizarInput("vencimientoOferta", e.target.value, "oferta")} error={inputs.oferta.vencimientoOferta[1]} />
                                                </Campo>}
                                                {!codigoOferta && <Campo>
                                                    <SpanValidacion active={inputs.oferta.minimoNoches[1]}><span>{inputs.oferta.minimoNoches[1]}</span><DivLinea/></SpanValidacion>
                                                    <label>Minimo de noches<span style={{color:"#eb1a1a"}}>*</span></label>
                                                    <Input onChange={(e)=>actualizarInput("minimoNoches", e.target.value, "oferta")} error={inputs.oferta.minimoNoches[1]} />
                                                </Campo>}
                                                
                                                <Campo style={{width:"100%", alignItems:"center"}}>
                                                    <label>Dias<span style={{color:"#eb1a1a"}}>*</span></label>
                                                    <SpanValidacion style={{marginRight: "44%", marginBottom: pc ? "8.5%" : "23%"}} active={inputs.oferta.dias[1]}><span>{inputs.oferta.dias[1]}</span><DivLinea/></SpanValidacion>
                                                    <Dia>
                                                        <label>Todos</label>
                                                        <input onChange={(e)=>actualizarInput("dias", e.target.checked, "oferta", "todos")} type="checkbox"/>
                                                    </Dia>
                                                    {inputs.oferta.dias[0][0] != "todos" && <Dias>                                                        
                                                        <Dia>
                                                            <label>Lunes</label>
                                                            <input onChange={(e)=>actualizarInput("dias", e.target.checked, "oferta", "lunes")} type="checkbox"/>
                                                        </Dia>
                                                        <Dia>
                                                            <label>Martes</label>
                                                            <input onChange={(e)=>actualizarInput("dias", e.target.checked, "oferta", "martes")} type="checkbox"/>
                                                        </Dia>
                                                        <Dia>
                                                            <label>Miercoles</label>
                                                            <input onChange={(e)=>actualizarInput("dias", e.target.checked, "oferta", "miercoles")} type="checkbox"/>
                                                        </Dia>
                                                        <Dia>
                                                            <label>Jueves</label>
                                                            <input onChange={(e)=>actualizarInput("dias", e.target.checked, "oferta", "jueves")} type="checkbox"/>
                                                        </Dia>
                                                        <Dia>
                                                            <label>Viernes</label>
                                                            <input onChange={(e)=>actualizarInput("dias", e.target.checked, "oferta", "viernes")} type="checkbox"/>
                                                        </Dia>
                                                        <Dia>
                                                            <label>Sabado</label>
                                                            <input onChange={(e)=>actualizarInput("dias", e.target.checked, "oferta", "sabado")} type="checkbox"/>
                                                        </Dia>
                                                        <Dia>
                                                            <label>Domingo</label>
                                                            <input onChange={(e)=>actualizarInput("dias", e.target.checked, "oferta", "domingo")} type="checkbox"/>
                                                        </Dia>
                                                    </Dias>}
                                                </Campo>
                                                <Btns style={{marginBottom:pc?"1vw":"1vw"}}>
                                                    <Button onClick={()=>{setOferta(false); setCodigoOferta(false); const inputs1 = inputs; inputs1.oferta = defaultInput.oferta; setInputs(JSON.parse(JSON.stringify(inputs1)))}} style={{fontSize:pc ? "0.85vw" : "3vw", padding:pc ? "0.3vw" : "1vw"}}>Cerrar</Button>
                                                    <Button onClick={()=>validarOferta()} style={{fontSize:pc ? "0.85vw" : "3vw", padding:pc ? "0.3vw" : "1vw"}}>Añadir {codigoOferta ? "sub-oferta" : "oferta"}</Button>
                                                </Btns>
                                </FilaModalHotel>
                                :
                                <FilaModalHotel>
                                    <Btns>
                                        <Button disabled={cargandoHotel} opacity={cargandoHotel} onClick={()=>{setCodigoOferta(false); setOferta(true)}} style={{fontSize:pc ? "0.85vw" : "3vw", padding:pc ? "0.3vw" : "1vw"}}>Agregar Oferta</Button>
                                    </Btns>
                                </FilaModalHotel>
                            }
                            <Btns>
                                <Button opacity={cargandoHotel} onClick={()=>!cargandoHotel && setModalAddHotel(false)}>Cancelar</Button>
                                    <Button style={{backgroundColor:cargandoHotel ? "#fff" : ""}} onClick={()=>!cargandoHotel && agregarHotel()} yes={true}>
                                    { cargandoHotel ?
                                        <CargandoCont><img src={cargandoImg}/></CargandoCont>
                                        :
                                        "Agregar hotel"
                                    }
                                </Button>
                            </Btns>
                        </ModalAddHotel>
                    </Modal>
                }

                { location.search == "?tab=ofertas" ?
                    <div>
                        <h2>OFERTAS</h2>
                        <OfertasTotalContenedor>
                            <OfertasContenedor msgVal={msgVal}>
                            { ofertas === undefined && <CargandoCont><img src={cargandoImg}/></CargandoCont>}
                            { (ofertas !== undefined && ofertas !== false) && 
                                    <OfertaContenedor>
                                    { cargando ?
                                        <CargandoCont><img src={cargandoImg}/></CargandoCont>
                                        : 
                                        <BtnAdd onClick={()=>document.querySelector("#btnAgregarOferta").click()}>
                                            <p>Agregar Oferta</p>
                                            <img src={mas}/>
                                        </BtnAdd>
                                    }
                                    </OfertaContenedor>
                                }
                                {(ofertas !== undefined && ofertas !== false) ? ofertas.length > 0 ? ofertas.map((value, index)=>(
                                    <OfertaContenedor key={index}>
                                        <Img img={value.img}/>
                                        <ImgEliminar onClick={()=>{setId(value.id); setModal(true)}} src={eliminar} />
                                        <BtnDescargar>
                                            <p onClick={()=>downloadImage(value.img)}>Descargar</p>
                                            <img src={descargar}/>
                                        </BtnDescargar>
                                    </OfertaContenedor>
                                ))
                                : <span>{msgVal}</span> : <span>{msgVal}</span>
                                }
                            </OfertasContenedor>
                        </OfertasTotalContenedor>

                        <input id="btnAgregarOferta" style={{display:"none"}} onChange={(e)=>addOferta(e.target.files[0])} type="file" accept=".jpg, .png, .jpeg"/>
                    </div>
                    : location.search == "?tab=hoteles" ? 
                    //HOTELES
                    <div>
                        <HotelesContenedor paddingBottom={true}>
                            <h2>Hoteles</h2>
                            {hoteles !== undefined && hoteles !== false && hoteles.length > 0 &&
                                <BuscarContenedor>
                                    <input value={inputHotel} onChange={(e)=>{setInputHotel(e.target.value)}} placeholder="Busca un hotel"/>
                                    {(inputHotel || search !== undefined) && <img onClick={()=>{setSearch(undefined); setInputHotel(""); setLocalidadInput("Todos"); document.querySelector("#optionLocal").value="Todos"}} style={{cursor:"pointer", width:pc ? "2.5vw" : "8vw", height: pc ? "2.5vw" : "8vw", margin:"auto 0"}} src={quitar1}/>}
                                    <button type="submit" onClick={(e)=>buscarHotel(e)}>
                                        <img src={buscar}/>
                                    </button>
                                </BuscarContenedor>
                            }
                            { hoteles !== undefined && hoteles !== false && hoteles.length > 0 &&
                                <div style={{display:"flex", alignItems:"center", marginLeft:"auto", marginRight:"10%", marginTop:pc ? "0.5vw" : "1.4vw"}}>
                                    <p style={{marginRight:pc ? "0.5vw" : "1vw", color:"#515151", fontSize:pc ? "1.3vw" : "3.5vw"}}>Zona hotelera:</p>
                                    <select style={{cursor:"pointer", fontSize:!pc && "3.5vw"}} id="optionLocal" defaultValue="Todos" onChange={(e)=>getHotelesLocalidad(e.target.value)}>
                                        <option defaultValue="Todos">Todos</option>
                                        <option>Zona este</option>
                                        <option>Zona norte</option>
                                    </select>
                                </div>
                            }
                            { cargando ?
                                <CargandoCont>
                                    <img src={cargandoImg} />
                                </CargandoCont>
                                : search !== undefined ? 
                                <HotelesContenedor>
                                    <HotelesCont>
                                        {(search !== false) ?
                                            search.length > 0 ?
                                            search.map((value)=>(
                                                <Hotel title={value.nombre} key={value.id} onClick={()=>{setIdHotel(value.id); setModalHotel(true)}}>
                                                    <HotelImgFondo imagen={hotelSinImg}><HotelImg imagen={value.fotoPerfil}/></HotelImgFondo>
                                                    <p>{value.nombre.length > 15 ? value.nombre.slice(0, 16)+"…" : value.nombre}</p>
                                                </Hotel>
                                            ))
                                            : 
                                            <p>No hay resultados para tu busqueda.</p> 
                                        : <p>{msgVal2}</p>
                                        }
                                    </HotelesCont>
                                </HotelesContenedor>
                                : localidadInput != "Todos" ?
                                    localidad !== undefined ?
                                        <HotelesContenedor>
                                            <HotelesCont>
                                                {(localidad[localidadInput] !== false) ?
                                                    localidad[localidadInput].length > 0 ?
                                                    localidad[localidadInput].map((value)=>(
                                                        <Hotel title={value.nombre} key={value.id} onClick={()=>{setIdHotel(value.id); setModalHotel(true)}}>
                                                            <HotelImgFondo imagen={hotelSinImg}><HotelImg imagen={value.fotoPerfil}/></HotelImgFondo>
                                                            <p>{value.nombre.length > 15 ? value.nombre.slice(0, 16)+"…" : value.nombre}</p>
                                                        </Hotel>
                                                    ))
                                                    : 
                                                    <p>No hay hoteles disponibles en esta localidad.</p> 
                                                : <p>{msgVal2}</p>
                                                }
                                            </HotelesCont>
                                            {
                                                (ultimoElementoLocalidad[localidadInput] !== undefined && ultimoElementoLocalidad[localidadInput] !== false) ?
                                                cargando1 ? 
                                                    <CargandoCont><img src={cargandoImg}/></CargandoCont>
                                                    : 
                                                    <p onClick={()=>getHotelesLocalidad(undefined, true)} style={{cursor:"pointer", textDecoration:"underline", padding:"0.5vw"}}>Ver mas</p>
                                                : 
                                                undefined   
                                            }
                                    </HotelesContenedor>
                                    : undefined
                                : <HotelesContenedor>
                                    <HotelesCont>
                                        <Hotel onClick={()=>setModalAddHotel(true)}>
                                            <HotelImg add={true} imagen={mas} />
                                            <p>Añadir hotel</p>
                                        </Hotel>
                                        {(hoteles !== false && hoteles !== undefined) ?
                                            hoteles.length > 0 ?
                                            hoteles.map((value)=>(
                                                <Hotel title={value.nombre} key={value.id} onClick={()=>{setIdHotel(value.id); setModalHotel(true)}}>
                                                    <HotelImgFondo imagen={hotelSinImg}><HotelImg imagen={value.fotoPerfil}/></HotelImgFondo>
                                                    <p>{value.nombre.length > 15 ? value.nombre.slice(0, 16)+"…" : value.nombre}</p>
                                                </Hotel>
                                            ))
                                            : 
                                            <p>No hay hoteles disponibles.</p> 
                                        : <p>{msgVal2}</p>
                                        }
                                    </HotelesCont>
                                    {
                                        (ultimoElemento !== undefined && ultimoElemento !== false) ?
                                        cargando1 ? 
                                            <CargandoCont><img src={cargandoImg}/></CargandoCont>
                                            : 
                                            <p onClick={()=>obtenerHoteles()} style={{cursor:"pointer", textDecoration:"underline", padding:"0.5vw"}}>Ver mas</p>
                                        : 
                                        undefined   
                                    }
                                </HotelesContenedor>        
                            }
                        </HotelesContenedor>
                        <Modal visible={modalHotel} setVisible={()=>{setIdHotel(""); setModalHotel(false)}}>
                            <ModalEliminar> 
                                    <h3>¿Que deseas hacer?</h3>
                                    {/* <p>Esta acción no se puede deshacer.</p> */}
                                    <Btns>
                                        <Button edit={true} onClick={()=>setEditHotel(true)}> Ver/Modificar</Button>
                                        <Button red={true} onClick={()=>{setModalHotel(false); setModal(true)}}>Eliminarlo</Button>
                                    </Btns>
                            </ModalEliminar>
                        </Modal>
                    </div>
                    : location.search == "?tab=usuarios" ?
                        <Users/>
                    : location.search == "?tab=seguros" ?
                        <Seguros/>        
                    : validarUrl() === true ?
                        <Solicitudes/>
                    : location.search == "?tab=villas" ?
                        <Villas/>
                    : <p>La pagina que visitaste no existe.</p>
                }
                <BtnCerrar onClick={()=>setModal(false)}><img src={quitar}/></BtnCerrar>
            </PlataformaContainer>
            }
        </div>
    );
}