import styled from "styled-components";

const pc = window.screen.width > 529 ? true : false;

export const CuadroContenedor = styled.div`
    display: flex;
    padding: ${pc ? "1.5vw" : "3vw"};
    padding-bottom: 0;
    border-radius: 2vw;
    background-color: ${props => props.tarifas ? "#004aad" : "#fff"};
    margin-top: ${pc ? "1vw" : "2vw"};
    overflow-x: ${!pc && "scroll"};
    width: ${!pc && "90vw"};
`;

export const TopCuadroContenedor = styled.div`
    display: flex;
    border-bottom: 1px solid ${pc ? props => props.tarifas ? "#fff" : "#B8B8B8" : "#B8B8B8"};
    
    & p {
        color: ${props => props.tarifas ? "#fff!important" : "#000"};
    }
`;

export const CuadroCont = styled.div`
    border: 1px solid ${pc ? props => props.tarifas ? "#fff" : "#B8B8B8" : "#B8B8B8"};
    border-bottom: none;
    display: flex;
    flex-direction: column;
`;

export const Cuadro = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid ${pc ? props => props.tarifas ? "#fff" : "#B8B8B8" : "#B8B8B8"};
    padding: ${pc ? "0.5vw" : "1.5vw"};
    width: ${ props => props.width ? props.width : pc ?  props.tarifas ? "9.5vw!important" : "15vw" : props.hotel ? "21vw" : "28vw"};

    & p {
        color:#000;
        text-align: center;
        font-size: ${pc ? props => props.tarifas ? "0.85vw!important" : "1vw!important" : "2.6vw!important"};
    }

    & input {
        width: 100%;
        padding: 0.7vw;
        padding-left: 1.5vw;
        font-size: ${pc ? props => props.tarifas ? "0.7vw" : "2.7vw" : "2.7vw"};
        background-color: #fff;
        border: none;
        border-radius: 1vw;
    }

    & input:focus {
        outline:none;
    }

    & select {
        width: 100%;
        padding: 0.5vw;
        font-size: ${pc ? props => props.tarifas ? "0.7vw" : "2.5vw" : "2.5vw"};
    }
`;

export const BottomCuadroContenedor = styled.div`
    display: ${props => props.search ? "none" : "flex"};
    border-bottom: 1px solid ${pc ? props => props.tarifas ? "#fff" : "#B8B8B8" : "#B8B8B8"};
    background-color: ${props => props.bloqueado ? "#AF1313" : "#004aad"};

    & p {
        font-weight: 200;
        color:#fff;
    }
`;