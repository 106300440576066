import { useLocation } from "react-router-dom";
import Pagina404 from "../../pagina404";

import TarifarioHoteles from "./hoteles";
import TarifarioVillas from "./villas";


export default function Tarifario (){

    const location = useLocation();

    return (
        <div>
            { location.search == "?tab=hoteles" ?
                <TarifarioHoteles/>
            : location.search == "?tab=villas" ?
                <TarifarioVillas/>
            : <Pagina404/>
            }
        
        </div>
    );
};