export async function descargarArchivo(url){
    try {
        await fetch(url)
        .then(resp => resp.blob())
        .then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = "documento.";
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        });
    } catch {
        
    }
}

export function comprobarLink (link){
    const httpIsTrue = link.charAt(0) + link.charAt(1) + link.charAt(2) + link.charAt(3);
    if (httpIsTrue == "http") {
        return true;
    } else {
        return false;
    }
}