import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ContenedorOpcion from "../contenedorOpcion";
import styled from "styled-components";
import sessionContext from "../../../contexts/sessionContext";
import { confirmarSolicitud, getSolicitudesCliente } from "../../../config/auth";
import  CargandoCont from "../../utils/cargando";
import Modal, {DetallesReserva, BtnCerrar} from "../../utils/modal";
import { descargarArchivo, comprobarLink } from "../../utils/funciones";
import { BtnConfirmar } from "../../utils/componentes";

//IMAGENES
import cargandoImg from "../../../images/cargando.gif";
import quitar from "../../../images/quitar1.png";
import descargar from "../../../images/descargar.png";
import subir from "../../../images/subir.png";
import editar from "../../../images/edit1.png";
import perfilHotelContext from "../../../contexts/perfilHotelContext";

const pc = window.screen.width > 529 ? true : false;

const VentasContainer = styled.div`
    display: flex;
    flex-direction: column;
    & h3 {
        color:#004aad;
        font-weight: 600;
        font-size: ${pc ? "2.1vw" : "7vw"};
        margin-top: ${pc && "1.5vw"};
    }
`;


const CuadroContenedor = styled.div`
    display: flex;
    background-color: #fff;
    margin-top: ${pc ? "0.5vw" : "2vw"};
    overflow-x: ${pc ? "" : "scroll"};
    width: 75vw;
`;

const TopCuadroTarifasContenedor = styled.div`
    display: flex;
    border-bottom: 1px solid #7E7979;
    background-color: #004aad;

    & p {
        color:#fff;
    }
`;

const CuadroTarifas = styled.div`
    border: none;
    border-bottom: none;
    display: flex;
    flex-direction: column;
`;

const Cuadro = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #7E7979;
    border-left: ${props => props.left ? "1px solid #7E7979" : "none"};
    padding: ${pc ? "0.5vw" : "1.5vw"};
    width: ${pc ? "15vw" : "28vw"};

    & p {
        text-align: center;
        font-size: ${pc ? "1.1vw" : " 2.7vw"};
    }

    & input {
        width: 100%;
        padding: 0.7vw;
        padding-left: 1.5vw;
        font-size: 2.7vw;
        background-color: #fff;
        border: none;
        border-radius: 1vw;
    }

    & input:focus {
        outline:none;
    }

    & select {
        width: 100%;
        padding: 0.5vw;
        font-size: 2.5vw;
    }
`;

const BottomCuadroTarifasContenedor = styled.div`
    display: flex;
    border-bottom: ${props => props.bottom ? "none" : "1px solid #7E7979"};
    background-color: #fff;
    opacity: ${props => props.bloqueado && "0.7"};

    & p {
        font-weight: 400;
        color: #004aad;
    }
`;

const TotalCont = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    margin-top: 0.5vw;

    & h4 {
        color:#004aad;
        font-size: ${pc ? "1.1vw" : " 4.5vw"};
        margin-right: 2vw;
    }
`;

const ValoresCont = styled.div`
    display: flex;
    border: 1px solid #000;
    border-right:none;;

    & p {
        color:#000;
        padding: ${pc ? "0.5vw" : "2vw"};
        font-size: ${pc ? "1.1vw" : "3vw"};
        border-right: 1px solid #000;
        font-weight: 600;
        text-align: center;
        width: ${pc ? "15vw" : "28vw"};
    }
`;

const BtnDescargar = styled.div`
    padding: ${pc ? "0.5vw" : "1vw"};
    cursor: pointer;
    border-radius: ${pc ? "0.3vw" : "1vw"};
    background-color: #E7971D;
    text-decoration: none;

    & img {
        width: ${pc ? "1.8vw" : "4vw"};
    }
    & p{
        color:#fff
    }
`;

const BtnEditar = styled.button`
    background-color: #F5B114;
    padding: ${pc ? "0.5vw" : "2vw"};
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    width: ${pc ? "45%" : "70%"};
    margin: 0 auto;
    margin-top: ${pc ? "1vw" : "3vw"};
    cursor:pointer;
    border-radius: ${pc ? "0.3vw" : "1vw"};

    & img {
        width: ${pc ? "1.4vw" : "5vw"};
        margin-left: ${pc ? "0.5vw" : "3vw"};
    }

    & p {
        font-weight:500;
        font-size: ${pc ? "1.2vw" : "4vw"};
        color:#fff;
    }

    &:hover {
        background-color: #F1AC0B;
    }
`;

const BtnSubir = styled.div`
    padding: ${pc ? "0.5vw" : "1vw"};
    cursor: pointer;
    border-radius: ${pc ? "0.3vw" : "1vw"};
    background-color: #8C8C8C;
    
    & img {
        width: ${pc ? "1.8vw" : "4vw"};
    }
    & p{
        color:#fff
    }
`;

export default function MisVentas (){

    const { session } = useContext(sessionContext);
    const { perfil, setPerfil } = useContext(perfilHotelContext);
    const location = useLocation();
    const navigate = useNavigate();
    
    let [pagina, setPagina] = useState(undefined);
    let [ultimoElemento, setUltimoElemento] = useState({seguros:undefined, traslados:undefined, vuelos:undefined, grupos:undefined, reservasVillas:undefined, reservasHoteles:undefined});
    let [solicitudes, setSolicitudes] = useState({seguros:undefined, traslados:undefined, vuelos:undefined, grupos:undefined, reservasVillas:undefined, reservasVillas:undefined});
    let [cargando, setCargando] = useState();
    let [modal, setModal] = useState("");
    let [tipo, setTipo] = useState("");
    let [cargando1, setCargando1] = useState(false);
    let [msgVal2, setMsgVal2] = useState("");

    useEffect(()=>{
        const tab = "?tab=";
        if (location.search == tab+"seguros-de-viajes") {
            setPagina("Seguros de viajes");
            setTipo("seguros");
        } else if (location.search == tab+"transporte") {
            setPagina("Traslados");
            setTipo("traslados");
        } else if (location.search == tab+"vuelos"){
            setPagina("Vuelos");
            setTipo("vuelos");
        } else if (location.search == tab+"grupos"){
            setPagina("Grupos");
            setTipo("grupos");
        } else if (location.search == tab+"reservasVillas"){
            setTipo("reservasVillas");
        } else if (!location.search){
            setTipo("reservasHoteles");
        }
    },[location.search]);


    async function getData(onlyTipo){
        let tipos = ["seguros", "traslados", "grupos", "reservasHoteles", "reservasVillas", "vuelos"];
        setCargando(true);
        if (onlyTipo) {
            if (solicitudes[onlyTipo] === undefined) {
                const result = await getSolicitudesCliente({onlyTipo:onlyTipo});
                if (result[0]) {
                    const newSolicitudes = solicitudes;
                    newSolicitudes[onlyTipo] =result[1].datos; 
                    setSolicitudes(JSON.parse(JSON.stringify(newSolicitudes)));
                    const newUltimoElemento = ultimoElemento;
                    newUltimoElemento[onlyTipo] = result[1].ultimoElemento;
                    setUltimoElemento(newUltimoElemento);
                } else {
                    const newSolicitudes = solicitudes;
                    newSolicitudes[onlyTipo] = result[0]; 
                    setSolicitudes(JSON.parse(JSON.stringify(newSolicitudes)));
                }
            } else {
                if (ultimoElemento[onlyTipo] !== false) {
                    const result = await getSolicitudesCliente({onlyTipo:onlyTipo, ultimoElemento:ultimoElemento[onlyTipo]});
                    const newUltimoElemento = ultimoElemento;
                    newUltimoElemento[onlyTipo] = result[1].ultimoElemento;
                    setUltimoElemento(newUltimoElemento);
                    if (result[0]) {
                        const newSolicitudes = solicitudes;
                        result[1].datos.map((value, index)=>{
                            let si = true;
                            newSolicitudes[onlyTipo].map((old)=>{
                                if (value.id == old.id) {
                                    si = false;
                                }
                            })
                            if (si) {
                                newSolicitudes[onlyTipo].push(value);
                            }
                        });
                        setSolicitudes(JSON.parse(JSON.stringify(newSolicitudes)));
                    } else {
                        const newSolicitudes = solicitudes;
                        newSolicitudes[onlyTipo] = result[0]; 
                        setSolicitudes(JSON.parse(JSON.stringify(newSolicitudes)));
                    }
                }
            }
        } else {
            tipos.map(async(tipo)=>{
                if (solicitudes[tipo] === undefined) {
                    const result = await getSolicitudesCliente({tipo:tipo});
                    if (result[0]) {
                        const newSolicitudes = solicitudes;
                        newSolicitudes[tipo] =result[1].datos; 
                        setSolicitudes(JSON.parse(JSON.stringify(newSolicitudes)));
                        const newUltimoElemento = ultimoElemento;
                        newUltimoElemento[tipo] = result[1].ultimoElemento;
                        setUltimoElemento(newUltimoElemento);
                    } else {
                        const newSolicitudes = solicitudes;
                        newSolicitudes[tipo] = result[0]; 
                        setSolicitudes(JSON.parse(JSON.stringify(newSolicitudes)));
                    }
                } else {
                    if (ultimoElemento[tipo] !== false) {
                        const result = await getSolicitudesCliente({tipo:tipo, ultimoElemento:ultimoElemento[tipo]});
                        const newUltimoElemento = ultimoElemento;
                        newUltimoElemento[tipo] = result[1].ultimoElemento;
                        setUltimoElemento(newUltimoElemento);
                        if (result[0]) {
                            const newSolicitudes = solicitudes;
                            result[1].datos.map((value, index)=>{
                                let si = true;
                                newSolicitudes[tipo].map((old)=>{
                                    if (value.id == old.id) {
                                        si = false;
                                    }
                                })
                                if (si) {
                                    newSolicitudes[tipo].push(value);
                                }
                            });
                            setSolicitudes(JSON.parse(JSON.stringify(newSolicitudes)));
                        } else {
                            const newSolicitudes = solicitudes;
                            newSolicitudes[tipo] = result[0]; 
                            setSolicitudes(JSON.parse(JSON.stringify(newSolicitudes)));
                        }
                    }
                }
            })
        }
        setCargando(false);
    }

    function calcular (tipo){
        const totales = {seguros:[0, 0], vuelos:[0, 0], reservasHoteles:[0, 0], reservasVillas:[0, 0], traslados:[0, 0], grupos:[0, 0]};
        const total = [0, 0];
        Object.keys(solicitudes).map((value)=>{
            if (solicitudes[value] !== undefined && solicitudes[value] !== false) {
                if (value != "reservasHoteles" && value != "reservasVillas"){
                    solicitudes[value].map((solicitud)=>{
                        if (solicitud.totalUs && solicitud.comisionUs) {
                            totales[value][0] = totales[value][0] + parseInt(solicitud.totalUs);
                            totales[value][1] = totales[value][1] + parseInt(solicitud.comisionUs);
                        }
                    });
                } else {
                    if (value == "reservasHoteles" || value == "reservasVillas") {
                        solicitudes[value].map((solicitud)=>{
                            totales[value][0] = totales[value][0] + JSON.parse(JSON.stringify(solicitud.campos.totalUs));
                            totales[value][1] = totales[value][1] + JSON.parse(JSON.stringify(solicitud.campos.comisionUs));
                        });
                    }
                }
            }
        });
        Object.keys(totales).map((value)=>{
            if (solicitudes[value] !== undefined && solicitudes[value] !== false) {
                if (totales[value][0] && totales[value][1]) {
                    total[0] = total[0]+totales[value][0];
                    total[1] = total[1]+totales[value][1];
                }                
            }
        });

        Object.keys(totales).map((value)=>{
            let total = totales[value][0].toString();
            let comision = totales[value][1].toString();
            total = total[0]+total[1]+total[2]+total[3]+total[4];
            comision = comision[0]+comision[1]+comision[2]+comision[3]+comision[4];
            totales[value][0] = parseInt(total);
            totales[value][1] = parseInt(comision);
        });

        if (tipo != "total") {
            if (totales[tipo][0] && totales[tipo][1]) {
                return totales[tipo];
            } else {
                return [0, 0];
            }
        } else {
            return total;
        };
    }

    async function confirmarSoli (){
        setCargando(true);
        const result = await confirmarSolicitud({id:modal.id, tipo:tipo});
        if (result === true) {
            const newModal = modal;
            newModal.confirmado = true;
            setModal(newModal);
            const newSolicitudes = solicitudes;
            newSolicitudes.map((value, index)=>{
                if (value.id == modal.id) {
                    newSolicitudes[index].confirmado = true;
                }
            });
            setSolicitudes(newSolicitudes);
        } else {
            if (result == "error-04") {
                setMsgVal2("La solicitud fue eliminada debido a que ya pasaron 72 horas sin confirmarla.");
                setTimeout(()=>{
                    setMsgVal2("");
                }, 3000);
                const newSolicitudes = solicitudes;
                newSolicitudes.map((value, index)=>{
                    if (value.id == modal.id) {
                        newSolicitudes.splice(index, 1);
                    }
                });
                setSolicitudes(newSolicitudes);
                setModal("");
            } else {
                setMsgVal2("Comprueba tu conexión a internet");
                setTimeout(()=>{
                    setMsgVal2("");
                }, 3000);
            }
        }
        setCargando(false);
    }

    function modificarReserva (){
        const newPerfil = perfil;
        newPerfil.reserva = modal;
        setPerfil(newPerfil);
        navigate("/"+(tipo == "reservasHoteles" ? "hotel/" : "villa/")+(tipo == "reservasHoteles" ? modal.hotel : modal.villa)+"?tab=modificar-reserva")
    }

    useEffect(()=>{
        if (session.user) {
            getData();
        }
    }, [session.user]);

    return (
        <ContenedorOpcion>
            <h2>Mis ventas</h2>
            {   !location.search ?
                    <VentasContainer>
                        <h3>Reservas de hoteles</h3>
                        {   solicitudes.reservasHoteles !== undefined ?
                                    solicitudes.reservasHoteles !== false ?
                                        solicitudes.reservasHoteles.length > 0 ? 
                                            <CuadroContenedor>
                                                <CuadroTarifas>
                                                    <TopCuadroTarifasContenedor>
                                                        <Cuadro>
                                                            <p>Hotel</p>
                                                        </Cuadro>
                                                        <Cuadro>
                                                            <p>Cliente</p>
                                                        </Cuadro>
                                                        <Cuadro>
                                                            <p>Detalles</p>
                                                        </Cuadro>
                                                        <Cuadro>
                                                            <p>Monto total</p>
                                                        </Cuadro>
                                                        <Cuadro>
                                                            <p>Mi comisión</p>
                                                        </Cuadro>
                                                    </TopCuadroTarifasContenedor>
                                                    { solicitudes.reservasHoteles.map((value, index)=>(
                                                        <BottomCuadroTarifasContenedor key={value.id}>
                                                            <Cuadro><p>{value.hotel}</p></Cuadro>
                                                            <Cuadro><p>{value.campos.titular}</p></Cuadro>
                                                            <Cuadro style={{cursor:"pointer"}} onClick={()=>setModal(JSON.parse(JSON.stringify(value)))}><p style={{textDecoration:"underline"}}>Ver detalles</p></Cuadro>
                                                            <Cuadro><p>RD $ {value.campos.totalUs}</p></Cuadro>
                                                            <Cuadro><p>RD $ {value.campos.comisionUs}</p></Cuadro>
                                                        </BottomCuadroTarifasContenedor>
                                                    ))
                                                    }                                                    
                                                </CuadroTarifas>
                                            </CuadroContenedor> 
                                        : <p>No tienes solicitudes de hoteles</p>
                                    : <p>Comprueba tu conexión a internet.</p>
                                : <CargandoCont><img src={cargandoImg}/></CargandoCont> 
                            }
                    </VentasContainer>
                : location.search == "?tab=reservasVillas" ?
                    <VentasContainer>
                        <h3>Reservas de villas</h3>
                        {   solicitudes.reservasHoteles !== undefined ?
                                solicitudes.reservasHoteles !== false ?
                                    solicitudes.reservasHoteles.length > 0 ? 
                                        <CuadroContenedor>
                                            <CuadroTarifas>
                                                <TopCuadroTarifasContenedor>
                                                    <Cuadro>
                                                        <p>Villa</p>
                                                    </Cuadro>
                                                    <Cuadro>
                                                        <p>Cliente</p>
                                                    </Cuadro>
                                                    <Cuadro>
                                                        <p>Detalles</p>
                                                    </Cuadro>
                                                    <Cuadro>
                                                        <p>Monto total</p>
                                                    </Cuadro>
                                                    <Cuadro>
                                                        <p>Mi comisión</p>
                                                    </Cuadro>
                                                </TopCuadroTarifasContenedor>
                                                { solicitudes.reservasVillas.map((value, index)=>(
                                                    <BottomCuadroTarifasContenedor key={value.id}>
                                                        <Cuadro><p>{value.villa}</p></Cuadro>
                                                        <Cuadro><p>{value.campos.titular}</p></Cuadro>
                                                        <Cuadro style={{cursor:"pointer"}} onClick={()=>setModal(JSON.parse(JSON.stringify(value)))}><p style={{textDecoration:"underline"}}>Ver detalles</p></Cuadro>
                                                        <Cuadro><p>RD $ {value.campos.totalUs}</p></Cuadro>
                                                        <Cuadro><p>RD $ {value.campos.comisionUs}</p></Cuadro>
                                                    </BottomCuadroTarifasContenedor>
                                                ))
                                                }                                                    
                                            </CuadroTarifas>
                                        </CuadroContenedor> 
                                    : <p>No tienes solicitudes de hoteles</p>
                                : <p>Comprueba tu conexión a internet.</p>
                            : <CargandoCont><img src={cargandoImg}/></CargandoCont> 
                        }
                    </VentasContainer>
                : (location.search == "?tab=grupos") || (location.search == "?tab=transporte") || (location.search == "?tab=vuelos") ?
                    <VentasContainer>
                        <h3>{pagina}</h3>
                        {   solicitudes[tipo] !== undefined ?
                                    solicitudes[tipo] !== false ?
                                        solicitudes[tipo].length > 0 ? 
                                            <CuadroContenedor>
                                                <CuadroTarifas>
                                                    <TopCuadroTarifasContenedor>
                                                        <Cuadro>
                                                            <p>Cliente</p>
                                                        </Cuadro>
                                                        <Cuadro>
                                                            <p>Detalles</p>
                                                        </Cuadro>
                                                        <Cuadro>
                                                        <p>Monto Total</p>
                                                        </Cuadro>
                                                        <Cuadro>
                                                            <p>Mi comisión</p>
                                                        </Cuadro>
                                                    </TopCuadroTarifasContenedor>
                                                    { solicitudes[tipo].map((value, index)=>(
                                                        <BottomCuadroTarifasContenedor key={value.id}>
                                                            <Cuadro><p>{tipo == "grupos" ? value.nombreGrupo : value.nombreTitular}</p></Cuadro>
                                                            <Cuadro style={{cursor:"pointer"}} onClick={()=>setModal(value)}><p style={{textDecoration:"underline"}}>Ver</p></Cuadro>
                                                            <Cuadro><p>RD $ {value.totalUs ? value.totalUs : 0}</p></Cuadro>
                                                            <Cuadro><p>RD $ {value.comisionUs ? value.comisionUs : 0}</p></Cuadro>
                                                        </BottomCuadroTarifasContenedor>
                                                    ))
                                                    }                                                    
                                                </CuadroTarifas>
                                            </CuadroContenedor> 
                                        : <p>No tienes solicitudes</p>
                                    : <p>Comprueba tu conexion a internet.</p>
                                : <CargandoCont><img src={cargandoImg}/></CargandoCont> 
                            }
                    </VentasContainer>
                : location.search == "?tab=seguros-de-viajes" ?
                    <VentasContainer>
                       <h3>{pagina}</h3> 
                        {   solicitudes.seguros !== undefined ?
                                solicitudes.seguros !== false ?
                                    solicitudes.seguros.length > 0 ? 
                                        <CuadroContenedor>
                                            <CuadroTarifas>
                                                <TopCuadroTarifasContenedor>
                                                    <Cuadro>
                                                        <p>Hotel</p>
                                                    </Cuadro>
                                                    <Cuadro>
                                                        <p>Cliente</p>
                                                    </Cuadro>
                                                    <Cuadro>
                                                        <p>Detalles</p>
                                                    </Cuadro>
                                                    <Cuadro>
                                                        <p>Monto Total</p>
                                                    </Cuadro>
                                                    <Cuadro>
                                                        <p>Mi comisión</p>
                                                    </Cuadro>
                                                </TopCuadroTarifasContenedor>
                                                { solicitudes.seguros.map((value, index)=>(
                                                    <BottomCuadroTarifasContenedor key={value.id}>
                                                        <Cuadro><p>{value.seguro}</p></Cuadro>
                                                        <Cuadro><p>{value.form.nombreTitular}</p></Cuadro>
                                                        <Cuadro style={{cursor:"pointer"}} onClick={()=>setModal(value)}><p style={{textDecoration:"underline"}}>Ver</p></Cuadro>
                                                        <Cuadro><p>RD $ {value.totalUs ? value.totalUs : 0}</p></Cuadro>
                                                        <Cuadro><p>RD $ {value.comisionUs ? value.comisionUs : 0}</p></Cuadro>
                                                    </BottomCuadroTarifasContenedor>
                                                ))
                                                }                                                    
                                            </CuadroTarifas>
                                        </CuadroContenedor> 
                                    : <p>No tienes solicitudes</p>
                                : <p>Comprueba tu conexion a internet.</p>
                            : <CargandoCont><img src={cargandoImg}/></CargandoCont> 
                        }
                    </VentasContainer>
                : location.search == "?tab=total-ganancias" ?
                    <VentasContainer>
                        <h3>Total ganancias</h3>
                        <CuadroContenedor>
                            <CuadroTarifas>
                                <TopCuadroTarifasContenedor>
                                    <Cuadro>
                                        <p>Servicio</p>
                                    </Cuadro>
                                    <Cuadro>
                                        <p>Monto Total</p>
                                    </Cuadro>
                                    <Cuadro>
                                        <p>Mi comisión</p>
                                    </Cuadro>
                                </TopCuadroTarifasContenedor>

                                    <BottomCuadroTarifasContenedor>
                                        <Cuadro left={true}><p>Hoteles</p></Cuadro>
                                        <Cuadro><p>{ solicitudes.reservasHoteles === undefined ? "Cargando.." : solicitudes.reservasHoteles === false ? "Error" :  "RD $ " + calcular("reservasHoteles")[0]}</p></Cuadro>
                                        <Cuadro><p>{ solicitudes.reservasHoteles === undefined ? "Cargando.." : solicitudes.reservasHoteles === false ? "Error" :  "RD $ " + calcular("reservasHoteles")[1]}</p></Cuadro>
                                    </BottomCuadroTarifasContenedor>
                                    <BottomCuadroTarifasContenedor>
                                        <Cuadro left={true}><p>Villas</p></Cuadro>
                                        <Cuadro><p>{ solicitudes.reservasVillas === undefined ? "Cargando.." : solicitudes.reservasVillas === false ? "Error" :  "RD $ " + calcular("reservasVillas")[0]}</p></Cuadro>
                                        <Cuadro><p>{ solicitudes.reservasVillas === undefined ? "Cargando.." : solicitudes.reservasVillas === false ? "Error" :  "RD $ " + calcular("reservasVillas")[1]}</p></Cuadro>
                                    </BottomCuadroTarifasContenedor>
                                    <BottomCuadroTarifasContenedor>                        
                                        <Cuadro left={true}><p>Vuelos</p></Cuadro>
                                        <Cuadro><p>{ solicitudes.vuelos === undefined ? "Cargando.." : solicitudes.vuelos === false ? "Error" :  "RD $ " + calcular("vuelos")[0]}</p></Cuadro>
                                        <Cuadro><p>{ solicitudes.vuelos === undefined ? "Cargando.." : solicitudes.vuelos === false ? "Error" :  "RD $ " + calcular("vuelos")[1]}</p></Cuadro>
                                    </BottomCuadroTarifasContenedor>
                                    <BottomCuadroTarifasContenedor>
                                        <Cuadro left={true}><p>Grupos</p></Cuadro>
                                        <Cuadro><p>{ solicitudes.grupos === undefined ? "Cargando.." : solicitudes.grupos === false ? "Error" :  "RD $ " + calcular("grupos")[0]}</p></Cuadro>
                                        <Cuadro><p>{ solicitudes.grupos === undefined ? "Cargando.." : solicitudes.grupos === false ? "Error" :  "RD $ " + calcular("grupos")[1]}</p></Cuadro>
                                    </BottomCuadroTarifasContenedor>
                                    <BottomCuadroTarifasContenedor>
                                        <Cuadro left={true}><p>Seguros de viajes</p></Cuadro>
                                        <Cuadro><p>{ solicitudes.seguros === undefined ? "Cargando.." : solicitudes.seguros === false ? "Error" :  "RD $ " + calcular("seguros")[0]}</p></Cuadro>
                                        <Cuadro><p>{ solicitudes.seguros === undefined ? "Cargando.." : solicitudes.seguros === false ? "Error" :  "RD $ " + calcular("seguros")[1]}</p></Cuadro>
                                    </BottomCuadroTarifasContenedor>
                                    <BottomCuadroTarifasContenedor>
                                        <Cuadro left={true}><p>Traslados</p></Cuadro>
                                        <Cuadro><p>{ solicitudes.traslados === undefined ? "Cargando.." : solicitudes.traslados === false ? "Error" :  "RD $ " + calcular("traslados")[0]}</p></Cuadro>
                                        <Cuadro><p>{ solicitudes.traslados === undefined ? "Cargando.." : solicitudes.traslados === false ? "Error" :  "RD $ " + calcular("traslados")[1]}</p></Cuadro>
                                    </BottomCuadroTarifasContenedor>
                                <BottomCuadroTarifasContenedor bottom={true}>
                                    <TotalCont>
                                        <h4>Total</h4>
                                        <ValoresCont>
                                            <Cuadro><p style={{borderRight:"none"}}>RD $  {calcular("total")[0]}</p></Cuadro>
                                            <Cuadro><p style={{borderRight:"none"}}>RD $  {calcular("total")[1]}</p></Cuadro>
                                        </ValoresCont>
                                    </TotalCont>
                                </BottomCuadroTarifasContenedor>
                            </CuadroTarifas>
                        </CuadroContenedor>
                        { (ultimoElemento.reservasHoteles !== false || ultimoElemento.vuelos !== false || ultimoElemento.traslados !== false || ultimoElemento.grupos !== false || ultimoElemento.seguros !== false) ? 
                            cargando1 ? 
                                <CargandoCont><img src={cargandoImg}/></CargandoCont> 
                                : 
                                <p onClick={()=>getData()} style={{cursor:"pointer", padding:"0.5vw", textDecoration:"underline"}}>Ver mas</p> 
                            : <p></p>
                        }
                    </VentasContainer>
                : <div><p>La sección que visitaste no existe</p></div>

            }

            <Modal visible={modal} setVisible={()=>setModal(false)}>
               { modal ? (tipo == "grupos" || tipo == "vuelos" || tipo == "traslados") ?
                    <DetallesReserva>
                        <BtnCerrar onClick={()=>setModal(false)}><img src={quitar}/></BtnCerrar>
                        <h2>Detalles de la cotización</h2>
                        <div><h3>Estado:</h3><span style={{color: modal.estado == "Cotizado" && "#26AC20"}}>{modal.estado}</span></div>
                        <div>
                            <h3>Info cotización:</h3>
                            <span>
                                { modal.infoSolicitud ? 
                                    comprobarLink(modal.infoSolicitud) 
                                        ? 
                                        <BtnDescargar onClick={()=>descargarArchivo(modal.infoSolicitud)}>
                                            <p>Descargar</p>
                                            <img src={descargar} />
                                        </BtnDescargar>   
                                        : 
                                        modal.infoSolicitud
                                    :
                                    "No hay info"
                                }
                            </span>
                        </div>
                        { modal.estado == "Cotizado" && <div><h3>Confirmado:</h3><span>{modal.confirmado ? "Si" : cargando ? <CargandoCont><img src={cargandoImg}/></CargandoCont>  : <BtnConfirmar onClick={()=>confirmarSoli()}>Confirmar</BtnConfirmar>}</span></div>}
                        <div><h3>Titular:</h3><span>{modal.nombreTitular}</span></div>
                        <div><h3>Fecha de ida:</h3><span>{modal.fechaIda}</span></div>
                        <div><h3>Fecha de regreso:</h3><span>{modal.fechaRegreso}</span></div>
                        <div><h3>Aeropuerto de salida:</h3><span>{modal.aeropuertoSalida}</span></div>
                        <div><h3>Aeropuerto de destino:</h3><span>{modal.aeropuertoDestino}</span></div>
                        <div><h3>Horario preferible:</h3><span>{modal.horarioPreferible}</span></div>
                        <div><h3>Adultos:</h3><span>{modal.adultos}</span></div>
                        <div><h3>Niños</h3><span>{modal.niños}</span></div>
                        { modal && modal.edades.map((value, index)=>(
                            <div key={index+1}><h3>Edad de niño {index+1}:</h3><span>{value}</span></div>
                        ))}
                        <div><h3>Observaciones:</h3><span>{modal.observaciones}</span></div>
                    </DetallesReserva>
                    : tipo == "seguros" ?
                    <DetallesReserva>
                        <BtnCerrar onClick={()=>setModal(false)}><img src={quitar}/></BtnCerrar>
                        <h2>Detalles de la solicitud</h2>
                        <div><h3>Estado</h3><span style={{color: modal.estado == "Confirmado" && "#26AC20"}}>{modal.estado}</span></div>
                        <div>
                            <h3>Transferencia Voucher:</h3>
                            <span>
                                { modal.transferenciaVoucher == "none" 
                                    ? <BtnSubir onClick={()=>document.querySelector("#btnArchivo").click()}>
                                            { !cargando1
                                                ? <div style={{display:"flex", alignItems:"center", justifyContent:"center", border:"none", margin:0}}>
                                                    <p>Subir Voucher</p>
                                                    <img src={subir}/>
                                                </div>
                                                : <p>Subiendo..</p>
                                            }
                                        </BtnSubir> 
                                    :   <BtnDescargar onClick={()=>descargarArchivo(modal.transferenciaVoucher)}>
                                            <p>Voucher</p>
                                            <img src={descargar} />
                                        </BtnDescargar> 
                                }
                            </span>
                        </div>
                        <div>
                            <h3>Documento de confirmación:</h3>
                            <span>
                                {modal.documentoConfirmacion == "none" ? "Sin documento" : 
                                    <BtnDescargar onClick={()=>descargarArchivo(modal.documentoConfirmacion)}>
                                        <p>Descargar</p>
                                        <img src={descargar} />
                                    </BtnDescargar>
                                }
                            </span>
                        </div>
                        <div><h3>Titular:</h3><span>{modal.form.nombreTitular}</span></div>
                        <div><h3>Destino:</h3><span>{modal.form.destino}</span></div>
                        <div><h3>Fecha de ida:</h3><span>{modal.form.desdeFecha}</span></div>
                        <div><h3>Fecha de regreso:</h3><span>{modal.form.hastaFecha}</span></div>
                        <div><h3>Cantidad de personas:</h3><span>{modal.form.cantidadPersonas}</span></div>
                        <div><h3>Seguro:</h3><span>{modal.seguro}</span></div>
                    </DetallesReserva>
                    : (tipo == "reservasHoteles" || tipo == "reservasVillas") &&
                     <DetallesReserva>
                        <BtnCerrar onClick={()=>setModal(false)}><img src={quitar}/></BtnCerrar>
                        <h2>Detalles de la reserva</h2>
                        <div><h3>Estado del pago de la reserva</h3><span style={{color: modal.estado == "Pagado" && "#26AC20"}}>{modal.estado}</span></div>
                        <div><h3>Titular:</h3><span>{modal.campos.titular}</span></div>
                        {tipo == "reservasHoteles" && <div><h3>Habitaciones</h3><span>{modal.form.length}</span></div>}
                        <div><h3>Monto total:</h3><span>{modal.campos.totalUs} USD</span></div>
                        <div><h3>Monto adicional por confirmar:</h3><span style={{color:modal.montoAdicional && modal.montoAdicional.toString().charAt(0) == "-" && "#1D9222"}}>{modal.montoAdicional ? modal.montoAdicional : 0} USD</span></div>
                        <div><h3>Monto neto a depositar:</h3><span>{modal.campos.totalUs-modal.campos.totalUs*8/100} USD</span></div>
                        <div><h3>Comision</h3><span>{modal.campos.totalUs*8/100} USD</span></div>
                        <BtnEditar onClick={()=>modificarReserva()}>
                            <p>Modificar Reserva</p>
                            <img src={editar} />
                        </BtnEditar>
                     </DetallesReserva>
                : undefined
               }
            </Modal>
        </ContenedorOpcion>    
    );
}