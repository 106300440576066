import styled from "styled-components";

const pc = window.screen.width > 529 ? true : false;

const ContModal = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3000;
    display: ${props => props.visible ? "flex" : "none"};
    align-items: center;
    justify-content: center;
`;

const Fondo = styled.div`
    position:absolute;
    background-color: rgba(0, 0, 0, 0.55);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
`;

const Contenedor = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: #fff;
    padding: ${pc ? "0.8vw 2vw" : "5vw"};
    padding-top: ${pc && "0.8vw"};
    border-radius: 2vw;
    max-height: ${props => props.add ? "95vh" : "75vh"};
    width: ${pc ? props => props.cargando ? "auto" : "40vw" : ""};
    overflow-y: ${props => props.cargando ? "none" : "scroll"};
`;

export default function Modal ({visible, setVisible, children, setVisible1, cargando, imagen, add}){
    return (
        <ContModal visible={visible}>
            <p id="btnCerrarModal" style={{display:"none"}} onClick={()=>setVisible1()} />
            <Fondo onClick={()=>setVisible()} />
           { imagen ? 
                children
            :
                <Contenedor add={add} style={{width:add && pc ? "50vw" : ""}} cargando={cargando}>
                    {children}
                </Contenedor>
            }
        </ContModal>
    )
}

export const DetallesReserva = styled.div`
    display: flex;
    flex-direction: column;

    & h2 {
        font-size: ${pc ? "1.7vw" : "6vw!important"};
        margin: 0 ${pc ? "auto" : "3vw"};
        margin-bottom: ${pc ? "0.5vw" : "3vw"};
        color: #004aad;
    }

    & div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: ${pc ? "0.2vw" : "3vw"};
        border-bottom: 1px solid #909090;
    }

    & h3 {
        color:#565555;
        font-size: ${pc ? "1vw" : "4vw"};
        font-weight: 600;
        line-height: ${pc ? "2.5vw" : "5vw"};
        max-width: ${pc ? "50%" : "35vw"};
    }

    & span {
        color:#000;
        font-size: ${pc ? "0.9vw" : "3.5vw"};
        font-weight: 600;
        max-width: 40%;
        text-align: center;
    }
`;

export const BtnCerrar = styled.div`
    position: absolute;
    right: ${pc ? "0.2vw" : "1vw"};
    top: 0.5vw;
    width: ${pc ? "3vw" : "7vw"};
    padding: 0.3vw;
    border: none!important;
    cursor: pointer;

    & img {
        width: 100%;
    }
`;