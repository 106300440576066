import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getSolicitudesCliente, searchHotelOVilla, subirVoucher } from "../../../config/auth";
import sesionContext from "../../../contexts/sessionContext";
import CargandoCont from "../../utils/cargando";
import styled from "styled-components";
import ContenedorOpcion from "../contenedorOpcion";
import Pagina404 from "../../pagina404";
import Modal, {BtnCerrar, DetallesReserva} from "../../utils/modal";
import perfilHotelContext from "../../../contexts/perfilHotelContext";
import {CuadroContenedor, TopCuadroContenedor, CuadroCont, Cuadro, BottomCuadroContenedor} from "../../utils/recuadro";
import { descargarArchivo } from "../../utils/funciones";

//imagenes
import hotelSinImg from "../../../images/hotelSinImg.png";
import buscar from "../../../images/search.png";
import cargandoImg from "../../../images/cargando.gif";
import quitar from "../../../images/quitar1.png";
import editar from "../../../images/edit1.png";
import descargar from "../../../images/descargar.png";
import subir from "../../../images/subir.png";

const pc = window.screen.width > 529 ? true : false;

const HotelesContenedor = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    padding-bottom: ${e => e.paddingBottom ? pc ? "0" : "10vw" : undefined};
`;

const BuscarContenedor = styled.form`
    display: flex;
    background-color: #E0E0E0;
    border-radius: 5vw;
    width: 85%;
    margin-top: 2vw;
    height: ${pc && "3vw"};

    & input {
        border: none;
        padding: ${!pc && "3vw"};
        padding-left: ${pc ? "1.3vw" : "3vw"};
        background-color: transparent;
        width: 90%;
    }
    & input:focus{
        outline: none;
    }
    & button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: ${pc ? "3.5vw" : "13%"};
        padding: ${pc ? "1vw" : "2.3vw"};
        background-color: #004aad;
        border-top-right-radius: 5vw;
        border-bottom-right-radius: 5vw;
        margin-left: auto;
        cursor: pointer;
        border: none;
    }
    & button img{
        width: 100%;
    }
`;

const HotelesCont = styled.div`
    margin-top:${pc ? "2vw" : "4.5vw"};
    display: flex;
    width: 100%;
    flex-wrap: wrap;
`;

const Hotel = styled.div`
    display: flex;
    margin: 0 1vw;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1vw;
    width: ${pc ? "10vw" : "30%"};
    margin-bottom: 2vw;
    cursor: pointer;

    & p {
        margin-top: 0.67vw;
        font-size: ${pc ? "1vw" : "2.75vw"};
        font-weight: 600;
        text-align: center;
    }
`;

const HotelImg = styled.div`
    width: ${pc ? "5vw" : "12vw"};
    height: ${pc ? "5vw" : "12vw"};
    background-image: url(${e => e.imagen ? e.imagen : hotelSinImg });
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 11vw;
`;
const HotelImgFondo = styled.div`
    width: ${pc ? "5vw" : "12vw"};
    height: ${pc ? "5vw" : "12vw"};
    background-image: url(${e => e.imagen ? e.imagen : hotelSinImg });
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 11vw;
`;

const ReservasCont = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;

    & h2 {
        margin-right: auto;
    }
`;

const BtnEditar = styled.button`
    background-color: #F5B114;
    padding: ${pc ? "0.5vw" : "2vw"};
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    width: ${pc ? "45%" : "70%"};
    margin: 0 auto;
    margin-top: ${pc ? "1vw" : "3vw"};
    cursor:pointer;
    border-radius: ${pc ? "0.3vw" : "1vw"};

    & img {
        width: ${pc ? "1.4vw" : "5vw"};
        margin-left: ${pc ? "0.5vw" : "3vw"};
    }

    & p {
        font-weight:500;
        font-size: ${pc ? "1.2vw" : "4vw"};
        color:#fff;
    }

    &:hover {
        background-color: #F1AC0B;
    }
`;

const BtnSubir = styled.div`
    padding: ${pc ? "0.5vw" : "1vw"};
    cursor: pointer;
    border-radius: ${pc ? "0.3vw" : "1vw"};
    background-color: #8C8C8C;
    
    & img {
        width: ${pc ? "1.8vw" : "4vw"};
    }
    & p{
        color:#fff
    }
`;

const BtnDescargar = styled.div`
    padding: ${pc ? "0.5vw" : "1vw"};
    cursor: pointer;
    border-radius: ${pc ? "0.3vw" : "1vw"};
    background-color: #E7971D;
    text-decoration: none;

    & img {
        width: ${pc ? "1.8vw" : "4vw"};
    }
    & p{
        color:#fff
    }
`;

const Msg = styled.div`
  position: fixed;
  display: ${props => props.activo ? "flex" : "none"};
  width: 100%;
  align-items: center;
  justify-content: center;
  bottom: 0;
  left: 0;

  & div {
    padding: ${pc ? "1vw 2vw" : "2vw 3vw"};
    margin-bottom: 3px;
    text-align: center;
    border-top-right-radius: 0.4vw;
    border-top-left-radius: 0.4vw;
    background-color: ${props => props.error ? "#26AC20" : "#eb1a1a"};
    color: #fff;
  }
`;

export default function Villas (){

    const location = useLocation();
    const navigate = useNavigate()
    const {session} = useContext(sesionContext);
    const { setPerfil, perfil } = useContext(perfilHotelContext);

    let [hoteles, setHoteles] = useState(undefined);
    let [inputHotel, setInputHotel] = useState(undefined);
    let [search, setSearch] = useState(undefined);
    let [cargando, setCargando] = useState(false);
    let [cargando1, setCargando1] = useState(false);
    let [localidad, setLocalidad] = useState({"Zona este":undefined, "Zona norte":undefined});
    let [localidadInput, setLocalidadInput] = useState("Todos");
    let [solicitudes, setSolicitudes] = useState(undefined);
    let [ultimoElemento, setUltimoElemento] = useState(undefined);
    let [ultimoElementoSolicitud, setUltimoElementoSolicitud] = useState(undefined);
    let [ultimoElementoLocalidad, setUltimoElementoLocalidad] = useState({"Zona este":undefined, "Zona norte":undefined});

    let [cargandoSubirArchivo, setCargandoSubirArchivo] = useState(false);
    let [modal, setModal] = useState(false);
    let [infoReserva, setInfoReserva] = useState();

    let [id, setId] = useState("");
    let [msgVal, setMsgVal] = useState("");
    let [msgVal1, setMsgVal1] = useState("");
    let [errorUploadFile, setErrorUploadFile] = useState("");

    async function obtenerHoteles (){
        if (hoteles === undefined) {
            setCargando(true);
            const result = await getSolicitudesCliente({tipo:"villas"});
            if (result[0]) {
                setHoteles(result[1].datos);
                setUltimoElemento(result[1].ultimoElemento);
            } else {
                setHoteles(result[0]);
                setMsgVal(result[1]);
            }
            setCargando(false);
        } else {
            if (ultimoElemento !== false) {
                setCargando1(true);
                const result = await getSolicitudesCliente({tipo:"villas", ultimoElemento:ultimoElemento});
                setUltimoElemento(result[1].ultimoElemento);
                if (result[0]) {
                    const newHoteles = hoteles;
                    result[1].datos.map((value)=>{
                        let si = true;
                        newHoteles.map((old)=>{
                            if (old.id == value.id) {
                                si = false;
                            }
                        })
                        if (si) {
                            newHoteles.push(value);
                        }
                    });
                    setHoteles(JSON.parse(JSON.stringify(newHoteles)));
                } else {
                    setHoteles(result[0]);
                    setMsgVal(result[1]);
                }
                setCargando1(false);
            }
        }
    }

    async function buscarHotel (e){
        e.preventDefault();
        if (inputHotel !== undefined) {
            if (inputHotel.replace(/\s+/g, " ").trim().length > 0) {
                setCargando(true);
                const result = await searchHotelOVilla(inputHotel.toLowerCase().replace(/\s+/g, " ").trim(), "villas");
                if (result[0]) {
                    setSearch(result[1]);
                } else {
                    setSearch(false);
                    setMsgVal(result[1]);
                }
                setCargando(false);
            }
        }
    }

    async function getHotelesLocalidad (local1, btn){
        const local = local1 || localidadInput;
        setSearch(undefined);
        setInputHotel("");
        if (local != "Todos") {
            setLocalidadInput(local);
            
            if (localidad[local] === undefined) {
                setCargando(true);
                const result = await getSolicitudesCliente({tipo:"villas", search:"zona", word:local});
                if (result[0]) {
                    const newLocalidad = localidad;
                    newLocalidad[local] = result[1].datos;
                    setLocalidad(JSON.parse(JSON.stringify(newLocalidad)));
                    const ultimoElementoLocalidad1 = ultimoElementoLocalidad;
                    ultimoElementoLocalidad1[local] = result[1].ultimoElemento;
                    setUltimoElementoLocalidad(ultimoElementoLocalidad1);
                } else {
                    const newLocalidad = localidad;
                    newLocalidad[local] = result[0];
                    setLocalidad(newLocalidad);
                    setMsgVal(result[1]);
                }
                setCargando(false);
            } else {
                if (btn) {
                    if (ultimoElementoLocalidad[local] !== false) {
                        setCargando1(true);
                        const result = await getSolicitudesCliente({tipo:"villas", search:"zona", word:local, ultimoElemento:ultimoElementoLocalidad[local]});
                        const ultimoElementoLocalidad1 = ultimoElementoLocalidad;
                        ultimoElementoLocalidad1[local] = result[1].ultimoElemento;
                        setUltimoElementoLocalidad(ultimoElementoLocalidad1);
                        if (result[0]) {
                            const newLocalidad = localidad;
                            result[1].datos.map((value)=>{
                                let si = true;
                                newLocalidad[local].map((old)=>{
                                    if (old.id == value.id) {
                                        si = false;
                                    }
                                })
                                if (si) {
                                    newLocalidad[local].push(value);
                                }
                            });
                            setLocalidad(newLocalidad);
                        } else {
                            const newLocalidad = localidad;
                            newLocalidad[local] = result[0];
                            setLocalidad(newLocalidad);
                            setMsgVal(result[1]);
                        }
                        setCargando1(false);
                    };
                }
            };
        } else {
            setLocalidadInput(local);
        }
    };

    async function getData (update){
        if (solicitudes === undefined || update === true) {
            const result = await getSolicitudesCliente({tipo:"reservasVillas"});
            if (result[0]) {
                setSolicitudes(result[1].datos);
                setUltimoElementoSolicitud(result[1].ultimoElemento);
            } else {
                setMsgVal1(result[1]);
                setSolicitudes(result[0]);
            }
        } else {
            if (ultimoElementoSolicitud !== false) {
                setCargando1(true);
                const result = await getSolicitudesCliente({tipo:"reservasVillas", ultimoElemento:ultimoElementoSolicitud});
                if (result[0]) {
                    setUltimoElementoSolicitud(result[1].ultimoElemento);
                    const newSeguros1 = solicitudes;
                    result[1].datos.map((value, index)=>{
                        let si = true;
                        newSeguros1.map((old)=>{
                            if (value.id == old.id) {
                                si = false;
                            }
                        })
                        if (si) {
                            newSeguros1.push(value);
                        }
                    });
                    setSolicitudes(newSeguros1);
                } else {
                    setSolicitudes(false);
                    setMsgVal1(result[1]);
                }
            }
        }
        setCargando1(false);
    }
  
    function modificarReserva (){
        const newPerfil = perfil;
        newPerfil.reserva = solicitudes[infoReserva.index];
        setPerfil(newPerfil);
        navigate("/villa/"+solicitudes[infoReserva.index].villa+"?tab=modificar-reserva");
    }

    async function enviarVoucher (archivo){
        setCargandoSubirArchivo(true);
        const result = await subirVoucher(archivo, solicitudes[id].id, "reservasVillas");
        if (result[0]) {
            const newModal = modal;
            newModal.transferenciaVoucher = result[1];
            getData(true);
            setModal(newModal);
            setId("");
        } else {
            setErrorUploadFile(result[1]);
            setTimeout(()=>{
                setErrorUploadFile("");
            },3500);
        }
        setCargandoSubirArchivo(false);
    }

    function montoNetoDepositar (num){
        let total = num-num*8/100;
        let totalFull = "";
        let index = false;

        total = total.toString().split("");

        total.map((num, index1)=>{
            if (num == ".") {
                index = index1;
            };
        });

        if (index) {
            total.map((num, index1)=>{
                if (index1 <= index+2) {
                    totalFull = totalFull+num;
                };
            });
        } else {
            totalFull = num;
        }

        return totalFull;
    }

    useEffect(()=>{
        if(session.user){
            obtenerHoteles();
            getData();
        }
    },[session.user])

    return (
        <ContenedorOpcion>
            { !location.search ?
                <HotelesContenedor paddingBottom={true}>
                    <h2>Villas</h2>
                    {hoteles !== undefined && hoteles !== false && hoteles.length > 0 &&
                        <BuscarContenedor>
                            <input value={inputHotel} onChange={(e)=>{setInputHotel(e.target.value)}} placeholder="Busca una villa"/>
                            {(inputHotel || search !== undefined) && <img onClick={()=>{setSearch(undefined); setInputHotel(""); setLocalidadInput("Todos"); document.querySelector("#optionLocal").value="Todos"}} style={{cursor:"pointer", width:pc ? "2.5vw" : "8vw", height: pc ? "2.5vw" : "8vw", margin:"auto 0"}} src={quitar}/>}
                            <button type="submit" onClick={(e)=>buscarHotel(e)}>
                                <img src={buscar}/>
                            </button>
                        </BuscarContenedor>
                    }
                    { hoteles !== undefined && hoteles !== false && hoteles.length > 0 &&
                        <div style={{display:"flex", alignItems:"center", marginLeft:"auto", marginRight:"10%", marginTop:pc ? "0.5vw" : "1.4vw"}}>
                            <p style={{marginRight:pc ? "0.5vw" : "1vw", color:"#515151", fontSize:pc ? "1.3vw" : "3.5vw"}}>Zona de villas:</p>
                            <select style={{cursor:"pointer", fontSize:!pc && "3.5vw"}} id="optionLocal" defaultValue="Todos" onChange={(e)=>getHotelesLocalidad(e.target.value)}>
                                <option defaultValue="Todos">Todos</option>
                                <option>Zona este</option>
                                <option>Zona norte</option>
                            </select>
                        </div>
                    }
                    { cargando ?
                        <CargandoCont>
                            <img src={cargandoImg} />
                        </CargandoCont>
                        : search !== undefined ? 
                        <HotelesContenedor>
                            <HotelesCont>
                                {(search !== false) ?
                                    search.length > 0 ?
                                    search.map((value)=>(
                                        <Hotel title={value.nombre} key={value.id} onClick={()=>navigate("/villa/"+value.nombre)}>
                                            <HotelImgFondo imagen={hotelSinImg}><HotelImg imagen={value.fotoPerfil}/></HotelImgFondo>
                                            <p>{value.nombre.length > 15 ? value.nombre.slice(0, 16)+"…" : value.nombre}</p>
                                        </Hotel>
                                    ))
                                    : 
                                    <p>No hay resultados para tu busqueda.</p> 
                                : <p>{msgVal}</p>
                                }
                            </HotelesCont>
                        </HotelesContenedor>
                        : localidadInput != "Todos" ?
                            localidad !== undefined ?
                                <HotelesContenedor>
                                    <HotelesCont>
                                        {(localidad[localidadInput] !== false) ?
                                            localidad[localidadInput].length > 0 ?
                                            localidad[localidadInput].map((value)=>(
                                                <Hotel title={value.nombre} key={value.id} onClick={()=>navigate("/villa/"+value.nombre)}>
                                                    <HotelImgFondo imagen={hotelSinImg}><HotelImg imagen={value.fotoPerfil}/></HotelImgFondo>
                                                    <p>{value.nombre.length > 15 ? value.nombre.slice(0, 16)+"…" : value.nombre}</p>
                                                </Hotel>
                                            ))
                                            : 
                                            <p>No hay villas disponibles en esta localidad.</p> 
                                        : <p>{msgVal}</p>
                                        }
                                    </HotelesCont>
                                    {
                                        (ultimoElementoLocalidad[localidadInput] !== undefined && ultimoElementoLocalidad[localidadInput] !== false) ?
                                        cargando1 ? 
                                            <CargandoCont><img src={cargandoImg}/></CargandoCont>
                                            : 
                                            <p onClick={()=>getHotelesLocalidad(undefined, true)} style={{cursor:"pointer", textDecoration:"underline", padding:"0.5vw"}}>Ver mas</p>
                                        : 
                                        undefined   
                                    }
                            </HotelesContenedor>
                            : undefined
                        : <HotelesContenedor>
                            <HotelesCont>
                                {(hoteles !== false && hoteles !== undefined) ?
                                    hoteles.length > 0 ?
                                    hoteles.map((value)=>(
                                        <Hotel title={value.nombre} key={value.id} onClick={()=>navigate("/villa/"+value.nombre)}>
                                            <HotelImgFondo imagen={hotelSinImg}><HotelImg imagen={value.fotoPerfil}/></HotelImgFondo>
                                            <p>{value.nombre.length > 15 ? value.nombre.slice(0, 16)+"…" : value.nombre}</p>
                                        </Hotel>
                                    ))
                                    : 
                                    <p>No hay villas disponibles.</p> 
                                : <p>{msgVal}</p>
                                }
                            </HotelesCont>
                            {
                                (ultimoElemento !== undefined && ultimoElemento !== false) ?
                                cargando1 ? 
                                    <CargandoCont><img src={cargandoImg}/></CargandoCont>
                                    : 
                                    <p onClick={()=>obtenerHoteles()} style={{cursor:"pointer", textDecoration:"underline", padding:"0.5vw"}}>Ver mas</p>
                                : 
                                undefined   
                            }
                        </HotelesContenedor>        
                    }
                </HotelesContenedor>
            : location.search == "?tab=mis-solicitudes" ?
                <ReservasCont>
                    <h2>Mis solicitudes</h2>       
                    {   solicitudes !== undefined ?
                                    solicitudes !== false ?
                                        solicitudes.length > 0 ? 
                                            <CuadroContenedor>
                                                <CuadroCont>
                                                    <TopCuadroContenedor>
                                                        <Cuadro>
                                                            <p>Villa</p>
                                                        </Cuadro>
                                                        <Cuadro>
                                                            <p>Cliente</p>
                                                        </Cuadro>
                                                        <Cuadro>
                                                            <p>Confirmación</p>
                                                        </Cuadro>
                                                        <Cuadro>
                                                            <p>Estado</p>
                                                        </Cuadro>
                                                    </TopCuadroContenedor>
                                                    { solicitudes.map((value, index)=>(
                                                        <BottomCuadroContenedor key={value.id}>
                                                            <Cuadro onClick={()=>{setModal(value); setInfoReserva({index:index, id:value.id}); setId(index)}} style={{cursor:"pointer"}}><p style={{textDecoration:"underline"}}>{value.villa}</p></Cuadro>
                                                            <Cuadro><p>{value.campos.titular}</p></Cuadro>
                                                            <Cuadro><p>{value.confirmacion}</p></Cuadro>
                                                            <Cuadro><p>{value.estado}</p></Cuadro>
                                                        </BottomCuadroContenedor>
                                                    ))
                                                    }                                                    
                                                </CuadroCont>
                                            </CuadroContenedor> 
                                        : <p>No tienes solicitudes de reserva</p>
                                    : <p>{msgVal1}</p>
                                : <CargandoCont><img src={cargandoImg}/></CargandoCont> 
                            }
                            { ultimoElementoSolicitud !== undefined && ultimoElementoSolicitud !== false ? 
                                cargando1 ? 
                                    <CargandoCont><img src={cargandoImg}/></CargandoCont> 
                                    : 
                                    <p onClick={()=>getData()} style={{cursor:"pointer", padding:"0.5vw", textDecoration:"underline"}}>Ver mas</p> 
                                : <p></p>
                            }
                </ReservasCont> 
                : <Pagina404/>
            }
            <Modal visible={modal} setVisible={()=>setModal(false)}>
                { infoReserva &&
                    <DetallesReserva>
                        <BtnCerrar onClick={()=>setModal(false)}><img src={quitar}/></BtnCerrar>
                        <h2>Detalles de la reserva</h2>
                        <div><h3>Estado del pago de la reserva</h3><span style={{color: solicitudes[infoReserva.index].estado == "Pagado" && "#26AC20"}}>{solicitudes[infoReserva.index].estado}</span></div>
                        <div>
                            <h3>Transferencia Voucher:</h3>
                            <span>
                                { modal.transferenciaVoucher == "none" 
                                    ? <BtnSubir onClick={()=>document.querySelector("#btnArchivoHoteles").click()}>
                                            { !cargandoSubirArchivo
                                                ? <div style={{display:"flex", alignItems:"center", justifyContent:"center", border:"none", margin:0}}>
                                                    <p>Subir Voucher</p>
                                                    <img src={subir}/>
                                                </div>
                                                : <p>Subiendo..</p>
                                            }
                                        </BtnSubir> 
                                    :   <BtnDescargar onClick={()=>descargarArchivo(modal.transferenciaVoucher)}>
                                            <p>Voucher</p>
                                            <img src={descargar} />
                                        </BtnDescargar> 
                                }
                            </span>
                        </div>
                        <div><h3>Titular:</h3><span>{solicitudes[infoReserva.index].campos.titular}</span></div>
                        <div><h3>Habitaciones</h3><span>{solicitudes[infoReserva.index].form.length}</span></div>
                        <div><h3>Monto total:</h3><span>{solicitudes[infoReserva.index].campos.totalUs} USD</span></div>
                        <div><h3>Monto adicional por confirmar:</h3><span style={{color:solicitudes[infoReserva.index].montoAdicional && solicitudes[infoReserva.index].montoAdicional.toString().charAt(0) == "-" && "#1D9222"}}>{solicitudes[infoReserva.index].montoAdicional ? solicitudes[infoReserva.index].montoAdicional : 0} USD</span></div>
                        <div><h3>Monto neto a depositar:</h3><span>{montoNetoDepositar(solicitudes[infoReserva.index].campos.totalUs-solicitudes[infoReserva.index].campos.totalUs)} USD</span></div>
                        <div><h3>Estado de pago de comisión:</h3><span style={{color: solicitudes[infoReserva.index].campos.estadoComision == true && "#26AC20"}}>{solicitudes[infoReserva.index].campos.estadoComision ? "Pagado" : "Pendiente"}</span></div>
                        <div><h3>Comision:</h3><span>{solicitudes[infoReserva.index].campos.totalUs} USD</span></div>
                        <BtnEditar onClick={()=>modificarReserva()}>
                            <p>Modificar Reserva</p>
                            <img src={editar} />
                        </BtnEditar>
                    </DetallesReserva>
                }
                <Msg activo={errorUploadFile} error={false}><div><p>{errorUploadFile}</p></div></Msg>
            </Modal>
            <input style={{display:"none"}} type="file" accept=".jpg, .png, .jpeg, .pdf" onChange={(e)=>enviarVoucher(e.target.files[0])} id="btnArchivoHoteles"/>
        </ContenedorOpcion>
    );
};