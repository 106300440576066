import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getSolicitudesCliente, searchHotelOVilla, searchTarifario } from "../../../config/auth";
import sesionContext from "../../../contexts/sessionContext";
import CargandoCont from "../../utils/cargando";
import styled from "styled-components";
import ContenedorOpcion from "../contenedorOpcion";
import {CuadroContenedor, TopCuadroContenedor, CuadroCont, Cuadro, BottomCuadroContenedor} from "../../utils/recuadro";

//imagenes
import btnDescargar from "../../../images/descargar.png";
import buscar from "../../../images/search.png";
import cargandoImg from "../../../images/cargando.gif";
import quitar from "../../../images/quitar1.png";
import { cambiarFecha, validarFecha } from "../../utils/validar";

const pc = window.screen.width > 529 ? true : false;

const HotelesContenedor = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    padding-bottom: ${e => e.paddingBottom ? pc ? "0" : "10vw" : undefined};

    & h2 {
        font-size: ${pc ? "2vw" : "6vw"};
    }
`;

const BuscarContenedor = styled.form`
    display: flex;
    background-color: #E0E0E0;
    border-radius: 5vw;
    width: 85%;
    margin-top: 2vw;
    height: ${pc && "3vw"};

    & input {
        border: none;
        padding: ${!pc && "3vw"};
        padding-left: ${pc ? "1.3vw" : "3vw"};
        background-color: transparent;
        width: 90%;
    }
    & input:focus{
        outline: none;
    }
    & button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: ${pc ? "3.5vw" : "13%"};
        padding: ${pc ? "1vw" : "2.3vw"};
        background-color: #004aad;
        border-top-right-radius: 5vw;
        border-bottom-right-radius: 5vw;
        margin-left: auto;
        cursor: pointer;
        border: none;
    }
    & button img{
        width: 100%;
    }
`;

const HotelesCont = styled.div`
    margin-top:${pc ? "2vw" : "4.5vw"};
    display: flex;
    width: 100%;
    flex-wrap: wrap;
`;

const BtnQuitarFecha = styled.div`
    margin-top: auto;
    display: flex;
    margin-left: ${!pc && "2vw"};

    & img {
        width: ${pc ? "1.8vw" : "7vw"};
        cursor: pointer;
    }
`;

const InputsFechasCont = styled.div`
    display:flex;
    width: ${pc ? "30%" : "100%"};
    margin: ${!pc && "1.5vw 0"};
    font-size: ${!pc && "3.2vw"};

    & input {
        font-size: ${!pc && "3.2vw"};
    }
    & div:nth-child(2) {
        margin-left: ${pc ? "0.6vw" : "3vw"};
    }
`;

const BtnCotizar = styled.div`
    border:none; 
    width:${pc ? "7vw" : ""};
    margin:${pc ? "0.5vw 1.5vw 1vw auto" : ""};
    border-radius:${pc ?"0.3vw":"0.8vw"};
    cursor:pointer;
    display: flex;
    color:#fff;
    padding:${pc ?"0.5vw":"1vw"};
    background-color:#FECC3D;
    transition: background-color 0.2s;
    justify-content: center;

    &:hover {
        background-color: #FEBC00;
    }
`;

export default function TarifarioHoteles (){

    const {session} = useContext(sesionContext);
    const navigate = useNavigate();
    
    let [hoteles, setHoteles] = useState(undefined);
    const inputHotel = useRef(undefined);
    let [search, setSearch] = useState(undefined);
    let [searchFecha, setSearchFecha] = useState(undefined);
    let [searchFechaDefault, setSearchFechaDefault] = useState(undefined);
    let [cargando, setCargando] = useState(false);
    let [cargando1, setCargando1] = useState(false);
    let [localidad, setLocalidad] = useState({"Zona este":undefined, "Zona norte":undefined});
    let [localidadInput, setLocalidadInput] = useState("Todos");
    let [ultimoElemento, setUltimoElemento] = useState(undefined);
    let [ultimoElementoLocalidad, setUltimoElementoLocalidad] = useState({"Zona este":undefined, "Zona norte":undefined});

    let [msgVal, setMsgVal] = useState("");

    let [desde, setDesde] = useState(undefined);
    let [hasta, setHasta] = useState(undefined);

    async function obtenerHoteles (){
        if (hoteles === undefined) {
            setCargando(true);
            const result = await getSolicitudesCliente({tipo:"hoteles"});
            if (result[0]) {
                setHoteles(result[1].datos);
                setUltimoElemento(result[1].ultimoElemento);
            } else {
                setHoteles(result[0]);
                setMsgVal(result[1]);
            }
            setCargando(false);
        } else {
            if (ultimoElemento !== false) {
                setCargando1(true);
                const result = await getSolicitudesCliente({tipo:"hoteles", ultimoElemento:ultimoElemento});
                setUltimoElemento(result[1].ultimoElemento);
                if (result[0]) {
                    const newHoteles = hoteles;
                    result[1].datos.map((value)=>{
                        let si = true;
                        newHoteles.map((old)=>{
                            if (old.id == value.id) {
                                si = false;
                            }
                        })
                        if (si) {
                            newHoteles.push(value);
                        }
                    });
                    setHoteles(JSON.parse(JSON.stringify(newHoteles)));
                } else {
                    setHoteles(result[0]);
                    setMsgVal(result[1]);
                }
                setCargando1(false);
            }
        }
    }

    async function buscarHotel (e){
        e.preventDefault();
        cancelSearchFecha();
        if (inputHotel.current.value !== undefined) {
            if (inputHotel.current.value.replace(/\s+/g, " ").trim().length > 0) {
                setCargando(true);
                const result = await searchHotelOVilla(inputHotel.current.value.toLowerCase().replace(/\s+/g, " ").trim(), "hoteles");
                if (result[0]) {
                    setSearch(result[1]);
                } else {
                    setSearch(false);
                    setMsgVal(result[1]);
                }
                setCargando(false);
            }
        }
    }

    async function getHotelesLocalidad (local1, btn, searchFechaProp){
        const local = local1 || localidadInput;
        setSearch(undefined);
        inputHotel.current.value = "";
        setLocalidadInput(local);
        if (searchFecha === undefined || searchFechaProp) {
            if (local != "Todos") {
                if (localidad[local] === undefined) {
                    setCargando(true);
                    const result = await getSolicitudesCliente({tipo:"hoteles", search:"zonaHotelera", word:local});
                    if (result[0]) {
                        const newLocalidad = localidad;
                        newLocalidad[local] = result[1].datos;
                        setLocalidad(JSON.parse(JSON.stringify(newLocalidad)));
                        const ultimoElementoLocalidad1 = ultimoElementoLocalidad;
                        ultimoElementoLocalidad1[local] = result[1].ultimoElemento;
                        setUltimoElementoLocalidad(ultimoElementoLocalidad1);
                    } else {
                        const newLocalidad = localidad;
                        newLocalidad[local] = result[0];
                        setLocalidad(newLocalidad);
                        setMsgVal(result[1]);
                    }
                    setCargando(false);
                } else {
                    if (btn) {
                        if (ultimoElementoLocalidad[local] !== false) {
                            setCargando1(true);
                            const result = await getSolicitudesCliente({tipo:"hoteles", search:"zonaHotelera", word:local, ultimoElemento:ultimoElementoLocalidad[local]});
                            const ultimoElementoLocalidad1 = ultimoElementoLocalidad;
                            ultimoElementoLocalidad1[local] = result[1].ultimoElemento;
                            setUltimoElementoLocalidad(ultimoElementoLocalidad1);
                            if (result[0]) {
                                const newLocalidad = localidad;
                                result[1].datos.map((value)=>{
                                    let si = true;
                                    newLocalidad[local].map((old)=>{
                                        if (old.id == value.id) {
                                            si = false;
                                        }
                                    })
                                    if (si) {
                                        newLocalidad[local].push(value);
                                    }
                                });
                                setLocalidad(newLocalidad);
                            } else {
                                const newLocalidad = localidad;
                                newLocalidad[local] = result[0];
                                setLocalidad(newLocalidad);
                                setMsgVal(result[1]);
                            }
                            setCargando1(false);
                        };
                    }
                };
            }
        } else {
            searchFechaLocalidad(local1);
        }
    };    

    async function searchTarifarioFecha(tipo, fecha){
        const desde1 = tipo == "desde" ? fecha : desde;
        const hasta1 = tipo == "hasta" ? fecha : hasta;
        let fechaValid = true;

        if (desde1) {
            if (desde1.split("/")[2] < 2022 || desde1.split("/")[2] > 2200) {
                fechaValid = false;
            }
        }
        if (hasta1) {
            if (hasta1.split("/")[2] < 2022 || hasta1.split("/")[2] > 2200) {
                fechaValid = false;
            }
        }

        if (desde1 && hasta1 && fechaValid) {
            setSearch(undefined);
            inputHotel.current.value = "";
            setCargando(true);
            const result = await searchTarifario({desde:desde1, hasta:hasta1});
            if (typeof result !== "string") {
                setSearchFecha(result);
                setSearchFechaDefault(result);
                if (localidadInput != "Todos") {
                    searchFechaLocalidad(localidadInput);
                }
            } else {
                
            }
            setCargando(false);
        }
    }

    function searchFechaLocalidad (localidad){
        if (localidad != "Todos") {
            const newSearchFecha = searchFechaDefault.filter(e => e.zonaHotelera == localidad);
            setSearchFecha(newSearchFecha);
        } else {
            setSearchFecha(searchFechaDefault);
        }
    }

    async function cancelSearchFecha (){
        setHasta(undefined);
        setDesde(undefined);
        document.querySelector("#desdeSelect").value="";
        document.querySelector("#hastaSelect").value="";
        if (searchFecha !== undefined) {
            if (localidadInput !== "Todos") {
                if (localidad[localidadInput] === undefined) {
                    await getHotelesLocalidad(false, false, true);
                }
            }
        }
        setSearchFecha(undefined);
    }

    useEffect(()=>{
        if(session.user){
            obtenerHoteles();
        }
    },[session.user]);

    return (
        <ContenedorOpcion>
            <HotelesContenedor paddingBottom={true}>
                <h2>Tarifario de hoteles</h2>
                {hoteles !== undefined && hoteles !== false && hoteles.length > 0 &&
                    <BuscarContenedor>
                        <input ref={inputHotel} placeholder="Busca un hotel"/>
                        {((inputHotel.current ? inputHotel.current.value : false) || search !== undefined) && <img onClick={()=>{setSearch(undefined); inputHotel.current.value = ""; setLocalidadInput("Todos"); document.querySelector("#optionLocal").value="Todos"}} style={{cursor:"pointer", width:pc ? "2.5vw" : "8vw", height: pc ? "2.5vw" : "8vw", margin:"auto 0"}} src={quitar}/>}
                        <button type="submit" onClick={(e)=>buscarHotel(e)}>
                            <img src={buscar}/>
                        </button>
                    </BuscarContenedor>
                }

                <div style={{display:"flex", flexDirection:pc ? "row" : "column", alignItems:"center", width:"100%", marginTop:"0.5vw"}}>
                    <InputsFechasCont>
                        <div>
                            <p>Desde</p>
                            <input id="desdeSelect" disabled={cargando ? true : false} onChange={(e)=>{setDesde(cambiarFecha(e.target.value)); searchTarifarioFecha("desde", cambiarFecha(e.target.value))}} type="date" />
                        </div>
                        <div>
                            <p>Hasta</p>
                            <input id="hastaSelect" disabled={cargando ? true : false} onChange={(e)=>{setHasta(cambiarFecha(e.target.value)); searchTarifarioFecha("hasta", cambiarFecha(e.target.value))}} type="date" />
                        </div>
                        {   (desde !== undefined || hasta !== undefined) &&
                            <BtnQuitarFecha onClick={()=>cancelSearchFecha()}>
                                <img src={quitar} />
                            </BtnQuitarFecha>  
                        }
                    </InputsFechasCont>                        
                    { hoteles !== undefined && hoteles !== false && hoteles.length > 0 &&
                        <div style={{display:"flex", alignItems:"center", marginLeft:"auto", marginRight:"10%", marginTop:pc ? "0.5vw" : "1.4vw"}}>
                            <p style={{marginRight:pc ? "0.5vw" : "1vw", color:"#515151", fontSize:pc ? "1.3vw" : "3.5vw"}}>Zona hotelera:</p>
                            <select disabled={cargando ? true : false} style={{cursor:"pointer", fontSize:!pc && "3.5vw"}} id="optionLocal" defaultValue="Todos" onChange={(e)=>!cargando && getHotelesLocalidad(e.target.value)}>
                                <option defaultValue="Todos">Todos</option>
                                <option>Zona este</option>
                                <option>Zona norte</option>
                            </select>
                        </div>
                    }
                </div>

                {   cargando ?
                    <CargandoCont>
                        <img src={cargandoImg} />
                    </CargandoCont>
                    : search !== undefined ? 
                    <HotelesContenedor>
                        <HotelesCont>
                            {(search !== false) ?
                                search.length > 0 ?
                                search.map((value)=>(
                                    <div key={value.id} style={{marginBottom: pc ? "1.7vw" : "6vw"}}>
                                                <h3>{value.nombre}</h3>
                                                <CuadroContenedor style={{marginBottom:"2vw", overflowX:"scroll", maxWidth:pc ? "74vw" : ""}} tarifas={true}>
                                                    <CuadroCont tarifas={true}>
                                                        <TopCuadroContenedor tarifas={true}>
                                                            <Cuadro tarifas={true}>
                                                                <p>Codigo de Oferta</p>
                                                            </Cuadro>
                                                            <Cuadro tarifas={true}>
                                                                <p>Habitación</p>
                                                            </Cuadro>
                                                            <Cuadro tarifas={true}>
                                                                <p>Niño gratis</p>
                                                            </Cuadro>
                                                            <Cuadro tarifas={true}>
                                                                <p>Minimo de noches</p>
                                                            </Cuadro>
                                                            <Cuadro style={{width:"40vw"}} tarifas={true}>
                                                                <p>Periodo</p>
                                                            </Cuadro>
                                                            <Cuadro tarifas={true}>
                                                                <p>Vencimiento de oferta</p>
                                                            </Cuadro>
                                                            <Cuadro tarifas={true}>
                                                                <p>Dias</p>
                                                            </Cuadro>
                                                            <Cuadro tarifas={true}>
                                                                <p>SGL</p>
                                                            </Cuadro>
                                                            <Cuadro tarifas={true} >
                                                                <p>DBL</p>
                                                            </Cuadro>
                                                            <Cuadro tarifas={true}>
                                                                <p>TPL</p>
                                                            </Cuadro>
                                                            <Cuadro tarifas={true}>
                                                                <p>Niños</p>
                                                            </Cuadro>
                                                        
                                                        </TopCuadroContenedor>
                                                        
                                                        { value.ofertas && value.ofertas.map((value, index)=>(
                                                                <BottomCuadroContenedor tarifas={true} bloqueado={validarFecha(value.vencimientoOferta) !== true && true} key={value.codigoOferta}>
                                                                    <Cuadro tarifas={true}><p>{value.codigoOferta}</p></Cuadro>
                                                                    <Cuadro tarifas={true}><p>{value.habitacion}</p></Cuadro>
                                                                    <Cuadro tarifas={true}><p>{value.niñoGratis == 0 ? "Ninguno" : value.niñoGratis == 1 ? "Primer niño gratis" : "Segundo niño gratis"}</p></Cuadro>
                                                                    <Cuadro tarifas={true}><p>{value.minimoNoches}</p></Cuadro>
                                                                    <Cuadro style={{width:"40vw"}} tarifas={true}><p>{value.desde + " - " + value.hasta}</p></Cuadro>
                                                                    <Cuadro tarifas={true} bloqueado={validarFecha(value.vencimientoOferta) !== true && true}><p>{value.vencimientoOferta}</p></Cuadro>
                                                                    <div style={{display:"flex", flexDirection:"column"}}>
                                                                        { value.ofertas.map((subOferta, indexSubOferta)=>(
                                                                            <div style={{display:"flex", borderTop:"none", height: value.ofertas.length == 1 && "100%", borderBottom:( value.ofertas.length == 1 || value.ofertas.length-1 != indexSubOferta) && "1px solid #fff"}}>
                                                                                <Cuadro tarifas={true}><p>{subOferta.dias.map((dia, indexDia)=>(dia+(subOferta.dias.length-2 == indexDia ? " y " : subOferta.dias.length > 0 && indexDia != subOferta.dias.length-1 ? ", " : "")))}</p></Cuadro>
                                                                                <Cuadro tarifas={true}><p>${subOferta.datos.SGL}</p></Cuadro>
                                                                                <Cuadro tarifas={true}><p>${subOferta.datos.DBL}</p></Cuadro>
                                                                                <Cuadro tarifas={true}><p>${subOferta.datos.TPL}</p></Cuadro>
                                                                                <Cuadro tarifas={true}><p>${subOferta.datos.niños}</p></Cuadro>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </BottomCuadroContenedor>
                                                        ))}
                                                        { value.ofertas.length == 0 &&
                                                            <p style={{color:"#fff", margin:"1vw"}}>No hay tarifas disponibles.</p>
                                                        }
                                                        
                                                    </CuadroCont>
                                                </CuadroContenedor>
                                                <BtnCotizar onClick={()=>navigate(`/hotel/${value.nombre}/`)}>
                                                    <p style={{fontSize:pc ?"1vw":"3vw"}}>Cotizar</p>
                                                </BtnCotizar>
                                            </div>
                                ))
                                : 
                                <p>No hay resultados para tu busqueda.</p> 
                            : <p>{msgVal}</p>
                            }
                        </HotelesCont>
                    </HotelesContenedor>
                    : searchFecha !== undefined ?
                    <HotelesContenedor>
                        <HotelesCont>
                            {
                                searchFecha.map((hotel)=>(
                                    <div key={hotel.id} style={{marginBottom: pc ? "1.7vw" : "6vw"}}>
                                        <h3>{hotel.nombre}</h3>
                                        <CuadroContenedor style={{marginBottom:"2vw", overflowX:"scroll", maxWidth:pc ? "74vw" : ""}} tarifas={true}>
                                            <CuadroCont tarifas={true}>
                                                <TopCuadroContenedor tarifas={true}>
                                                    <Cuadro tarifas={true}>
                                                        <p>Codigo de Oferta</p>
                                                    </Cuadro>
                                                    <Cuadro tarifas={true}>
                                                        <p>Habitación</p>
                                                    </Cuadro>
                                                    <Cuadro tarifas={true}>
                                                        <p>Niño gratis</p>
                                                    </Cuadro>
                                                    <Cuadro tarifas={true}>
                                                        <p>Minimo de noches</p>
                                                    </Cuadro>
                                                    <Cuadro style={{width:"40vw"}} tarifas={true}>
                                                        <p>Periodo</p>
                                                    </Cuadro>
                                                    <Cuadro tarifas={true}>
                                                        <p>Vencimiento de oferta</p>
                                                    </Cuadro>
                                                    <Cuadro tarifas={true}>
                                                        <p>Dias</p>
                                                    </Cuadro>
                                                    <Cuadro tarifas={true}>
                                                        <p>SGL</p>
                                                    </Cuadro>
                                                    <Cuadro tarifas={true} >
                                                        <p>DBL</p>
                                                    </Cuadro>
                                                    <Cuadro tarifas={true}>
                                                        <p>TPL</p>
                                                    </Cuadro>
                                                    <Cuadro tarifas={true}>
                                                        <p>Niños</p>
                                                    </Cuadro>
                                                
                                                </TopCuadroContenedor>
                                                
                                                { hotel.ofertas && hotel.ofertas.map((value, index)=>(
                                                        <BottomCuadroContenedor tarifas={true} search={validarFecha(value.vencimientoOferta) !== true && true} key={value.codigoOferta}>
                                                            <Cuadro tarifas={true}><p>{value.codigoOferta}</p></Cuadro>
                                                            <Cuadro tarifas={true}><p>{value.habitacion}</p></Cuadro>
                                                            <Cuadro tarifas={true}><p>{value.niñoGratis == 0 ? "Ninguno" : value.niñoGratis == 1 ? "Primer niño gratis" : "Segundo niño gratis"}</p></Cuadro>
                                                            <Cuadro tarifas={true}><p>{value.minimoNoches}</p></Cuadro>
                                                            <Cuadro style={{width:"40vw"}} tarifas={true}><p>{value.desde + " - " + value.hasta}</p></Cuadro>
                                                            <Cuadro tarifas={true} bloqueado={validarFecha(value.vencimientoOferta) !== true && true}><p>{value.vencimientoOferta}</p></Cuadro>
                                                            <div style={{display:"flex", flexDirection:"column"}}>
                                                                { value.ofertas.map((subOferta, indexSubOferta)=>(
                                                                    <div style={{display:"flex", borderTop:"none", height: value.ofertas.length == 1 && "100%", borderBottom:( value.ofertas.length == 1 || value.ofertas.length-1 != indexSubOferta) && "1px solid #fff"}}>
                                                                        <Cuadro tarifas={true}><p>{subOferta.dias.map((dia, indexDia)=>(dia+(subOferta.dias.length-2 == indexDia ? " y " : subOferta.dias.length > 0 && indexDia != subOferta.dias.length-1 ? ", " : "")))}</p></Cuadro>
                                                                        <Cuadro tarifas={true}><p>${subOferta.datos.SGL}</p></Cuadro>
                                                                        <Cuadro tarifas={true}><p>${subOferta.datos.DBL}</p></Cuadro>
                                                                        <Cuadro tarifas={true}><p>${subOferta.datos.TPL}</p></Cuadro>
                                                                        <Cuadro tarifas={true}><p>${subOferta.datos.niños}</p></Cuadro>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </BottomCuadroContenedor>
                                                ))}
                                                { hotel.ofertas.length == 0 &&
                                                    <p style={{color:"#fff", margin:"1vw"}}>No hay tarifas disponibles.</p>
                                                }
                                                
                                            </CuadroCont>
                                        </CuadroContenedor>
                                        <BtnCotizar onClick={()=>navigate(`/hotel/${hotel.nombre}/`)}>
                                            <p style={{fontSize:pc ?"1vw":"3vw"}}>Cotizar</p>
                                        </BtnCotizar>
                                    </div>
                                ))
                            }
                        </HotelesCont>
                    </HotelesContenedor>
                    : localidadInput != "Todos" ?
                        localidad !== undefined ?
                        <HotelesContenedor>
                                <HotelesCont>
                                    {(localidad[localidadInput] !== false) ?
                                        localidad[localidadInput].length > 0 ?
                                        localidad[localidadInput].map((value)=>(
                                            <div key={value.id} style={{marginBottom: pc ? "1.7vw" : "6vw"}}>
                                                <h3>{value.nombre}</h3>
                                                <CuadroContenedor style={{marginBottom:"2vw", overflowX:"scroll", maxWidth:pc ? "74vw" : ""}} tarifas={true}>
                                                    <CuadroCont tarifas={true}>
                                                        <TopCuadroContenedor tarifas={true}>
                                                            <Cuadro tarifas={true}>
                                                                <p>Codigo de Oferta</p>
                                                            </Cuadro>
                                                            <Cuadro tarifas={true}>
                                                                <p>Habitación</p>
                                                            </Cuadro>
                                                            <Cuadro tarifas={true}>
                                                                <p>Niño gratis</p>
                                                            </Cuadro>
                                                            <Cuadro tarifas={true}>
                                                                <p>Minimo de noches</p>
                                                            </Cuadro>
                                                            <Cuadro style={{width:"40vw"}} tarifas={true}>
                                                                <p>Periodo</p>
                                                            </Cuadro>
                                                            <Cuadro tarifas={true}>
                                                                <p>Vencimiento de oferta</p>
                                                            </Cuadro>
                                                            <Cuadro tarifas={true}>
                                                                <p>Dias</p>
                                                            </Cuadro>
                                                            <Cuadro tarifas={true}>
                                                                <p>SGL</p>
                                                            </Cuadro>
                                                            <Cuadro tarifas={true} >
                                                                <p>DBL</p>
                                                            </Cuadro>
                                                            <Cuadro tarifas={true}>
                                                                <p>TPL</p>
                                                            </Cuadro>
                                                            <Cuadro tarifas={true}>
                                                                <p>Niños</p>
                                                            </Cuadro>
                                                        
                                                        </TopCuadroContenedor>
                                                        
                                                        { value.ofertas && value.ofertas.map((value, index)=>(
                                                                <BottomCuadroContenedor tarifas={true} bloqueado={validarFecha(value.vencimientoOferta) !== true && true} key={value.codigoOferta}>
                                                                    <Cuadro tarifas={true}><p>{value.codigoOferta}</p></Cuadro>
                                                                    <Cuadro tarifas={true}><p>{value.habitacion}</p></Cuadro>
                                                                    <Cuadro tarifas={true}><p>{value.niñoGratis == 0 ? "Ninguno" : value.niñoGratis == 1 ? "Primer niño gratis" : "Segundo niño gratis"}</p></Cuadro>
                                                                    <Cuadro tarifas={true}><p>{value.minimoNoches}</p></Cuadro>
                                                                    <Cuadro style={{width:"40vw"}} tarifas={true}><p>{value.desde + " - " + value.hasta}</p></Cuadro>
                                                                    <Cuadro tarifas={true} bloqueado={validarFecha(value.vencimientoOferta) !== true && true}><p>{value.vencimientoOferta}</p></Cuadro>
                                                                    <div style={{display:"flex", flexDirection:"column"}}>
                                                                        { value.ofertas.map((subOferta, indexSubOferta)=>(
                                                                            <div style={{display:"flex", borderTop:"none", height: value.ofertas.length == 1 && "100%", borderBottom:( value.ofertas.length == 1 || value.ofertas.length-1 != indexSubOferta) && "1px solid #fff"}}>
                                                                                <Cuadro tarifas={true}><p>{subOferta.dias.map((dia, indexDia)=>(dia+(subOferta.dias.length-2 == indexDia ? " y " : subOferta.dias.length > 0 && indexDia != subOferta.dias.length-1 ? ", " : "")))}</p></Cuadro>
                                                                                <Cuadro tarifas={true}><p>${subOferta.datos.SGL}</p></Cuadro>
                                                                                <Cuadro tarifas={true}><p>${subOferta.datos.DBL}</p></Cuadro>
                                                                                <Cuadro tarifas={true}><p>${subOferta.datos.TPL}</p></Cuadro>
                                                                                <Cuadro tarifas={true}><p>${subOferta.datos.niños}</p></Cuadro>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </BottomCuadroContenedor>
                                                        ))}
                                                        { value.ofertas.length == 0 &&
                                                            <p style={{color:"#fff", margin:"1vw"}}>No hay tarifas disponibles.</p>
                                                        }
                                                        
                                                    </CuadroCont>
                                                </CuadroContenedor>
                                                <BtnCotizar onClick={()=>navigate(`/hotel/${value.nombre}/`)}>
                                                    <p style={{fontSize:pc ?"1vw":"3vw"}}>Cotizar</p>
                                                </BtnCotizar>
                                            </div>
                                        ))
                                        : 
                                        <p>No hay hoteles disponibles en esta localidad.</p> 
                                    : <p>{msgVal}</p>
                                    }
                                </HotelesCont>
                                {
                                    (ultimoElementoLocalidad[localidadInput] !== undefined && ultimoElementoLocalidad[localidadInput] !== false) ?
                                    cargando1 ? 
                                        <CargandoCont><img src={cargandoImg}/></CargandoCont>
                                        : 
                                        <p onClick={()=>getHotelesLocalidad(undefined, true)} style={{cursor:"pointer", textDecoration:"underline", padding:"0.5vw"}}>Ver mas</p>
                                    : 
                                    undefined   
                                }
                        </HotelesContenedor>
                        : undefined
                    :  
                    <HotelesContenedor>
                        <HotelesCont>
                            {(hoteles !== false && hoteles !== undefined) ?
                                hoteles.length > 0 ?
                                hoteles.map((value)=>(
                                    <div key={value.id} style={{marginBottom: pc ? "1.7vw" : "6vw"}}>
                                        <h3>{value.nombre}</h3>
                                        <CuadroContenedor style={{overflowX:"scroll", maxWidth:pc ? "74vw" : ""}} tarifas={true}>
                                            <CuadroCont tarifas={true}>
                                                <TopCuadroContenedor tarifas={true}>
                                                    <Cuadro tarifas={true}>
                                                        <p>Codigo de Oferta</p>
                                                    </Cuadro>
                                                    <Cuadro tarifas={true}>
                                                        <p>Habitación</p>
                                                    </Cuadro>
                                                    <Cuadro tarifas={true}>
                                                        <p>Niño gratis</p>
                                                    </Cuadro>
                                                    <Cuadro tarifas={true}>
                                                        <p>Minimo de noches</p>
                                                    </Cuadro>
                                                    <Cuadro style={{width:"40vw"}} tarifas={true}>
                                                        <p>Periodo</p>
                                                    </Cuadro>
                                                    <Cuadro tarifas={true}>
                                                        <p>Vencimiento de oferta</p>
                                                    </Cuadro>
                                                    <Cuadro tarifas={true}>
                                                        <p>Dias</p>
                                                    </Cuadro>
                                                    <Cuadro tarifas={true}>
                                                        <p>SGL</p>
                                                    </Cuadro>
                                                    <Cuadro tarifas={true} >
                                                        <p>DBL</p>
                                                    </Cuadro>
                                                    <Cuadro tarifas={true}>
                                                        <p>TPL</p>
                                                    </Cuadro>
                                                    <Cuadro tarifas={true}>
                                                        <p>Niños</p>
                                                    </Cuadro>
                                                
                                                </TopCuadroContenedor>
                                                
                                                { value.ofertas && value.ofertas.map((value, index)=>(
                                                        <BottomCuadroContenedor tarifas={true} bloqueado={validarFecha(value.vencimientoOferta) !== true && true} key={value.codigoOferta}>
                                                            <Cuadro tarifas={true}><p>{value.codigoOferta}</p></Cuadro>
                                                            <Cuadro tarifas={true}><p>{value.habitacion}</p></Cuadro>
                                                            <Cuadro tarifas={true}><p>{value.niñoGratis == 0 ? "Ninguno" : value.niñoGratis == 1 ? "Primer niño gratis" : "Segundo niño gratis"}</p></Cuadro>
                                                            <Cuadro tarifas={true}><p>{value.minimoNoches}</p></Cuadro>
                                                            <Cuadro style={{width:"40vw"}} tarifas={true}><p>{value.desde + " - " + value.hasta}</p></Cuadro>
                                                            <Cuadro tarifas={true} bloqueado={validarFecha(value.vencimientoOferta) !== true && true}><p>{value.vencimientoOferta}</p></Cuadro>
                                                            <div style={{display:"flex", flexDirection:"column"}}>
                                                                { value.ofertas.map((subOferta, indexSubOferta)=>(
                                                                    <div style={{display:"flex", borderTop:"none", height: value.ofertas.length == 1 && "100%", borderBottom:( value.ofertas.length == 1 || value.ofertas.length-1 != indexSubOferta) && "1px solid #fff"}}>
                                                                        <Cuadro tarifas={true}><p>{subOferta.dias.map((dia, indexDia)=>(dia+(subOferta.dias.length-2 == indexDia ? " y " : subOferta.dias.length > 0 && indexDia != subOferta.dias.length-1 ? ", " : "")))}</p></Cuadro>
                                                                        <Cuadro tarifas={true}><p>${subOferta.datos.SGL}</p></Cuadro>
                                                                        <Cuadro tarifas={true}><p>${subOferta.datos.DBL}</p></Cuadro>
                                                                        <Cuadro tarifas={true}><p>${subOferta.datos.TPL}</p></Cuadro>
                                                                        <Cuadro tarifas={true}><p>${subOferta.datos.niños}</p></Cuadro>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </BottomCuadroContenedor>
                                                ))

                                                }

                                                { value.ofertas.length == 0 &&
                                                    <p style={{color:"#fff", margin:"1vw"}}>No hay tarifas disponibles.</p>
                                                }
                                                
                                            </CuadroCont>
                                        </CuadroContenedor>
                                        <BtnCotizar onClick={()=>navigate(`/hotel/${value.nombre}/`)}>
                                            <p style={{fontSize:pc ?"1vw":"3vw"}}>Cotizar</p>
                                            {/* <img src={btnDescargar} style={{width:pc ? "1.4vw" : "4.5vw"}} /> */}
                                        </BtnCotizar>
                                    </div>
                                ))
                                : 
                                <p>No hay hoteles disponibles.</p> 
                            : <p>{msgVal}</p>
                            }
                        </HotelesCont>
                        {
                            (ultimoElemento !== undefined && ultimoElemento !== false) ?
                            cargando1 ? 
                                <CargandoCont><img src={cargandoImg}/></CargandoCont>
                                : 
                                <p onClick={()=>obtenerHoteles()} style={{cursor:"pointer", textDecoration:"underline", padding:"0.5vw"}}>Ver mas</p>
                            : 
                            undefined   
                        }
                    </HotelesContenedor>        
                }
            </HotelesContenedor>
        
        </ContenedorOpcion>
    );
};