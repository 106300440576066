import { useContext, createContext, useState } from "react";

const perfilHotelContext = createContext({
    perfil:{name:"", imgUrl:""},
    setPerfil:()=>{}
});

export default perfilHotelContext;

export function HotelContextProvider ({children}){

    let [perfil, setPerfil] = useState({name:"", imgUrl:""});

    return <perfilHotelContext.Provider value={{perfil, setPerfil}}>
        {children}
    </perfilHotelContext.Provider>
}