import styled from "styled-components";
import logo from "../images/logo22.webp";
import firmaDahiana from "../images/firmaDahiana.png";

const pc = window.screen.width > 529 ? true : false;

const TerminosCont = styled.div`
    display: flex;
    align-items: center;
    padding: 4vw;
    justify-content: center;
    padding-top: ${pc ? "6vw" :"17vw"};
    flex-direction: column;
    margin-left: ${pc && "17vw" };

    & h2 {
        color:#004aad;
        font-weight: bold;
        margin-bottom: 2vw;
        margin-top: ${!pc && "4vw"};
        font-size: ${pc ? "2vw" : "5.5vw"};
        text-align: center;
    }
    & h3 {
        color:#004aad;
        font-weight: bold;
        margin-bottom: 1vw;
        margin-top: ${pc ? "3vw" : "10vw"};
        font-size: ${pc ? "1.5vw" : "5vw"};
        margin-right: auto;
    }
    & span {
        font-weight: bold;
        font-size: ${pc ? "1.2vw" : "" };
    }
    & a {
        color: #004aad;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }
`;

const Logo = styled.img`
    width: ${pc ? "12vw" : "35vw"};
    margin-bottom: 2.5vw;
`;

export default function terminosycondiciones(){
    return (
        <TerminosCont>
            <Logo src={logo} />
            <h2>TÉRMINOS Y CONDICIONES DEL PROGRAMA</h2>
            
            <p>Los términos y condiciones establecen que Turisfreelance es una plataforma de apoyo para los agentes de viajes independientes, la cual funciona como un motor de ingresos desde casa.</p>
            <h3>AFILIACIÓN</h3>
            <p>Para participar en el programa debe ser mayor de edad, suministrarnos la foto del documento de identidad y realizar un único pago de RD$2,000 para tener acceso a las herramientas de trabajo y entrenamientos. Este pago no se reembolsa en caso de cancelar la afiliación.
            <br/><br/>Cada afiliado será un agente independiente de Turisfreelance, no será un colaborador o empleado. Nada de lo contenido en el presente tiene la intención de crear una relación colaborador(a) o entiéndase empleado(a) con Turisfreelance.
            </p>
            <h3>CLÁUSULAS</h3>
            <p>Cada agente independiente debe ejecutar sus funciones en base a las políticas de Turisfreelance y es responsable del manejo de las ventas, informaciones de sus clientes y el uso que le dé a las herramientas puestas a la disposición. <br/>
            <br/>
            Turisfreelance se reserva el derecho de cancelar la membrecía de cualquier afiliado que haga mal uso de la misma o ejecute cualquier acto que ponga en riesgo la transparencia y la imagen de Turisfreelance.
            <br/>
            <br/>
            El agente afiliado descarga a Turisfreelance de cualquier acto delictivo que cometa en nombre de la empresa y será responsable de asumir los gastos correspondientes a cualquier demanda judicial.
            </p>
            <h3>VENTAS </h3>
            <p>
            Cada afiliado  tendrá acceso a la venta de hoteles, resorts, villas, vuelos, traslados y seguros de viaje
            <br/>
            <br/>
            <span>Reservas de hoteles y villas: </span>Las ventas están sujetas a tarifas dinámicas las cuales no son garantizadas hasta confirmar la solicitud y pueden variar sin previo aviso. Para reservar, el agente debe disponer de al menos RD$1,000 por habitación y debe saldar hasta 10 días antes de la llegada, en caso contrario la reserva quedaría cancelada.
            Las reservas cuya fecha de entrada sea menor a 10 días, deben ser saldadas al momento de reservar.
            <br/>
            <br/>
            El depósito inicial de RD$1,000 por habitación forma parte del monto total de la solicitud, el mismo no es reembolsable, en caso de cancelación este monto quedaría como crédito a favor para una futura reserva. Las cancelaciones gratuitas deben solicitarse mínimo 72 horas antes de la llegada para hacer la devolución del monto total menos el depósito inicial de RD$1,000 por habitación. 
            <br/>
            <br/>
            Las cancelaciones en un periodo menor a 72 horas tienen penalidad de 1 a 2 noches. En la penalidad cobrada al cliente, no se incluirá la comisión del agente de viajes ya que dicha penalidad es cobrada por el hotel o villa. Si el cliente cancela dentro de su período de gracia, se debe emitir el reembolso total incluyendo la comisión del agente de viajes.
            <br/>
            <br/>
            Antes del agente de viajes solicitar una reserva debe consultar la disponibilidad con el equipo de soporte. 
            Las solicitudes de modificación de reservas están sujetas a cambios en la tarifa con la cual fue confirmada originalmente.
            <br/>
            <br/>
            El comprobante del pago total o de cada abono a las reservas, deben ser subidos a través de la oficina virtual.
            <br/>
            <br/>
            <span>Reservas de vuelos: </span>El agente afiliado deberá llenar un formulario para la solicitud de vuelos y estará recibiendo la cotización de manera inmediata a través de su oficina virtual. Los precios obtenidos en la cotización no son garantizados hasta recibir el pago del monto total. El comprobante de pago y la foto del pasaporte del cliente a viajar deben ser subidos en la oficina virtual en donde obtuvo la cotización del vuelo. El agente afiliado es el responsable de los datos que incluya en el formulario de solicitud de vuelo. Una vez se haya emitido el ticket aéreo, Turisfreelance no se hace responsable de los gastos que puedan existir por modificaciones, cancelaciones y/o por cualquier otro motivo que dependa del cliente, del agente afiliado o de la línea aérea.
            <br/>
            <br/>
            <span>Reservas de traslados: </span>Las tarifas de este servicio no son fijas puesto a que dependen del tipo de servicio que el cliente solicite. El agente afiliado deberá llenar un formulario para la solicitud y estará recibiendo la cotización de manera inmediata a través de su oficina virtual. Para confirmar la reserva del traslado se requiere de al menos el 10% del monto total. El pago del monto total debe realizarse 7 días antes del servicio, en caso de cancelación se cargará una penalidad del 10% en base al monto total del servicio. En la penalidad cobrada al cliente, no se incluirá la comisión del agente de viajes ya que dicha penalidad es cobrada por la empresa de transporte.
            <br/>
            <br/>
            <span>Ventas de seguros de viaje:</span>: El agente afiliado tendrá a mano las tarifas correspondientes a cada plan en conjunto con los detalles del mismo y podrá contratarlo a través de un formulario. Estos planes están sujetos a las políticas, términos y condiciones  del proveedor de seguros.
            <h3>PAGOS</h3>
            El cliente del agente afiliado deberá efectuar los pagos directamente a la cuenta empresarial de Turisfreelance a través de transferencia bancaria o con tarjeta de crédito/débito. Para los pagos con tarjeta de crédito/débito, Turisfreelance enviará al cliente un link de pago para que pueda efectuar el mismo. Este link de pago está sujeto a un porcentaje que varía entre 2% a 5%, el cual es cargado por la compañía emisora de dicho servicio.  El agente afiliado se compromete a brindar a sus clientes los precios reales sin alterarlos, ya que esto provocaría una diferencia frente a las tarifas del mercado y evitaría cerrar ventas.
            <br/>
            <br/>
            Turisfreelance es responsable de los pagos una vez estén en la cuenta bancaria de la empresa, estando estos sujetos a los términos y condiciones de la venta.
            <h3>COMISIONES</h3>
            El agente afiliado gozará de las diferentes comisiones que incluye cada  venta de servicios turísticos y de viajes. Estas comisiones serán pagadas los días 07 y 21 de cada mes después del cliente hacer uso del servicio. Si el cliente cancela el servicio dentro de su periodo de gracia, esta comisión debe ser reembolsada, ya que forma parte del monto total que ha pagado.
            <br/>
            <br/>
            Cada servicio turístico y de viajes está sujeto a las siguientes comisiones:
            <br/>
            <br/>
            <span>Reservas de hoteles: </span>8% de comisión en base al monto total de la venta. Esta comisión está incluida en los precios que se le muestra al cliente.

            <br/>
            <br/>
            <span>Reservas de villas: </span> 8% de comisión en base al monto total de la venta. Esta comisión está incluida en los precios que se le muestra al cliente.
            <br/>
            <br/>
            <span>Ventas de vuelos: </span>Hasta un 8% de comisión en base al monto total de la venta. Esta comisión está incluida en los precios que se le muestra al cliente.
            <br/>
            <br/>
            <span>Seguros de viajes: </span>10% de comisión en base al monto total de la venta. Esta comisión está incluida en los precios que se le muestra al cliente.
            <br/>
            <br/>
            <span>Reservas de traslados: </span>15% de comisión en base al monto total de la venta. Esta comisión está incluida en los precios que se le muestra al cliente.
            <h3>PREMIOS</h3>
            Los 10 agentes afiliados con mayor volumen de ventas se convierten en agente estrella de Turisfreelance. Esto les permite participar en sorteos de vacaciones y también ser elegido para Fam Trips, en donde podrán conocer diferentes hoteles hospedándose gratis durante un fin de semana o un pasadía, entre otros lugares turístico.
            <br />
            <br />
            El agente afiliado ganador del sorteo para Fam Trips estará representando a Turisfreelance en esta actividad. Estos son programas en donde los agentes de viajes son invitados a conocer uno o más hoteles durante un fin de semana totalmente gratis.
            <br />
            <br />
            Nuestros 10 agentes estrella también participarán para ganar un fin de semana gratis para 2 personas en un hotel resort. Este sorteo se estará realizando 1 ó 2 veces al año.
            <br />
            <h3>TERMINACIÓN DE AFILIACIÓN</h3>
            La participación en el Programa se encuentra sujeta a suspensión, revocación o cancelación. El agente afiliado tiene el derecho de salir en cualquier momento.
            <br />
            <br />
            La inactividad puede causar cancelaciones sin previo aviso. Cada agente afiliado con ventas inactivas durante 6 meses, pierde la afiliación y herramientas de ventas del programa. La reactivación tendrá un costo de RD$1,500 pesos dominicanos.


            <h3>CAMBIOS EN LOS TERMINOS Y CONDICIONES</h3>
            Los términos y condiciones descritos en este documento han sido establecidos por
            Turisfreelance a los 10 días del mes de Octubre del año 2022. Los mismos están sujetos
            a cambios y serán notificados a sus agentes afiliados.
            </p>
            <img style={{width:pc ? "28vw" : "70vw", marginTop:"5vw"}} src={firmaDahiana} />
            <p>Dahiana Fernández</p>
            <p>C.E.O Turisfreelance</p>
        </TerminosCont>
    )
}