import React from "react";
import { Routes, Route, BrowserRouter} from "react-router-dom";
import Inicio from "./components/inicio";
import Navbar from "./components/navbar/navbar";
import Login from "./components/login";
import Plataforma from "./components/ofertas";
import { ProviderSessionContext } from "./contexts/sessionContext";
import { HotelContextProvider } from "./contexts/perfilHotelContext";
import Hoteles from "./components/secciones/hoteles/hoteles";
import Villas from "./components/secciones/villas/villas";
import Traslados from "./components/secciones/traslados/traslados";
import Vuelos from "./components/secciones/vuelos/vuelos";
import Grupos from "./components/secciones/grupos/grupos";
import SegurosDeViajes from "./components/secciones/segurosDeViajes/segurosDeViajes";
import MisVentas from "./components/secciones/misVentas/misVentas";
import PerfilHotel from "./components/secciones/hoteles/perfilHotel";
import PerfilVilla from "./components/secciones/villas/perfilVilla";
import Pagina404 from "./components/pagina404";
import MiCuenta from "./components/secciones/miCuenta/miCuenta";
import Admin from "./components/admin/admin";
import Politicas from "./components/politicas";
import Terminos from "./components/terminosycondiciones";
import Tarifario from "./components/secciones/tarifario/tarifario";

export default function RouterComponent(){

    return (
        <ProviderSessionContext>
            <HotelContextProvider>
                <BrowserRouter>
                    <Navbar>
                        <Routes>
                            <Route path="/" element={<Inicio/>}/>
                            <Route path="/cuenta" element={<p>Cuenta</p>}/>
                            <Route path="/login" element={<Login/>} />
                            <Route path="/plataforma/ofertas" element={<Plataforma/>} />
                            <Route path="/plataforma/hoteles" element={<Hoteles/>}/>
                            <Route path="/plataforma/tarifario" element={<Tarifario/>}/>
                            <Route path="/plataforma/villas" element={<Villas/>}/>
                            <Route path="/plataforma/vuelos" element={<Vuelos/>}/>
                            <Route path="/plataforma/traslados" element={<Traslados/>}/>
                            <Route path="/plataforma/seguros-de-viajes" element={<SegurosDeViajes/>}/>
                            <Route path="/plataforma/grupos" element={<Grupos/>}/>
                            <Route path="/plataforma/mis-ventas" element={<MisVentas/>}/>
                            <Route path="/plataforma/perfil" element={<MiCuenta/>}/>
                            <Route path="/hotel/:name" element={<PerfilHotel/>}/>
                            <Route path="/villa/:name" element={<PerfilVilla/>}/>
                            <Route path="/admin" element={<Admin/>} />
                            <Route path="/politicas" element={<Politicas/>} />
                            <Route path="/terminos-y-condiciones" element={<Terminos/>} />

                            <Route path="*" element={<Pagina404 marginTop={true}/>}/>
                        </Routes> 
                    </Navbar>
                </BrowserRouter>
            </HotelContextProvider>
        </ProviderSessionContext>
    )
}