export function restaFechas(f1,f2){
    let aFecha1 = f1.split('/');
    let aFecha2 = f2.split('/');
    let fFecha1 = Date.UTC(aFecha1[2],aFecha1[1]-1,aFecha1[0]);
    let fFecha2 = Date.UTC(aFecha2[2],aFecha2[1]-1,aFecha2[0]);
    let dif = fFecha2 - fFecha1;
    let dias = Math.floor(dif / (1000 * 60 * 60 * 24));

    return dias;    
}

export function cambiarFechaInput (fechaOld){
    let fecha = "";
    let dia = fechaOld[0];
    let mes = "";
    let año = "";
    if (fechaOld[1] != "/") {
        dia = dia+fechaOld[1];
    }

    if (dia.length > 1) {
        mes = fechaOld[3];
        if (fechaOld[4] != "/") {
            mes = mes+fechaOld[4];
        }
    } else {
        mes = fechaOld[2];
        if (fechaOld[3] != "/") {
            mes = mes+fechaOld[3];
        }
    };

    if (dia.length > 1 && mes.length > 1) {
        año = fechaOld[6] + fechaOld[7] + fechaOld[8] + fechaOld[9];
    } else if (dia.length > 1 || mes.length > 1) {
        año = fechaOld[5] + fechaOld[6] + fechaOld[7] + fechaOld[8];
        if (dia.length < 2) {
            dia = 0 + dia;
        }else if (mes.length < 2){
            mes = 0 + mes;
        }
    } else {
        año = fechaOld[4] + fechaOld[5] + fechaOld[6] + fechaOld[7];
        dia = 0 + dia;
        mes = 0 + mes;
    };

    fecha = año+"-"+mes+"-"+dia;

    return fecha;
}

export function validarFecha (fecha){
    let result = true;
    let dia = "";
    let mes = "";
    let año = "";
    const fechaActual = new Date;
            
    if (fecha.length > 3 && fecha.length < 11) {
        let dobleNum = [false, false];
    
        if (fecha.charAt(0) == 0) {
            result = "El dia no puede comenzar con cero.";
        }
        if (fecha.charAt(1) != "/") {
            dobleNum[0] = true;
            dia = fecha.charAt(0) + fecha.charAt(1);
        } else {
            dia = fecha.charAt(0);
        }
        
        if (dobleNum[0] == true) {
            if (fecha.charAt(4) != "/") {
                dobleNum[1] = true;
                mes = fecha.charAt(3) + fecha.charAt(4);
            } else {
                mes = fecha.charAt(3);
            }
            if (fecha.charAt(3) == 0) {
                result = "El mes no puede comenzar con cero.";
            }
        } else {
            if (fecha.charAt(3) != "/") {
                dobleNum[1] = true;
                mes = fecha.charAt(2) + fecha.charAt(3);
            } else {
                mes = fecha.charAt(2);
            }
            if (fecha.charAt(2) == 0) {
                result = "El mes no puede comenzar con cero.";
            }
        }

        if (dobleNum[0] && dobleNum[1]) {
            if (fecha.charAt(6) && fecha.charAt(7) && fecha.charAt(8) && fecha.charAt(9)) {
                año = fecha.charAt(6) + fecha.charAt(7) + fecha.charAt(8) + fecha.charAt(9);
                if (fecha.length > 10) {
                    result = "El año es incorrecto.";
                }
            } else {
                result = "El año debe tener 4 cifras";
            }
        } else {
            if (!dobleNum[0] && !dobleNum[1]) {
                if (fecha.charAt(4) && fecha.charAt(5) && fecha.charAt(6) && fecha.charAt(7)) {
                    año = fecha.charAt(4) + fecha.charAt(5) + fecha.charAt(6) + fecha.charAt(7);
                    if (fecha.length > 8) {
                        result = "El año es incorrecto.";
                    }
                } else {
                    result = "El año debe tener 4 cifras";
                }
            } else if (dobleNum[0] || dobleNum[1]) {
                if (fecha.charAt(5) && fecha.charAt(6) && fecha.charAt(7) && fecha.charAt(8)) {
                    año = fecha.charAt(5) + fecha.charAt(6) + fecha.charAt(7) + fecha.charAt(8);
                    if (fecha.length > 9) {
                        result = "El año es incorrecto.";
                    }
                } else {
                    result = "El año debe tener 4 cifras";
                }  
            }
            
        }

        if (año.length == 4 && result == true) {
            if (dia > 0 && dia < 32) {
                if (mes > 0 && mes < 13) {
                    if (año == fechaActual.getFullYear()){
                        if (mes == fechaActual.getMonth()+1) {
                            if (dia < fechaActual.getDate()) {
                                result = "El dia que ingresaste ya paso.";
                            }
                        } else if (mes < fechaActual.getMonth()+1) {
                            result = "El mes que ingresaste ya paso."
                        }
                    } else if ( año < fechaActual.getFullYear()) {
                        result = "El año que ingresaste ya paso.";
                    }
                } else {
                    result = "El mes que ingresaste es incorrecto.";
                }
            } else {
                result = "El dia que ingresaste es incorrecto.";
            }
        }

        for (let i = 0; i < fecha.length; i++){
        if (fecha.charAt(i) != "/") {
           if (!isNum(fecha.charAt(i))) {
               result = "Solo se admiten numeros.";
           }
        }
    } 

    } else {
        result = "El formato de la fecha es incorrecto.";
    }

    return result;
}

export function cambiarFecha (fecha){
    let newFecha = fecha.split("-");

    if (newFecha.length > 1) {
        if (newFecha[1].charAt(0) == 0) {
            newFecha[1] = newFecha[1].charAt(1);
        }
        if (newFecha[2].charAt(0) == 0) {
            newFecha[2] = newFecha[2].charAt(1);
        }
    
        newFecha = newFecha[2] + "/" + newFecha[1] + "/" + newFecha[0];
    } else {
        newFecha = "";
    }

    return newFecha;
}

export function isNum (num) {
    let result = true;
    if (!isNaN(num)) {
        const arrayNum = num.toString().split("");
        arrayNum.map((value)=>{
            if (value == "+" || value == "-" || value === ".") {
                result = false;
            }

        })

    } else {
        result = false;
    }

    return result;
}

export function calcularTotal (form1, tarifas, precios){
    let index1 = "";
    let total = 0;
    const form = JSON.parse(JSON.stringify(form1));
    const info = {precioAdulto:[], precioNiño:[], niñoGratis:"", dias:0};

    try {
        
        form.forEach((value, index)=>{
            let valueInput = value.msgValidacion ? value.msgValidacion : value;
            const dias = restaFechas(valueInput.fechaEntrada[0], valueInput.fechaSalida[0]);
            
            if (valueInput.adultos) {
                valueInput.cantidadAdultos = [valueInput.adultos[0]];
                delete valueInput.adultos;
            }

            if (valueInput.cantidadAdultos[0] > 0) {
                const codigoTarifa = value.msgValidacion ? valueInput.codigoTarifa[0] : valueInput.codigoTarifa;
                index1 = tarifas.findIndex(e => e.codigoOferta == codigoTarifa);

                const diasSemana = [];
                const campoOferta = valueInput.cantidadAdultos[0] == 1 ? "SGL" : valueInput.cantidadAdultos[0] == 2 ? "DBL" : "TPL";
                const indexOfertaTodos = tarifas[index1].ofertas.findIndex(e => e.dias[0] == "todos");

                let valorCantPersonas = 0;
                let valorCantNiños = 0;

                if (tarifas && dias > 0) {        
                    let fechaPrimerDia = cambiarFechaInput(valueInput.fechaEntrada[0]);
                    fechaPrimerDia = new Date(fechaPrimerDia[5]+fechaPrimerDia[6]+"/"+fechaPrimerDia[8]+fechaPrimerDia[9]+"/"+fechaPrimerDia[0]+fechaPrimerDia[1]+fechaPrimerDia[2]+fechaPrimerDia[3]);

                    const diasDeLaSemana = ["domingo", "lunes", "martes", "miercoles", "jueves", "viernes", "sabado"];
                    for (let i = 0; i < dias; i++) {
                        diasSemana[i] = diasDeLaSemana[fechaPrimerDia.getDay()];
                        fechaPrimerDia.setDate(fechaPrimerDia.getDate()+1);
                    };

                    if (index == 0) {
                        info.dias = diasSemana.length;
                    }

                    diasSemana.map((dia)=>{
                        let noHayDia = false;
                        tarifas[index1].ofertas.map((oferta, index)=>{
                            oferta.dias.map((diaOferta)=>{
                                if (diaOferta == dia) {
                                    valorCantPersonas = parseInt(valorCantPersonas) + parseInt(oferta.datos[campoOferta] * (campoOferta == "SGL" ? 1 : campoOferta == "DBL" ? 2 : campoOferta == "TPL" && 3));
                                    valorCantNiños = valorCantNiños + parseInt(oferta.datos.niños*valueInput.niños[0]);
                                    noHayDia = true;
                                    if (valueInput.niños[0] > 0) {
                                        if (tarifas[index1].niñoGratis == 1) {
                                            valorCantNiños = parseInt(valorCantNiños)-parseInt(oferta.datos.niños);
                                        };
                                        if (tarifas[index1].niñoGratis == 2) {
                                            if (valueInput.niños[0] > 1) {
                                                valorCantNiños = parseInt(valorCantNiños)-parseInt(oferta.datos.niños);
                                            };
                                        };
                                   }
                                };
                            });
                        });
                        if (!noHayDia) {
                            valorCantPersonas = parseInt(valorCantPersonas) + parseInt(tarifas[index1].ofertas[indexOfertaTodos].datos[campoOferta] * (campoOferta == "SGL" ? 1 : campoOferta == "DBL" ? 2 : campoOferta == "TPL" && 3));
                            valorCantNiños = valorCantNiños + parseInt(tarifas[index1].ofertas[indexOfertaTodos].datos.niños*valueInput.niños[0]);
                            if (valueInput.niños[0] > 0) {
                                if (tarifas[index1].niñoGratis == 1) {
                                    valorCantNiños = parseInt(valorCantNiños)-parseInt(tarifas[index1].ofertas[indexOfertaTodos].datos.niños);
                                };
                                if (tarifas[index1].niñoGratis == 2) {
                                    if (valueInput.niños[0] > 1) {
                                        valorCantNiños = parseInt(valorCantNiños)-parseInt(tarifas[index1].ofertas[indexOfertaTodos].datos.niños);
                                    };
                                };
                           }
                        };
                    });
                }


                info.precioAdulto.push(valorCantPersonas/dias/valueInput.cantidadAdultos[0]);
                valueInput.niños[0] > 0 && info.precioNiño.push(valorCantNiños/dias/valueInput.niños[0]);
                info.niñoGratis = tarifas[index1].niñoGratis == 0 ? "Ningun" : tarifas[index1].niñoGratis == 1 ? "Primer" : "Segundo";

                total = total + valorCantPersonas + valorCantNiños;
                
                /* if (dias < tarifas[index1].minimoNoches) {
                    total = 0;
                } */
            };
        });
    } catch (e) {
        
    }
    
    if (isNaN(total)) {
        total = 0;
    }

    if (precios) {
        return [total, info];
    } else {
        return total;
    }
}

export function calcularTotalVilla (formProp, tarifas, cotizacion){
    let total = 0;

    let form = "";
    if (cotizacion) {
        form = formProp[0];
    } else {
        form = formProp[0].msgValidacion;
    }

    const rangoPersonas = form.rangoPersonas[0];
    const fechaEntrada = form.fechaEntrada[0];
    const fechaSalida = form.fechaSalida[0];
    const codigoTarifa = cotizacion ? form.codigoTarifa : form.codigoTarifa[0];

    if (validarFecha(fechaEntrada) == true && validarFecha(fechaSalida) == true) {
        tarifas.map((tarifa)=>{
            if (tarifa.codigoOferta == codigoTarifa) {
                tarifa.rangos.map((rango, index)=>{
                    if (rangoPersonas == rango.rangoMin+"-"+rango.rangoMax) {
                        const diasSemana = [];
                        const dias = restaFechas(fechaEntrada, fechaSalida);
                        const indexDiaTodos = rango.rangos.findIndex(e => e.dias[0] == "todos"); 

                        let fechaPrimerDia = cambiarFechaInput(fechaEntrada);
                        fechaPrimerDia = new Date(fechaPrimerDia[5]+fechaPrimerDia[6]+"/"+fechaPrimerDia[8]+fechaPrimerDia[9]+"/"+fechaPrimerDia[0]+fechaPrimerDia[1]+fechaPrimerDia[2]+fechaPrimerDia[3]);

                        const diasDeLaSemana = ["domingo", "lunes", "martes", "miercoles", "jueves", "viernes", "sabado"];
                        for (let i = 0; i < dias; i++) {
                            diasSemana[i] = diasDeLaSemana[fechaPrimerDia.getDay()];
                            fechaPrimerDia.setDate(fechaPrimerDia.getDate()+1);
                        };

                        diasSemana.map((dia)=>{
                            let noHayDia = true;
                            rango.rangos.map((rangoDia)=>{
                                rangoDia.dias.map((diaOferta)=>{
                                    if (dia == diaOferta) {
                                        total = parseInt(total) + parseInt(rangoDia.precio); 
                                        noHayDia = false;
                                    };
                                });
                            });
                            if (noHayDia) {
                                if (indexDiaTodos > -1) {
                                    total = parseInt(total) + parseInt(rango.rangos[indexDiaTodos].precio);
                                };
                            };
                        });
                    };
                });
            };
        });
    };

    if (isNaN(total)) {
        total = 0;
    }

    return total;
}